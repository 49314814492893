import React from "react";

import { getCurrencySymbol } from "asos-web-currency-formatter";

import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";

import Icon from "@components/picture-icon";
import { Translation } from "@components/translation";

import { giftVoucher } from "@configuration/voucher-types";

import styles from "./index.css";

export interface VoucherDetailsProps {
  countryName?: string;
  currency: string;
  maskedVoucherCode: string;
  openRestrictionModal: () => void;
  voucherType: string;
}

const VoucherDetails = ({
  countryName = "",
  currency,
  maskedVoucherCode,
  openRestrictionModal,
  voucherType,
}: VoucherDetailsProps) => {
  const currencySymbol = getCurrencySymbol(currency);

  const voucherRestructionMessageId = `voucher-restriction-message-${maskedVoucherCode}`;
  const voucherRestrictionMessage =
    voucherType === giftVoucher
      ? "ma_web_giftcardsandvouchers_voucherrestrictionmessage"
      : "ma_web_giftcardsandvouchers_cardrestrictionmessage";

  return (
    <div
      className={styles.notificationWrapper}
      data-auto-id="voucher-restriction-warning-message"
    >
      <div className={styles.lineBreak}>
        <HorizontalRule />
      </div>
      <div className={styles.voucherRestriction}>
        <span className={styles.voucherRestrictionMessageIcon}>
          <Icon id="info" size="small" />
        </span>
        <div
          id={voucherRestructionMessageId}
          className={styles.voucherRestrictionMessage}
        >
          <Translation
            id={voucherRestrictionMessage}
            values={{
              country: countryName,
              currency:
                currency === currencySymbol
                  ? `${currency}`
                  : `${currencySymbol} ${currency}`,
            }}
          />
        </div>{" "}
        <button
          className={styles.voucherRestrictionLink}
          aria-describedby={voucherRestructionMessageId}
          onClick={openRestrictionModal}
        >
          <Translation id="ma_web_giftcardsandvouchers_restrictionslink" />
        </button>
      </div>
    </div>
  );
};

export default VoucherDetails;
