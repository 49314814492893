import { createAction } from "redux-actions";

import config from "@appConfig";

import { requestNotification } from "../application/actions";
import * as api from "./api";
import {
  getIfLinkedAccountsLoaded,
  getCurrentLoggedInProvider,
} from "./selectors";
import * as types from "./types";
const providers = {
  facebook: "Facebook",
  googleplus: "Google+",
  apple: "Apple",
};

export const receiveLinkedAccounts = createAction(
  types.RECEIVE_LINKED_ACCOUNTS
);
export const requestLinkedAccounts = createAction(
  types.REQUEST_LINKED_ACCOUNTS
);

export const requestConnectLinkAccount = createAction(
  types.REQUEST_CONNECT_LINKED_ACCOUNT
);

export const receiveDisconnectLinkedAccount = createAction(
  types.RECEIVE_DISCONNECT_LINKED_ACCOUNT
);
export const requestDisconnectLinkedAccount = createAction(
  types.REQUEST_DISCONNECT_LINKED_ACCOUNT
);

export const receiveCurrentLoggedInProvider = createAction(
  types.RECEIVE_CURRENT_LOGGED_IN_PROVIDER
);

export const apiError = createAction(types.API_ERROR);

export const attemptUnlinkCurrentLoginProvider = createAction(
  types.ATTEMPT_UNLINK_CURRENT_LOGIN_PROVIDER
);

const handleConnectLinkedAccountResponse = (
  dispatch,
  connectLinkedAccountResponse,
  linkedAccounts
) => {
  const connectResult = connectLinkedAccountResponse.result;

  const throwConnectLinkedAccountError = () => {
    const error = new Error("Failed to connect to linked account");
    error.providerToLink = connectLinkedAccountResponse.provider;
    throw error;
  };

  const connectedProvider = connectLinkedAccountResponse.provider;
  if (connectResult !== "success") {
    throwConnectLinkedAccountError();
  }

  const hasLinkedAccount = linkedAccounts.find(
    (linkedAccount) => linkedAccount.provider == connectedProvider
  );
  if (hasLinkedAccount) {
    const providerName = providers[connectedProvider];
    return dispatch(
      requestNotification("success", "ma_web_socialconnect_provideradded", {
        provider: providerName,
      })
    );
  }
  throwConnectLinkedAccountError();
};

export const getLinkedAccounts =
  (connectLinkedAccountResponse, getLinkedAccountsFn = api.getLinkedAccounts) =>
  async (dispatch, getState, identity) => {
    const state = getState();
    if (getIfLinkedAccountsLoaded(state)) {
      return null;
    }

    dispatch(requestLinkedAccounts());

    try {
      const response = await getLinkedAccountsFn(identity);
      if (connectLinkedAccountResponse.result) {
        handleConnectLinkedAccountResponse(
          dispatch,
          connectLinkedAccountResponse,
          response.linkedAccounts
        );
      }

      return dispatch(receiveLinkedAccounts(response));
    } catch (err) {
      return dispatch(receiveLinkedAccounts(err));
    }
  };

export const connectLinkedAccount = (provider) => async (dispatch) => {
  dispatch(requestConnectLinkAccount());

  window.location = `${
    config.authorityUri
  }identity/social/connect/${provider.toLowerCase()}?returnUrl=https://${
    window.location.hostname
  }/my-account/social-accounts`;
};

export const disconnectLinkedAccount =
  (provider, disconnectLinkedAccountFn = api.disconnectLinkedAccount) =>
  async (dispatch, getState, identity) => {
    const providerName = providers[provider];
    dispatch(requestDisconnectLinkedAccount());

    try {
      const state = getState();

      // TODO: The following check is a safe guard but needs to be replaced with WD-24318
      // once it is made available
      const currentLoggedInProvider = getCurrentLoggedInProvider(state);

      const disconnectingLoginProviderIsCurrentLoggedInProvider =
        currentLoggedInProvider === provider;
      if (disconnectingLoginProviderIsCurrentLoggedInProvider) {
        return dispatch(attemptUnlinkCurrentLoginProvider());
      }

      const response = await disconnectLinkedAccountFn(identity, provider);
      dispatch(
        receiveDisconnectLinkedAccount({
          ...response,
          ...{ unlinkedProvider: provider },
        })
      );

      return dispatch(
        requestNotification("success", "ma_web_socialconnect_providerremoved", {
          provider: providerName,
        })
      );
    } catch (err) {
      err.provider = provider;
      dispatch(receiveDisconnectLinkedAccount(err));
      return dispatch(apiError(err));
    }
  };
