import React from "react";

import { SmartLink as SmartLinkComponent } from "@asosteam/asos-web-component-library-smart-link";

import { useFormatUrl } from "@hooks/useFormatUrl";

export const SmartLink = (props) => {
  const formatUrl = useFormatUrl();

  return <SmartLinkComponent {...props} formatUrl={formatUrl} />;
};
