import isAbsoluteUrl from "@utils/is-absolute-url";

import { buildHeaders } from "./headers";
import { buildUri } from "./uri";

export type RequestOptions = {
  headers?: Record<string, string>;
  method?: "GET" | "POST" | "DELETE" | "PATCH" | "PUT";
  body?: string;
  query?: Record<string, unknown>;
};

export const buildRequestComponents = async (
  path: string,
  options: RequestOptions,
  authHeader: Record<string, string> | null = {},
  customerId: string | null,
  customerGuid: string | null
) => {
  const isThirdParty = isAbsoluteUrl(path);
  const method = options.method ?? "GET";

  const headers = buildHeaders(options.headers, authHeader, isThirdParty);

  const url = buildUri(
    path,
    options.query,
    customerId,
    customerGuid,
    isThirdParty,
    method === "GET"
  );

  return {
    url,
    method,
    headers,
    isThirdParty,
  };
};
