import { createAction } from "redux-actions";
import { hasSubmitSucceeded, isSubmitting, submit } from "redux-form";

import { notifyAppReady as monitoringNotifyAppReady } from "@utils/monitoring";

import {
  APP_STARTUP_ERROR,
  CLEAR_API_ERRORS,
  DISMISS_NOTIFICATION,
  FINISH_STARTUP_LOADING,
  FINISH_USER_LEAVING,
  PAGE_LOAD_COMPLETE,
  REQUEST_NOTIFICATION,
  START_USER_LEAVING,
  RESET_MENU_CLICKED,
  SET_MENU_CLICKED,
} from "./types";

export const appStartupError = createAction(APP_STARTUP_ERROR);
export const finishStartupLoading = createAction(FINISH_STARTUP_LOADING);
export const dismissNotification = createAction(DISMISS_NOTIFICATION);
export const requestNotification = createAction(
  REQUEST_NOTIFICATION,
  (type, textKey, textValues = {}) => ({
    type,
    textKey,
    textValues,
  })
);
export const startUserLeaving = createAction(
  START_USER_LEAVING,
  null,
  (callback) => ({ callback })
);
export const finishUserLeaving = createAction(FINISH_USER_LEAVING);

export const pollForm = (formName) => (dispatch, getState) =>
  new Promise((resolve) => {
    const poll = () => {
      setTimeout(
        () => (isSubmitting(formName)(getState()) ? poll() : resolve()),
        100
      );
    };
    poll();
  });

export const submissionSucceeded = (formName) => (dispatch, getState) =>
  hasSubmitSucceeded(formName)(getState());

export const saveFormAndExitModal =
  (
    formName,
    pollFormFn = pollForm,
    submissionSuccededFn = submissionSucceeded
  ) =>
  (dispatch) => {
    dispatch(submit(formName));

    dispatch(pollFormFn(formName)).then(() => {
      if (dispatch(submissionSuccededFn(formName))) {
        dispatch(finishUserLeaving(true));
      }
    });
  };

export const clearApiErrors = createAction(CLEAR_API_ERRORS);

export const notifyFinishStartupLoading =
  (monitoringNotifyAppReadyFn = monitoringNotifyAppReady) =>
  (dispatch) => {
    monitoringNotifyAppReadyFn();
    return dispatch(finishStartupLoading());
  };

export const pageLoadComplete = createAction(PAGE_LOAD_COMPLETE);
export const setMenuClicked = createAction(SET_MENU_CLICKED);
export const resetMenuClicked = createAction(RESET_MENU_CLICKED);

export const trackPageLoadComplete = (pageLoadEventData) => (dispatch) =>
  dispatch(pageLoadComplete(pageLoadEventData));
