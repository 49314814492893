// Errors
export const ADD_ADDRESS_BOOK_ERROR = "ADD_ADDRESS_BOOK_ERROR";

// Get addresses
export const RECEIVE_ADDRESSES = "RECEIVE_ADDRESSES";
export const REQUEST_ADDRESSES = "REQUEST_ADDRESSES";

// Add addresses
export const REQUEST_ADD_ADDRESS = "REQUEST_ADD_ADDRESS";
export const RECEIVE_ADD_ADDRESS = "RECEIVE_ADD_ADDRESS";

// Edit address
export const REQUEST_EDIT_ADDRESS = "REQUEST_EDIT_ADDRESS";
export const RECEIVE_EDIT_ADDRESS = "RECEIVE_EDIT_ADDRESS";

// Change default address
export const CONFIRM_CHANGE_DEFAULT_ADDRESS = "CONFIRM_CHANGE_DEFAULT_ADDRESS";
export const REQUEST_CHANGE_DEFAULT_ADDRESS = "REQUEST_CHANGE_DEFAULT_ADDRESS";

// Find address
export const REQUEST_FIND_ADDRESS = "REQUEST_FIND_ADDRESS";
export const RECEIVE_FIND_ADDRESS = "RECEIVE_FIND_ADDRESS";
export const REMOVE_CRUMB = "REMOVE_CRUMB";
export const ADD_CRUMB = "ADD_CRUMB";
export const CLEAR_BREAD_CRUMBS = "CLEAR_BREAD_CRUMBS";

// Retrieve Address
export const REQUEST_RETRIEVE_ADDRESS = "REQUEST_RETRIEVE_ADDRESS";
export const RECEIVE_RETRIEVE_ADDRESS = "RECEIVE_RETRIEVE_ADDRESS";

// Delete address
export const REQUEST_DELETE_ADDRESS = "REQUEST_DELETE_ADDRESS";
export const CONFIRM_DELETE_ADDRESS = "CONFIRM_DELETE_ADDRESS";
export const REQUIRE_DELETE_ADDRESS_ACCEPTANCE =
  "REQUIRE_DELETE_ADDRESS_ACCEPTANCE";
export const CONFIRM_DELETE_ADDRESS_ACCEPTANCE =
  "CONFIRM_DELETE_ADDRESS_ACCEPTANCE";
export const DENY_DELETE_ADDRESS_ACCEPTANCE = "DENY_DELETE_ADDRESS_ACCEPTANCE";
export const CANCEL_DELETE_ADDRESS_ACCEPTANCE =
  "CANCEL_DELETE_ADDRESS_ACCEPTANCE";
export const ADD_ADDRESS_BOOK_WARNING = "ADD_ADDRESS_BOOK_WARNING";

export const UNLOAD_ADDRESSES = "UNLOAD_ADDRESSES";

export const DEFAULT_BILLING_ADDRESS_UPDATED =
  "DEFAULT_BILLING_ADDRESS_UPDATED";

export const SHOW_MANUAL_ADDRESS_FIELDS = "SHOW_MANUAL_ADDRESS_FIELDS";

export const API_ERROR = "API_ADDRESSES_ERROR";

export const RESET_ADDRESS_FINDER_SEARCH = "RESET_ADDRESS_FINDER_SEARCH";
