import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./index.css";

export const LoadingRectanglePlaceholder = () => (
  <div className={classnames(styles.placeholder, styles.rectanglePlaceholder)}>
    &nbsp;
  </div>
);

export const LoadingCirclePlaceholder = ({ small }) => (
  <div
    className={classnames({
      [styles.placeholder]: true,
      [styles.circlePlaceholder]: true,
      [styles.small]: small,
    })}
  >
    &nbsp;
  </div>
);

LoadingCirclePlaceholder.defaultProps = {
  small: false,
};

LoadingCirclePlaceholder.propTypes = {
  small: PropTypes.bool,
};
