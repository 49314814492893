import React from "react";

import {
  LoadingRectanglePlaceholder,
  LoadingCirclePlaceholder,
} from "../placeholders";

import styles from "./index.css";

const LoadingNavItem = () => (
  <li className={styles.item}>
    <div className={styles.iconWrapper}>
      <LoadingCirclePlaceholder small />
    </div>
    <LoadingRectanglePlaceholder />
  </li>
);

const LoadingNavigation = () => (
  <div>
    <div className={styles.greeting}>
      <div className={styles.headerImage} />
      <div className={styles.picAndName}>
        <div className={styles.profilePic}>
          <LoadingCirclePlaceholder />
        </div>
        <div className={styles.names}>
          <div className={styles.hi}>
            <LoadingRectanglePlaceholder />
          </div>
          <div className={styles.name}>
            <LoadingRectanglePlaceholder />
          </div>
        </div>
      </div>
    </div>
    <ul className={styles.loadingNavItemList}>
      <LoadingNavItem />
      <LoadingNavItem />
      <LoadingNavItem />
    </ul>
    <ul className={styles.loadingNavItemList}>
      <LoadingNavItem />
    </ul>
    <ul className={styles.loadingNavItemList}>
      <LoadingNavItem />
      <LoadingNavItem />
      <LoadingNavItem />
      <LoadingNavItem />
      <LoadingNavItem />
      <LoadingNavItem />
    </ul>
    <ul className={styles.loadingNavItemList}>
      <LoadingNavItem />
    </ul>
  </div>
);

export default LoadingNavigation;
