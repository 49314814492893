import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { Heading } from "@asosteam/asos-web-component-library-heading";
import { LEAVESDEN_1 } from "@asosteam/asos-web-component-library-typography";

import { Translation } from "@components/translation";

import ProfilePicture from "./profile-picture";

import styles from "./component.css";

const CHAR_LIMIT = 50;
const ELLIPSIS = "\u2026";
const clampEnd = CHAR_LIMIT - ELLIPSIS.length;

const clampString = (string) => [
  string.slice(0, clampEnd),
  string.slice(clampEnd, string.length),
];

const NavigationGreeting = ({ firstName, lastName, appLoaded }) => {
  const name = `${firstName} ${lastName}`;
  const [clampedName, restOfName] = clampString(name);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerImage} />
      <div
        className={classnames(styles.lowerCard, { [styles.loaded]: appLoaded })}
      >
        <ProfilePicture firstName={firstName} lastName={lastName} />
        <div className={styles.greeting}>
          <Heading
            domElement="h2"
            className={styles.textContainer}
            sizing={LEAVESDEN_1}
          >
            <div className={styles.greetingText}>
              <Translation id="ma_web_navigation_greeting" />
            </div>
            <div className={styles.names} aria-label={name}>
              <span>{clampedName}</span>
              {restOfName && (
                <span className={styles.ellipsis}>{ELLIPSIS}</span>
              )}
              {restOfName && (
                <span className={styles.restOfName}>{restOfName}</span>
              )}
            </div>
          </Heading>
        </div>
      </div>
    </div>
  );
};

NavigationGreeting.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  appLoaded: PropTypes.bool.isRequired,
};

export default NavigationGreeting;
