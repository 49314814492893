import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../error";
import PremierDelivery from "./premier-delivery";

const Premier = () => (
  <Routes>
    <Route index element={<PremierDelivery />} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export default Premier;
