import { getVisitedPages } from "@state/application/selectors";
import { ROUTER_LOCATION_CHANGE } from "@state/application/types";

export default (scrollTo) => (store) => (next) => (action) => {
  if (action.type === ROUTER_LOCATION_CHANGE) {
    const state = store.getState();
    const visitedPages = getVisitedPages ? getVisitedPages(state) : [];

    if (visitedPages && action.payload.location.key !== "default") {
      const isNewPage = !visitedPages.includes(action.payload.location.key);

      if (isNewPage) {
        scrollTo(0, 0);
      }
    }
  }

  return next(action);
};
