import React from "react";
import { Link } from "react-router-dom";

import { Translation } from "@components/translation";

import styles from "./index.css";

export type ErrorProps = {
  "data-auto-id"?: string;
  id: string;
  values?: Record<any, any>;
  type: string;
  link?: Record<string, string>;
};

const Error: React.FC<ErrorProps> = ({
  type,
  id,
  values = undefined,
  link,
  ...props
}) => {
  const autoId = props["data-auto-id"] || undefined;

  return (
    <div
      className={styles.error}
      id={id}
      data-auto-id={autoId}
      aria-describedby={id}
    >
      <Translation id={type} values={values} />
      {link && (
        <Link to={link.url} className={styles.errorLink}>
          <Translation id={link.id} values={values} />
        </Link>
      )}
    </div>
  );
};

export default Error;
