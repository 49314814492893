import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { useParams } from "react-router-dom";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import {
  acceptDeleteAddress,
  attemptDeleteAddress,
  cancelDeleteAddress,
  getAddresses,
} from "@state/addresses/actions";
import * as addressesSelectors from "@state/addresses/selectors";
import { getCountries } from "@state/countries/actions";
import * as countrySelectors from "@state/countries/selectors";

import DeleteAddressModal from "../address-book/delete-address-modal";

const loadContent = () =>
  import(/* webpackChunkName: 'address-form' */ "../address-form");

const EditAddress = () => {
  const { bundle: AddressForm } = useBundle(loadContent);
  const dispatch = useDispatch();

  const acceptDeleteAddressCall = () =>
    dispatch(acceptDeleteAddress("deleteAddress"));
  const attemptDeleteAddressCall = (id) => dispatch(attemptDeleteAddress(id));
  const cancelDeleteAddressCall = () => dispatch(cancelDeleteAddress());

  useEffect(() => {
    dispatch(getAddresses);
    dispatch(getCountries);
  }, []);

  const { id } = useParams();

  const {
    addressBook,
    editAddressApiError,
    deleteAddressApiError,
    deletedAddress,
    fatalError,
    formInitialValues,
    loaded,
    requireDeleteAcceptance,
    savedAddress,
  } = useSelector((state: any) => {
    return {
      addressBook: addressesSelectors.getAddressBook(state),
      editAddressApiError: state.addresses.apiErrors.editAddress,
      deleteAddressApiError: state.addresses.apiErrors.deleteAddress,
      deletedAddress: state.addresses.deletedAddress,
      fatalError: addressesSelectors.getHasError(state),
      formInitialValues: addressesSelectors.getAddressFromId(state, id),
      loaded:
        countrySelectors.hasLoaded(state) &&
        addressesSelectors.hasLoaded(state),
      requireDeleteAcceptance: state.addresses.requireDeleteAcceptance,
      savedAddress: addressesSelectors.getHasSavedAddress(state),
    };
  });

  if (savedAddress || deletedAddress) {
    return <Navigate to="/addresses" />;
  }

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageContainer>
          <PageHeader
            dataAutoId="EditAddressHeader"
            description="ma_web_addressbook_editaddress_description"
            icon="addresses"
            previousPageLink="/addresses"
            title="ma_web_addressbook_editaddress_title"
            type="secondary"
          />
        </PageContainer>
      }
      pageId="edit-address"
      pageTitleKey="ma_web_addressbook_editaddress_title"
      showErrorSection={fatalError}
    >
      {AddressForm && (
        <AddressForm
          addressBook={addressBook}
          attemptDeleteAddress={attemptDeleteAddressCall}
          editAddressApiError={editAddressApiError}
          deleteAddressApiError={deleteAddressApiError}
          formType="edit"
          initialValues={formInitialValues}
          loaded={loaded}
        />
      )}
      <DeleteAddressModal
        isOpen={requireDeleteAcceptance}
        onAccept={acceptDeleteAddressCall}
        onCancel={cancelDeleteAddressCall}
      />
    </Page>
  );
};

export default EditAddress;
