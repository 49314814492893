import React from "react";
import { useSelector } from "react-redux";

import PageContainer from "@components/layout/page-container";
import Loading, { LoadingContainer } from "@components/loading";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";
import { useUrlPremierRedirects } from "@hooks/useUrlPremierRedirects";

import {
  getPremierIsRedirecting,
  getFatalError as getFatalErrorPremier,
  hasPremierSubscription,
  hasLoaded as hasPremierSubscriptionsLoaded,
} from "@state/premier/selectors";
import {
  getFatalError as getFatalErrorSubscription,
  hasLoaded as getSubscriptionOptionsLoaded,
} from "@state/subscription-options/selectors";

import AsosPremierBlack from "./premier_4-point-black.png";
import AsosPremierColoured from "./premier_4-point-color.png";

const loadContent = () =>
  import(/* webpackChunkName: 'premier' */ "./component");

const PremierDelivery = () => {
  const { bundle: Content } = useBundle(loadContent);
  const pageNameKey = "ma_web_premierdelivery_title";
  const { isActive, isRedirecting, fatalError, loaded } = useSelector(
    (state) => ({
      isRedirecting: getPremierIsRedirecting(state),
      isActive: hasPremierSubscription(state),
      fatalError:
        getFatalErrorSubscription(state) || getFatalErrorPremier(state),
      loaded:
        getSubscriptionOptionsLoaded(state) &&
        hasPremierSubscriptionsLoaded(state),
    })
  );

  const { redirectionInProgress } = useUrlPremierRedirects();
  const contentLoaded = Content && loaded;

  if (redirectionInProgress) {
    return (
      <LoadingContainer fullScreen>{!loaded && <Loading />}</LoadingContainer>
    );
  }

  return (
    <Page
      loaded={loaded && !isRedirecting}
      pageHeader={
        <PageContainer>
          <PageHeader
            detached={isActive}
            hasBgImage={isActive}
            title={pageNameKey}
            hasBottomPadding
            headingImage={{
              src: isActive ? AsosPremierBlack : AsosPremierColoured,
              alt: "Asos Premier",
            }}
            isPremierPage
          />
        </PageContainer>
      }
      pageId="premier-delivery"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError}
    >
      {contentLoaded && !isRedirecting && <Content />}
    </Page>
  );
};

export default PremierDelivery;
