import Cookie from "js-cookie";
import { parse as parseUrl } from "url";

const TRACKING_DEBUG_PARAM_NAME = "analytics";

export const setAnalyticsDebugMode = (url, setCookieFn = Cookie.set) => {
  const analyticsDebugModeFromUrl = parseUrl(url, true).query[
    TRACKING_DEBUG_PARAM_NAME
  ];

  if (analyticsDebugModeFromUrl) {
    setCookieFn(TRACKING_DEBUG_PARAM_NAME, analyticsDebugModeFromUrl);
  }
};
