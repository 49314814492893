import React from "react";

import {
  Card,
  CardSection,
  CardList,
} from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import ButtonGroup from "@components/button-group";
import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../components/button";
import GiftHelpAccordion from "../gift-help-accordion";

import styles from "./index.css";

export interface BuyVoucherProps {
  buyGiftVoucher: () => void;
  openAddGiftCardOrVoucher: () => void;
}

const BuyVoucher = ({
  buyGiftVoucher,
  openAddGiftCardOrVoucher,
}: BuyVoucherProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <CardList isSemantic={false}>
      <Card>
        <CardSection>
          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <Icon id="gifts" />
            </div>

            <Heading sizing={LONDON_2} domElement="h2" isUppercase={true}>
              {formatTranslation("ma_web_giftcardsandvouchers_novoucherstitle")}
            </Heading>

            <div className={styles.paragraphContainer}>
              <Paragraph>
                {formatTranslation(
                  "ma_web_giftcardsandvouchers_novouchersmessage"
                )}
              </Paragraph>
            </div>

            <ButtonGroup flexDisplay={true} centerContent={true}>
              <Button
                buttonType="primary"
                data-auto-id="GiftCardsAndVouchersHeaderButton"
                onClick={openAddGiftCardOrVoucher}
                to="/gift-cards-and-vouchers/add"
              >
                {formatTranslation("ma_web_giftcardsandvouchers_addgift")}
              </Button>
              <Button
                buttonType="primary-purchase"
                data-auto-id="buy-gift-voucher"
                onClick={buyGiftVoucher}
                to="ExternalLink.GiftCardsAndVouchers.BuyGiftVoucher"
              >
                {formatTranslation(
                  "ma_web_giftcardsandvouchers_buygiftvoucher"
                )}
              </Button>
            </ButtonGroup>
          </div>
        </CardSection>
      </Card>
      <GiftHelpAccordion
        title={formatTranslation("ma_web_giftcardsandvouchers_needhelp")}
        topLinks={[
          {
            id: "gift-card-vouchers-faq",
            link: "ExternalLink.GiftCardsAndVouchers.GiftCardVouchersFAQ",
            textKey: "ma_web_giftcardsandvouchers_giftcardvouchersfaq",
          },
        ]}
      />
    </CardList>
  );
};

export default BuyVoucher;
