import { handleActions } from "redux-actions";

import * as addresses from "../addresses/types";
import { CLEAR_API_ERRORS } from "../application/types";
import {
  ADD_PAYMENT_METHOD_ERROR,
  API_ERROR,
  ATTEMPT_DELETE_DEFAULT_PAYMENT_METHOD,
  ATTEMPT_SAVE_PAYMENT_CARD,
  CONFIRM_ADD_PAYMENT_METHOD,
  CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD,
  CONFIRM_DELETE_PAYMENT_ACCEPTANCE,
  CONFIRM_DELETE_PAYMENT_METHOD,
  CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE,
  DENY_DELETE_PAYMENT_ACCEPTANCE,
  RECEIVE_ALL_PAYMENT_METHODS,
  RECEIVE_PAYMENT_METHODS_OPTIONS,
  REQUEST_ADD_PAYMENT_METHOD,
  REQUEST_CHANGE_DEFAULT_PAYMENT_METHOD,
  REQUEST_DELETE_PAYMENT_METHOD,
  REQUEST_PAYMENT_METHODS_OPTIONS,
  REQUIRE_DELETE_PAYMENT_ACCEPTANCE,
  UNLOAD_PAYMENT_METHODS,
} from "./types";

const isCardPaymentOption = (option) => option.id === "card";
const areSame = (a, b) => a.toLowerCase() === b.toLowerCase();

function getCardPaymentMethods({ customerPayments, paymentOptions }) {
  if (!customerPayments.cards) {
    return [];
  }

  const cardOptions = paymentOptions.find(isCardPaymentOption);
  return customerPayments.cards.map((card) => {
    const cardScheme = cardOptions.cardSchemes.find((scheme) =>
      areSame(scheme.name, card.cardScheme)
    );
    return {
      ...card,
      ...cardScheme,
      type: "card",
    };
  });
}

function getPaymentProcessorMethods({ customerPayments, paymentOptions }) {
  return Object.keys(customerPayments).reduce((digitalMethods, methodName) => {
    if (methodName === "cards" || customerPayments[methodName] === null) {
      return digitalMethods;
    }
    const paymentOption = paymentOptions.find((option) =>
      areSame(option.id, methodName)
    );
    const combinedCustomerOption = {
      ...customerPayments[methodName],
      ...paymentOption,
      type: "processor",
    };
    return [...digitalMethods, combinedCustomerOption];
  }, []);
}

const normalisePaymentMethods = (payment) => [
  ...getCardPaymentMethods(payment),
  ...getPaymentProcessorMethods(payment),
];

const sortPaymentMethods = (paymentMethods) =>
  paymentMethods.slice().sort((a, b) => {
    if (a.isDefault) {
      return -1;
    }

    if (b.isDefault) {
      return 1;
    }

    if (a.expired) {
      return 1;
    }

    if (b.expired) {
      return -1;
    }

    return 0;
  });

const removeCustomerPayment = (id, customerPayments) =>
  customerPayments.filter(
    ({ id: customerPaymentId }) => customerPaymentId !== id
  );

const setDefaultCustomerPayment = (customerPayments, id) =>
  customerPayments.map((customerPayment) => ({
    ...customerPayment,
    isDefault: customerPayment.id === id,
  }));

const refinePaymentOptions = (paymentOptions, permittedPaymentOptions) => {
  const now = new Date().getTime();
  return paymentOptions
    .filter(({ id }) => permittedPaymentOptions.includes(id))
    .map(({ newPeriodExpiryDate, ...method }) => ({
      ...method,
      isNew:
        newPeriodExpiryDate && new Date(newPeriodExpiryDate).getTime() > now,
    }));
};

const updateMethodsAndWallet = (
  state,
  { error, payload: { response, permittedPaymentOptions } }
) => {
  if (error) {
    return {
      ...state,
      fatalError: true,
      methodsLoaded: false,
      walletLoaded: false,
    };
  }

  return {
    ...state,
    fatalError: false,
    walletLoaded: true,
    customerPayments: sortPaymentMethods(normalisePaymentMethods(response)),
    methodsLoaded: true,
    paymentOptions: refinePaymentOptions(
      response.paymentOptions,
      permittedPaymentOptions
    ),
  };
};

const reducer = handleActions(
  {
    [ADD_PAYMENT_METHOD_ERROR]: (state, { payload: { id, message } }) => ({
      ...state,
      customerPaymentsError: { id, message },
    }),
    [API_ERROR]: (state, { payload: { userAction, immediate } }) => {
      const { maxRetries, tries } = state;
      if (tries[userAction] >= maxRetries || immediate) {
        return {
          ...state,
          apiErrors: {},
          attemptingToAddServiceId: "",
          fatalError: true,
          loaded: false,
          savingCard: false,
          tries: {},
        };
      }

      return {
        ...state,
        apiErrors: {
          [userAction]: true,
        },
        attemptingToAddServiceId: "",
        fatalError: false,
        loaded: true,
        savingCard: false,
        tries: {
          [userAction]: (tries[userAction] || 0) + 1,
        },
      };
    },
    [ATTEMPT_DELETE_DEFAULT_PAYMENT_METHOD]: (state) => ({
      ...state,
      attemptedToDeleteDefaultPayment: true,
    }),
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      apiErrors: {},
      tries: {},
    }),
    [REQUEST_PAYMENT_METHODS_OPTIONS]: (state) => ({
      ...state,
      fatalError: false,
      methodsLoaded: false,
      walletLoaded: false,
    }),
    [RECEIVE_PAYMENT_METHODS_OPTIONS]: updateMethodsAndWallet,
    [CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD]: (
      state,
      { error, payload: { id } }
    ) =>
      error
        ? {
            ...state,
            walletLoaded: true,
          }
        : {
            ...state,
            attemptedToDeleteDefaultPayment: false,
            customerPayments: sortPaymentMethods(
              setDefaultCustomerPayment(state.customerPayments, id)
            ),
            customerPaymentsError: undefined,
            walletLoaded: true,
          },
    [REQUEST_ADD_PAYMENT_METHOD]: (state, { payload: { id } }) => ({
      ...state,
      attemptingToAddServiceId: id,
    }),
    [CONFIRM_ADD_PAYMENT_METHOD]: (state) => ({
      ...state,
      walletLoaded: false,
      methodsLoaded: false,
    }),
    [REQUEST_CHANGE_DEFAULT_PAYMENT_METHOD]: (state) => ({
      ...state,
      walletLoaded: false,
    }),
    [REQUEST_DELETE_PAYMENT_METHOD]: (state) => ({
      ...state,
      deletingPaymentMethod: true,
    }),
    [REQUIRE_DELETE_PAYMENT_ACCEPTANCE]: (
      state,
      { payload: { id, type } }
    ) => ({
      ...state,
      paymentMethodAcceptanceId: id,
      paymentMethodAcceptanceType: type,
      requireDeleteAcceptance: true,
    }),
    [CONFIRM_DELETE_PAYMENT_METHOD]: (state, { error, payload: { id } }) =>
      error
        ? {
            ...state,
            paymentMethodAcceptanceId: undefined,
            paymentMethodAcceptanceType: undefined,
            deletingPaymentMethod: false,
          }
        : {
            ...state,
            attemptedToDeleteDefaultPayment: false,
            customerPayments: removeCustomerPayment(id, state.customerPayments),
            customerPaymentsError: undefined,
            paymentMethodAcceptanceId: undefined,
            paymentMethodAcceptanceType: undefined,
            deletingPaymentMethod: false,
          },
    [CONFIRM_DELETE_PAYMENT_ACCEPTANCE]: (state) => ({
      ...state,
      requireDeleteAcceptance: false,
    }),
    [DENY_DELETE_PAYMENT_ACCEPTANCE]: (state) => ({
      ...state,
      paymentMethodAcceptanceId: undefined,
      paymentMethodAcceptanceType: undefined,
      requireDeleteAcceptance: false,
    }),
    [UNLOAD_PAYMENT_METHODS]: (state) => ({
      ...state,
      cardSaved: false,
      attemptingToAddServiceId: "",
      attemptedToDeleteDefaultPayment: false,
      customerPaymentsError: undefined,
    }),
    [CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE]: (state) => ({
      ...state,
      apiErrors: {},
      fatalError: false,
      savingCard: false,
      cardSaved: true,
      methodsLoaded: false,
      tries: {},
    }),
    [ATTEMPT_SAVE_PAYMENT_CARD]: (state) => ({
      ...state,
      savingCard: true,
      methodsLoaded: false,
    }),
    [addresses.DEFAULT_BILLING_ADDRESS_UPDATED]: (state) => ({
      ...state,
      methodsLoaded: false,
    }),
    [RECEIVE_ALL_PAYMENT_METHODS]: (state, { error, payload }) => {
      if (error) {
        return state;
      }

      const getPaymentFields = ({ name, imageUrl }) => ({
        imageUrl,
        name,
      });

      const methods = payload.reduce((types, option) => {
        if (option.cardSchemes && Array.isArray(option.cardSchemes)) {
          return [
            ...types,
            ...option.cardSchemes.reduce(
              (schemes, scheme) => [...schemes, getPaymentFields(scheme)],
              []
            ),
          ];
        }

        if (option.id.toLowerCase() === "giftvoucher") {
          return types;
        }

        return [...types, getPaymentFields(option)];
      }, []);

      return {
        ...state,
        allPaymentMethods: methods,
      };
    },
  },
  {
    allPaymentMethods: [],
    apiErrors: {},
    attemptedToDeleteDefaultPayment: false,
    attemptingToAddServiceId: "",
    cardSaved: false,
    customerPayments: [],
    customerPaymentsError: {},
    deletingPaymentMethod: false,
    fatalError: false,
    maxRetries: 2,
    methodsLoaded: false,
    newCardScheme: undefined,
    paymentMethodAcceptanceId: undefined,
    paymentMethodAcceptanceType: undefined,
    paymentOptions: [],
    requireDeleteAcceptance: false,
    savingCard: false,
    tries: {},
    walletLoaded: false,
  }
);

export default reducer;
