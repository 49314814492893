import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CardWithHeading } from "@asosteam/asos-web-component-library-card-with-heading";
import { CardWithSteps } from "@asosteam/asos-web-component-library-card-with-steps";
import {
  CardSection,
  CardList,
} from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import AddLabelIcon from "@asosteam/asos-web-component-library-svg-icons/lib/add-label.svg";
import DropOffIcon from "@asosteam/asos-web-component-library-svg-icons/lib/drop-off.svg";
import EmailIcon from "@asosteam/asos-web-component-library-svg-icons/lib/email.svg";
import PrintIcon from "@asosteam/asos-web-component-library-svg-icons/lib/print.svg";
import TickIcon from "@asosteam/asos-web-component-library-svg-icons/lib/tick.svg";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import appConfig from "@appConfig";

import { Button } from "@components/button";
import FieldLabel from "@components/field-label";
import ItemImages from "@components/item-images";
import Page from "@components/page";
import PageHeader from "@components/page-header";
import StoreAddress from "@components/store-address";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import {
  navigateToOrders,
  resetReturnsState as clearCreatedReturn,
} from "@state/returns/actions";
import { getReturnBookingConfirmation } from "@state/returns/selectors";

import styles from "./index.css";

const ReturnConfirmation = () => {
  const { booking } = useSelector((state) => ({
    booking: getReturnBookingConfirmation(state),
  }));

  const dispatch = useDispatch();

  const formatTranslation = useFormatTranslation();

  useEffect(() => {
    if (booking) {
      dispatch(clearCreatedReturn());
    }
  }, [booking]);

  if (!booking) {
    dispatch(navigateToOrders());
    return null;
  }

  const { items, returnLocation, itemsTotal } = booking;
  const printerlessReturn = returnLocation.provider.printerlessReturn;
  const { consolidatedReturnsDisabled, returnsPromiseEnabled } = appConfig;
  const showHowToPackageReturnCopy =
    !printerlessReturn && consolidatedReturnsDisabled;

  const printerlessReturnCopy = [
    {
      iconSrc: DropOffIcon,
      text: formatTranslation(
        "returns_printer_not_required_instructions_1_updated",
        {
          courier: returnLocation.provider.name,
        }
      ),
    },
    {
      iconSrc: AddLabelIcon,
      text: formatTranslation(
        "returns_printer_not_required_instructions_2_updated",
        {
          courier: returnLocation.provider.name,
        }
      ),
    },
  ];

  const noPrinterlessReturnCopy = [
    {
      iconSrc: PrintIcon,
      text: formatTranslation("returns_printer_required_instructions_1"),
    },
    {
      iconSrc: DropOffIcon,
      text: formatTranslation(
        "returns_printer_required_instructions_2_updated",
        {
          courier: returnLocation.provider.name,
        }
      ),
    },
  ];

  const returnPromiseCopy = {
    iconSrc: EmailIcon,
    text: formatTranslation("returns_promise_instructions_3", {
      days: returnLocation.provider.refundProcessInDays,
    }),
  };

  const nextStepItems = printerlessReturn
    ? printerlessReturnCopy
    : noPrinterlessReturnCopy;

  if (returnsPromiseEnabled) {
    nextStepItems.push(returnPromiseCopy);
  }

  return (
    <Page
      loaded
      pageHeader={
        <PageHeader
          description="ma_web_returnconfirmation_heading_description"
          detached
          previousPageLink="/orders"
          title="ma_web_returnconfirmation_heading_title"
          type="secondary"
        />
      }
      pageId="return-confirmation"
      pageTitleKey="ma_web_orderreturn_heading_title"
      showErrorSection={false}
    >
      <CardList>
        {printerlessReturn && (
          <CardWithHeading
            heading={formatTranslation(
              "ma_faster_refunds_returns_summary_thankyou_subtext"
            )}
            isUppercase={true}
          >
            <Paragraph>
              {formatTranslation("returns_label_guidance_instructions")}
            </Paragraph>
          </CardWithHeading>
        )}

        <CardWithSteps
          title={formatTranslation("ma_web_returnconfirmation_nextsteps_title")}
          items={nextStepItems}
          titleIsUppercase
        />
        {showHowToPackageReturnCopy && (
          <CardWithHeading
            heading={formatTranslation(
              "ma_web_returnconfirmation_packing_title"
            )}
            isUppercase={true}
          >
            <Paragraph>
              {formatTranslation("ma_web_returnconfirmation_packing_subtitle")}
            </Paragraph>
            <CardSection
              hasBottomPadding={true}
              hasLeftPadding={false}
              hasRightPadding={false}
              hasTopPadding={true}
            >
              <HorizontalRule />
            </CardSection>

            <CardWithSteps
              items={[
                {
                  iconSrc: TickIcon,
                  iconHasBorder: false,
                  text: (
                    <Translation
                      id={"ma_web_returnconfirmation_packing_tip1"}
                    />
                  ),
                },
                {
                  iconAltText: TickIcon,
                  iconHasBorder: false,
                  text: (
                    <Translation
                      id={"ma_web_returnconfirmation_packing_tip2"}
                    />
                  ),
                },
              ]}
            />
          </CardWithHeading>
        )}
        <CardWithHeading
          heading={formatTranslation(
            "ma_web_returnconfirmation_statussummary_title"
          )}
          isUppercase={true}
        >
          <div className={styles.infoBlock} data-auto-id="status-text">
            <FieldLabel
              text={formatTranslation(
                "ma_web_returnconfirmation_statussummary_statuslabel"
              )}
            />
            <Paragraph>
              {formatTranslation(
                "ma_web_returnconfirmation_statussummary_statustext"
              )}
            </Paragraph>
          </div>
          <Paragraph className={styles.infoBlock}>
            {formatTranslation(
              "ma_web_returnconfirmation_statussummary_descriptiontext"
            )}
            <div className={styles.returnsPolicyInfo}>
              <Translation
                id="ma_web_returnconfirmation_statussummary_descriptionlink"
                values={{
                  link: formatTranslation(
                    "ma_web_orderreturn_returnitem_paymentinformationlink"
                  ),
                }}
              />
            </div>
          </Paragraph>

          <div className={styles.infoBlock}>
            <FieldLabel
              text={formatTranslation(
                "ma_web_returnconfirmation_statussummary_methodlabel"
              )}
            />
            <div className={styles.provider}>
              <img
                src={returnLocation.provider.logoUri}
                alt={returnLocation.provider.name}
                className={styles.providerIcon}
              />
              <div className={styles.providerName}>
                {returnLocation.provider.name}
              </div>
            </div>
            <Paragraph>
              {returnLocation.store.name}
              <br />
              <StoreAddress address={returnLocation.store.address} />
            </Paragraph>
          </div>
          <div className={styles.locationInfo}>
            <Paragraph>
              <span>
                {formatTranslation("returns_carrier_info", {
                  courier: returnLocation.provider.name,
                })}
              </span>
            </Paragraph>
          </div>
          <div className={styles.infoBlock}>
            <Heading domElement="h3" sizing={LONDON_2} isUppercase={true}>
              {formatTranslation(
                "ma_web_returnconfirmation_statussummary_itemsheading",
                { smart_count: itemsTotal }
              )}
            </Heading>
            <div className={styles.images}>
              <ItemImages
                ariaLabel={formatTranslation(
                  "ma_web_returnconfirmation_statussummary_aria_images"
                )}
                centerAlignOnSmallScreens={false}
                imageLimit={4}
                items={items}
              />
            </div>
          </div>
          <div className={styles.centered}>
            <Button
              buttonType="secondary"
              data-auto-id="view-my-returns"
              to="/returns"
            >
              {formatTranslation("ma_web_returnconfirmation_viewreturnsbutton")}
            </Button>
          </div>
        </CardWithHeading>
      </CardList>
    </Page>
  );
};

export default ReturnConfirmation;
