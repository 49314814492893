import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import { getAddresses } from "@state/addresses/actions";
import {
  getAddressBook,
  getDefaultBillingAddress,
  getDefaultDeliveryAddress,
  getHasError,
  getHasSavedAddress,
  hasLoaded as hasAddressesLoaded,
} from "@state/addresses/selectors";
import { clearApiErrors } from "@state/application/actions";
import { getCountries } from "@state/countries/actions";
import { hasLoaded as hasCountriesLoaded } from "@state/countries/selectors";
import {
  getFirstName,
  getIfDetailsLoaded as hasUserDetailsLoaded,
  getLastName,
} from "@state/user/selectors";

const loadContent = () =>
  import(/* webpackChunkName: 'address-form' */ "../address-form");

const AddAddress = () => {
  const { bundle: AddressForm } = useBundle(loadContent);
  const dispatch = useDispatch();

  const {
    addressBook,
    fatalError,
    loaded,
    savedAddress,
    addAddressApiError,
    formInitialValues,
  } = useSelector((state: { addresses }) => {
    const deliveryAddress = getDefaultDeliveryAddress(state);
    return {
      addressBook: getAddressBook(state),
      fatalError: getHasError(state),
      loaded:
        hasCountriesLoaded(state) &&
        hasAddressesLoaded(state) &&
        hasUserDetailsLoaded(state),
      savedAddress: getHasSavedAddress(state),
      addAddressApiError: state.addresses.apiErrors.addAddress,
      formInitialValues: {
        countryCode: deliveryAddress ? deliveryAddress.countryCode : undefined,
        defaultBilling: !getDefaultBillingAddress(state),
        defaultDelivery: !deliveryAddress,
        firstName: getFirstName(state),
        lastName: getLastName(state),
        telephoneMobile: deliveryAddress
          ? deliveryAddress.telephoneMobile
          : undefined,
      },
    };
  });

  useEffect(() => {
    dispatch(clearApiErrors());
    dispatch(getAddresses());
    dispatch(getCountries());
  }, []);

  if (savedAddress) {
    return <Navigate to="/addresses" />;
  }

  const hasAddresses = addressBook.length !== 0;
  const backLink = hasAddresses ? "/addresses" : "/";

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageContainer>
          <PageHeader
            type="secondary"
            icon="addresses"
            title="ma_web_addressbook_addnewaddress_title"
            description={
              addressBook.length
                ? "ma_web_addressbook_addnewaddress_description"
                : "ma_web_addressbook_addnewaddress_nonesaved"
            }
            descriptionType={hasAddresses ? null : "info"}
            previousPageLink={backLink}
          />
        </PageContainer>
      }
      pageId="add-address"
      pageTitleKey="ma_web_addressbook_addnewaddress_title"
      showErrorSection={fatalError}
    >
      {AddressForm && (
        <AddressForm
          addAddressApiError={addAddressApiError}
          addressBook={addressBook}
          formType="add"
          initialValues={formInitialValues}
          loaded={loaded}
        />
      )}
    </Page>
  );
};

export default AddAddress;
