let currentCallback;

export default (startActionType, finishActionType) =>
  () =>
  (next) =>
  (action) => {
    if (action.type === startActionType) {
      currentCallback = action.meta.callback;
    } else if (action.type === finishActionType) {
      currentCallback(action.payload);
      currentCallback = undefined;
    }

    return next(action);
  };
