import merge from "lodash.merge";

import appConfig from "@appConfig";

import { addressForm } from "@configuration/addresses";

const getAddresses = (state) => state.addresses;

export const getAddressBook = (state) => getAddresses(state).addressBook;

export const getAddressBookError = (state) =>
  getAddresses(state).addressBookError;

export const getAddressFromId = (state, addressId) =>
  getAddresses(state).addressBook.find(
    (address) => address.addressId === parseInt(addressId, 10)
  );

export const getHasError = (state) => getAddresses(state).fatalError;

export const hasLoaded = (state) => getAddresses(state).loaded;

export const getHasSavedAddress = (state) => getAddresses(state).savedAddress;

export const getIsValid = (state) => getAddresses(state).valid;

export const getDefaultBillingAddress = (state) =>
  (getAddressBook(state) || []).find((address) => address.defaultBilling);

export const getDefaultDeliveryAddress = (state) =>
  (getAddressBook(state) || []).find((address) => address.defaultDelivery);

export const getDefaultBillingAddressCountry = (state) => {
  const defaultAddress = getDefaultBillingAddress(state);
  return defaultAddress ? defaultAddress.countryCode : null;
};

export const getAddressFinder = (state) => getAddresses(state).addressFinder;

export const getAddressFinderItems = (state) => getAddressFinder(state).items;

export const getAddressFinderLoaded = (state) => getAddressFinder(state).loaded;

export const getAddressFinderError = (state) =>
  !!getAddresses(state).apiErrors.addressFinder;

export const getAddressAcceptanceId = (state) =>
  getAddresses(state).addressAcceptanceId;

export const removeAddress = (state, addressId) => {
  const addressBook = state.addressBook || [];
  return {
    ...state,
    addressBook: addressBook.filter((a) => a.addressId !== addressId),
  };
};

export const getAddressBreadCrumbs = (state) =>
  getAddressFinder(state).breadCrumbs;

export const getLastAddressBreadCrumb = (state) => {
  const breadcrumbs = getAddressBreadCrumbs(state);
  return breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : {};
};

export const shouldShowSelectedAddressBox = (state) =>
  getAddressFinder(state).showSelectedAddressBox;

export const isManualAddressFieldsOpen = (state) =>
  getAddresses(state).showManualAddressFields;

export const getOptionsFromObject = (givenObject) =>
  Object.entries(givenObject).map(([value, text]) => ({
    text,
    value,
  }));

export const getConfigFor = (countryCode) => {
  const countrySpecificConfig = addressForm[countryCode];
  if (countrySpecificConfig) {
    return merge({}, addressForm.default, countrySpecificConfig);
  }
  return addressForm.default;
};

export const getInitialValuesFor = (countryCode) =>
  getConfigFor(countryCode).initialValues;

export const getFieldPropertiesFor = (fieldName, countryCode) => ({
  ...getConfigFor(countryCode).fields[fieldName],
  name: fieldName,
});

export const getIfAddressFinderSupportsCountry = (countryCode) =>
  appConfig.supportedAddressFinderCountries.includes(countryCode);

const getFields = (countryCode) => getConfigFor(countryCode).fields;
const getField = (field, countryCode) => getFields(countryCode)[field];
export const getFieldMaxLength = (field, countryCode) =>
  getField(field, countryCode).maxLength;

export const getFieldValidationPattern = (field, countryCode) =>
  getField(field, countryCode).pattern;

const getValidationRules = (countryCode) =>
  getConfigFor(countryCode).validationRules;
export const getValidationRulesForField = (field, countryCode) =>
  getValidationRules(countryCode)[field];
