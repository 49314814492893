import { EventEmitter } from "fbemitter";
import cookie from "js-cookie";

const getAnalyticsDebugMode = () => cookie.get("analytics") === "true";

export default () => {
  if (window && !window.analyticsEventEmitter) {
    const emitter = new EventEmitter();
    window.analyticsEventEmitter = emitter;
  }
};

export const emitAnalyticsEvent = (trackingEvent) => {
  if (getAnalyticsDebugMode()) {
    // eslint-disable-next-line no-console
    console.log(trackingEvent.id, trackingEvent.payload);
  }

  return window.analyticsEventEmitter.emit(
    trackingEvent.id,
    trackingEvent.payload
  );
};
