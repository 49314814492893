import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";

import { updateReturningItem } from "@state/returns/actions";
import { getReturnItem } from "@state/returns/selectors";

import ReturnOrderContainer from "./component";
import validate from "./validate";

export const onFieldChange =
  (variantId, orderReference, fieldName, newValue) => (dispatch) =>
    dispatch(
      updateReturningItem({
        [fieldName]: newValue,
        variantId,
        orderReference,
      })
    );

export const onSubmit = (values, dispatch, props, validateFn = validate) => {
  const errors = validateFn(values, props);
  if (errors) {
    throw new SubmissionError(errors);
  }
};

const mapStateToProps = (state, ownProps) => {
  const product = getReturnItem(
    state,
    ownProps.variantId,
    ownProps.orderReference
  );
  return {
    initialValues: {
      quantity: 1,
      ...product,
    },
  };
};

const mapDispatchToProps = {
  onFieldChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    onSubmit,
    validate,
  })(ReturnOrderContainer)
);
