import React from "react";

import ProductName from "@components/product-name";

import ItemAttributes from "../item-attributes";

import styles from "./index.css";

export interface ReturnableItemSummaryProps {
  colour: string;
  name: string;
  size: string;
  returnableQuantity?: number;
}

const ReturnableItemSummary = ({
  colour,
  name,
  size,
  returnableQuantity,
}: ReturnableItemSummaryProps) => {
  const attributes = [
    {
      label: "ma_web_returnableitemsummary_product_colour",
      value: colour,
    },
    {
      label: "ma_web_returnableitemsummary_product_size",
      value: size,
    },
    {
      label: "ma_web_returnableitemsummary_product_quantity",
      value: returnableQuantity,
    },
  ];
  return (
    <div>
      <div className={styles.name}>
        <ProductName isCreateReturn>{name}</ProductName>
      </div>
      <ItemAttributes attributes={attributes} />
    </div>
  );
};

export default ReturnableItemSummary;
