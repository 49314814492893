import React from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";
import { Checkbox } from "@asosteam/asos-web-component-library-checkbox";
import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";

import ProductImage from "@components/product-image";
import ProductWrapper from "@components/product-wrapper";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import ReturnItemForm from "../return-item-form";
import ReturnToBrand from "../return-to-brand";
import ReturnableItemSummary from "../returnable-item-summary";
import ReturnsPolicy from "../returns-policy";

import styles from "./index.css";

export const onChange =
  (
    returnFormOpen,
    openReturnForm,
    closeReturnForm,
    item,
    getReturnChargeMessages,
    orderReference
  ) =>
  () => {
    if (!returnFormOpen) {
      openReturnForm({
        variantId: item.variantId,
        orderReference,
        returnByDate: item.returnByDate,
        orderDate: item.orderDate,
      });
    } else {
      closeReturnForm({ variantId: item.variantId, orderReference });
    }
    getReturnChargeMessages();
  };
export const returnableToAsos = (value) => {
  return value.returnsPolicy?.policyRule === "FinalSale" ||
    value.canReturnToAsos !== (undefined || true)
    ? false
    : true;
};

export const returnPolicy = (item) => {
  if (item.returnsPolicy && item.returnsPolicy.policyRule === "FinalSale") {
    return <ReturnsPolicy {...item.returnsPolicy} />;
  }
  if (
    item.canReturnToAsos !== (undefined || true) &&
    item.returnToName !== null
  ) {
    return <ReturnToBrand item={item} />;
  }
  return null;
};

export interface ReturnableItemProps {
  closeReturnForm: (arg0: any) => void;
  isFirstItem: boolean;
  isLastItem: boolean;
  item: {
    imageUrl: string;
    variantId?: number;
    returnableQuantity?: number;
    canReturnToAsos?: boolean;
    colour: string;
    name: string;
    size: string;
    returnByDate: string;
  };
  getReturnChargeMessages: () => void;
  openReturnForm: (arg0: any) => void;
  returnFormOpen?: boolean;
  returnReasons: {
    text: string;
    value: number;
  }[];
  orderReference: string;
}

const ReturnableItem = ({
  closeReturnForm,
  isFirstItem,
  isLastItem,
  item,
  openReturnForm,
  getReturnChargeMessages,
  returnFormOpen = false,
  returnReasons,
  orderReference,
}: ReturnableItemProps) => {
  const canReturnToAsos = returnableToAsos(item);
  const formatTranslation = useFormatTranslation();

  return (
    <ProductWrapper>
      {!isFirstItem && (
        <div className={styles.rule}>
          <HorizontalRule />
        </div>
      )}
      <CardSection
        hasBottomPadding={!isLastItem}
        hasLeftPadding={false}
        hasRightPadding={false}
        hasTopPadding={!isFirstItem}
      >
        <div className={styles.wrapper}>
          <div className={styles.image}>
            <ProductImage
              alt={formatTranslation("ma_web_returnableitem_aria_imagery")}
              url={`https://${item.imageUrl}`}
            />
          </div>
          <div className={styles.details}>
            <ReturnableItemSummary {...item} />
          </div>
          {canReturnToAsos ? (
            <div className={styles.select}>
              <Checkbox
                checked={returnFormOpen}
                data-auto-id={`return-item-checkbox-${item.variantId}`}
                input={{
                  onChange: onChange(
                    returnFormOpen,
                    openReturnForm,
                    closeReturnForm,
                    item,
                    getReturnChargeMessages,
                    orderReference
                  ),
                  name: "selectItemToReturn",
                }}
                text={formatTranslation(
                  "ma_web_orderreturn_returnitem_checkboxlabel"
                )}
              />
              {returnFormOpen && (
                <ReturnItemForm
                  form={`return-item-${item.variantId}-${orderReference}`}
                  returnableQuantity={item.returnableQuantity}
                  returnReasons={returnReasons}
                  variantId={item.variantId}
                  orderReference={orderReference}
                />
              )}
            </div>
          ) : (
            returnPolicy(item)
          )}
        </div>
      </CardSection>
    </ProductWrapper>
  );
};

export default ReturnableItem;
