import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";

import { clearApiErrors } from "@state/application/actions";
import * as actions from "@state/gift-cards-and-vouchers/actions";
import {
  getAddGiftVoucherFormLoaded,
  hasApiErrors,
} from "@state/gift-cards-and-vouchers/selectors";

import { scrollErrorIntoView } from "@utils/scroll-into-view";

import TranslatedFormComponent from "../../form";
import validate from "../validate";

const validateValues = (values) => validate(values);

export const onSubmit = async (
  values,
  dispatch,
  props,
  submitGiftVoucherFn = actions.submitGiftVoucher,
  validateFn = validate
) => {
  const response = await dispatch(submitGiftVoucherFn(values));
  if (response.error && response.payload.apiValidationError) {
    const validationResults = validateFn(
      {
        ...values,
        errorCodes:
          response.payload.body &&
          response.payload.body.map((d) => d.errorCode),
      },
      props
    );

    throw new SubmissionError(validationResults);
  }
  if (response.error) throw new SubmissionError();
};

export const onSubmitFail = (
  validationResults,
  dispatch,
  submitError,
  props,
  scrollErrorIntoViewFn = scrollErrorIntoView
) => {
  if (validationResults || submitError.errors) {
    scrollErrorIntoViewFn();
  }
};

const mapStateToProps = (state) => ({
  hasApiError: hasApiErrors(state),
  loaded: getAddGiftVoucherFormLoaded(state),
});

const mapDispatchToProps = {
  clearApiErrors,
  submitGiftVoucher: (body) => actions.submitGiftVoucher(body),
};

const fields = [
  {
    maxLength: 16,
    name: "Code",
    type: null,
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    fields,
    form: "addGiftVoucher",
    onChange: validateValues,
    onSubmit,
    onSubmitFail,
    type: "AddGiftVoucher",
    validate: validateValues,
  })(TranslatedFormComponent)
);
