import { handleActions } from "redux-actions";

import appConfig from "@appConfig";

import { CLEAR_API_ERRORS } from "../application/types";
import * as types from "./types";

const updateLinkedAccounts = (state, { error, payload }) => {
  if (error) {
    if (payload.providerToLink) {
      return {
        ...state,
        loaded: true,
        fatalError: false,
        providerWithError: payload.providerToLink,
      };
    }
    return {
      ...state,
      loaded: false,
      fatalError: true,
      providerWithError: "",
    };
  }

  const oldAccounts = state.linkedAccounts;
  const updatedAccounts = payload.linkedAccounts;
  const linkedAccounts = oldAccounts.map((oldAccount) => {
    const linkedAccount = updatedAccounts.find(
      (updatedAccount) => updatedAccount.provider === oldAccount.provider
    );
    if (linkedAccount) {
      return {
        ...oldAccount,
        ...linkedAccount,
        isLinked: true,
      };
    }
    return oldAccount;
  });
  return {
    ...state,
    apiError: false,
    fatalError: false,
    linkedAccounts,
    loaded: true,
    providerWithError: "",
    tries: 0,
  };
};

const reducer = handleActions(
  {
    [types.API_ERROR]: (state) => {
      const { maxRetries, tries } = state;
      if (tries >= maxRetries) {
        return {
          ...state,
          apiError: false,
          fatalError: true,
          loaded: false,
          tries: 0,
        };
      }
      return {
        ...state,
        fatalError: false,
        loaded: true,
        tries: tries + 1,
        apiError: true,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      apiError: false,
      tries: 0,
    }),
    [types.ATTEMPT_UNLINK_CURRENT_LOGIN_PROVIDER]: (state) => ({
      ...state,
      attemptedToUnlinkCurrentLoginProvider: true,
    }),
    [types.REQUEST_LINKED_ACCOUNTS]: (state) => ({
      ...state,
      fatalError: false,
      loaded: false,
    }),
    [types.RECEIVE_LINKED_ACCOUNTS]: updateLinkedAccounts,
    [types.RECEIVE_DISCONNECT_LINKED_ACCOUNT]: (state, { error, payload }) => {
      const { unlinkedProvider } = payload;
      let providerWithError = "";
      let linkedAccounts = state.linkedAccounts;
      if (error) {
        providerWithError = payload.provider;
      } else {
        linkedAccounts = state.linkedAccounts.map((oldAccount) => {
          if (oldAccount.provider === unlinkedProvider) {
            return {
              ...oldAccount,
              isLinked: false,
            };
          }
          return oldAccount;
        });
      }
      return {
        ...state,
        apiError: !!providerWithError,
        attemptedToUnlinkCurrentLoginProvider: false,
        fatalError: false,
        linkedAccounts,
        providerWithError,
        tries: 0,
      };
    },
    [types.RECEIVE_CURRENT_LOGGED_IN_PROVIDER]: (state, { payload }) => {
      const { loginProvider } = payload;
      const linkedAccounts = state.linkedAccounts.map((oldAccount) => {
        if (oldAccount.provider === loginProvider) {
          return {
            ...oldAccount,
            isCurrentlySignedInWith: true,
          };
        }
        return oldAccount;
      });
      return {
        ...state,
        apiError: false,
        fatalError: false,
        linkedAccounts,
        loginProvider,
        tries: 0,
      };
    },
  },
  {
    attemptedToUnlinkCurrentLoginProvider: false,
    apiError: false,
    fatalError: false,
    maxRetries: 2,
    tries: 0,
    linkedAccounts: appConfig?.permittedLoginProviders?.map((provider) => ({
      provider,
      isCurrentlySignedInWith: false,
      isLinked: false,
    })),
    loaded: false,
    loginProvider: "",
    providerWithError: "",
  }
);

export default reducer;
