import React, { useEffect, useRef, useState } from "react";

import { mainId } from "@configuration/application";

import ScrollToTop from "./component";

export const SCROLL_BUFFER = 30;

const ScrollToTopWrapper = () => {
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const lastCalculatedPosition = useRef(0);
  const lastHeight = useRef(0);

  const setButtonVisibility = (
    scrollPosition: number,
    windowHeight: number,
    atTop: boolean,
    pageHeight: number
  ) => {
    const scrollingUp = scrollPosition < lastCalculatedPosition.current;
    const scrollingDown = scrollPosition > lastCalculatedPosition.current;
    const hasScrollBeyondHalfWindow = scrollPosition > windowHeight / 2;
    const sameHeight = windowHeight === lastHeight.current;

    // This is to control appearance of button when on mobile and scrolling beyond window in mobile ( rubber banding )
    const bottomScroll = pageHeight - windowHeight;
    const viewPortWithinDocument = scrollPosition < bottomScroll;

    const shouldHide = sameHeight && (scrollingDown || atTop);
    const shouldShow =
      scrollingUp && hasScrollBeyondHalfWindow && viewPortWithinDocument;

    if (shouldHide) {
      setShowBackToTopButton(false);
    }
    if (shouldShow) {
      setShowBackToTopButton(true);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const atTop = currentScrollPosition === 0;

      const outsideBuffer =
        currentScrollPosition <
          lastCalculatedPosition.current - SCROLL_BUFFER ||
        currentScrollPosition > lastCalculatedPosition.current + SCROLL_BUFFER;

      if (outsideBuffer || atTop) {
        const pageHeight = document.documentElement.scrollHeight;

        setButtonVisibility(
          currentScrollPosition,
          windowHeight,
          atTop,
          pageHeight
        );

        lastCalculatedPosition.current = currentScrollPosition;
        lastHeight.current = windowHeight;
      }
    };

    const handleAnimationFrame = () => {
      window.requestAnimationFrame(handleScroll);
    };

    handleScroll();
    window.addEventListener("scroll", handleAnimationFrame);
    window.addEventListener("resize", handleAnimationFrame);
    return () => {
      window.removeEventListener("scroll", handleAnimationFrame);
      window.removeEventListener("resize", handleAnimationFrame);
    };
  }, []);

  const onClick = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    // SetTimeout Hack to stop safari from flickering
    // when setting window.href during scroll.
    window.setTimeout(() => {
      // Set window hash to force tabbing and screenreaders back to top
      window.location.href = `#${mainId}`;

      // solution from https://axesslab.com/skip-links/
      // add tabindex to make focusable and remove again
      const element = document.querySelector(`#${mainId}`) as HTMLElement;

      element.setAttribute("tabindex", "-1");
      element.addEventListener("blur focusout", () => {
        element.removeAttribute("tabindex");
      });
      element.focus();
    });
  };

  return <ScrollToTop onClick={onClick} show={showBackToTopButton} />;
};

export default ScrollToTopWrapper;
