import { createAction } from "redux-actions";
import { getFormSyncErrors, autofill } from "redux-form";

import mapKeys from "lodash.mapkeys";

import { getLanguage } from "@state/application/selectors";

import { camelCase } from "@utils/string";

import { requestNotification } from "../application/actions";
import * as api from "./api";
import * as constants from "./constants";
import * as selectors from "./selectors";
import * as types from "./types";

const supportedValidations = [
  "invalidAddress1",
  "invalidAddress2",
  "invalidAddressFirstName",
  "invalidAddressLastName",
  "invalidCharactersAddress1",
  "invalidCharactersAddress2",
  "invalidCharactersAddressFirstName",
  "invalidCharactersAddressLastName",
  "invalidCharactersCountyStateProvinceOrArea",
  "invalidCharactersLocality",
  "invalidCharactersPostalCode",
  "invalidCountyStateProvinceOrArea",
  "invalidLocality",
  "invalidPostalCode",
  "invalidPostalCodeForCountry",
  "invalidTelephoneMobile",
  "missingAddress1",
  "missingAddressFirstName",
  "missingAddressLastName",
  "missingCountryCode",
  "missingLocality",
  "missingPostalCode",
  "missingTelephoneMobile",
];

const inSupportedValidations = (errorCode) =>
  supportedValidations.find((code) => code === errorCode);

const getUnSupportedValidations = (errorCodes) =>
  errorCodes.filter(({ errorCode }) => !inSupportedValidations(errorCode));

const supportedValidationError = (error) =>
  error.apiValidationError && getUnSupportedValidations(error.body).length < 1;

export const receiveAddresses = createAction(types.RECEIVE_ADDRESSES);
export const requestAddresses = createAction(types.REQUEST_ADDRESSES);
export const requestAddAddress = createAction(types.REQUEST_ADD_ADDRESS);
export const receiveAddAddress = createAction(types.RECEIVE_ADD_ADDRESS);
export const requestFindAddress = createAction(types.REQUEST_FIND_ADDRESS);
export const receiveFindAddress = createAction(types.RECEIVE_FIND_ADDRESS);
export const requestRetrieveAddress = createAction(
  types.REQUEST_RETRIEVE_ADDRESS
);
export const receiveRetrieveAddress = createAction(
  types.RECEIVE_RETRIEVE_ADDRESS
);
export const requestEditAddress = createAction(types.REQUEST_EDIT_ADDRESS);
export const receiveEditAddress = createAction(types.RECEIVE_EDIT_ADDRESS);
export const unloadAddresses = createAction(types.UNLOAD_ADDRESSES);
export const clearBreadCrumbs = createAction(types.CLEAR_BREAD_CRUMBS);
export const removeCrumb = createAction(types.REMOVE_CRUMB);
export const addCrumb = createAction(types.ADD_CRUMB, (crumb) => crumb);
export const defaultBillingAddressUpdated = createAction(
  types.DEFAULT_BILLING_ADDRESS_UPDATED
);

export const openManualAddressFields = createAction(
  types.SHOW_MANUAL_ADDRESS_FIELDS
);

// Errors
export const addAddressBookError = createAction(
  types.ADD_ADDRESS_BOOK_ERROR,
  (id, message) => ({ id, message })
);

export const addAddressBookWarning = createAction(
  types.ADD_ADDRESS_BOOK_WARNING,
  (id, message) => ({ id, message })
);

// Delete address
export const requestDeleteAddress = createAction(types.REQUEST_DELETE_ADDRESS);

export const apiError = createAction(types.API_ERROR);

export const confirmDeleteAddress = createAction(
  types.CONFIRM_DELETE_ADDRESS,
  (id) => ({ id })
);

export const requireDeleteAddressAcceptance = createAction(
  types.REQUIRE_DELETE_ADDRESS_ACCEPTANCE,
  (id) => ({ id })
);

export const confirmDeleteAddressAcceptance = createAction(
  types.CONFIRM_DELETE_ADDRESS_ACCEPTANCE
);

export const denyDeleteAddressAcceptance = createAction(
  types.DENY_DELETE_ADDRESS_ACCEPTANCE,
  (id, message) => ({ id, message })
);

export const cancelDeleteAddressAcceptance = createAction(
  types.CANCEL_DELETE_ADDRESS_ACCEPTANCE
);

export const resetAddressFinderSearch = createAction(
  types.RESET_ADDRESS_FINDER_SEARCH
);

// Get addresses
export const getAddresses =
  (getAddressesApiFn = api.getAddresses) =>
  async (dispatch, getState, identity) => {
    if (selectors.getIsValid(getState())) {
      return null;
    }

    dispatch(requestAddresses());

    try {
      const response = await getAddressesApiFn(identity);
      return dispatch(receiveAddresses(response));
    } catch (err) {
      return dispatch(receiveAddresses(err));
    }
  };

export const resetAddressErrors = () => (dispatch) =>
  dispatch(unloadAddresses());

export const attemptDeleteAddress = (id) => async (dispatch, getState) => {
  const state = getState();

  const defaultBillingAddress = selectors.getDefaultBillingAddress(state);
  const defaultDeliveryAddress = selectors.getDefaultDeliveryAddress(state);

  if (
    defaultBillingAddress.addressId === id &&
    defaultDeliveryAddress.addressId === id
  ) {
    return dispatch(
      addAddressBookWarning(
        id,
        "ma_web_addressbook_deleteaddress_choosedifferentdefaultbillinganddelivery"
      )
    );
  } else if (defaultBillingAddress.addressId === id) {
    return dispatch(
      addAddressBookWarning(
        id,
        "ma_web_addressbook_deleteaddress_choosedifferentdefaultbilling"
      )
    );
  } else if (defaultDeliveryAddress.addressId === id) {
    return dispatch(
      addAddressBookWarning(
        id,
        "ma_web_addressbook_deleteaddress_choosedifferentdefaultdelivery"
      )
    );
  }

  return dispatch(requireDeleteAddressAcceptance(id));
};

export const acceptDeleteAddress =
  (
    userAction,
    deleteAddressApiFn = api.deleteAddress,
    getAddressesFn = getAddresses
  ) =>
  async (dispatch, getState, identity) => {
    dispatch(confirmDeleteAddressAcceptance());
    dispatch(requestDeleteAddress());

    const state = getState();
    const addressId = selectors.getAddressAcceptanceId(state);
    try {
      await deleteAddressApiFn(addressId, identity);
      dispatch(
        requestNotification(
          "generic",
          "ma_web_addressbook_deleteaddress_success"
        )
      );

      dispatch(confirmDeleteAddress(addressId));
      return dispatch(getAddressesFn());
    } catch (err) {
      dispatch(cancelDeleteAddressAcceptance());

      if (userAction === "deleteAddressInline") {
        return dispatch(
          addAddressBookError(
            addressId,
            "ma_web_addressbook_deleteaddress_unabletryagain"
          )
        );
      }

      return dispatch(apiError({ userAction }));
    }
  };

export const cancelDeleteAddress = () => (dispatch) =>
  dispatch(cancelDeleteAddressAcceptance());

// Change default billing address
export const confirmChangeDefaultAddress = createAction(
  types.CONFIRM_CHANGE_DEFAULT_ADDRESS,
  (id, billingAddress) => ({ id, billingAddress })
);

export const requestChangeDefaultAddress = createAction(
  types.REQUEST_CHANGE_DEFAULT_ADDRESS
);

export const changeDefaultAddress =
  (
    id,
    addressType,
    changeDefaultAddressFn = api.changeDefaultAddress,
    getAddressesFn = getAddresses
  ) =>
  async (dispatch, getState, identity) => {
    dispatch(requestChangeDefaultAddress());

    const state = getState();
    const oldDefaultAddress =
      addressType === constants.BILLING_ADDRESS
        ? selectors.getDefaultBillingAddress(state)
        : selectors.getDefaultDeliveryAddress(state);

    try {
      await changeDefaultAddressFn(
        oldDefaultAddress.addressId,
        id,
        addressType,
        identity
      );

      dispatch(
        confirmChangeDefaultAddress(
          id,
          addressType === constants.BILLING_ADDRESS
        )
      );

      if (addressType === constants.BILLING_ADDRESS) {
        dispatch(defaultBillingAddressUpdated());
      }

      dispatch(
        requestNotification(
          "success",
          addressType === constants.BILLING_ADDRESS
            ? "ma_web_addressbook_defaultbillingaddressupdated"
            : "ma_web_addressbook_defaultdeliveryaddressupdated"
        )
      );
      return dispatch(getAddressesFn());
    } catch (err) {
      dispatch(confirmChangeDefaultAddress(err));
      return dispatch(
        addAddressBookError(id, "ma_web_addressbook_addressupdateerror")
      );
    }
  };

export const addAddress =
  (
    newAddress,
    addAddressApiFn = api.addAddress,
    getAddressesFn = getAddresses
  ) =>
  async (dispatch, getState, identity) => {
    dispatch(requestAddAddress());
    const state = getState();
    const { addressFinder } = state.form.address.fields;
    const addressFinderUsed = addressFinder && addressFinder.autofilled;
    try {
      const response = await addAddressApiFn(newAddress, identity);
      dispatch(receiveAddAddress({ ...response, addressFinderUsed }));

      if (newAddress.defaultBilling) {
        dispatch(defaultBillingAddressUpdated());
      }

      dispatch(
        requestNotification(
          "success",
          "ma_web_addressbook_addaddresssuccessful"
        )
      );
      return dispatch(getAddressesFn());
    } catch (err) {
      if (supportedValidationError(err)) {
        return dispatch(receiveAddAddress(err));
      }

      err.apiValidationError = false;
      err.userAction = "addAddress";

      return dispatch(apiError(err));
    }
  };

// ADDRESS FINDER

const sanitiseAddressFinderResponse = (response) => ({
  items: response.Items.map((item) =>
    mapKeys(item, (value, key) => camelCase(key))
  ),
});

export const findAddress =
  (query, container, countryCode, findAddressApiFn = api.findAddress) =>
  async (dispatch, getState) => {
    dispatch(requestFindAddress());

    if (!query.length) {
      return dispatch(resetAddressFinderSearch());
    }

    const lang = getLanguage(getState());

    try {
      /*
      Workaround for WD-2659 and how postcodeanywhere.co.uk don't play
      nicely with spaces when escaped using percent encoding.
      1) Normalise all kinds of white space first,
      2) Then escape other characters
      3) Then change space escaping to be `+`.
    */
      const safeQuery = encodeURIComponent(query.replace(/\s+/g, "+")).replace(
        /%2B/g,
        "+"
      );
      const response = await findAddressApiFn(
        safeQuery,
        container,
        countryCode,
        lang
      );

      const sanitised = sanitiseAddressFinderResponse(response);
      const anyWithError = sanitised.items.find((item) => item && !!item.error);

      if (anyWithError) {
        throw new Error(anyWithError);
      }

      return dispatch(receiveFindAddress(sanitised));
    } catch (err) {
      return dispatch(receiveFindAddress(err));
    }
  };

const addressFinderSelectionMap = (payload) => ({
  address1: payload.company
    ? `${payload.company}, ${payload.line1}`
    : payload.line1,
  address2: payload.line3
    ? `${payload.line2}, ${payload.line3}`
    : payload.line2,
  locality: payload.city,
  countyStateProvinceOrArea: payload.provinceName,
  countyStateProvinceOrAreaCode:
    payload.provinceCode && `${payload.countryIso2}-${payload.provinceCode}`,
  postalCode: payload.postalCode,
});

export const retrieveAddress =
  (
    id,
    retrieveAddressApiFn = api.retrieveAddress,
    autofillFn = autofill,
    getFormSyncErrorsFn = getFormSyncErrors
  ) =>
  async (dispatch, getState) => {
    dispatch(requestRetrieveAddress());

    try {
      const response = await retrieveAddressApiFn(id);
      const formattedAddress = addressFinderSelectionMap(
        sanitiseAddressFinderResponse(response).items[0]
      );

      dispatch(autofillFn("address", "addressFinder", ""));

      const countryFieldNames = Object.keys(formattedAddress);
      countryFieldNames.forEach((field) => {
        dispatch(autofillFn("address", field, formattedAddress[field]));
      });

      const confirmSelectedAddress = dispatch(receiveRetrieveAddress());

      const formErrors = getFormSyncErrorsFn("address")(getState());
      if (formErrors && countryFieldNames.find((field) => formErrors[field])) {
        dispatch(openManualAddressFields());
      }
      return confirmSelectedAddress;
    } catch (err) {
      return dispatch(receiveRetrieveAddress(err));
    }
  };

export const editAddress =
  (
    updatedAddress,
    editAddressApiFn = api.editAddress,
    getAddressesFn = getAddresses
  ) =>
  async (dispatch, getState, identity) => {
    dispatch(requestEditAddress());
    try {
      const response = await editAddressApiFn(updatedAddress, identity);
      dispatch(receiveEditAddress(response));
      dispatch(
        requestNotification(
          "success",
          "ma_web_addressbook_editaddresssuccessful"
        )
      );

      if (updatedAddress.defaultBilling) {
        dispatch(defaultBillingAddressUpdated());
      }

      return dispatch(getAddressesFn());
    } catch (err) {
      if (supportedValidationError(err)) {
        return dispatch(receiveEditAddress(err));
      }

      err.apiValidationError = false;
      err.userAction = "editAddress";

      return dispatch(apiError(err));
    }
  };
