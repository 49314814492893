import { get as getXSiteOrigin } from "./x-site-origin-provider";

export const isMockedEnvironment = (getXSiteOriginFn = getXSiteOrigin) => {
  if (window.location.hostname.includes("my.asos.com")) {
    return false;
  }

  const xSiteOrigin = getXSiteOriginFn();
  if (!xSiteOrigin) {
    return false;
  }

  return xSiteOrigin.toLowerCase().includes("stub");
};
