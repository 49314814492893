import React from "react";

import getWindow from "@utils/window";

import AddGiftCard from "../add-gift-card";
import getInternationalGiftCardsLink from "../add-gift/getInternationalGiftCardsLink";

const hasCtaRefCo = () =>
  getWindow().location.search.indexOf("ctaRef=giftcard") !== -1;

export interface AddGiftCardRouteHandlerProps {
  browseCountry: string;
  getInternationalGiftCardsLinkFn?: (
    browseCountry: string,
    hasCtaRefCo: boolean
  ) => string;
  hasCtaRefCoFn?: () => boolean;
}

const AddGiftCardRouteHandler = ({
  browseCountry,
  getInternationalGiftCardsLinkFn = getInternationalGiftCardsLink,
  hasCtaRefCoFn = hasCtaRefCo,
}: AddGiftCardRouteHandlerProps) => {
  const internationalGiftCardsLink = getInternationalGiftCardsLinkFn(
    browseCountry,
    hasCtaRefCoFn()
  );

  if (internationalGiftCardsLink) {
    getWindow().location = internationalGiftCardsLink as unknown as Location;
    return null;
  } else {
    return <AddGiftCard />;
  }
};

export default AddGiftCardRouteHandler;
