import { connect } from "react-redux";

import { getCountry } from "@state/application/selectors";

import AddGiftCardRouteHandler from "./component";

const mapStateToProps = (state) => {
  const browseCountry = getCountry(state);

  return {
    browseCountry,
  };
};

export default connect(mapStateToProps)(AddGiftCardRouteHandler);
