import React from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import FieldLabel from "@components/field-label";
import StoreAddress from "@components/store-address";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../../components/button";

import styles from "./index.css";

export interface StoreDetailsProps {
  dataAutoId?: string;
  provider: {
    logoUri: string;
    name: string;
  };
  resetReturnLocation: () => void;
  store: {
    address: {
      address1: string;
      postalCode: string;
    };
    distanceInMeters: number;
    distanceInMiles: number;
    name: string;
  };
}

const StoreDetails = ({
  dataAutoId = undefined,
  provider,
  resetReturnLocation,
  store,
}: StoreDetailsProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <CardSection>
      <div className={styles.wrapper} data-auto-id={dataAutoId}>
        <div className={styles.heading}>
          <div className={styles.text}>
            <div className={styles.label}>
              <FieldLabel
                text={formatTranslation(
                  "ma_web_orderreturn_returnmethod_dropoff_selectedheading"
                )}
                inline
              />
            </div>
          </div>
        </div>
        <div className={styles.detail}>
          <div className={styles.text}>
            <div className={styles.name}>
              <img
                alt={provider.name}
                className={styles.icon}
                role="presentation"
                src={provider.logoUri}
              />
              {store.name}
            </div>
            <div className={styles.address}>
              <StoreAddress address={store.address} />
            </div>
          </div>
          <div className={styles.button}>
            <Button
              data-auto-id="changeDropOffLocation"
              buttonType="tertiary"
              onClick={resetReturnLocation}
              size="small"
            >
              {formatTranslation("ma_web_orderreturn_returnmethod_change")}
            </Button>
          </div>
        </div>
      </div>
    </CardSection>
  );
};

export default StoreDetails;
