import React from "react";

import RetryErrorWrapper from "@components/retry-error-wrapper";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../button";

import Styles from "./index.css";

export interface LoadMoreButtonProps {
  apiError: boolean;
  failureMessageKey: string;
  loading?: boolean;
  loadMore: () => void;
}

const LoadMoreButton = ({
  apiError,
  failureMessageKey,
  loading = false,
  loadMore,
}: LoadMoreButtonProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <RetryErrorWrapper
      failureMessage={failureMessageKey}
      overrideAction={() => loadMore()}
      isDisabled={loading}
      showSpinner
      apiError={apiError}
    >
      <div className={Styles.buttonWrapper}>
        <Button
          buttonType="secondary"
          data-auto-id="LoadMoreButton"
          onClick={() => loadMore()}
          disabled={loading}
          isInProgress={loading}
        >
          {!loading && formatTranslation("ma_web_pagination_loadmore")}
        </Button>
      </div>
    </RetryErrorWrapper>
  );
};

export default LoadMoreButton;
