import request from "asos.myaccount.request";

import { paginationSize } from "@configuration/gift-vouchers";

const vouchersEndpoint = "finance/vouchers/v2/vouchers";

const associateGiftCardEndpoint = (giftCardCode) =>
  `finance/giftcards/v1/giftcard/${giftCardCode}/associate`;

const associateGiftVoucherEndpoint = (giftVoucherCode) =>
  `finance/vouchers/v2/vouchers/${giftVoucherCode}/select`;

export const associateGiftCard = (
  { code, pin },
  identity,
  requestFn = request
) =>
  requestFn(associateGiftCardEndpoint(code), identity, {
    body: JSON.stringify({
      pinNumber: pin,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

export const associateGiftVoucher = async (
  { code },
  browseCountry,
  browseCurrency,
  identity,
  requestFn = request
) => {
  const customerId = identity.customer.customerId;

  const endpoint = associateGiftVoucherEndpoint(code);

  return requestFn(endpoint, identity, {
    body: JSON.stringify({
      CustomerId: customerId,
      country: browseCountry,
      currency: browseCurrency,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
};

export const getGiftVouchers = async (
  offset,
  browseCountry,
  browseCurrency,
  identity,
  requestFn = request
) =>
  requestFn(vouchersEndpoint, identity, {
    query: {
      CustomerId: identity.customer.customerId,
      limit: paginationSize,
      country: browseCountry,
      currency: browseCurrency,
      offset,
    },
  });
