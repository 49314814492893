import React from "react";

import Shocked from "@asosteam/asos-web-component-library-svg-icons/lib/shocked-emoji.svg";

import NoContentScreen from "@components/no-content-screen";
import { Translation } from "@components/translation";

import styles from "./index.css";

const ErrorSection = ({
  showCheckoutText = true,
}: {
  showCheckoutText?: boolean;
}) => (
  <NoContentScreen
    buttonLink="/"
    buttonText="ma_web_navigation_backtomyaccount"
    buttonType="secondary"
    title="ma_web_errors_oops"
    image={
      <img src={Shocked} className={styles.errorImage} alt="shocked emoji" />
    }
    showAsCard={false}
  >
    <span data-testid="error-section" role="alert">
      <Translation id="ma_web_errors_cannotshowanythingonpage" />
      <br />
      {showCheckoutText && <Translation id="ma_web_errors_checkoutmyaccount" />}
    </span>
  </NoContentScreen>
);

export default ErrorSection;
