export const API_ERROR = "API_USER_ERROR";
export const RECEIVE_PREMIER_SUBSCRIPTIONS = "RECEIVE_PREMIER_SUBSCRIPTIONS";
export const RECEIVE_PREMIER_STATUS = "RECEIVE_PREMIER_STATUS";
export const REQUEST_PREMIER_SUBSCRIPTIONS = "REQUEST_PREMIER_SUBSCRIPTIONS";
export const PREMIER_WAITING_ON_API = "PREMIER_WAITING_ON_API";
export const PREMIER_CLICK_JOIN = "PREMIER_CLICK_JOIN";
export const PREMIER_FREE_TRIAL_CLICK_JOIN = "PREMIER_FREE_TRIAL_CLICK_JOIN";
export const PREMIER_SUBSCRIPTION_MANAGE = "PREMIER_SUBSCRIPTION_MANAGE";
export const PREMIER_REDIRECTING = "PREMIER_REDIRECTING";
export const PREMIER_FINISHED_REDIRECTING = "PREMIER_FINISHED_REDIRECTING";
