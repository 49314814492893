import React from "react";

import FormField from "@components/form-field";
import Select from "@components/select";

type Options = {
  selected?: boolean;
  text: string;
  value: any;
};

export type DropdownProps = {
  label?: string;
  meta: {
    form?: string;
    error?: {
      id: string;
    };
    touched?: boolean;
  };
  options: Options[];
  input: {
    name: string;
    value: any;
    onChange: () => void;
    onBlur: () => void;
  };
  placeholder?: string;
  hasMargin?: boolean;
  required?: boolean;
  showLabel?: boolean;
  hidden?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  hasMargin = true,
  hidden = false,
  input,
  label = undefined,
  meta,
  options,
  placeholder = undefined,
  required = false,
  showLabel = true,
  ...props
}) => {
  const ariaDescribedBy = {
    error: `error-${input.name}`,
    label: `label-${input.name}`,
  };
  return (
    <FormField
      {...meta}
      ariaDescribedBy={ariaDescribedBy}
      data-auto-id={`${meta.form}-${input.name}`}
      hasMargin={hasMargin}
      hidden={hidden}
      label={label}
      showLabel={showLabel}
    >
      <Select
        {...props}
        {...input}
        hidden={hidden}
        aria-describedby={`${ariaDescribedBy.label} ${ariaDescribedBy.error}`}
        aria-invalid={meta.error && meta.touched}
        aria-label={!showLabel ? label : null}
        aria-required={required}
        options={options}
        placeholder={placeholder}
      />
    </FormField>
  );
};

export default Dropdown;
