import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { premierHandleRedirect as premierHandleRedirectAction } from "@state/premier/actions";
import {
  hasSubscriptionOptions as hasSubscriptionOptionsSelector,
  showPremierAutoRenewals as showPremierAutoRenewalsSelector,
} from "@state/subscription-options/selectors";

import { redirectToUrl, validateUrl } from "@utils/url";

const stripeRedirectKey = "premierStripeRedirect";

export const useUrlPremierRedirects = () => {
  const dispatch = useDispatch();
  const { showPremierAutoRenewals, hasSubscriptionOptions } = useSelector(
    (state) => ({
      hasSubscriptionOptions: hasSubscriptionOptionsSelector(state),
      showPremierAutoRenewals: showPremierAutoRenewalsSelector(state),
    })
  );

  const [redirectionInProgress, setRedirectionInProgress] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const directParam = searchParams.get("direct");
  const urlParam = searchParams.get("redirectUrl");
  const redirectUrl = validateUrl(urlParam) ? urlParam : "";

  const isSubscriptionOptionId = searchParams.get("subscriptionOptionId");
  const subscriptionOptionId = isSubscriptionOptionId
    ? parseInt(isSubscriptionOptionId)
    : null;
  const trialOfferId = searchParams.get("trialOfferId");

  const hasAlreadyRedirected = searchParams.get(stripeRedirectKey);

  const handleUrlParamRedirect = (callback) => {
    if (!hasSubscriptionOptions) {
      return;
    }

    if (!hasAlreadyRedirected && showPremierAutoRenewals) {
      searchParams.set(stripeRedirectKey, "true");
      setSearchParams(searchParams);

      setRedirectionInProgress(true);

      callback();
    } else if (hasAlreadyRedirected) {
      searchParams.delete(stripeRedirectKey);
      setSearchParams(searchParams);

      if (redirectUrl) {
        redirectToUrl(redirectUrl);
      }
    }
  };

  useEffect(() => {
    if (directParam === "purchase") {
      handleUrlParamRedirect(() => {
        dispatch(
          premierHandleRedirectAction({
            ...(subscriptionOptionId && { subscriptionOptionId }),
            ...(redirectUrl && { redirectUrl }),
            ...(trialOfferId && { trialOfferId }),
            direct: true,
          })
        );
      });
    } else {
      setRedirectionInProgress(false);
    }
  }, [showPremierAutoRenewals, hasSubscriptionOptions]);

  return {
    redirectionInProgress,
  };
};
