import { createAction } from "redux-actions";

import { sdk as audienceSDK } from "@asosteam/asos-web-audience-sdk";

import { REQUEST_AUDIENCE_SEGMENTS, RECEIVE_AUDIENCE_SEGMENTS } from "./types";

export const requestAudienceSegments = createAction(REQUEST_AUDIENCE_SEGMENTS);
export const receiveAudienceSegments = createAction(RECEIVE_AUDIENCE_SEGMENTS);

const authHeader = async (identity) => {
  const token = await identity.getAccessToken();
  return `Bearer ${token}`;
};

export const getAudienceSegments =
  () => async (dispatch, getState, identity) => {
    dispatch(requestAudienceSegments());

    try {
      const initialisedSDK = audienceSDK({
        getAuthHeader: () => authHeader(identity),
        getCustomerGuid: () => identity.customer.customerGuid,
      });

      const segments = await initialisedSDK.getSegments();

      return dispatch(receiveAudienceSegments(segments));
    } catch (err) {
      return dispatch(receiveAudienceSegments(err));
    }
  };
