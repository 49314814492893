import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styles from "./index.css";

type LoadingListProps = {
  children?: React.ReactElement[];
  component: string | (() => void) | React.ComponentType<any>;
};

const LoadingList: React.FC<LoadingListProps> = ({
  children = undefined,
  component,
}) => (
  <TransitionGroup component={component}>
    {children.map((child) => (
      <CSSTransition
        classNames={{
          enter: styles.entry,
          enterActive: styles.active,
        }}
        timeout={{ enter: 500, exit: 500 }}
        key={child.key}
      >
        {child}
      </CSSTransition>
    ))}
  </TransitionGroup>
);

export default LoadingList;
