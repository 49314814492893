import React from "react";
import { Field } from "redux-form";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import InputFieldWithClear from "@components/input-field-with-clear";
import RetryErrorWrapper from "@components/retry-error-wrapper";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { dropOff } from "@configuration/return-items";

import { Button } from "../../../../../components/button";

import styles from "./component.css";

export interface DropOffFormProps {
  handleSubmit: (arg0: any) => void;
  loading: boolean;
  searchError: boolean;
}

const DropOffForm = ({
  handleSubmit,
  loading,
  searchError,
}: DropOffFormProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <CardSection hasBottomPadding={false} hasTopPadding={false}>
        <Field
          {...dropOff.fields.search}
          id="DropOffSearch"
          label={formatTranslation(dropOff.fields.search.label)}
          placeholder={formatTranslation(dropOff.fields.search.placeholder)}
          component={InputFieldWithClear}
          name="search"
        />
      </CardSection>
      <div className={styles.buttons}>
        <RetryErrorWrapper
          failureMessage="ma_web_orderreturn_returnmethod_dropoff_error"
          apiError={searchError}
        >
          <Button
            type="submit"
            data-auto-id="search-drop-off-btn"
            isInProgress={loading}
          >
            {!loading &&
              formatTranslation("ma_web_orderreturn_dropoff_searchbutton")}
          </Button>
        </RetryErrorWrapper>
      </div>
    </form>
  );
};

export default DropOffForm;
