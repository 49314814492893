import React from "react";

import classnames from "classnames";

import styles from "./index.css";

type ListBlockSectionProps = {
  children: React.ReactNode;
  isSlim?: boolean;
};

const ListBlockSection: React.FC<ListBlockSectionProps> = ({
  children,
  isSlim = false,
}) => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.slim]: isSlim,
    })}
  >
    {children}
  </div>
);

export default ListBlockSection;
