import React from "react";

import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LEAVESDEN_3 } from "@asosteam/asos-web-component-library-typography";

import { Translation } from "@components/translation";

interface LoadingContainerProps {
  isLastPage: boolean;
  lastPageLabel: string;
  remainingPageLabel: string;
  itemsCount: number;
  total: number;
}

const LoadingIndicator = ({
  isLastPage,
  lastPageLabel,
  remainingPageLabel,
  itemsCount,
  total,
}: LoadingContainerProps) => (
  <Paragraph sizing={LEAVESDEN_3}>
    <Translation
      id={isLastPage ? lastPageLabel : remainingPageLabel}
      values={{
        smart_count: itemsCount,
        total,
      }}
    />
  </Paragraph>
);

export default LoadingIndicator;
