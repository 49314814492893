import React from "react";
import Helmet from "react-helmet";

import PropTypes from "prop-types";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

if (process.env.NODE_ENV === "test") {
  Helmet.canUseDOM = false;
}

const PageInfo = ({ meta, title, ...rest }) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Helmet
      title={formatTranslation(title)}
      titleTemplate="%s | ASOS"
      meta={[
        ...meta,
        { property: "og:title", content: formatTranslation(title) },
      ]}
      {...rest}
    />
  );
};

PageInfo.propTypes = {
  ...Helmet.propTypes,
  meta: PropTypes.arrayOf(PropTypes.shape({})),
};

PageInfo.defaultProps = {
  meta: [],
};

export default PageInfo;
