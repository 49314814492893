export const addressForm = {
  default: {
    fields: {
      firstName: {
        label: "ma_web_mydetails_firstname",
        maxLength: 100,
        required: true,
      },
      lastName: {
        label: "ma_web_mydetails_lastname",
        maxLength: 100,
        required: true,
      },
      telephoneMobile: {
        label: "ma_web_addressbook_phonenumber",
        maxLength: 25,
        required: true,
      },
      countryCode: {
        label: "ma_web_addressbook_country",
        required: true,
      },
      addressFinder: {
        label: "ma_web_addressbook_addressfinder",
        maxLength: 100,
        placeholder: "ma_web_addressbook_startbytypingfirstline",
      },
      address1: {
        label: "ma_web_addressbook_address1",
        maxLength: 35,
        required: true,
      },
      address2: {
        label: "ma_web_addressbook_address2",
        maxLength: 35,
        required: false,
      },
      locality: {
        label: "ma_web_addressbook_city",
        maxLength: 32,
        required: true,
      },
      countyStateProvinceOrArea: {
        label: "ma_web_addressbook_county",
        maxLength: 32,
        required: false,
      },
      countyStateProvinceOrAreaCode: {
        label: "ma_web_addressbook_county",
        required: true,
      },
      postalCode: {
        label: "ma_web_addressbook_postalcode",
        maxLength: 10,
        required: true,
      },
    },
    initialValues: {
      countyStateProvinceOrArea: "",
      countyStateProvinceOrAreaCode: "",
      addressFinder: "",
      postalCode: "",
    },
    validationRules: {
      address1: {
        missingAddress1: "ma_web_addressbook_validation_address1",
      },
      address2: {},
      countryCode: {
        missingCountryCode: "ma_web_addressbook_validation_countrycode",
      },
      countyStateProvinceOrArea: {
        invalidCharactersCountyStateProvinceOrArea:
          "ma_web_addressbook_validation_countystateprovinceorareainvalid",
      },
      countyStateProvinceOrAreaCode: {
        missingCountyStateProvinceOrAreaCode:
          "ma_web_addressbook_validation_county",
      },
      firstName: {
        missingAddressFirstName: "ma_web_addressbook_validation_firstname",
        invalidCharactersAddressFirstName:
          "ma_web_mydetails_validation_firstnameinvalid",
      },
      lastName: {
        missingAddressLastName: "ma_web_addressbook_validation_lastname",
        invalidCharactersAddressLastName:
          "ma_web_mydetails_validation_lastnameinvalid",
      },
      locality: {
        missingLocality: "ma_web_addressbook_validation_city",
      },
      postalCode: {
        invalidPostalCodeForCountry:
          "ma_web_addressbook_validation_postalcodeinvalidforcountry",
        missingPostalCode: "ma_web_addressbook_validation_postalcode",
      },
      telephoneMobile: {
        invalidTelephoneMobile:
          "ma_web_addressbook_validation_telephonemobileinvalid",
        missingTelephoneMobile: "ma_web_addressbook_validation_telephonemobile",
      },
    },
  },
  AU: {
    fields: {
      countyStateProvinceOrAreaCode: {
        label: "ma_web_addressbook_state",
      },
      locality: {
        label: "ma_web_addressbook_suburb",
      },
    },
    validationRules: {
      locality: {
        missingLocality: "ma_web_addressbook_validation_suburb",
      },
      countyStateProvinceOrAreaCode: {
        missingCountyStateProvinceOrAreaCode:
          "ma_web_addressbook_validation_state",
      },
    },
  },
  CA: {
    fields: {
      countyStateProvinceOrAreaCode: {
        label: "ma_web_addressbook_province",
      },
      postalCode: {
        label: "ma_web_addressbook_postalcode",
        pattern: "(^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\\s{1}[0-9][A-Z][0-9]$)",
      },
    },
    validationRules: {
      countyStateProvinceOrArea: {
        missingCountyStateProvinceOrAreaCode:
          "ma_web_addressbook_validation_province",
      },
      postalCode: {
        invalidPostalCodeForCountry:
          "ma_web_addressbook_validation_zipcodeinvalidforcountry",
        missingPostalCode: "ma_web_addressbook_validation_zipcode",
      },
    },
  },
  CN: {
    fields: {
      countyStateProvinceOrAreaCode: {
        label: "ma_web_addressbook_province",
      },
    },
    validationRules: {
      countyStateProvinceOrAreaCode: {
        missingCountyStateProvinceOrAreaCode:
          "ma_web_addressbook_validation_province",
      },
    },
  },
  RU: {
    fields: {
      countyStateProvinceOrAreaCode: {
        label: "ma_web_addressbook_area",
      },
    },
    validationRules: {
      countyStateProvinceOrAreaCode: {
        missingCountyStateProvinceOrAreaCode:
          "ma_web_addressbook_validation_area",
      },
    },
  },
  US: {
    fields: {
      countyStateProvinceOrAreaCode: {
        label: "ma_web_addressbook_state",
      },
      locality: {
        label: "ma_web_addressbook_suburb",
      },
      postalCode: {
        label: "ma_web_addressbook_zipcode",
        pattern: "(^\\d{5}$)|(^\\d{5}[ |-]\\d{4}$)",
      },
    },
    validationRules: {
      locality: {
        missingLocality: "ma_web_addressbook_validation_suburb",
      },
      countyStateProvinceOrAreaCode: {
        missingCountyStateProvinceOrAreaCode:
          "ma_web_addressbook_validation_state",
      },
      postalCode: {
        invalidPostalCodeForCountry:
          "ma_web_addressbook_validation_zipcodeinvalidforcountry",
        missingPostalCode: "ma_web_addressbook_validation_zipcode",
      },
    },
  },
  XK: {
    fields: {
      countyStateProvinceOrArea: {
        disabled: true,
      },
    },
    initialValues: {
      countyStateProvinceOrArea: "Kosovo",
    },
  },
  GB: {
    fields: {
      addressFinder: {
        placeholder: "ma_web_addressbook_searchbypostcode",
      },
    },
  },
} as const;
