const getCountries = (state) => state.countries;

export const hasLoaded = (state) => getCountries(state).loaded;

export const getIsValid = (state) => getCountries(state).valid;

export const getCountryCodes = (state) => getCountries(state).countryCodes;

export const getCountriesByCountryCode = (state) =>
  getCountries(state).countriesByCountryCode;

export const getRegionsByCountryCode = (state) =>
  getCountries(state).regionsByCountryCode;

export const getRegionsForCountryCode = (state, countryCode) =>
  getRegionsByCountryCode(state)[countryCode];

export const getCountryForCountryCode = (state, countryCode) =>
  getCountriesByCountryCode(state)[countryCode];
