import React from "react";

import classnames from "classnames";

import { Heading } from "@asosteam/asos-web-component-library-heading";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import Logo from "@asosteam/asos-web-component-library-svg-icons/lib/logo.svg";
import { LONDON_5 } from "@asosteam/asos-web-component-library-typography";

import CountryFlagIcon from "@components/country-flag-icon";
import Price from "@components/price";
import { Translation } from "@components/translation";

import { giftCard } from "@configuration/voucher-types";

import voucher from "./images/gift-voucher.svg";

import styles from "./index.css";

export interface GiftCardProps {
  country?: string;
  currency: string;
  disabled: boolean;
  language: string;
  value: number;
  voucherType: string;
}

const GiftCard = ({
  country = "",
  currency,
  disabled,
  language,
  value,
  voucherType,
}: GiftCardProps) => {
  const asosLogo = Logo;
  const hasFlag = country !== "";

  return (
    <div className={styles.cardWrapper}>
      <div
        className={classnames(styles.card, {
          [styles.cardDisabled]: disabled,
        })}
      >
        <img alt="" src={voucher} className={styles.cardBackground} />
        <img alt="" src={asosLogo} className={styles.asosLogo} />
        <div
          className={classnames(styles.cardDetails, {
            [styles.giftCardBow]: voucherType === giftCard,
            [styles.spaceUpBottom]: hasFlag,
          })}
        >
          <Heading
            sizing={LONDON_5}
            domElement="h3"
            className={styles.currentValue}
            isUppercase={true}
          >
            <Translation id="ma_web_giftcardsandvouchers_currentvalue" />
          </Heading>
          {hasFlag && (
            <span className={styles.flagIcon}>
              <CountryFlagIcon id={country} size="small" />
            </span>
          )}
          <Paragraph
            sizing="london1"
            isSecondary
            className={classnames(styles.value, {
              [styles.valueWithFlag]: hasFlag,
            })}
          >
            <Price currency={currency} language={language} value={value} />
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default GiftCard;
