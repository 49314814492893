// Create range inclusive of `from` and `exclusive of `to`.
// Can also produce negative ranges
// Ex. createRange(10, 5) returns [5, 6, 7, 8, 9]
export const createRange = (to, from = 0) =>
  Array.from(new Array(Math.abs(to - from)), (x, i) =>
    to > from ? i + from : from - i
  );

export const uniqueBy = (prop, arr) =>
  arr.reduce(
    (all, current) =>
      all.find((item) => item[prop] === current[prop])
        ? all
        : [...all, current],
    []
  );

const updateArray = (array, condition, action) =>
  array.map((item, index) => {
    if (!condition(item, index)) {
      return item;
    }
    return action(item);
  });

// Immutably updates an object within an array without altering the order the original array.
// Inspired by http://redux.js.org/docs/recipes/reducers/ImmutableUpdatePatterns.html#updating-an-item-in-an-array
export const updateObjectInArray = (array, condition, update) => {
  const action = (item) => ({
    ...item,
    ...update(item),
  });

  return updateArray(array, condition, action);
};

export const replaceInArray = (array, condition, replacement) => {
  const action = () => replacement;
  return updateArray(array, condition, action);
};

export const moveItem = (arr, from, to) => {
  if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
    return arr;
  }
  const clone = [...arr];
  Array.prototype.splice.call(
    clone,
    to,
    0,
    Array.prototype.splice.call(clone, from, 1)[0]
  );
  return clone;
};

export const uniqueByProperty = (array, propertyName) =>
  array.filter(
    (entry, i) =>
      array.findIndex((a) => a[propertyName] === entry[propertyName]) === i
  );
