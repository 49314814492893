import React from "react";

import classnames from "classnames";

import Error from "@components/error";
import FieldLabel from "@components/field-label";

import styles from "./index.css";

type AriaDescribedBy = {
  label?: string;
  error?: string;
};

export type FormFieldsetProps = {
  ariaDescribedBy: AriaDescribedBy;
  autofilled?: boolean;
  children: React.ReactNode;
  className?: string;
  error?: Record<any, any>;
  hasMargin?: boolean;
  hasSmallMargin?: boolean;
  hidden?: boolean;
  label: string;
  role?: string;
  showLabel?: boolean;
  touched?: boolean;
  showRequired?: boolean;
};

export const FormFieldset: React.FC<FormFieldsetProps> = ({
  ariaDescribedBy,
  autofilled = false,
  children,
  className = "",
  error = null,
  hasMargin = true,
  hasSmallMargin = false,
  hidden = false,
  label,
  role = null,
  showLabel = true,
  showRequired = false,
  touched = false,
}) => (
  <fieldset
    aria-label={!showLabel ? label : null}
    className={classnames(className, {
      [styles.notVisible]: hidden,
      [styles.hasSmallMargin]: hasSmallMargin,
      [styles.hasMargin]: hasMargin,
      [styles.hasError]: error && touched,
    })}
    data-has-error={error && touched ? true : null}
    role={role}
  >
    {showLabel && (
      <legend className={styles.label}>
        <FieldLabel text={label} showRequired={showRequired} />
      </legend>
    )}
    <span className={styles.inputWrapper}>{children}</span>
    {error && (touched || autofilled) && (
      <Error id={ariaDescribedBy.error} type={error.id} values={error.values} />
    )}
  </fieldset>
);

FormFieldset.displayName = "FormFieldset";

export type FormFieldProps = {
  ariaDescribedBy: AriaDescribedBy;
  autofilled?: boolean;
  children: React.ReactNode;
  "data-auto-id"?: string;
  error?: Record<any, any>;
  hasMargin?: boolean;
  hasSmallMargin?: boolean;
  hidden?: boolean;
  label?: string;
  showLabel?: boolean;
  showRequired?: boolean;
  touched?: boolean;
};
/* eslint-disable jsx-a11y/label-has-for */
const FormField: React.FC<FormFieldProps> = ({
  ariaDescribedBy,
  autofilled = false,
  children,
  error = null,
  hasMargin = true,
  hasSmallMargin = false,
  hidden = false,
  label = undefined,
  showLabel = true,
  showRequired = false,
  touched = false,
  ...props
}) => {
  const autoId = props["data-auto-id"] || undefined;
  return (
    <>
      <label
        htmlFor={ariaDescribedBy.label}
        aria-label={!showLabel ? label : null}
        className={classnames(styles.label, {
          [styles.notVisible]: hidden,
          [styles.hasMargin]: hasMargin,
          [styles.hasSmallMargin]: hasSmallMargin,
          [styles.hasError]: error && touched,
        })}
        data-auto-id={autoId}
        data-has-error={error && touched ? true : null}
      >
        {showLabel && <FieldLabel text={label} showRequired={showRequired} />}
        <span className={styles.inputWrapper}>{children}</span>
      </label>
      {error && (touched || autofilled) && (
        <Error
          data-auto-id="field-error"
          type={error.id}
          link={error.link}
          id={ariaDescribedBy.error}
          values={error.values}
        />
      )}
    </>
  );
};

export default FormField;
