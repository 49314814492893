import { connect } from "react-redux";

import { appLoaded } from "@state/application/selectors";
import {
  getFirstName as getUserFirstName,
  getLastName as getUserLastName,
} from "@state/user/selectors";

import NavigationGreeting from "./component";

const mapStateToProps = (state) => ({
  firstName: getUserFirstName(state),
  lastName: getUserLastName(state),
  appLoaded: appLoaded(state),
});

export default connect(mapStateToProps)(NavigationGreeting);
