import { handleActions } from "redux-actions";

import orderBy from "lodash.orderby";

import { getReturnDetailsIndexFromReturnReference } from "./selectors";
import { REQUEST_RETURN_DETAILS, RECEIVE_RETURN_DETAILS } from "./types";

const reducer = handleActions(
  {
    [REQUEST_RETURN_DETAILS]: (state) => ({
      ...state,
      detailsFatalError: false,
      loadedDetails: false,
    }),
    [RECEIVE_RETURN_DETAILS]: (state, { error, payload }) => {
      const existingEntry = getReturnDetailsIndexFromReturnReference({
        returnDetails: state,
      })(payload.returnReference);
      const hasExistingEntry = existingEntry !== -1;
      if (error) {
        return {
          ...state,
          detailsFatalError: !hasExistingEntry,
          loadedDetails: true,
        };
      }

      const { productItems, statusHistory, ...rest } = payload;
      const detail = {
        ...rest,
        items: productItems,
        statusHistory: orderBy(statusHistory, "date", "asc"),
      };

      if (hasExistingEntry) {
        return {
          ...state,
          details: [
            ...state.details.slice(0, existingEntry),
            detail,
            ...state.details.slice(existingEntry + 1),
          ],
          detailsFatalError: false,
          loadedDetails: true,
        };
      }

      return {
        ...state,
        details: [...state.details, detail],
        detailsFatalError: false,
        loadedDetails: true,
      };
    },
  },
  {
    details: [],
    detailsFatalError: false,
    loadedDetails: false,
  }
);

export default reducer;
