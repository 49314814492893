/* eslint-disable no-control-regex, no-useless-escape */
import { differenceInYears, isFuture, getDaysInMonth } from "date-fns";

export const isNotPresent = (field) =>
  !field || new RegExp(/^\s+$/).test(field);

export const isInvalidField = (field) => new RegExp(/["&<>]/).test(field);

export const isInvalidName = (field) => new RegExp(/["&<>.]/).test(field);

export const isInvalidPhoneNumber = (field) =>
  !new RegExp(/^(\s*\d){1,25}\s*$/).test(field);

export const isGreaterThanXCharacters = (value, maxLength) =>
  value.length > maxLength;

export const isInvalidEmailAddress = (email) => {
  const emailRegex = new RegExp(
    /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{1,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/g
  );

  return !emailRegex.test(email);
};

export const dateIsOver100YearsAgo = ({ year, month, day }) =>
  differenceInYears(new Date(), new Date(year, month, day)) >= 100;

export const dateIsInFuture = ({ year, month, day }) =>
  isFuture(new Date(year, month, day));

export const dateHasInvalidDay = ({ year, month, day }) =>
  getDaysInMonth(new Date(year, month, 1)) < day;

export const containsCyrillicCharacters = (field) =>
  new RegExp(/[\u0400-\u04FF]/).test(field);

export const containsNonAsciiCharacters = (field) =>
  new RegExp(/[^\x00-\x7F]/).test(field);

export const containsOnlyLatinCharacters = (string) =>
  new RegExp(
    /^[\u0000-\u0131\u0134-\u0137\u0139-\u013e\u0141-\u0148\u014c-\u017e\u0189\u0191-\u0192\u0197\u019a\u019f-\u01a1\u01ab\u01ae-\u01b0\u01b6\u01c0\u01c3\u01cd-\u01dc\u01de-\u01df\u01e4-\u01ed\u01f0]*$/ // eslint-disable-line no-control-regex
  ).test(string);

export const containsXssRiskCharacters = (string) =>
  new RegExp(/[<>]/).test(string);

export const hasInValidPattern = (field, pattern) =>
  pattern && !new RegExp(pattern).test(field);
