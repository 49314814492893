import React from "react";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";

import HelpAccordion from "@components/help-accordion";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

export interface GiftHelpAccordionProps {
  title?: string;
  topLinks?: Array<{
    id: string;
    link?: string;
    onClick?: () => void;
    textKey: string;
  }>;
}

const GiftHelpAccordion = ({
  title = null,
  topLinks = [],
}: GiftHelpAccordionProps) => {
  const formatTranslation = useFormatTranslation();
  const giftTypes = [
    {
      method: formatTranslation(
        "ma_web_giftcardsandvouchers_whatisagiftcard_title"
      ),
      body: (
        <div>
          <Paragraph>
            {formatTranslation(
              "ma_web_giftcardsandvouchers_whatisagiftcard_descriptionline1"
            )}
          </Paragraph>
          <Paragraph>
            {formatTranslation(
              "ma_web_giftcardsandvouchers_whatisagiftcard_descriptionline2"
            )}
          </Paragraph>
        </div>
      ),
      links: [
        {
          label: formatTranslation("ma_web_footer_terms"),
          link: "ExternalLink.Footer.Terms",
        },
        {
          label: formatTranslation(
            "ma_web_giftcardsandvouchers_whatisagiftcard_faq"
          ),
          link: "ExternalLink.GiftCardsAndVouchers.GiftCardVouchersFAQ",
        },
      ],
    },
    {
      method: formatTranslation(
        "ma_web_giftcardsandvouchers_whatisagiftvoucher_title"
      ),
      body: (
        <div>
          <Paragraph>
            {formatTranslation(
              "ma_web_giftcardsandvouchers_whatisagiftvoucher_descriptionline1"
            )}
          </Paragraph>
          <Paragraph>
            {formatTranslation(
              "ma_web_giftcardsandvouchers_whatisagiftvoucher_descriptionline2"
            )}
          </Paragraph>
        </div>
      ),
      links: [
        {
          label: formatTranslation("ma_web_footer_terms"),
          link: "ExternalLink.Footer.Terms",
        },
        {
          label: formatTranslation(
            "ma_web_giftcardsandvouchers_whatisagiftvoucher_faq"
          ),
          link: "ExternalLink.GiftCardsAndVouchers.GiftCardVouchersFAQ",
        },
      ],
    },
  ];

  return (
    <Card>
      <CardSection>
        <HelpAccordion
          items={giftTypes}
          title={title}
          topLinks={topLinks}
          isSemantic={false}
        />
      </CardSection>
    </Card>
  );
};

export default GiftHelpAccordion;
