export const API_ERROR = "API_LINKED_ACCOUNTS_ERROR";

export const ATTEMPT_UNLINK_CURRENT_LOGIN_PROVIDER =
  "ATTEMPT_UNLINK_CURRENT_LOGIN_PROVIDER";

export const REQUEST_CONNECT_LINKED_ACCOUNT = "REQUEST_CONNECT_LINKED_ACCOUNT";

export const RECEIVE_LINKED_ACCOUNTS = "RECEIVE_LINKED_ACCOUNTS";
export const REQUEST_LINKED_ACCOUNTS = "REQUEST_LINKED_ACCOUNTS";

export const RECEIVE_DISCONNECT_LINKED_ACCOUNT =
  "RECEIVE_DISCONNECT_LINKED_ACCOUNT";
export const REQUEST_DISCONNECT_LINKED_ACCOUNT =
  "REQUEST_DISCONNECT_LINKED_ACCOUNT";

export const RECEIVE_CURRENT_LOGGED_IN_PROVIDER =
  "RECEIVE_CURRENT_LOGGED_IN_PROVIDER";
