/* eslint-disable import/no-duplicates */
import { format, Locale } from "date-fns";
import { da, de, enGB, es, fr, it, nl, pl, ru, sv } from "date-fns/locale";

import { useLocaleContext } from "../context/LocaleContext";

const SUPPORTED_LOCALES: Record<string, Locale> = {
  da,
  de,
  en: enGB,
  es,
  fr,
  it,
  nl,
  pl,
  ru,
  sv,
};

export const useFormatDate = () => {
  const { baseLanguageCode } = useLocaleContext();

  return (dateFormat: string, date: Date): string => {
    const locale = SUPPORTED_LOCALES[baseLanguageCode];

    return format(date, dateFormat, { locale });
  };
};
