export const CLEAR_CANCEL_ORDER = "CLEAR_CANCEL_ORDER";

export const CLEAR_ORDER_CANCEL_REASONS_ATTEMPTS =
  "CLEAR_ORDER_CANCEL_REASONS_ATTEMPTS";
export const RECEIVE_ORDER_CANCEL_REASONS = "RECEIVE_ORDER_CANCEL_REASONS";
export const REQUEST_ORDER_CANCEL_REASONS = "REQUEST_ORDER_CANCEL_REASONS";

export const RECEIVE_ORDER_DETAILS = "RECEIVE_ORDER_DETAILS";
export const REQUEST_ORDER_DETAILS = "REQUEST_ORDER_DETAILS";

export const RECEIVE_ORDERS = "RECEIVE_ORDERS";
export const REQUEST_ORDERS = "REQUEST_ORDERS";

export const RECEIVE_CANCEL_ORDER = "RECEIVE_CANCEL_ORDER";
export const REQUEST_CANCEL_ORDER = "REQUEST_CANCEL_ORDER";

export const API_ERROR = "API_ERROR";

export const OPEN_TRACK_HELP = "OPEN_HELP_URL";

export const OPEN_TRACK_PARCEL = "OPEN_TRACK_PARCEL_URL";

export const PRODUCT_DETAILS = "PRODUCT_DETAILS";

export const REQUEST_PAYMENT_DETAILS = "REQUEST_PAYMENT_DETAILS";
export const RECEIVE_PAYMENT_DETAILS = "RECEIVE_PAYMENT_DETAILS";
export const UPDATE_RETURN_RMA_REQUEST_DATE_TIME =
  "UPDATE_RETURN_RMA_REQUEST_DATE_TIME";
