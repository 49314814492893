export const onlyNumbers = (field) => new RegExp(/^[0-9]*$/).test(field);

const isInApiErrorCodes = (errorCodes) => (code) => errorCodes.includes(code);

const validateCode = (value, hasApiError) => {
  if (!value) {
    return { id: "ma_web_addgiftcard_missingcode" };
  }
  if (value.length !== 16 || !onlyNumbers(value)) {
    return { id: "ma_web_addgiftcard_giftcardnumberinvalid" };
  }
  if (hasApiError("GiftCardAlreadyAssociated")) {
    return { id: "ma_web_addgiftcard_giftcardalreadyassociated" };
  }
  if (hasApiError("GiftCardExpired")) {
    return { id: "ma_web_addgiftcard_giftcardexpired" };
  }
  if (hasApiError("GiftCardNotFoundOrInvalidPin")) {
    return { id: "ma_web_addgiftcard_giftcardnotfoundorinvalidpin" };
  }
  if (hasApiError("GiftCardNotActivated")) {
    return { id: "ma_web_addgiftcard_giftcardnotactivated" };
  }
  if (hasApiError("GiftCardNumberInvalid") || hasApiError("GiftCardInvalid")) {
    return { id: "ma_web_addgiftcard_giftcardnumberinvalid" };
  }
  return undefined;
};

const validatePin = (value, hasApiError) => {
  if (!value) {
    return { id: "ma_web_addgiftcard_missingpin" };
  }
  if (value.length !== 4 || !onlyNumbers(value)) {
    return { id: "ma_web_addgiftcard_giftcardpininvalid" };
  }
  if (hasApiError("GiftCardPinInvalid")) {
    return { id: "ma_web_addgiftcard_giftcardpininvalid" };
  }
  return undefined;
};

const validate = ({ code, pin, errorCodes = [] }) => {
  const hasApiError = isInApiErrorCodes(errorCodes);
  return {
    code: validateCode(code, hasApiError),
    pin: validatePin(pin, hasApiError),
  };
};

export default validate;
