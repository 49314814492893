import { createAction } from "redux-actions";

import { requestNotification } from "../application/actions";
import * as api from "./api";
import { getIfDetailsLoaded } from "./selectors";
import * as types from "./types";

export const finishUpdateUserDetails = createAction(
  types.FINISH_UPDATE_USER_DETAILS
);
export const requestUpdateUserDetails = createAction(
  types.REQUEST_UPDATE_USER_DETAILS
);
export const receiveUserDetails = createAction(types.RECEIVE_USER_DETAILS);
export const requestUserDetails = createAction(types.REQUEST_USER_DETAILS);

export const clickRenewSubscription = createAction(
  types.CLICK_RENEW_SUBSCRIPTION
);

export const linkRenewSubscription = () => (dispatch) => {
  dispatch(clickRenewSubscription());
};

export const lastUpdatedUserDetailsFields = createAction(
  types.UPDATE_USER_DETAILS_UPDATED_FIELDS,
  (fieldName) => ({ fieldName })
);

export const apiError = createAction(types.API_ERROR);

export const getUserDetails =
  (getUserFn = api.getUser) =>
  async (dispatch, getState, identity) => {
    const state = getState();
    if (getIfDetailsLoaded(state)) {
      return null;
    }

    dispatch(requestUserDetails());

    try {
      const response = await getUserFn(identity);

      return dispatch(receiveUserDetails(response));
    } catch (err) {
      return dispatch(receiveUserDetails(err));
    }
  };

export const updateUsersDetails =
  (userDetails, updateUserFn = api.updateUser) =>
  async (dispatch, getState, identity) => {
    dispatch(requestUpdateUserDetails());

    try {
      const response = await updateUserFn(userDetails, identity);
      dispatch(finishUpdateUserDetails(response));
      return dispatch(
        requestNotification("success", "ma_web_mydetails_savesuccess")
      );
    } catch (err) {
      if (err.apiValidationError) {
        return dispatch(finishUpdateUserDetails(err));
      }
      return dispatch(apiError(err));
    }
  };
