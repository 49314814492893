import request from "asos.myaccount.request";

import appConfig from "@appConfig";

const PAYMENT_DETAILS_ENDPOINT =
  "customer/paymentdetails/v2/customers/{customerId}/paymentdetails";
const PAYMENT_DETAILS_CARD_ENDPOINT =
  "customer/paymentdetails/v2/customers/{customerId}/paymentdetails/cards";
const PAYMENT_METHODS_ENDPOINT = "finance/paymentoptions/v2/paymentmethods";

const combineOutput = (customerPayments, paymentOptions) => ({
  customerPayments,
  paymentOptions: paymentOptions.paymentMethods,
});

export const getPaymentMethods = async (
  lang,
  countryCode,
  requestFn = request
) => {
  const {
    enablePayPalIn3ESIT,
    paypalpayin3ESITKey,
    paymentOptionsApiVersionKey,
  } = appConfig;

  const apiVersionKey = enablePayPalIn3ESIT
    ? paypalpayin3ESITKey
    : paymentOptionsApiVersionKey;

  return requestFn(`${PAYMENT_METHODS_ENDPOINT}/${apiVersionKey}`, null, {
    query: {
      ...(countryCode && { billingCountry: countryCode }),
      lang,
    },
  });
};

export const getPayments = async (
  lang,
  identity,
  countryCode,
  requestFn = request
) => {
  const customerPaymentsRequest = requestFn(PAYMENT_DETAILS_ENDPOINT, identity);
  const paymentMethodsRequest = getPaymentMethods(lang, countryCode, requestFn);

  const [customerPaymentsResponse, paymentMethodsResponse] = await Promise.all([
    customerPaymentsRequest,
    paymentMethodsRequest,
  ]);

  return combineOutput(customerPaymentsResponse, paymentMethodsResponse);
};

export const changeDefault = (id, type, identity, requestFn = request) => {
  const isCard = type === "card";
  const specificEndpoint = isCard
    ? PAYMENT_DETAILS_CARD_ENDPOINT
    : PAYMENT_DETAILS_ENDPOINT;

  return requestFn(`${specificEndpoint}/${id.toLowerCase()}`, identity, {
    body: JSON.stringify({ isDefault: true }),
    headers: {
      "Content-Type": "application/json",
    },
    method: isCard ? "PATCH" : "PUT",
  });
};

export const deletePaymentMethod = (
  id,
  type,
  identity,
  requestFn = request
) => {
  const isCard = type === "card";
  const specificEndpoint = isCard
    ? PAYMENT_DETAILS_CARD_ENDPOINT
    : PAYMENT_DETAILS_ENDPOINT;
  const specificId = isCard ? id : id.toLowerCase();

  return requestFn(`${specificEndpoint}/${specificId}`, identity, {
    method: "DELETE",
  });
};

export const addNewPaymentService = (
  service,
  identity,
  isDefault,
  requestFn = request
) =>
  requestFn(`${PAYMENT_DETAILS_ENDPOINT}/${service.toLowerCase()}`, identity, {
    body: JSON.stringify({ isDefault }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });

export const addNewPaymentCard = (
  cardDetails,
  identity,
  requestFn = request
) => {
  return requestFn(PAYMENT_DETAILS_CARD_ENDPOINT, identity, {
    body: JSON.stringify({
      ...cardDetails,
      tokenSource: "myAccount",
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
};
