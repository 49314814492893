import { v4 as uuid } from "uuid";

import {
  getApplicationVersion,
  getPagePlatform,
} from "../../document-handlers";

const getAsosHeaders = (appVersion, platform) => ({
  ...(platform && {
    "asos-c-ismobile": platform.toLowerCase() === "mobile",
    "asos-c-istablet": platform.toLowerCase() === "tablet",
  }),
  "asos-c-name": "Asos.Customer.MyAccount.Web.Ui",
  "asos-c-plat": "Web",
  "asos-c-ver": appVersion,
  "asos-cid": uuid(),
});

export const buildHeaders = (
  requestSpecificHeaders: Record<string, string> = {},
  authHeader: Record<string, string> | null = {},
  isThirdParty: boolean
) => {
  const asosApiHeaders = !isThirdParty
    ? getAsosHeaders(getApplicationVersion(), getPagePlatform())
    : {};
  return {
    Accept: "application/json",
    ...authHeader,
    ...asosApiHeaders,
    ...requestSpecificHeaders,
  };
};
