import React from "react";

import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";

import ListBlockSection from "@components/list-block-section";
import PictureIcon from "@components/picture-icon";
import { SmartLink } from "@components/smart-link";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface Link {
  id: string;
  link?: string;
  onClick?: () => void;
  textKey: string;
  urlParameters?: Record<any, any>;
}

export type LinkListProps = {
  isSemantic?: boolean;
  links: Link[];
};

const LinkList = ({ links, isSemantic = true }: LinkListProps) => {
  const formatTranslation = useFormatTranslation();
  const Components = isSemantic
    ? { Wrapper: "ul" as React.ElementType, Item: "li" as React.ElementType }
    : { Wrapper: "div" as React.ElementType, Item: "div" as React.ElementType };

  return (
    <Components.Wrapper>
      {links.map(({ id, link, onClick, textKey, urlParameters }, i) => (
        <Components.Item key={id} className={styles.linkListContainer}>
          <SmartLink
            data-auto-id={id}
            onClick={onClick}
            to={link}
            urlParameters={urlParameters}
          >
            <ListBlockSection>
              <span className={styles.linkText}>
                {formatTranslation(textKey)}
              </span>
              <span className={styles.linkIcon}>
                <PictureIcon id="link" />
              </span>
            </ListBlockSection>
          </SmartLink>
          <div className={styles.linkHorizonalRule}>
            {i !== links.length - 1 && <HorizontalRule />}
          </div>
        </Components.Item>
      ))}
    </Components.Wrapper>
  );
};

export default LinkList;
