import React from "react";

import styles from "./index.css";

type VisuallyHiddenProps = {
  children: React.ReactNode;
};

const VisuallyHidden: React.FC<VisuallyHiddenProps> = ({ children }) => (
  <span className={styles.visuallyHidden}>{children}</span>
);

export default VisuallyHidden;
