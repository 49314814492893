import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { BARNSLEY_3 } from "@asosteam/asos-web-component-library-typography";

import VisuallyHidden from "@components/visually-hidden";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const getFirstLetter = (word) => (word && word.length > 0 ? word[0] : "");

const ProfilePicture = ({ firstName, lastName }) => {
  const formatTranslation = useFormatTranslation();

  return (
    <div className={styles.wrapper}>
      <span className={classnames(styles.userInitials, BARNSLEY_3)}>
        <VisuallyHidden>
          {`${formatTranslation(
            "ma_web_navigation_greeting"
          )} ${firstName} ${lastName}`}
        </VisuallyHidden>
        {`${getFirstLetter(firstName)}${getFirstLetter(lastName)}`}
      </span>
    </div>
  );
};

ProfilePicture.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func,
};

export default ProfilePicture;
