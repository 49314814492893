import React from "react";

import classnames from "classnames";

import { Heading } from "@asosteam/asos-web-component-library-heading";
import { LONDON_3 } from "@asosteam/asos-web-component-library-typography";

import Accordion from "@components/accordion";
import LinkList from "@components/link-list";
import { SmartLink } from "@components/smart-link";

import styles from "./index.css";

type Link = {
  label: string;
  link: string;
};

export type HelpAccordionItemContentProps = {
  body: React.ReactNode;
  links?: Link[];
};

const HelpAccordionItemContent: React.FC<HelpAccordionItemContentProps> = ({
  body,
  links = [],
}) => (
  <div className={styles.contentWrapper}>
    <div className={styles.contentList}>{body}</div>
    <div
      className={classnames({
        [styles.contentFooter]: true,
        [styles.hasContent]: links,
      })}
    >
      {links &&
        links.map(
          (aLink) =>
            aLink && (
              <SmartLink
                className={styles.footerLink}
                key={aLink.link}
                newWindow
                to={aLink.link}
              >
                {aLink.label}
              </SmartLink>
            )
        )}
    </div>
  </div>
);

const getAccordionContent = (items) =>
  items.map((item) => ({
    title: item.method,
    content: HelpAccordionItemContent(item),
  }));

type TopLinks = {
  id: string;
  link?: string;
  onClick?: () => void;
  textKey: string;
};

type Items = {
  method: string;
  body: React.ReactNode;
};

export type HelpAccordionProps = {
  title?: string;
  isSemantic?: boolean;
  headingLevel?: string;
  topLinks?: TopLinks[];
  items: Items[];
};

const HelpAccordion: React.FC<HelpAccordionProps> = ({
  title = null,
  items,
  topLinks = [],
  isSemantic = true,
  headingLevel = "h2",
}) => {
  const accordionContent = getAccordionContent(items);
  return (
    <div>
      {title && (
        <div className={styles.title}>
          <Heading sizing={LONDON_3} domElement={headingLevel}>
            {title.toUpperCase()}
          </Heading>
        </div>
      )}
      {topLinks.length > 0 && (
        <LinkList links={topLinks} isSemantic={topLinks.length > 1} />
      )}
      <Accordion items={accordionContent} isSemantic={isSemantic} />
    </div>
  );
};

export default HelpAccordion;
