import appConfig from "@appConfig";

export const getSubscriptionOptionsState = (state) => state.subscriptionOptions;

export const getFatalError = (state) =>
  getSubscriptionOptionsState(state).fatalError;

export const hasLoaded = (state) => getSubscriptionOptionsState(state).loaded;

export const getSubscriptionOptions = (state) =>
  getSubscriptionOptionsState(state).subscriptionOptions;

export const hasSubscriptionOptions = (state) =>
  hasLoaded(state) ? getSubscriptionOptions(state).length !== 0 : false;

export const getLatestSubscription = (state) => {
  const subscriptions = getSubscriptionOptions(state);
  return (
    hasSubscriptionOptions(state) && subscriptions[subscriptions.length - 1]
  );
};

export const showPremierAutoRenewals = (state) => {
  return (
    appConfig.premierAutoRenewalsEnabled &&
    hasSubscriptionOptions(state) &&
    getLatestSubscription(state)?.supportedInstructionTypes?.includes(
      "Recurring"
    )
  );
};

export const getExpiringSoonThresholdDays = (state) => {
  return (
    hasSubscriptionOptions(state) &&
    getLatestSubscription(state).preExpiryPromptPeriodInDays
  );
};

export const getTrialOffer = (state) => {
  return (
    hasSubscriptionOptions(state) && getLatestSubscription(state).trialOffer
  );
};
