import React from "react";

import { CardWithHeading } from "@asosteam/asos-web-component-library-card-with-heading";
import { Card } from "@asosteam/asos-web-component-library-cards";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";

import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import ConsolidatedReturnsMessage from "../../../../components/consolidated-returns-message";
import ReturnableItem from "./returnable-item";

export interface ReturnItemsProps {
  closeReturnForm: (arg0: any) => void;
  noItemsError: boolean;
  openReturnForm: (arg0: any) => void;
  getReturnChargeMessages: () => void;
  openReturnForms: string[];
  returnableItems: {
    itemsFromSelectedOrder: any[];
    itemsFromOtherOrders: any[];
  };
  returnReasons: any[];
}

const ReturnItems = ({
  closeReturnForm,
  noItemsError,
  openReturnForm,
  getReturnChargeMessages,
  openReturnForms,
  returnableItems: { itemsFromSelectedOrder, itemsFromOtherOrders },
  returnReasons,
}: ReturnItemsProps) => {
  const formatTranslation = useFormatTranslation();
  const returnableItemsQuantity = itemsFromOtherOrders
    ? itemsFromOtherOrders.reduce(
        (totalQuantities, item) => totalQuantities + item.returnableQuantity,
        0
      )
    : [];

  return (
    <div>
      <Card>
        {noItemsError && (
          <div data-has-error>
            <NotificationBar data-auto-id="no-items-selected" type="error">
              <Translation id="ma_web_returns_noitemsselectederror" />
            </NotificationBar>
          </div>
        )}
      </Card>
      <CardWithHeading
        heading={formatTranslation("returns_consolidation_title_phase3")}
        isUppercase={true}
      >
        <div data-auto-id="return-items">
          {itemsFromSelectedOrder.map((item, index) => (
            <ul
              data-auto-id="return-item"
              key={`${item.variantId}-${item.orderReference}`}
            >
              <ReturnableItem
                closeReturnForm={closeReturnForm}
                isFirstItem={index === 0}
                isLastItem={index === itemsFromSelectedOrder.length - 1}
                item={item}
                openReturnForm={openReturnForm}
                getReturnChargeMessages={getReturnChargeMessages}
                orderReference={item.orderReference}
                returnFormOpen={openReturnForms.includes(
                  `${item.variantId}-${item.orderReference}`
                )}
                returnReasons={returnReasons}
              />
            </ul>
          ))}
        </div>
      </CardWithHeading>
      {returnableItemsQuantity > 0 && (
        <CardWithHeading
          heading={formatTranslation("returns_consolidation_title_2_phase3")}
          hasTopMargin={true}
          padContent={false}
          isUppercase={true}
        >
          <ConsolidatedReturnsMessage />
          <ul data-auto-id="return-items">
            {itemsFromOtherOrders.map((item, index) => (
              <ReturnableItem
                closeReturnForm={closeReturnForm}
                isFirstItem={index === 0}
                isLastItem={index === itemsFromOtherOrders.length - 1}
                item={item}
                openReturnForm={openReturnForm}
                getReturnChargeMessages={getReturnChargeMessages}
                orderReference={item.orderReference}
                returnFormOpen={openReturnForms.includes(
                  `${item.variantId}-${item.orderReference}`
                )}
                returnReasons={returnReasons}
                key={`${item.variantId}-${item.orderReference}`}
              />
            ))}
          </ul>
        </CardWithHeading>
      )}
    </div>
  );
};

export default ReturnItems;
