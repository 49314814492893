import React from "react";

import classnames from "classnames";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const VoucherCode = () => {
  const formatTranslation = useFormatTranslation();
  return (
    <div className={styles.voucher}>
      <div className={styles.sender}>
        <span className={styles.from}>
          {formatTranslation("ma_web_addgiftvoucher_fromlabel")}
        </span>{" "}
        @ASOS.com
      </div>
      <div className={styles.content}>
        <span className={classnames(styles.skeleton, styles.long)} />
        <span className={classnames(styles.skeleton, styles.short)} />
        <span className={styles.code}>1234567890123456</span>
        <span className={classnames(styles.skeleton, styles.medium)} />
      </div>
    </div>
  );
};

export default VoucherCode;
