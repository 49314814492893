import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import { clearApiErrors } from "@state/application/actions";
import { getPaymentMethodsAndOptions } from "@state/payment/actions";
import { getPaymentProcessorOptions } from "@state/payment/selectors";

import { performanceTimerStart, performanceTimerEnd } from "@utils/monitoring";

const bundleLoader = () =>
  import(/* webpackChunkName: 'add-card' */ "./content");

export interface paymentSelectorProps {
  payment: {
    fatalError: boolean;
    savingCard: boolean;
  };
}

const AddPaymentCard: React.FC = () => {
  const { bundle: Content, fatalError: bundleError } = useBundle(bundleLoader);

  const [secureFormReady, setSecureFormReady] = useState(false);

  const { savingCard, fatalError, previousPageLink } = useSelector(
    (state: paymentSelectorProps) => {
      const unsubscribedPaymentProcessors = getPaymentProcessorOptions(state);

      return {
        savingCard: state.payment.savingCard,
        fatalError: state.payment.fatalError,
        previousPageLink: unsubscribedPaymentProcessors.length
          ? "/payment-methods/add"
          : "/payment-methods",
      };
    }
  );

  const dispatch = useDispatch();

  const clearApiErrorsCall = () => dispatch(clearApiErrors());

  useEffect(() => {
    performanceTimerStart("secureCardForm");
    dispatch(getPaymentMethodsAndOptions());
  }, []);

  const pageNameKey = "ma_web_addpaymentcard_title";
  const allLoaded = Content !== null && secureFormReady && !savingCard;

  if (allLoaded) {
    performanceTimerEnd("secureCardForm");
  }

  return (
    <Page
      loaded={allLoaded}
      pageHeader={
        <PageContainer>
          <PageHeader
            title={pageNameKey}
            previousPageLink={previousPageLink}
            type="secondary"
            description="ma_web_addpaymentcard_getstarted"
          />
        </PageContainer>
      }
      pageId="add-payment-card"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError || bundleError}
      smallLoadingContainer
    >
      {Content && (
        <Content
          clearApiErrors={clearApiErrorsCall}
          setSecureFormReady={setSecureFormReady}
        />
      )}
    </Page>
  );
};

export default AddPaymentCard;
