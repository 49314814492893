import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { ButtonWithSubmitError } from "@asosteam/asos-web-component-library-button-with-submit-error";
import { ErrorWrapper } from "@asosteam/asos-web-component-library-error-wrapper";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { clearApiErrors } from "@state/application/actions";

import styles from "./index.css";

type RetryErrorWrapperProps = {
  failureMessage?: string;
  hasAutoFocus?: boolean;
  isDisabled?: boolean;
  overrideAction?: () => void;
  showSpinner?: boolean;
  children: React.ReactNode;
  apiError?: boolean;
  classname?: string;
};

const RetryErrorWrapper = ({
  failureMessage = "ma_web_errors_issuestryagain",
  hasAutoFocus = false,
  isDisabled = false,
  overrideAction = undefined,
  showSpinner,
  classname = "",
  children,
  apiError = false,
}: RetryErrorWrapperProps) => {
  const dispatch = useDispatch();
  const formatTranslation = useFormatTranslation();

  useEffect(() => {
    dispatch(clearApiErrors());
  }, []);

  return (
    <>
      {!apiError ? (
        <div className={styles.retryErrorWraper}>{children}</div>
      ) : (
        <ErrorWrapper classname={classname}>
          <TransitionGroup>
            <CSSTransition
              component="div"
              classNames={{
                appear: styles.errorAppear,
                appearActive: styles.errorAppearActive,
              }}
              timeout={{ enter: 500, exit: 0 }}
            >
              <ButtonWithSubmitError
                errorMsg={formatTranslation(failureMessage)}
                onClick={overrideAction}
                size="large"
                type="primary-purchase"
                data-auto-id="retry-button"
                autoFocus={hasAutoFocus} //eslint-disable-line
                disabled={isDisabled}
                isInProgress={showSpinner && isDisabled}
                classname={styles.errorWrapperButton}
              >
                {formatTranslation("error_general_button_title_retry")}
              </ButtonWithSubmitError>
            </CSSTransition>
          </TransitionGroup>
        </ErrorWrapper>
      )}
    </>
  );
};

export default RetryErrorWrapper;
