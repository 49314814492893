const getReturnsState = (state) => state.returns;

export const reasonsHaveLoaded = (state) =>
  getReturnsState(state).reasonsLoaded;

export const getReturnReasons = (state) => getReturnsState(state).returnReasons;

export const getReturnReference = (state) =>
  getReturnsState(state).returnReference;

export const getReturnReferenceId = (state) => {
  const returnCreated = getReturnReference(state);
  if (returnCreated) {
    return returnCreated.returnReference;
  }
  return null;
};

export const getReturnOrderReference = (state) => {
  const returnCreated = getReturnReference(state);
  if (returnCreated) {
    return returnCreated.orderReference;
  }
  return null;
};

export const referenceHasLoaded = (state) =>
  getReturnsState(state).referenceLoaded;

export const returnableItemsHasLoaded = (state) =>
  getReturnsState(state).returnableItemsLoaded;

export const getReturnableItems = (state) =>
  getReturnsState(state).returnableItems;

export const getIfReturnIsBeingCreated = (state) =>
  getReturnsState(state).creatingReturnFor;

export const getIfCreatingReturnForOrder = (state) => (orderReference) =>
  getIfReturnIsBeingCreated(state) === orderReference;

export const createReturnFatalError = (state) =>
  getReturnsState(state).createReturnFatalError;

export const getCreateReturnAttempts = (state) =>
  getReturnsState(state).createReturnAttempts;

export const getReturnItems = (state) => getReturnsState(state).returningItems;

export const getReturnItem = (state, variantId, orderReference) =>
  getReturnItems(state)[`${variantId}-${orderReference}`];

export const getIfCreateReturnErroredForOrder = (state) => (orderRef) => {
  const returnAttempts = getCreateReturnAttempts(state);
  return returnAttempts.orderReference === orderRef && returnAttempts.tries > 0;
};

export const getDropOffResults = (state) =>
  getReturnsState(state).dropOffResults;

export const getDropOffStores = (state) => getDropOffResults(state).stores;

export const hasDropOffStoreAvailable = (state) =>
  getDropOffStores(state).length > 0;

export const getDropOffStoresProviders = (state) =>
  getDropOffResults(state).providers;

export const isRequestingDropOffPoints = (state) =>
  getReturnsState(state).requestingDropOffPoints;

export const isRequestingPlaces = (state) =>
  getReturnsState(state).requestingPlaces;

export const getDropOffSearchAddress = (state) =>
  getReturnsState(state).dropOffSearchAddress;

export const getIfNoPlacesWereFound = (state) =>
  getReturnsState(state).noPlacesFound;

export const getPlacesErrorCount = (state) =>
  getReturnsState(state).placesErrorCount;

export const getSearchedDropOffLocation = (state) =>
  getDropOffSearchAddress(state).formattedAddress;

export const getPlacesError = (state) => getPlacesErrorCount(state) > 0;

export const getDropOffErrorCount = (state) =>
  getReturnsState(state).dropOffErrorCount;

export const getDropOffLocationsError = (state) =>
  getDropOffErrorCount(state) > 0;

export const getDropOffSearchFatalError = (state) =>
  getPlacesErrorCount(state) + getDropOffErrorCount(state) >= 3;

export const getPlacesSearchResults = (state) => getReturnsState(state).places;

export const hasMutlipleLocationOptions = (state) =>
  getPlacesSearchResults(state).length > 1;

export const getReturnLocation = (state) =>
  getReturnsState(state).returnLocation;

export const getReturnLocationProviderName = (state) => {
  const returnLocation = getReturnLocation(state);
  if (returnLocation) {
    return returnLocation.provider.name;
  }
  return null;
};

export const getIfBookingReturn = (state) =>
  getReturnsState(state).isBookingReturn;

export const getBookReturnErrorCount = (state) =>
  getReturnsState(state).bookReturnErrorCount;

export const getBookReturnError = (state) => getBookReturnErrorCount(state) > 0;

export const getBookReturnFatalError = (state) =>
  getBookReturnErrorCount(state) >= 3;

export const getFatalError = (state) => {
  const returnsState = getReturnsState(state);
  return (
    returnsState.fatalReasonsError ||
    returnsState.fatalReferenceError ||
    returnsState.fatalReturnableItemsError ||
    getBookReturnFatalError(state)
  );
};

export const getOpenReturnForms = (state) =>
  getReturnsState(state).openReturnForms;

export const hasItemSelectedForReturn = (state) => {
  const returningItems = getReturnItems(state);
  const openReturnForms = getOpenReturnForms(state);
  return (
    openReturnForms.length > 0 &&
    openReturnForms.some(
      (formId) => returningItems[formId] && returningItems[formId].reasonId
    )
  );
};

export const getIfAttemptedToPlaceReturn = (state) =>
  getReturnsState(state).hasAttemptedToPlaceReturn;

export const getReturnMultiParcelUserReviewedBannerStatus = (state) =>
  getReturnsState(state).hasBeenReviewed;

export const getReturnBookingConfirmation = (state) =>
  getReturnsState(state).bookedReturn;

export const getReturnChargeMessage = (state) =>
  getReturnsState(state).returnChargeMessage;
