import { handleActions } from "redux-actions";

import { REQUEST_AUDIENCE_SEGMENTS, RECEIVE_AUDIENCE_SEGMENTS } from "./types";

const reducer = handleActions(
  {
    [REQUEST_AUDIENCE_SEGMENTS]: (state) => ({
      ...state,
      loaded: false,
    }),
    [RECEIVE_AUDIENCE_SEGMENTS]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            fatalError: true,
            loaded: true,
          }
        : {
            ...state,
            segments: payload,
            fatalError: false,
            loaded: true,
          },
  },
  {
    fatalError: false,
    loaded: false,
    segments: [],
  }
);

export default reducer;
