import { createAction } from "redux-actions";
import { push } from "redux-first-history";

import { requestNotification } from "../application/actions";
import {
  getCountry as getBrowseCountry,
  getCurrency as getBrowseCurrency,
} from "../application/selectors";
import * as api from "./api";
import * as selectors from "./selectors";
import * as types from "./types";

export const requestAssociateGiftCard = createAction(
  types.REQUEST_ASSOCIATE_GIFT_CARD
);
export const receiveAssociateGiftCard = createAction(
  types.RECEIVE_ASSOCIATE_GIFT_CARD
);
export const requestAssociateGiftVoucher = createAction(
  types.REQUEST_ASSOCIATE_GIFT_VOUCHER
);
export const receiveAssociateGiftVoucher = createAction(
  types.RECEIVE_ASSOCIATE_GIFT_VOUCHER
);
export const giftCardApiError = createAction(types.GIFT_CARD_API_ERROR);
export const giftVoucherApiError = createAction(types.GIFT_VOUCHER_API_ERROR);
export const requestGiftVouchers = createAction(types.REQUEST_GIFT_VOUCHERS);
export const receiveGiftVouchers = createAction(types.RECEIVE_GIFT_VOUCHERS);
export const resetSaveNewGiftCardAndVoucher = createAction(
  types.RESET_SAVE_NEW_GIFT_CARD_AND_VOUCHER
);
export const trackOpenAddGiftCard = createAction(
  types.TRACK_OPEN_ADD_GIFT_CARD
);
export const trackSuccessfulAddGiftCard = createAction(
  types.TRACK_SUCCESSFUL_ADD_GIFT_CARD
);
export const trackOpenAddGiftVoucher = createAction(
  types.TRACK_OPEN_ADD_GIFT_VOUCHER
);
export const trackSuccessfulAddGiftVoucher = createAction(
  types.TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER
);
export const trackOpenAddGiftCardOrVoucher = createAction(
  types.TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER
);
export const trackBuyGiftVoucher = createAction(types.TRACK_BUY_GIFT_VOUCHER);
export const trackAddGiftCardAttempt = createAction(
  types.TRACK_ADD_GIFT_CARD_ATTEMPT
);
export const trackAddGiftVoucherAttempt = createAction(
  types.TRACK_ADD_GIFT_VOUCHER_ATTEMPT
);

const supportedValidations = [
  "GiftCardAlreadyAssociated",
  "GiftCardExpired",
  "GiftCardInvalid",
  "GiftCardNotActivated",
  "GiftCardNotFoundOrInvalidPin",
  "GiftCardNumberInvalid",
  "GiftCardPinInvalid",
  "VoucherAlreadyAssociated",
  "VoucherCancelled",
  "VoucherCodeChecksumMismatch",
  "VoucherCodeInvalid",
  "VoucherExpired",
  "VoucherNotFound",
  "PossibleGiftCard",
];

const inSupportedValidations = (errorCode) =>
  supportedValidations.find((code) => code === errorCode);

const getUnSupportedValidations = (errorCodes) =>
  errorCodes.filter(({ errorCode }) => !inSupportedValidations(errorCode));

const supportedValidationError = (error) =>
  error.body ? getUnSupportedValidations(error.body).length < 1 : false;

export const submitGiftCard =
  (giftcard, associateGiftCardFn = api.associateGiftCard) =>
  async (dispatch, getState, identity) => {
    dispatch(requestAssociateGiftCard());
    dispatch(trackAddGiftCardAttempt());
    try {
      const result = await associateGiftCardFn(giftcard, identity);
      dispatch(receiveAssociateGiftCard(result));
      dispatch(trackSuccessfulAddGiftCard());
      return dispatch(
        requestNotification(
          "success",
          "ma_web_addgiftcard_associategiftcardsuccessful"
        )
      );
    } catch (err) {
      if (supportedValidationError(err)) {
        err.apiValidationError = true;
        return dispatch(receiveAssociateGiftCard(err));
      }

      err.apiValidationError = false;
      err.userAction = "submitGiftCard";
      return dispatch(giftCardApiError(err));
    }
  };

export const submitGiftVoucher =
  (giftVoucher, associateGiftVoucherFn = api.associateGiftVoucher) =>
  async (dispatch, getState, identity) => {
    dispatch(requestAssociateGiftVoucher());
    dispatch(trackAddGiftVoucherAttempt());

    const state = getState();

    try {
      const result = await associateGiftVoucherFn(
        giftVoucher,
        getBrowseCountry(state),
        getBrowseCurrency(state),
        identity
      );
      dispatch(receiveAssociateGiftVoucher(result));
      dispatch(trackSuccessfulAddGiftVoucher());
      return dispatch(
        requestNotification(
          "success",
          "ma_web_addgiftvoucher_associategiftvouchersuccessful"
        )
      );
    } catch (err) {
      if (supportedValidationError(err)) {
        err.apiValidationError = true;
        return dispatch(receiveAssociateGiftVoucher(err));
      }

      err.apiValidationError = false;
      err.userAction = "submitGiftVoucher";
      return dispatch(giftVoucherApiError(err));
    }
  };

export const openAddGiftCard = () => (dispatch) => {
  dispatch(push("/gift-cards-and-vouchers/add/card"));
  dispatch(trackOpenAddGiftCard());
};

export const openAddGiftVoucher = () => (dispatch) => {
  dispatch(push("/gift-cards-and-vouchers/add/voucher"));
  dispatch(trackOpenAddGiftVoucher());
};

export const openAddGiftCardOrVoucher = () => (dispatch) => {
  dispatch(push("/gift-cards-and-vouchers/add"));
  dispatch(trackOpenAddGiftCardOrVoucher());
};

export const buyGiftVoucher = () => (dispatch) => {
  dispatch(trackBuyGiftVoucher());
};

export const getGiftVouchers =
  (loadMore = false, getGiftVouchersFn = api.getGiftVouchers) =>
  async (dispatch, getState, identity) => {
    const state = getState();

    if (!loadMore && selectors.getGiftVouchersLoaded(state)) {
      return null;
    }

    dispatch(requestGiftVouchers({ loadMore }));

    const offset = selectors.getVouchers(state).length;
    try {
      const response = await getGiftVouchersFn(
        offset,
        getBrowseCountry(state),
        getBrowseCurrency(state),
        identity
      );
      return dispatch(
        receiveGiftVouchers({
          append: loadMore,
          response,
        })
      );
    } catch (err) {
      return dispatch(receiveGiftVouchers(err));
    }
  };
