import { handleActions } from "redux-actions";

import * as types from "./types";

const getCountriesByCountryCode = (payload) =>
  payload.countries.reduce(
    (acc, { code, name }) => ({ ...acc, [code]: name }),
    {}
  );

const getCountryCodes = (payload) => payload.countries.map(({ code }) => code);

const getSubRegions = (subRegions) =>
  subRegions.reduce(
    (acc, { code, name }) => ({
      ...acc,
      [code]: name,
    }),
    {}
  );

const getRegionsByCountryCode = (payload) =>
  payload.countries.reduce(
    (acc, { code, subRegions, hasSubregions }) =>
      hasSubregions ? { ...acc, [code]: getSubRegions(subRegions) } : acc,
    {}
  );

const reducer = handleActions(
  {
    [types.RECEIVE_COUNTRIES]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            fatalError: true,
            loaded: true,
            valid: false,
          }
        : {
            ...state,
            countriesByCountryCode: getCountriesByCountryCode(payload),
            countryCodes: getCountryCodes(payload),
            fatalError: false,
            loaded: true,
            regionsByCountryCode: getRegionsByCountryCode(payload),
            valid: true,
          },
    [types.REQUEST_COUNTRIES]: (state) => ({
      ...state,
      loaded: false,
    }),
  },
  {
    countriesByCountryCode: {},
    countryCodes: [],
    fatalError: false,
    loaded: false,
    regionsByCountryCode: {},
    valid: false,
  }
);

export default reducer;
