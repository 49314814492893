/* eslint-disable no-case-declarations */
import { getOrderFromOrderRef } from "@state/analytics/middleware";
import { getMenuClicked } from "@state/application/selectors";
import { getOrdersState, getTotalOrders } from "@state/orders/selectors";
import { getPremierAnalytics } from "@state/premier/analytics";
import { isPremierSuccessUrl } from "@state/premier/selectors";

import {
  determineDeliveryType,
  DELIVERY_TYPES,
} from "@utils/orders/deliveryType";

import { AnalyticsState } from "./pageLoadHook";

export function getPageLoadPayload(
  pageId: string,
  state: AnalyticsState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch
) {
  switch (pageId) {
    case "orderhistory":
      const orders = getOrdersState(state).all;
      const deliveryTypeCounts = getDeliveryTypeCounts(orders);
      return {
        totalOrders: getTotalOrders(state),
        deliveryTypeCounts,
      };
    case "orderdetails":
      const orderReference = state.router.location.pathname.split("/")[3];
      const order = getOrderFromOrderRef(state, orderReference);
      return {
        order: {
          status: order.status,
          numberOfDeliveryGroups: order.deliveryGroupsDetail?.length || 1,
          products: productDeliveryData(order),
        },
      };
    case "premier":
      if (getMenuClicked(state)) {
        return {
          premierAutorenewals: true,
          premierStatus: getPremierAnalytics(state),
          premierStep: "menu-click",
        };
      } else if (isPremierSuccessUrl()) {
        return {
          premierAutorenewals: true,
          premierStatus: getPremierAnalytics(state),
          premierStep: "successful",
        };
      }
      return {};
    default:
      return {};
  }
}

function productDeliveryData(order) {
  return order.items
    .filter((item) => item.itemType === "Product")
    .map((item) => {
      if (
        !order.deliveryGroupsDetail ||
        order.deliveryGroupsDetail.length === 0
      ) {
        return {
          productId: item.productId,
          sourceId: item.seller?.id ? null : "primary",
          sellerId: item.seller?.id || "asos",
          deliveryType: determineDeliveryType(item.seller, null),
        };
      }

      const { source } = order.deliveryGroupsDetail.find((dgd) => {
        return dgd.variants.find((v) => v.variantId === item.variantId);
      });

      return {
        productId: item.productId,
        sourceId: source?.id || "primary",
        sellerId: item.seller?.id || "asos",
        deliveryType: determineDeliveryType(item.seller, source),
      };
    });
}

function getDeliveryTypeCounts(orders) {
  const deliveryTypeCounts = orders.reduce(
    (counts, order) => {
      order.deliveryGroupsDetail.forEach((dg) => {
        const mappedItems = order.items?.filter(({ variantId }) =>
          dg.variants.some(
            (parcelVariant) => parcelVariant.variantId === variantId
          )
        );

        let isAfs = false;
        let isDtc = false;

        mappedItems?.forEach((item) => {
          const deliveryType = determineDeliveryType(item.seller, dg.source);
          if (
            deliveryType === DELIVERY_TYPES.AFS_NON_PRIME ||
            deliveryType === DELIVERY_TYPES.AFS_PRIME
          ) {
            isAfs = true;
          } else if (deliveryType === DELIVERY_TYPES.DTC) {
            isDtc = true;
          }
        });

        if (isAfs) {
          counts.afs += 1;
        } else if (isDtc) {
          counts.dtc += 1;
        }
      });

      return counts;
    },
    {
      afs: 0,
      dtc: 0,
    }
  );
  return deliveryTypeCounts;
}
