import React from "react";

import { Translation } from "@components/translation";

import { mainId } from "@configuration/application";

import styles from "./index.css";

type SkipToContentProps = {
  id?: string;
  skipToId?: (id: string) => void;
};

const replaceHistoryStateWithoutStateChange = (hash, windowHistory) => {
  windowHistory.replaceState({}, "", `#${hash}`);
};

export const skipToId = (id, windowHistory = window.history) => {
  replaceHistoryStateWithoutStateChange(id, windowHistory);
};

const SkipToContent = ({
  id = mainId,
  skipToId: skipToIdFn = skipToId,
}: SkipToContentProps) => (
  <a
    className={styles.banner}
    href={`#${id}`}
    onClick={() => {
      skipToIdFn(id);
    }}
  >
    <Translation id="ma_web_accessibility_skipto" />
  </a>
);

export default SkipToContent;
