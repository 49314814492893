import { createAction } from "redux-actions";

import { getLanguage, getECommerceStore } from "../application/selectors";
import * as api from "./api";
import * as selectors from "./selectors";
import * as types from "./types";

export const requestCountries = createAction(types.REQUEST_COUNTRIES);
export const receiveCountries = createAction(types.RECEIVE_COUNTRIES);

export const getCountries =
  (getCountriesApiFn = api.getCountries) =>
  async (dispatch, getState) => {
    const state = getState();
    if (selectors.getIsValid(state)) {
      return null;
    }

    const language = getLanguage(state);
    const store = getECommerceStore(state);

    dispatch(requestCountries());

    try {
      const response = await getCountriesApiFn(language, store);
      return dispatch(receiveCountries(response));
    } catch (err) {
      return dispatch(receiveCountries(err));
    }
  };
