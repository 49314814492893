import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { submit } from "redux-form";

import appConfig from "@appConfig";

import NoContentScreen from "@components/no-content-screen";
import Page from "@components/page";
import PageHeader from "@components/page-header";
import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { createReturn as createReturnAction } from "@state/returns/actions";
import {
  getDropOffSearchFatalError,
  getFatalError as getFatalReturnsError,
  getReturnableItems,
  getReturnReferenceId,
  reasonsHaveLoaded,
  referenceHasLoaded,
  returnableItemsHasLoaded,
} from "@state/returns/selectors";

import { scrollElementIdIntoView } from "@utils/scroll-into-view";

import CreateReturn from "./create-return";

const ReturnOrder = () => {
  const formatTranslation = useFormatTranslation();
  const { orderReference } = useParams();

  const {
    fatalError,
    loaded,
    returnableItems,
    returnReferenceId,
    section,
    shouldNotPromptAboutLeaving,
  } = useSelector((state: any) => {
    const { returnsEnabled } = appConfig;
    return {
      fatalError:
        getFatalReturnsError(state) ||
        getDropOffSearchFatalError(state) ||
        !returnsEnabled,
      loaded:
        returnableItemsHasLoaded(state) &&
        referenceHasLoaded(state) &&
        reasonsHaveLoaded(state),
      returnableItems: getReturnableItems(state),
      returnReferenceId: getReturnReferenceId(state),
      section: window.location.hash.replace(/^#/, ""),
      shouldNotPromptAboutLeaving: state.returns.shouldNotPromptAboutLeaving,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!returnReferenceId) {
      dispatch(createReturnAction(orderReference));
    }
  }, [
    dispatch,
    orderReference,
    returnReferenceId,
    shouldNotPromptAboutLeaving,
  ]);

  useEffect(() => {
    if (section !== "") {
      scrollElementIdIntoView(section, false);
    }
  }, [section]);

  const returnableItemsQuantity = returnableItems.itemsFromSelectedOrder
    ? returnableItems.itemsFromSelectedOrder.reduce(
        (totalQuantities, item) => totalQuantities + item.returnableQuantity,
        0
      )
    : [];

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageHeader
          description="ma_web_orderreturn_heading_description"
          detached
          previousPageLink="/orders"
          title="ma_web_orderreturn_heading_title"
          type="secondary"
        />
      }
      pageId="create-return"
      pageTitleKey="ma_web_orderreturn_heading_title"
      showErrorSection={fatalError}
    >
      {loaded && returnableItemsQuantity > 0 ? (
        <CreateReturn submit={submit} />
      ) : (
        <NoContentScreen
          buttonText="ma_web_orderreturn_returnitem_noitems_button"
          buttonLink="/orders"
          image={<Icon id="returns" size="large" />}
          title="ma_web_orderreturn_returnitem_noitems_title"
        >
          {formatTranslation("ma_web_orderreturn_returnitem_noitems_subtitle")}
        </NoContentScreen>
      )}
    </Page>
  );
};

export default ReturnOrder;
