import { connect } from "react-redux";

import { silentTokenRefresh } from "@state/identity/actions";

import activatingPremier from "./component";

const mapDispatchToProps = (dispatch) => ({
  silentTokenRefresh: () => dispatch(silentTokenRefresh()),
});

export default connect(undefined, mapDispatchToProps)(activatingPremier);
