import { parse } from "url";

const URL_ALLOW_LIST_REGEX = /^(www|my|secure|test)\.(asos|asosservices)\.com$/;

export const getSiteDomain = (url) => {
  const { hostname } = parse(url);

  return hostname.split(".").slice(1).join(".");
};

export const urlContainsString = (value) => {
  return window.location.href.includes(value);
};

export const redirectToUrl = (url) => {
  url && window.location.replace(url);
};

export const addFragmentToUrl = (url, fragment) => {
  try {
    const urlObject = new URL(url);

    if (urlObject.host.match(URL_ALLOW_LIST_REGEX).length) {
      urlObject.hash = fragment;
      return urlObject.toString();
    }

    return "";
  } catch {
    return "";
  }
};

export const validateUrl = (url) => {
  try {
    const urlObject = new URL(url);
    return !!urlObject.host.match(URL_ALLOW_LIST_REGEX);
  } catch {
    return false;
  }
};
