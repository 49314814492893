const getCommunicationPreferences = (state) => state.communicationPreferences;

export const communicationPreferencesError = (state) =>
  getCommunicationPreferences(state).apiError;

export const getPreferences = (state) =>
  getCommunicationPreferences(state).preferences;

export const hasFatalError = (state) =>
  getCommunicationPreferences(state).fatalError;

export const hasPreferencesLoaded = (state) =>
  getCommunicationPreferences(state).preferencesLoaded;

export const isMarketingPreferencesReconsentBeenSubmitted = (state) =>
  getCommunicationPreferences(state).isFormSubmitted;

export const isMarketingPreferencesReconsentRequired = (state) =>
  getPreferences(state).some(
    ({ consentStatus }) =>
      !!(
        (consentStatus === "reconsentRequired" ||
          consentStatus === "unknown") &&
        !isMarketingPreferencesReconsentBeenSubmitted(state)
      )
  );

export const maxRetries = (state) =>
  getCommunicationPreferences(state).maxRetries;

export const tries = (state) => getCommunicationPreferences(state).tries;

export const getServiceId = (state) =>
  getCommunicationPreferences(state).serviceId;
