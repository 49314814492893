import React from "react";

import PropTypes from "prop-types";

import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";

import Greeting from "@components/navigation-greeting";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const WelcomeMat = ({ notification }) => {
  const formatTranslation = useFormatTranslation();

  return (
    <div className={styles.welcome}>
      <Greeting />
      {notification && (
        <NotificationBar type="alert">
          {formatTranslation(notification.translationKey)}
        </NotificationBar>
      )}
    </div>
  );
};

WelcomeMat.propTypes = {
  notification: PropTypes.shape({
    translationKey: PropTypes.string,
  }),
};

WelcomeMat.defaultProps = {
  notification: null,
};

export default WelcomeMat;
