import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import {
  getCountry,
  getECommerceStore,
  getLanguage,
} from "@state/application/selectors";
import {
  clearCancelOrder,
  clickProductDetails,
  getCancelReasons,
  getOrderDetails,
  openTrackParcelUrl,
  trackOpenHelpLink,
  updateReturnRmaRequestDateTime,
} from "@state/orders/actions";
import { getIfOrderIsBeingCancelled as getIfOrderIsBeingCancelledFn } from "@state/orders/selectors";
import {
  clearCreateReturnAttempts,
  createReturn,
} from "@state/returns/actions";
import {
  createReturnFatalError,
  getIfCreateReturnErroredForOrder,
  getIfCreatingReturnForOrder,
} from "@state/returns/selectors";

const loadContent = () =>
  import(/* webpackChunkName: 'order-details' */ "./components");

export const OrderDetails = () => {
  const { bundle: OrderDetail } = useBundle(loadContent);
  const { orderReference } = useParams();

  const {
    apiError,
    cancelReasons,
    cancelReasonsLoading,
    country,
    creatingReturnForOrder,
    details,
    eCommerceStore,
    fatalError,
    getIfOrderIsBeingCancelled,
    language,
    loaded,
  } = useSelector((state: any) => {
    const {
      apiError,
      cancelReasons,
      cancelReasonsLoading,
      details,
      detailsFatalError,
      loadedDetails,
      fatalError,
    } = state.orders;
    return {
      apiError: {
        ...apiError,
        createReturn: getIfCreateReturnErroredForOrder(state)(orderReference),
      },
      cancelReasons,
      cancelReasonsLoading,
      country: getCountry(state),
      creatingReturnForOrder:
        getIfCreatingReturnForOrder(state)(orderReference),
      details,
      eCommerceStore: getECommerceStore(state),
      fatalError:
        detailsFatalError || fatalError || createReturnFatalError(state),
      getIfOrderIsBeingCancelled: getIfOrderIsBeingCancelledFn(state),
      language: getLanguage(state),
      loaded: loadedDetails,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateReturnRmaRequestDateTime({
        rmaRequestDateTime: new Date().toISOString(),
      })
    );
    dispatch(clearCancelOrder());
    dispatch(getOrderDetails(orderReference));
    dispatch(clearCreateReturnAttempts());
  }, [orderReference]);

  const order = details.find((o) => o.orderReference === orderReference);
  const error = fatalError || (loaded && !order);

  return (
    <Page
      loaded={loaded && !!order}
      pageHeader={
        <PageHeader
          dataAutoId="OrderDetails"
          description="ma_web_order_description"
          detached
          previousPageLink="/orders"
          title="ma_web_order_title"
          type="secondary"
        />
      }
      pageId="order-details"
      pageTitleKey="ma_web_order_title"
      showErrorSection={error}
      showScrollToTopButton
    >
      {OrderDetail && !!order && (
        <OrderDetail
          apiError={apiError}
          cancelReasons={cancelReasons}
          cancelReasonsLoading={cancelReasonsLoading}
          clickProductDetails={(args) => dispatch(clickProductDetails(args))}
          country={country}
          createReturn={(orderReference) =>
            dispatch(createReturn(orderReference))
          }
          creatingReturnForOrder={creatingReturnForOrder}
          eCommerceStore={eCommerceStore}
          getCancelReasons={(orderReference) =>
            dispatch(getCancelReasons(orderReference))
          }
          language={language}
          openTrackParcelUrl={(args) => dispatch(openTrackParcelUrl(args))}
          order={order}
          orderIsBeingCancelled={getIfOrderIsBeingCancelled(orderReference)}
          trackOpenHelpLink={(args) => dispatch(trackOpenHelpLink(args))}
        />
      )}
    </Page>
  );
};

export default OrderDetails;
