import request from "asos.myaccount.request";

const CUSTOMER_RETURNS_ENDPOINT =
  "commerce/customerreturns/v2/customers/{customerId}/returns";

export const getReturnDetails = (
  returnReference,
  language,
  identity,
  requestFn = request
) =>
  requestFn(
    `${CUSTOMER_RETURNS_ENDPOINT}/${returnReference}?lang=${language}`,
    identity
  );
