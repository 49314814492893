import React from "react";

import InputField from "@components/input-field";

import styles from "./index.css";

const handleClick = (action) => () => {
  action();
};

export interface InputFieldWithActionButtonProps {
  action: () => void;
  buttonText?: string;
  input: {
    name: string;
  };
  meta?: {
    form: string;
  };
  [x: string]: any;
}

const InputFieldWithActionButton = ({
  buttonText = undefined,
  input,
  meta,
  action,
  ...props
}: InputFieldWithActionButtonProps) => {
  const button = buttonText ? (
    <button
      className={styles.action}
      data-auto-id={`${meta.form}-${input.name}-action`}
      onClick={handleClick(action)}
      type="button"
    >
      {buttonText}
    </button>
  ) : null;
  return (
    <InputField
      label={""}
      {...props}
      button={button}
      input={input}
      meta={meta}
    />
  );
};

export default InputFieldWithActionButton;
