import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

import Logo from "@asosteam/asos-web-component-library-svg-icons/lib/logo.svg";

import { GridColumn, GridRow } from "@components/grid";
import ContentWrapper from "@components/layout/content-wrapper";
import SiteSeal from "@components/site-seal";
import { SmartLink } from "@components/smart-link";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface HeaderProps {
  digiCertSealCode?: string;
  language?: string;
  isHome: boolean;
}

const Header = ({ digiCertSealCode, language, isHome }: HeaderProps) => {
  const formatTranslation = useFormatTranslation();

  return (
    <header className={styles.header} role="banner">
      <ContentWrapper hasPaddingSmall hasPaddingMedium>
        <GridRow>
          <GridColumn smallSize={3} mediumSize={3} largeSize={2}>
            <div className={styles.columnContent}>
              <SmartLink
                className={styles.columnContentInner}
                to="ExternalLink.HomePage"
                newWindow={false}
              >
                <img alt="ASOS" src={Logo} className={styles.asosLogo} />
              </SmartLink>
            </div>
          </GridColumn>
          <GridColumn
            smallSize={6}
            mediumSize={6}
            largeSize={8}
            className={styles.titleColumn}
          >
            <div className={styles.columnContent}>
              <div
                className={classnames(
                  styles.columnContentInner,
                  styles.columnContentInnerHeader
                )}
              >
                {isHome ? (
                  <span className={styles.title}>
                    {formatTranslation("ma_web_header_title")}
                  </span>
                ) : (
                  <Link to="/" className={styles.titleLink}>
                    <span className={styles.title}>
                      {formatTranslation("ma_web_header_title")}
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </GridColumn>
          <GridColumn smallSize={3} mediumSize={3} largeSize={2}>
            <div
              className={classnames(styles.columnContent, styles.vendorArea)}
            >
              <SiteSeal
                language={language}
                digiCertSealCode={digiCertSealCode}
              />
            </div>
          </GridColumn>
        </GridRow>
      </ContentWrapper>
    </header>
  );
};

export default Header;
