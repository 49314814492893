import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import {
  getAddresses,
  acceptDeleteAddress,
  cancelDeleteAddress,
  resetAddressErrors,
} from "@state/addresses/actions";
import { getCountries } from "@state/countries/actions";
import * as countriesSelectors from "@state/countries/selectors";

import DeleteAddressModal from "./delete-address-modal";

const loadContent = () =>
  import(/* webpackChunkName: 'address-book' */ "./content");

import styles from "./index.css";

const pageNameKey = "ma_web_addressbook_title";

const AddressBook = () => {
  const { bundle: AddressBookContent } = useBundle(loadContent);
  const dispatch = useDispatch();
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(true);

  const acceptDeleteAddressCall = () =>
    dispatch(acceptDeleteAddress("deleteAddressInline"));
  const cancelDeleteAddressCall = () => dispatch(cancelDeleteAddress());

  const {
    addressBook,
    changedDefaultAddress,
    fatalError,
    requireDeleteAcceptance,
    loaded,
  } = useSelector((state: any) => {
    const addresses = state.addresses;
    return {
      addressBook: addresses.addressBook,
      changedDefaultAddress: addresses.changedDefaultAddress,
      fatalError: addresses.fatalError,
      requireDeleteAcceptance: addresses.requireDeleteAcceptance,
      loaded:
        addresses.loaded &&
        !addresses.changingDefaultAddress &&
        !addresses.deletingAddress &&
        countriesSelectors.hasLoaded(state),
    };
  });

  const scrollToTopOfPage = (args) => {
    window.scroll(args);
  };

  useEffect(() => {
    dispatch(getAddresses());
    dispatch(getCountries());
    dispatch(resetAddressErrors());
  }, []);

  useEffect(() => {
    setShowScrollToTopButton(false);
    scrollToTopOfPage({ top: 0, behavior: "smooth" });
    setTimeout(() => setShowScrollToTopButton(true), 1000);
  }, [changedDefaultAddress]);

  const addNewAddressPath = "/addresses/add";

  if (loaded && !fatalError && !addressBook.length) {
    return <Navigate to={addNewAddressPath} />;
  }

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <div className={styles.headerWrapper}>
          <PageHeader
            buttonTo={addNewAddressPath}
            buttonText="ma_web_addressbook_addnew"
            dataAutoId="AddressBookHeader"
            icon="addresses"
            title={pageNameKey}
          />
        </div>
      }
      pageId="address-book"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError}
      showScrollToTopButton={showScrollToTopButton}
    >
      {AddressBookContent && <AddressBookContent addressBook={addressBook} />}
      <DeleteAddressModal
        isOpen={requireDeleteAcceptance}
        onAccept={acceptDeleteAddressCall}
        onCancel={cancelDeleteAddressCall}
      />
    </Page>
  );
};

export default AddressBook;
