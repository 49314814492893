import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PropTypes from "prop-types";

import Logo from "@asosteam/asos-web-component-library-svg-icons/lib/logo.svg";

import { GridRow, GridColumn } from "@components/grid";
import ContentWrapper from "@components/layout/content-wrapper";

import LoadingAppContent from "./loading-app-content";
import LoadingNavigation from "./loading-navigation";
import { LoadingRectanglePlaceholder } from "./placeholders";

import styles from "./index.css";

const LoadingAppContentSection = ({
  appLoaded,
  contentFlex,
  showChrome,
  showSmallNavigationOnly,
  loadingHeader = false,
}) => (
  <TransitionGroup>
    {!appLoaded && (
      <CSSTransition
        classNames={{
          exit: styles.leave,
          exitActive: styles.leaveActive,
        }}
        timeout={{ enter: 0, exit: 300 }}
      >
        <div className={styles.loadingLayout}>
          {loadingHeader ? (
            <header className={styles.header} role="banner">
              <ContentWrapper hasPaddingSmall hasPaddingMedium>
                <GridRow flex>
                  <GridColumn smallSize={3} mediumSize={3} largeSize={2}>
                    <div className={styles.columnContent}>
                      <img alt="ASOS" src={Logo} className={styles.asosLogo} />
                    </div>
                  </GridColumn>
                  <GridColumn
                    smallSize={6}
                    mediumSize={6}
                    largeSize={8}
                    className={styles.titleColumn}
                  >
                    <div className={styles.title}>
                      <LoadingRectanglePlaceholder />
                    </div>
                  </GridColumn>
                </GridRow>
              </ContentWrapper>
            </header>
          ) : null}
          <ContentWrapper>
            <GridRow flex={contentFlex}>
              {showChrome && (
                <GridColumn
                  hideSmallOnly={!showSmallNavigationOnly}
                  smallSize={12}
                  largeSize={4}
                >
                  <LoadingNavigation />
                </GridColumn>
              )}
              <GridColumn
                hideSmallOnly={showSmallNavigationOnly}
                smallSize={12}
                largeSize={showChrome ? 8 : 12}
                flex={contentFlex}
              >
                <LoadingAppContent />
              </GridColumn>
            </GridRow>
          </ContentWrapper>
        </div>
      </CSSTransition>
    )}
  </TransitionGroup>
);

LoadingAppContentSection.propTypes = {
  appLoaded: PropTypes.bool.isRequired,
  contentFlex: PropTypes.bool.isRequired,
  showChrome: PropTypes.bool.isRequired,
  showSmallNavigationOnly: PropTypes.bool.isRequired,
  loadingHeader: PropTypes.bool,
};

export default LoadingAppContentSection;
