import request from "asos.myaccount.request";

const CUSTOMER_PASSWORD_ENDPOINT =
  "customer/identity/v1/identities/{customerId}/password";

export const changePassword = (passwords, identity, requestFn = request) =>
  requestFn(CUSTOMER_PASSWORD_ENDPOINT, identity, {
    body: JSON.stringify({
      existingPassword: passwords.existingPassword,
      password: passwords.password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });
