import { connect } from "react-redux";
import { getFormNames } from "redux-form";

import {
  appStartupError,
  finishUserLeaving,
  notifyFinishStartupLoading,
  saveFormAndExitModal,
} from "@state/application/actions";
import { getIsLeaving, getPlatform } from "@state/application/selectors";
import { getAudienceSegments } from "@state/audience-segments/actions";
import {
  communicationPreferencesError,
  hasPreferencesLoaded,
} from "@state/communication-preferences/selectors";
import {
  authenticateIdentity,
  getIdentityProfile,
  login,
} from "@state/identity/actions";
import { getPremierSubscriptions } from "@state/premier/actions";
import { getSubscriptionOptions } from "@state/subscription-options/actions";
import { getUserDetails } from "@state/user/actions";

import { noticeError } from "@utils/monitoring";

import App from "./components";
import { getPageLoadStatus } from "./components/utils";

export const getFormName = (state) =>
  getFormNames()(state).find((formName) => formName);

const mapStateToProps = (state) => ({
  communicationPreferencesError: communicationPreferencesError(state),
  formName: getFormName(state),
  hasPreferencesLoaded: hasPreferencesLoaded(state),
  isLeaving: getIsLeaving(state),
  isPageDataLoaded: getPageLoadStatus(state).loaded,
  pageId: getPageLoadStatus(state)?.pageId,
  noticeError,
  platform: getPlatform(state),
});

const mapDispatchToProps = {
  appStartupError,
  authenticateIdentity,
  finishUserLeaving,
  getIdentityProfile,
  getAudienceSegments,
  getSubscriptionOptions,
  getPremierSubscriptions,
  getUserDetails,
  login,
  notifyFinishStartupLoading,
  saveFormAndExitModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
