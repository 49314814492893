export const dropOff = {
  form: "drop-off",
  fields: {
    search: {
      label: "ma_web_orderreturn_returnmethod_dropoff_findapoint",
      placeholder: "ma_web_orderreturn_returnmethod_dropoff_searchbypostcode",
      required: true,
    },
  },
} as const;

export const returnItems = {
  form: "return-order",
  fields: {
    quantity: {
      label: "ma_web_orderreturn_returnitem_quantity",
      required: true,
    },
    reasonId: {
      label: "ma_web_orderreturn_returnitem_reason",
      placeholder: "ma_web_orderreturn_returnitem_reasonplaceholder",
      required: true,
    },
    notes: {
      label: "ma_web_orderreturn_returnitem_additionalinfo",
      maxLength: 500,
      placeholder: "ma_web_orderreturn_returnitem_optionalnote",
    },
  },
} as const;
