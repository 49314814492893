import React from "react";

import PropTypes from "prop-types";

import styles from "./index.css";

const PageContainer = ({ children }) => (
  <div className={styles.addMargin}>{children}</div>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default PageContainer;
