const getApplication = (state) => state.application;
export const getIdentityState = (state) => state.identity;

export const getIsLeaving = (state) => getApplication(state).isLeaving;

export const getECommerceStore = (state) =>
  getApplication(state).eCommerceStore;

export const getCountry = (state) => getApplication(state).country;

export const getLanguage = (state) => getApplication(state).language;

export const getPlatform = (state) => getApplication(state).platform;

export const getIfMobilePlatform = (state) =>
  getPlatform(state).toLowerCase() === "mobile";

export const getVisitedPages = (state) => getApplication(state).visitedPages;

export const getApplicationConfig = (state) => getApplication(state).config;

export const getAuthorityUri = (state) =>
  getApplicationConfig(state).authorityUri;

export const getMapsSdkUri = (state) => getApplicationConfig(state).mapsSdkUri;

export const getPermittedPaymentOptions = (state) =>
  getApplicationConfig(state).permittedPaymentOptions;

export const getSecureFormSDKLocation = (state) =>
  getApplicationConfig(state).secureCardFormSDK;

export const getAsosAssetsLocation = (state) =>
  getApplicationConfig(state).asosAssetsLocation;

export const getFlagAssetsLocation = (state) =>
  getApplicationConfig(state).flagAssetsLocation;

export const getCurrency = (state) => getApplication(state).currency;

export const getAppStartupError = (state) =>
  !!getApplication(state).appStartupError ||
  !!getIdentityState(state).fatalError;

export const appLoaded = (state) => getApplication(state).loaded;

export const getDigiCertSealCode = (state) =>
  getApplicationConfig(state).digiCertSealCode;

export const getMapsKey = (state) => getApplicationConfig(state).mapsKey;

export const getSiteRoot = (state) => getApplication(state).siteRoot;

export const getIfChromeShouldBeShown = (state) =>
  getApplication(state).showChrome;

export const getMenuClicked = (state) => getApplication(state).menuClicked;
