import inViewport from "in-viewport";

const SCROLL_TRANSITION_DELAY = 100;
const SMOOTHSCROLL_ANIMATION_TIME = 468; // The library does not export this variable and there is no callback

export const scrollElementIdIntoView = (
  id,
  smooth = false,
  queryScope = document
) => {
  setTimeout(() => {
    const targetElement = queryScope.getElementById(id);
    if (targetElement) {
      const options = smooth ? { behavior: "smooth" } : undefined;
      targetElement.scrollIntoView(options);
    }
  });
};

export const scrollErrorIntoView = (
  queryScope = document,
  isWithinViewport = inViewport
) => {
  setTimeout(() => {
    const firstError = queryScope.querySelector("[data-has-error]");
    if (firstError) {
      setTimeout(
        () => (firstError.querySelector("input, select") || firstError).focus(),
        SCROLL_TRANSITION_DELAY + SMOOTHSCROLL_ANIMATION_TIME
      );

      if (!isWithinViewport(firstError)) {
        setTimeout(
          () => firstError.scrollIntoView({ behavior: "smooth" }),
          SCROLL_TRANSITION_DELAY
        );
      }
    }
  });
};
