import { connect } from "react-redux";

import { getIfChromeShouldBeShown } from "@state/application/selectors";

import PageHeader from "./component";

const mapStateToProps = (state) => ({
  isChromeless: !getIfChromeShouldBeShown(state),
});

export default connect(mapStateToProps)(PageHeader);
