import React from "react";
import { useSelector, useDispatch } from "react-redux";

import ButtonGroup from "@components/button-group";
import Modal from "@components/modal";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import {
  acceptDeletePaymentMethod,
  denyDeletePaymentAcceptance,
} from "@state/payment/actions";

import { Button } from "../../../../components/button";

import styles from "./index.css";

const DeletePaymentMethodModal = () => {
  const dispatch = useDispatch();
  const formatTranslation = useFormatTranslation();

  const { requireDeleteAcceptance } = useSelector(
    (state: { payment: { requireDeleteAcceptance: boolean } }) => {
      return { requireDeleteAcceptance: state.payment.requireDeleteAcceptance };
    }
  );
  return (
    <Modal
      contentLabel={formatTranslation("ma_web_deletepaymentmethodmodal_title")}
      isOpen={requireDeleteAcceptance}
      onRequestClose={() => dispatch(denyDeletePaymentAcceptance())}
      title={formatTranslation("ma_web_deletepaymentmethodmodal_title")}
      padContent
    >
      <div data-auto-id="DeletePaymentMethodModal">
        <div className={styles.question}>
          {formatTranslation("ma_web_deletepaymentmethodmodal_question")}
        </div>
        <ButtonGroup flexDisplay centerContent>
          <Button
            data-auto-id="DeletePaymentMethodModalAccept"
            onClick={() => dispatch(acceptDeletePaymentMethod())}
            type="button"
          >
            {formatTranslation("ma_web_deletepaymentmethodmodal_delete")}
          </Button>
          <Button
            data-auto-id="DeletePaymentMethodModalCancel"
            buttonType="secondary"
            onClick={() => dispatch(denyDeletePaymentAcceptance())}
            type="button"
          >
            {formatTranslation("ma_web_deletepaymentmethodmodal_cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export default DeletePaymentMethodModal;
