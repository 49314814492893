import React, { createContext, useContext } from "react";

import Polyglot from "node-polyglot";

export const getLanguage = (ietf: string): string => ietf.split("-")[0];

interface BaseLocaleContext {
  language: string;
  siteRoot: string;
  translations: Record<any, any>;
  tags?: Record<any, any>;
}

interface LocaleContextType extends BaseLocaleContext {
  baseLanguageCode: string;
  polyglotInstance: Polyglot;
}

export const LocaleContext = createContext<LocaleContextType>(null);

export interface LocaleContextProviderProps extends BaseLocaleContext {
  children: React.ReactNode;
}

export const LocaleContextProvider = ({
  language,
  siteRoot,
  translations,
  tags = {},
  children,
}: LocaleContextProviderProps) => {
  const baseLanguageCode = getLanguage(language);
  const polyglotInstance = new Polyglot({
    locale: baseLanguageCode,
    phrases: translations,
  });

  const value = {
    language,
    siteRoot,
    translations,
    tags,
    baseLanguageCode,
    polyglotInstance,
  };

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};

export const useLocaleContext = () => useContext(LocaleContext);
