import request from "asos.myaccount.request";

const COMMUNICATION_PREFERENCES_ENDPOINT =
  "customer/preference/v1/preferences/{customerId}";

export const getCommunicationPreferences = (
  country,
  lang,
  identity,
  requestFn = request
) =>
  requestFn(COMMUNICATION_PREFERENCES_ENDPOINT, identity, {
    query: {
      country,
      expand: "content",
      lang,
    },
  });

export const saveCommunicationPreferences = (
  userPreferences,
  identity,
  requestFn = request
) =>
  requestFn(COMMUNICATION_PREFERENCES_ENDPOINT, identity, {
    body: JSON.stringify({
      sourceReason: "MyAccount",
      services: userPreferences,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PATCH",
  });
