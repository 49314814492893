import React, { useRef, useState } from "react";

import AccordionItem from "./accordion-item";

export const getKey = (index, title) =>
  typeof title !== "object" ? title : index;

type AccordionItem = {
  content: React.ReactNode;
  key?: string;
  title: string;
};

type AccordionProps = {
  isSlim?: boolean;
  isSemantic?: boolean;
  openItem?: string;
  items: AccordionItem[];
};

const Accordion: React.FC<AccordionProps> = ({
  isSlim = false,
  isSemantic = true,
  items,
  openItem = undefined,
}) => {
  const collapsedItems = items.map((item, i) => {
    const isCollapsed =
      openItem === undefined || item.key === undefined || item.key !== openItem;
    return { ...item, collapsed: isCollapsed, id: i };
  });

  const [formattedItems, setFormattedItems] = useState(collapsedItems);

  const wrapperRef = useRef(null);

  const toggle = (index: number) => {
    const items = formattedItems.map((item) => ({
      ...item,
      collapsed: item.id === index ? !item.collapsed : true,
    }));

    setFormattedItems(items);

    const component = wrapperRef.current.childNodes[index];
    setTimeout(() => {
      component.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const isList = formattedItems.length > 1;
  const WrappingComponent = isList ? (isSemantic ? "ul" : "div") : "div";

  return (
    <WrappingComponent ref={wrapperRef}>
      {formattedItems.map(({ title, key, ...props }, i) => (
        <AccordionItem
          {...props}
          isSlim={isSlim}
          isSemantic={isSemantic}
          isList={isList}
          key={key || getKey(i, title)}
          position={i}
          title={title}
          toggle={toggle}
        />
      ))}
    </WrappingComponent>
  );
};

export default Accordion;
