import request from "asos.myaccount.request";

import { returnsPageSize } from "@configuration/returns-history";

import { replaceTokens } from "@utils/string";

const RETURNS_ENDPOINT =
  "commerce/customerreturns/v2/customers/{customerId}/returns";
const RETURNS_ASSET_ACCESS_TOKEN_ENDPOINT =
  "commerce/customerreturns/v2/customers/{customerId}/returns/{returnReference}/ReturnDocuments/AccessToken";

const getReturnsAssetAccessTokenEndpoint = (returnReference) =>
  replaceTokens(RETURNS_ASSET_ACCESS_TOKEN_ENDPOINT, { returnReference });

export const getReturnedOrders = (
  lang,
  identity,
  offset,
  requestFn = request
) =>
  requestFn(RETURNS_ENDPOINT, identity, {
    query: {
      lang,
      limit: returnsPageSize,
      offset,
    },
  });

export const getAssetAccessToken = (
  returnReference,
  returnDocumentUri,
  identity,
  requestFn = request
) =>
  requestFn(getReturnsAssetAccessTokenEndpoint(returnReference), identity, {
    body: JSON.stringify({ returnDocumentUri }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
