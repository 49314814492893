export class RequestError extends Error {
  public statusCode: number;
  public body: string;
  public apiValidationError = false;

  constructor(url, statusCode, body) {
    const message = `The request '${url}' failed with status '${statusCode}': '${statusCode}'`;
    super(message);
    this.statusCode = statusCode;
    this.body = body;
    this.apiValidationError = this.statusCode === 400;
  }
}
