import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";
import { useFormatUrl } from "@hooks/useFormatUrl";

import { clearApiErrors } from "@state/application/actions";
import { getReturns } from "@state/returns-history/actions";

const bundleLoader = () =>
  import(/* webpackChunkName: 'returns-list' */ "./returns-list");

export const ReturnsHistory: React.FC = () => {
  const dispatch = useDispatch();
  const formatUrl = useFormatUrl();

  const { bundle: ReturnsList, fatalError: bundleError } =
    useBundle(bundleLoader);

  const {
    returnsHistoryLoaded: loaded,
    fatalError,
    returns,
  } = useSelector(
    (state: {
      returnsHistory: {
        loaded: boolean;
        fatalError: boolean;
        returns: Array<any>;
      };
    }) => {
      return {
        returnsHistoryLoaded: state.returnsHistory.loaded,
        fatalError: state.returnsHistory.fatalError,
        returns: state.returnsHistory.returns,
      };
    }
  );

  useEffect(() => {
    dispatch(clearApiErrors());

    dispatch(
      getReturns({
        isInitialRequest: true,
      })
    );
  }, []);

  const pageReady = ReturnsList && loaded;
  const hasReturns = returns.length > 0;

  return (
    <Page
      loaded={pageReady}
      pageHeader={
        <PageHeader
          title="ma_web_returns_title"
          icon="returns"
          hasBottomPadding
          buttonTo={
            hasReturns ? formatUrl("ExternalLink.Returns.NeedHelp", {}) : ""
          }
          buttonText="ma_web_navigation_needhelp"
          buttonOnRight={true}
        />
      }
      pageId="returns-history"
      pageTitleKey="ma_web_returns_title"
      showErrorSection={fatalError || bundleError}
      showScrollToTopButton
    >
      {pageReady && <ReturnsList />}
    </Page>
  );
};

export default ReturnsHistory;
