import { handleActions } from "redux-actions";

import appConfig from "@appConfig";

import { filterServices } from "@utils/filterServices";

import { CLEAR_API_ERRORS } from "../application/types";
import {
  API_ERROR,
  CONFIRM_SAVED_COMMUNICATION_PREFERENCES,
  RECEIVE_PREFERENCES,
  REQUEST_PREFERENCES,
} from "./types";

const serviceFilter = (services) => {
  const filteredServices = services.filter((service) =>
    appConfig.permittedCommunicationOptions.includes(service.serviceId)
  );

  return filteredServices.filter(
    (service) => new Date(service.publishedFrom) < new Date()
  );
};

const setServiceId = ({ services = [] }) => {
  const serviceFilterFn = serviceFilter(services);

  const publishedServiceIds = serviceFilterFn.map(
    (service) => service.serviceId
  );
  return publishedServiceIds;
};

const setServiceAndPreferences = ({ services = [] }) => {
  const serviceFilterFn = serviceFilter(services);

  if (serviceFilterFn.length) {
    return serviceFilterFn.map((service) => ({
      serviceId: service.serviceId,
      preferences: service.preferences.map(
        (preference) => preference.preferenceId
      ),
    }));
  }
  return [];
};

const parseServices = ({ services = [] }, serviceId: string[]) => {
  return filterServices(services, serviceId).reduce(
    (allPrefs, { preferences }) => [...allPrefs, ...preferences],
    []
  );
};

const reducer = handleActions(
  {
    [API_ERROR]: (state) => {
      const { maxRetries, tries } = state;
      if (tries >= maxRetries) {
        return {
          ...state,
          apiError: false,
          fatalError: true,
          preferencesLoaded: false,
        };
      }
      return {
        ...state,
        apiError: true,
        fatalError: false,
        preferencesLoaded: true,
        tries: tries + 1,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      apiError: false,
      fatalError: false,
      tries: 0,
    }),
    [CONFIRM_SAVED_COMMUNICATION_PREFERENCES]: (state, { payload }) => ({
      ...state,
      apiError: false,
      fatalError: false,
      isFormSubmitted: true,
      preferencesLoaded: true,
      preferences: state.preferences.map((pref) => ({
        ...pref,
        customerChannels: payload.find(
          (newPref) => pref.preferenceId === newPref.preferenceId
        ).customerChannels,
      })),
    }),
    [REQUEST_PREFERENCES]: (state) => ({
      ...state,
      preferencesLoaded: false,
    }),
    [RECEIVE_PREFERENCES]: (state, { error = false, payload }) => ({
      ...state,
      fatalError: error,
      serviceId: !error ? setServiceAndPreferences(payload) : [],
      preferences: !error ? parseServices(payload, setServiceId(payload)) : [],
      preferencesLoaded: !error,
      tries: 0,
    }),
  },
  {
    apiError: false,
    fatalError: false,
    maxRetries: 2,
    serviceId: [],
    preferences: [],
    preferencesLoaded: false,
    tries: 0,
  }
);

export default reducer;
