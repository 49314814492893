import React from "react";

import ButtonGroup from "@components/button-group";
import Modal from "@components/modal";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../../components/button";

import styles from "./index.css";

export interface VoucherReturnsModalProps {
  isOpen: boolean;
  onAccept: () => void;
}

const VoucherReturnsModal = ({
  isOpen,
  onAccept,
}: VoucherReturnsModalProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Modal
      contentLabel={formatTranslation(
        "ma_web_giftcardsandvouchers_returnsmodal_content"
      )}
      isOpen={isOpen}
      onRequestClose={onAccept}
      title={formatTranslation("ma_web_giftcardsandvouchers_returnslink")}
      padContent
    >
      <div data-auto-id="VoucherReturnsModal">
        <div className={styles.content}>
          {formatTranslation(
            "ma_web_giftcardsandvouchers_returnsmodal_content"
          )}
        </div>
        <ButtonGroup flexDisplay centerContent>
          <Button
            onClick={onAccept}
            data-auto-id="DismissVoucherReturnsModal"
            type="button"
          >
            {formatTranslation(
              "ma_web_giftcardsandvouchers_returnsmodal_buttonlabel"
            )}
          </Button>
        </ButtonGroup>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={formatTranslation(
            "ma_web_giftcardsandvouchers_returnsmodal_helplink"
          )}
          className={styles.helpLink}
        >
          {formatTranslation(
            "ma_web_giftcardsandvouchers_returnsmodal_helplabel"
          )}
        </a>
      </div>
    </Modal>
  );
};

export default VoucherReturnsModal;
