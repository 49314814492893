/**
 * The getPreviousValue function takes in the value to set on a cookie as first argument
 * takes the cookie name as second argument
 * returns the previous value of the cookie
 * The cookie itself can be found saved under s_pers on the browser.
 * The getVisitNum function returns the current page visit count, which increments only
 * after 30 minutes of inactivity
 */
export const getPageLoadCount = () => {
  if (window?.s?.getPreviousValue && window?.s?.getVisitNum) {
    const adobeMeasurementInstance = window.s;

    // Check to see if any of the cookies have been set by any other app the user is coming from
    const previousPageLoadCount = adobeMeasurementInstance.getPreviousValue(
      "",
      "eVar225"
    );
    const visitCountCookie = adobeMeasurementInstance.getPreviousValue(
      "",
      "visitCount"
    );

    const currentPageLoadCount = adobeMeasurementInstance.getVisitNum();

    // Return 1 and set eVar225 to 1 if cookies haven't been set or 30 minutes of inactivity have elapsed
    if (
      (!previousPageLoadCount && !visitCountCookie) ||
      currentPageLoadCount !== visitCountCookie
    ) {
      adobeMeasurementInstance.getPreviousValue("1", "eVar225");
      adobeMeasurementInstance.getPreviousValue(
        currentPageLoadCount,
        "visitCount"
      );

      return "1";
    }

    const increment = Number(previousPageLoadCount) + 1;
    adobeMeasurementInstance.getPreviousValue(increment.toString(), "eVar225");
    adobeMeasurementInstance.getPreviousValue(
      currentPageLoadCount,
      "visitCount"
    );

    return increment.toString();
  }
};
