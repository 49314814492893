import React from "react";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface ItemAttributesProps {
  attributes: {
    ariaLabel?: string;
    label: string;
    value: string | number;
  }[];
}

const ItemAttributes = ({ attributes }: ItemAttributesProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <div className={styles.attributesWrapper}>
      <div className={styles.attributes}>
        {attributes.map(({ value, label, ariaLabel }) => (
          <div className={styles.attribute} key={label}>
            <span
              aria-label={ariaLabel ? formatTranslation(ariaLabel) : null}
              className={styles.label}
            >
              {formatTranslation(label)}
              :&nbsp;
            </span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemAttributes;
