import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";
import { useFormatUrl } from "@hooks/useFormatUrl";

import { clearApiErrors } from "@state/application/actions";
import {
  clearCancelOrder,
  getOrders,
  updateReturnRmaRequestDateTime,
} from "@state/orders/actions";
import { clearCreateReturnAttempts } from "@state/returns/actions";
import { createReturnFatalError } from "@state/returns/selectors";

const loadContent = () =>
  import(/* webpackChunkName: 'order-list' */ "./order-list");

const OrderHistory = () => {
  const { bundle: OrderList } = useBundle(loadContent);
  const { fatalError, loaded, orders } = useSelector((state: any) => ({
    fatalError: state.orders.fatalError || createReturnFatalError(state),
    orders: state.orders.all,
    loaded: state.orders.loaded,
  }));
  const hasOrders = orders.length > 0;

  const dispatch = useDispatch();
  const formatUrl = useFormatUrl();

  useEffect(() => {
    dispatch(clearApiErrors());
    dispatch(getOrders());
    dispatch(clearCancelOrder());
    dispatch(clearCreateReturnAttempts());
    dispatch(
      updateReturnRmaRequestDateTime({
        rmaRequestDateTime: new Date().toISOString(),
      })
    );
  }, []);

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageHeader
          title="ma_web_orderhistory_title"
          icon="orders"
          hasBottomPadding
          buttonTo={
            hasOrders ? formatUrl("ExternalLink.Orders.NeedHelp", {}) : ""
          }
          buttonText="ma_web_navigation_needhelp"
          buttonOnRight={true}
        />
      }
      pageId="order-history"
      pageTitleKey="ma_web_orderhistory_title"
      showErrorSection={fatalError}
      showScrollToTopButton
    >
      {loaded && !!OrderList && <OrderList />}
    </Page>
  );
};

export default OrderHistory;
