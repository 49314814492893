export const API_ERROR = "API_USER_ERROR";
export const ATTEMPT_UNLINK_CURRENT_LOGIN_PROVIDER =
  "ATTEMPT_UNLINK_CURRENT_LOGIN_PROVIDER";
export const FINISH_UPDATE_USER_DETAILS = "FINISH_UPDATE_USER_DETAILS";
export const RECEIVE_USER_DETAILS = "RECEIVE_USER_DETAILS";
export const UPDATE_USER_DETAILS_UPDATED_FIELDS =
  "UPDATE_USER_DETAILS_UPDATED_FIELDS";
export const REQUEST_UPDATE_USER_DETAILS = "REQUEST_UPDATE_USER_DETAILS";
export const REQUEST_USER_DETAILS = "REQUEST_USER_DETAILS";
export const CLICK_RENEW_SUBSCRIPTION = "CLICK_RENEW_SUBSCRIPTION";
