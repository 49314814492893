import React from "react";

import PropTypes from "prop-types";

import ResponsiveImage from "@components/responsive-image";

import styles from "./index.css";

const imageSrcSizes = `
  ${styles.ordersListLBreakpoint} ${styles.largeListImageMaxSize},
  ${styles.mBreakpoint} ${styles.mediumImageMaxSize},
  ${styles.sBreakpoint} ${styles.smallImageMaxSize},
  ${styles.defaultImageMaxSize}
`;

const ItemImage = ({ index, item, length, maxImages }) => (
  <div>
    {index === maxImages - 1 && length > maxImages && (
      <div className={styles.overlay}>
        <span className={styles.extra}>+{length - maxImages}</span>
      </div>
    )}
    <ResponsiveImage
      alt={item.name}
      url={item.imageUrl}
      dynamicDimensions={{
        imageSizes: imageSrcSizes,
        srcSetSizes: [100, 200, 300, 400, 500, 600, 700, 800],
        defaultSrcSetIndex: 1,
      }}
    />
  </div>
);

ItemImage.propTypes = {
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  maxImages: PropTypes.number.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default ItemImage;
