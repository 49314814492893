import React from "react";

import {
  Card,
  CardSection,
  CardList,
} from "@asosteam/asos-web-component-library-cards";

import Layout from "@components/layout";
import PageContainer from "@components/layout/page-container";
import PageHeader from "@components/page-header";
import PageInfo from "@components/page-info";
import SectionDivider from "@components/section-divider";
import VisuallyHidden from "@components/visually-hidden";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../components/button";
import GiftHelpAccordion from "../gift-help-accordion";

import styles from "./component.css";

export interface AddGiftProps {
  internationalGiftCardsLink?: string;
  openAddGiftCard: () => void;
  openAddGiftVoucher: () => void;
}

const AddGift = ({
  internationalGiftCardsLink,
  openAddGiftCard,
  openAddGiftVoucher,
}: AddGiftProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Layout data-auto-id="add-gift-cards-and-vouchers">
      <PageInfo title="ma_web_giftcardsandvouchers_addgift" meta={[]} />
      <PageContainer>
        <PageHeader
          dataAutoId="AddGiftCardsAndVouchersHeader"
          previousPageLink="/gift-cards-and-vouchers"
          title="ma_web_giftcardsandvouchers_addgift"
          type="secondary"
        />
      </PageContainer>
      <CardList isSemantic={false}>
        <Card>
          <CardSection hasTopPadding={false}>
            <div className={styles.buttonWrapper}>
              <Button
                buttonType="secondary"
                data-auto-id="addGiftCard"
                onClick={() => {
                  if (internationalGiftCardsLink) {
                    window.location =
                      internationalGiftCardsLink as unknown as Location;
                  } else {
                    openAddGiftCard();
                  }
                }}
              >
                <VisuallyHidden>
                  {formatTranslation(
                    "ma_web_addpaymentmethod_addindefinitearticle"
                  )}
                </VisuallyHidden>
                {formatTranslation("ma_web_giftcardsandvouchers_giftcard")}
              </Button>
              <SectionDivider>
                {formatTranslation("ma_web_addpaymentmethod_methodlistor")}
              </SectionDivider>
              <Button
                buttonType="secondary"
                data-auto-id="addGiftVoucher"
                onClick={() => openAddGiftVoucher()}
              >
                <VisuallyHidden>
                  {formatTranslation(
                    "ma_web_addpaymentmethod_addindefinitearticle"
                  )}
                </VisuallyHidden>
                {formatTranslation("ma_web_giftcardsandvouchers_giftvoucher")}
              </Button>
            </div>
          </CardSection>
        </Card>
        <GiftHelpAccordion
          title={formatTranslation("ma_web_giftcardsandvouchers_needhelp")}
        />
      </CardList>
    </Layout>
  );
};

export default AddGift;
