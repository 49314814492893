import * as types from "@state/analytics/types";

import { emitter } from "./analyticsEmitter";
import { getBasicPayload } from "./analyticsUtils";
import { getPageInteractionPayload } from "./pageInteractionHandler";
import { AnalyticsState } from "./pageLoadHook";

export const sendPageInteraction = async (
  type: string,
  state: AnalyticsState,
  data: object
) => {
  const basicPayload = getBasicPayload(state);
  const interactionPayload = getPageInteractionPayload(type, state, data);
  const trackingEvent = {
    id: types.PAGE_INTERACTION,
    payload: {
      ...basicPayload,
      ...interactionPayload,
    },
  };

  emitter(trackingEvent, state.router.location.pathname);
};
