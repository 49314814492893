import { connect } from "react-redux";

import { getCountry } from "@state/application/selectors";
import {
  openAddGiftCard,
  openAddGiftVoucher,
} from "@state/gift-cards-and-vouchers/actions";

import AddGift from "./component";
import getInternationalGiftCardsLink from "./getInternationalGiftCardsLink";

const mapStateToProps = (state) => {
  const browseCountry = getCountry(state);

  const internationalGiftCardsLink = getInternationalGiftCardsLink(
    browseCountry,
    false
  );

  return { internationalGiftCardsLink };
};

const mapDispatchToProps = {
  openAddGiftCard,
  openAddGiftVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGift);
