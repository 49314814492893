import React from "react";

import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface ReturnsWarningMessageProps {
  returnsWarningMessage: string;
}

const ReturnsWarningMessage = ({
  returnsWarningMessage,
}: ReturnsWarningMessageProps) => {
  const formatTranslation = useFormatTranslation();

  return (
    returnsWarningMessage && (
      <div
        className={styles.warningMessageWrapper}
        data-testid="returns-warning-message"
      >
        <div>
          <Icon id="returns" />
        </div>
        <div className={styles.warningMessage}>
          <h3 className={styles.warningHeading + " london2"}>
            {formatTranslation("ma_web_orderreturn_return_policy_title")}
          </h3>
          <div
            className={styles.warningMessageBody}
            dangerouslySetInnerHTML={{ __html: returnsWarningMessage }}
          />
        </div>
      </div>
    )
  );
};

export default ReturnsWarningMessage;
