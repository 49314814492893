import { connect } from "react-redux";

import { getFlagAssetsLocation } from "@state/application/selectors";

import CountryFlagIcon from "./component";

const mapStateToProps = (state) => ({
  flagAssetsLocation: getFlagAssetsLocation(state),
});

export default connect(mapStateToProps)(CountryFlagIcon);
