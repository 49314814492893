import { handleActions } from "redux-actions";

import { maxRetries } from "@configuration/gift-vouchers";

import { CLEAR_API_ERRORS } from "../application/types";
import * as types from "./types";

const applyGiftCard = (state, { payload }) => ({
  ...state,
  addGiftCardForm: {
    ...state.addGiftCardForm,
    loaded: true,
    savedNewGiftCard: true,
  },
  voucherCount: state.voucherCount + 1,
  vouchers: [...state.vouchers, payload],
});

const applyGiftVoucher = (state, { payload }) => ({
  ...state,
  addGiftVoucherForm: {
    ...state.addGiftVoucherForm,
    loaded: true,
    savedNewGiftVoucher: true,
  },
  voucherCount: state.voucherCount + 1,
  vouchers: [...state.vouchers, payload],
});

const reducer = handleActions(
  {
    [types.REQUEST_GIFT_VOUCHERS]: (state, { payload: { loadMore } }) => ({
      ...state,
      loaded: loadMore,
      loadingMore: loadMore,
      tries: {
        ...state.tries,
        loadMore: state.tries.loadMore + (loadMore ? 1 : 0),
      },
    }),
    [types.RECEIVE_GIFT_VOUCHERS]: (
      state,
      { error, payload: { append, response } }
    ) => {
      if (error) {
        if (state.loadingMore) {
          return {
            ...state,
            apiErrors: {
              ...state.apiErrors,
              loadMore: true,
            },
            loaded: true,
            loadingMore: false,
            fatalError: state.tries.loadMore > maxRetries,
          };
        }
        return {
          ...state,
          loaded: true,
          fatalError: true,
        };
      }

      return {
        ...state,
        addGiftCardForm: {
          ...state.addGiftCardForm,
          fatalError: false,
          savedNewGiftCard: false,
        },
        addGiftVoucherForm: {
          ...state.addGiftVoucherForm,
          fatalError: false,
          savedNewGiftVoucher: false,
        },
        apiErrors: {
          ...state.apiErrors,
          loadMore: false,
        },
        fatalError: false,
        loaded: true,
        loadingMore: false,
        tries: {
          ...state.tries,
          loadMore: 0,
        },
        vouchers: [...(append ? state.vouchers : []), ...response.vouchers],
        voucherCount: response.itemCount,
      };
    },
    [types.REQUEST_ASSOCIATE_GIFT_CARD]: (state) => ({
      ...state,
      addGiftCardForm: {
        ...state.addGiftCardForm,
        loaded: false,
      },
    }),
    [types.RECEIVE_ASSOCIATE_GIFT_CARD]: (state, payload) =>
      payload.error
        ? {
            ...state,
            addGiftCardForm: {
              ...state.addGiftCardForm,
              loaded: true,
            },
          }
        : applyGiftCard(state, payload),
    [types.REQUEST_ASSOCIATE_GIFT_VOUCHER]: (state) => ({
      ...state,
      addGiftVoucherForm: {
        ...state.addGiftVoucherForm,
        loaded: false,
      },
    }),
    [types.RECEIVE_ASSOCIATE_GIFT_VOUCHER]: (state, payload) =>
      payload.error
        ? {
            ...state,
            addGiftVoucherForm: {
              ...state.addGiftVoucherForm,
              loaded: true,
            },
          }
        : applyGiftVoucher(state, payload),
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      apiErrors: {},
      addGiftCardForm: {
        ...state.addGiftCardForm,
        fatalError: false,
      },
      addGiftVoucherForm: {
        ...state.addGiftVoucherForm,
        fatalError: false,
      },
      fatalError: false,
      tries: {
        loadMore: 0,
      },
    }),
    [types.GIFT_CARD_API_ERROR]: (state, { payload: { userAction } }) => {
      const { tries } = state;
      if (tries[userAction] >= maxRetries) {
        return {
          ...state,
          apiErrors: {},
          addGiftCardForm: {
            ...state.addGiftCardForm,
            fatalError: true,
            loaded: false,
          },
        };
      }
      return {
        ...state,
        addGiftCardForm: {
          ...state.addGiftCardForm,
          fatalError: false,
          loaded: true,
        },
        tries: {
          ...state.tries,
          [userAction]: (tries[userAction] || 0) + 1,
        },
        apiErrors: {
          ...state.apiErrors,
          [userAction]: true,
        },
      };
    },
    [types.GIFT_VOUCHER_API_ERROR]: (state, { payload: { userAction } }) => {
      const { tries } = state;
      if (tries[userAction] >= maxRetries) {
        return {
          ...state,
          apiErrors: {},
          addGiftVoucherForm: {
            ...state.addGiftVoucherForm,
            fatalError: true,
            loaded: false,
          },
        };
      }
      return {
        ...state,
        addGiftVoucherForm: {
          ...state.addGiftVoucherForm,
          fatalError: false,
          loaded: true,
        },
        tries: {
          ...state.tries,
          [userAction]: (tries[userAction] || 0) + 1,
        },
        apiErrors: {
          ...state.apiErrors,
          [userAction]: true,
        },
      };
    },
    [types.RESET_SAVE_NEW_GIFT_CARD_AND_VOUCHER]: (state) => ({
      ...state,
      addGiftCardForm: {
        ...state.addGiftCardForm,
        savedNewGiftCard: false,
      },
      addGiftVoucherForm: {
        ...state.addGiftVoucherForm,
        savedNewGiftVoucher: false,
      },
    }),
  },
  {
    addGiftCardForm: {
      fatalError: false,
      loaded: true,
      savedNewGiftCard: false,
    },
    addGiftVoucherForm: {
      fatalError: false,
      loaded: true,
      savedNewGiftVoucher: false,
    },
    apiErrors: {},
    fatalError: false,
    loaded: false,
    loadingMore: false,
    tries: {
      loadMore: 0,
    },
    voucherCount: 0,
    vouchers: [],
  }
);

export default reducer;
