import React, { useState } from "react";

import classnames from "classnames";

import placeholder from "@asosteam/asos-web-component-library-svg-icons/lib/placeholder.svg";

import styles from "./index.css";

export interface ResponsiveImageProps {
  url: string;
  alt: string;
  fixedDimensions?: {
    height: number;
    width: number;
  };
  dynamicDimensions?: {
    imageSizes: string;
    srcSetSizes: number[] | [number, number][];
    defaultSrcSetIndex?: number;
  };
  onLoad?: () => void;
  renderWrapped?: boolean;
}

const ResponsiveImage = ({
  url,
  alt,
  fixedDimensions,
  dynamicDimensions,
  renderWrapped = true,
  onLoad,
}: ResponsiveImageProps) => {
  const [error, setError] = useState(false);
  const conflictingProps = fixedDimensions && dynamicDimensions;
  const srcSetHasPresets =
    dynamicDimensions !== undefined &&
    dynamicDimensions["srcSetSizes"] !== undefined &&
    dynamicDimensions?.srcSetSizes[0].constructor === Array;

  const getImageSrc = () => {
    if (error || conflictingProps) {
      return placeholder;
    } else if (
      dynamicDimensions !== undefined &&
      dynamicDimensions["srcSetSizes"] !== undefined &&
      dynamicDimensions["defaultSrcSetIndex"] !== undefined
    ) {
      const { srcSetSizes, defaultSrcSetIndex } = dynamicDimensions;
      if (srcSetHasPresets) {
        return `${url}?$n_${srcSetSizes[defaultSrcSetIndex][0]}w$&wid=${srcSetSizes[defaultSrcSetIndex][1]}&fit=constrain`;
      } else {
        return `${url}?wid=${srcSetSizes[defaultSrcSetIndex]}&fit=constrain`;
      }
    } else if (fixedDimensions !== undefined) {
      return `${url}?wid=${fixedDimensions.width}&hei=${fixedDimensions.height}&fit=crop`;
    }
    return placeholder;
  };

  const getSrcSet = () => {
    if (error || conflictingProps) {
      return;
    } else if (
      dynamicDimensions !== undefined &&
      dynamicDimensions["srcSetSizes"] !== undefined
    ) {
      if (srcSetHasPresets) {
        return (dynamicDimensions.srcSetSizes as [number, number][])
          .map(([presetSize, ourSize]) => {
            return `${url}?$n_${presetSize}w$&wid=${ourSize}&fit=constrain ${ourSize}w`;
          })
          .join(",");
      } else {
        return dynamicDimensions.srcSetSizes
          .map((ourSize) => `${url}?wid=${ourSize}&fit=constrain ${ourSize}w`)
          .join(",");
      }
    } else if (fixedDimensions !== undefined) {
      const { width, height } = fixedDimensions;

      return `${url}?wid=${width}&hei=${height}&fit=crop, ${url}?wid=${
        width * 1.5
      }&hei=${height * 1.5}&fit=crop 1.5x,
    ${url}?wid=${width * 2}&hei=${height * 2}&fit=crop 2x, 
    ${url}?wid=${width * 3}&hei=${height * 3}&fit=crop 3x`;
    }
    return;
  };

  return (
    <img
      className={classnames(styles.image, {
        [styles.imageResponsive]: renderWrapped && !fixedDimensions,
      })}
      alt={alt}
      sizes={
        dynamicDimensions && dynamicDimensions.imageSizes
          ? dynamicDimensions.imageSizes
          : null
      }
      onError={() => setError(true)}
      src={getImageSrc()}
      srcSet={getSrcSet()}
      onLoad={onLoad ? onLoad : null}
    />
  );
};

export default ResponsiveImage;
