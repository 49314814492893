import React from "react";

import { setDay } from "date-fns";

import FormattedDate from "@components/formatted-date";

import styles from "./index.css";

export interface StoreOpeningHoursProps {
  tradingPeriods: {
    day: string;
    weekDayIndex: number;
    openingHours: string;
  }[];
}

const StoreOpeningHours = ({ tradingPeriods }: StoreOpeningHoursProps) => {
  const dateToday = new Date();
  return (
    <table className={styles.openingTimes} data-auto-id="storeOpenHours">
      <tbody>
        {tradingPeriods.map((dailyEntry) => {
          const { weekDayIndex } = dailyEntry;
          const dayOfWeekDate = setDay(dateToday, weekDayIndex);

          return (
            <tr key={`day-${weekDayIndex}`}>
              <td className={styles.day}>
                <FormattedDate date={dayOfWeekDate} format="dddd:" />
              </td>
              <td>{dailyEntry.openingHours}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default StoreOpeningHours;
