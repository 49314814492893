export const getNotBouncedVisit = () => {
  if (window !== undefined) {
    const adobeMeasurementInstance = window.s;
    const rawVisitPageCount = parseInt(adobeMeasurementInstance?.eVar225);
    const visitPageCount = isNaN(rawVisitPageCount) ? 1 : rawVisitPageCount;
    const notBouncedVisit = visitPageCount > 1;

    return {
      visitPageCount,
      ...(notBouncedVisit ? { notBouncedVisit } : {}), // Only send to optimizely when nonBounceVisit is true
    };
  }
  return {};
};
