import React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";
import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { triggerApiError } from "@state/password/actions";

import { scrollErrorIntoView } from "@utils/scroll-into-view";

export interface ChangePasswordState {
  password: {
    fatalError: boolean;
    loaded: boolean;
  };
}

const bundleLoader = () =>
  import(/* webpackChunkName: 'change-password' */ "./form");

const ChangePassword = () => {
  const formatTranslation = useFormatTranslation();
  const { bundle: ChangePasswordForm, fatalError: bundleError } =
    useBundle(bundleLoader);
  const { fatalError, loaded } = useSelector(
    (state: ChangePasswordState) => state.password
  );
  const failChangePassword = (
    validationResults,
    dispatch,
    err,
    props,
    scrollErrorIntoViewFn = scrollErrorIntoView
  ) => {
    if (err instanceof SubmissionError) {
      scrollErrorIntoViewFn();
    } else {
      dispatch(triggerApiError(err));
    }
  };

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageContainer>
          <PageHeader
            title="ma_web_changepassword_title"
            icon="padlock"
            description={`${formatTranslation(
              "ma_web_changepassword_description"
            )} ${formatTranslation("ma_web_indicates_required_field")}.`}
            descriptionTranslated
          />
        </PageContainer>
      }
      pageId="change-password"
      pageTitleKey="ma_web_changepassword_title"
      showErrorSection={fatalError || bundleError}
    >
      {ChangePasswordForm && (
        <ChangePasswordForm onSubmitFail={failChangePassword} />
      )}
    </Page>
  );
};

export default ChangePassword;
