import React from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import {
  LoadingCirclePlaceholder,
  LoadingRectanglePlaceholder,
} from "../placeholders";

import styles from "./index.css";

const LoadingAppContent = () => (
  <div className={styles.appPlaceholder}>
    <div className={styles.appPlaceholderContent}>
      <div className={styles.dummyPageHeader}>
        <div className={styles.iconPlaceholder}>
          <LoadingCirclePlaceholder small />
        </div>
        <div className={styles.headerPlaceholder}>
          <LoadingRectanglePlaceholder />
        </div>
      </div>
      <CardSection className={styles.dummyPageContent}>
        <div className={styles.mockTitlePlaceholder}>
          <LoadingRectanglePlaceholder />
        </div>
        <div className={styles.firstPlaceholder}>
          <LoadingRectanglePlaceholder />
        </div>
        <div className={styles.secondPlaceholder}>
          <LoadingRectanglePlaceholder />
        </div>
        <div className={styles.thirdPlaceholder}>
          <LoadingRectanglePlaceholder />
        </div>
        <div className={styles.fourthPlaceholder}>
          <LoadingRectanglePlaceholder />
        </div>
      </CardSection>
    </div>
    &nbsp;
  </div>
);

export default LoadingAppContent;
