import React from "react";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";

import ButtonGroup from "@components/button-group";
import Modal from "@components/modal";
import { SmartLink } from "@components/smart-link";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../components/button";

import styles from "./index.css";

export interface MultiParcelHelpModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const MultiParcelHelpModal = ({
  isOpen,
  onRequestClose,
}: MultiParcelHelpModalProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Modal
      contentLabel={formatTranslation("ma_multi_returns_more_info")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={formatTranslation("ma_multi_returns_limit_title")}
      padContent
    >
      <Card data-auto-id="">
        <CardSection
          className={styles.modalContent}
          hasTopPadding={false}
          hasBottomPadding={false}
          hasLeftPadding={false}
          hasRightPadding={false}
        >
          {formatTranslation("ma_multi_returns_more_info")}
        </CardSection>

        <CardSection
          className={styles.modalContent}
          hasBottomPadding={false}
          hasTopPadding={false}
          hasLeftPadding={false}
          hasRightPadding={false}
        >
          {formatTranslation("ma_multi_returns_more_info2")}
        </CardSection>

        <ButtonGroup flexDisplay centerContent>
          <Button
            onClick={onRequestClose}
            data-auto-id="DismissVoucherRestrictionModal"
            type="button"
          >
            {formatTranslation("ma_multi_returns_more_button")}
          </Button>
        </ButtonGroup>

        <SmartLink
          newWindow
          rel="noopener noreferrer"
          to="ExternalLink.Returns.MultiParcelHelpLink"
          className={styles.modalHelpLink}
        >
          {formatTranslation("ma_multi_returns_more_help_link")}
        </SmartLink>
      </Card>
    </Modal>
  );
};

export default MultiParcelHelpModal;
