import Cookie from "js-cookie";
import queryString from "query-string";

const xSiteOriginKey = "x-site-origin";

const getXSiteOriginQsValue = () =>
  queryString.parse(window.location.search)[xSiteOriginKey] || null;
const getXSiteOriginCookieValue = (getCookieValueFn = Cookie.get) =>
  getCookieValueFn(xSiteOriginKey) || null;

export const get = (getCookieValueFn = Cookie.get) =>
  getXSiteOriginQsValue() ||
  getXSiteOriginCookieValue(getCookieValueFn) ||
  null;
