import React from "react";

import LoadingAppContentSection from "@components/layout/loading-app-content-section";

import styles from "./index.css";

const LayoutSkeleton = () => (
  <div className={styles.layout}>
    <div className={styles.inner}>
      <div className={styles.main}>
        <LoadingAppContentSection
          appLoaded={false}
          showChrome={true}
          loadingHeader={true}
          contentFlex={true}
          showSmallNavigationOnly={true}
        />
      </div>
    </div>
  </div>
);

export default LayoutSkeleton;
