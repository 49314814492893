import React from "react";

import classnames from "classnames";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { createRange } from "@utils/array";
import { isVoucher } from "@utils/product";

import ItemImage from "./item-image";

import styles from "./index.css";

export interface ItemImagesProps {
  ariaLabel?: string;
  centerAlignOnSmallScreens: boolean;
  imageLimit?: number;
  items: Record<any, any>[];
}

const ItemImages = ({
  ariaLabel = null,
  centerAlignOnSmallScreens,
  imageLimit = 3,
  items,
}: ItemImagesProps) => {
  const formatTranslation = useFormatTranslation();
  const columns = createRange(imageLimit, 0);
  const isEven = !(items.length % 2);

  return (
    <div
      aria-label={ariaLabel}
      className={classnames({
        [styles.items]: true,
        [styles.centerOnSmall]: centerAlignOnSmallScreens,
        [styles.gaps]: items.length < imageLimit,
        [styles.even]: isEven,
        [styles.odd]: !isEven,
      })}
    >
      {columns.map((colIndex) => {
        const item = items[colIndex];
        const itemProps = {
          name: formatTranslation("ma_web_giftcardsandvouchers_giftvoucher"),
          ...item,
        };
        return (
          <div className={styles.column} key={colIndex}>
            <div
              className={classnames({
                [styles.columnInner]: true,
                [styles.product]: item && !isVoucher(item),
              })}
            >
              {item && (
                <ItemImage
                  index={colIndex}
                  item={itemProps}
                  length={items.length}
                  maxImages={imageLimit}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ItemImages;
