import React, { useEffect } from "react";

import styles from "./index.css";

const SUPPORTED_SPLASH_LANGUAGES = [
  "en",
  "es",
  "de",
  "fr",
  "it",
  "ja",
  "ko",
  "nl",
  "pt",
  "ru",
  "sv",
  "zh",
  "zt",
];

type SiteSealProps = {
  digiCertSealCode: string;
  language: string;
  supportedLanguages?: string[];
};

const SiteSeal: React.FC<SiteSealProps> = ({
  language,
  digiCertSealCode,
  supportedLanguages = SUPPORTED_SPLASH_LANGUAGES,
}) => {
  const getSupportedLanguage = () => {
    const matchingLanguage = language.split(/\W/g).reduceRight(
      (previousValue, fragment, index, langParts) => {
        if (previousValue.supported) {
          return previousValue;
        }
        const ietfTag = langParts.slice(0, index + 1).join("_");
        return {
          supported: supportedLanguages.includes(ietfTag),
          tag: ietfTag,
        };
      },
      { supported: false, tag: "" }
    );
    return (
      matchingLanguage.supported ? matchingLanguage.tag : "en"
    ).toLowerCase();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "digiCert";
    script.setAttribute("nonce", window.ASOS.myAccount.nonce);
    const scriptText = document.createTextNode(
      `var __dcid = __dcid || [];
      __dcid.push({"cid":"DigiCertClickID_${digiCertSealCode}","tag":"${digiCertSealCode}"});
      (function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());`
    );
    script.appendChild(scriptText);
    document.head.appendChild(script);
  }, [digiCertSealCode]);

  const lang = getSupportedLanguage();
  return (
    <div
      data-testid="digicert-test-id"
      id={`DigiCertClickID_${digiCertSealCode}`}
      lang={lang}
      className={styles.digiCert}
    ></div>
  );
};

export default SiteSeal;
