import ChangePassword from "./components";

export const apiValidations = (response) => {
  const errorCodes =
    response.payload.body && response.payload.body.map((b) => b.errorCode);
  const apiErrors = {
    missingPassword: {
      password: { id: "ma_web_changepassword_validation_required" },
    },
    invalidPassword: {
      password: { id: "ma_web_changepassword_validation_minimumlength" },
    },
    invalidPasswordCombination: {
      password: { id: "ma_web_change_password_same_password_error" },
    },
    invalidLengthPassword: {
      password: { id: "ma_web_changepassword_validation_maximumlength" },
    },
    passwordMustChange: {
      password: { id: "ma_web_changepassword_validation_passwordusedbefore" },
    },
    passwordMismatch: {
      existingPassword: { id: "ma_web_changepassword_validation_nomatch" },
    },
  };
  if (errorCodes) {
    return errorCodes.reduce(
      (result, code) => Object.assign(result, apiErrors[code]),
      {}
    );
  }
  return {};
};

export default ChangePassword;
