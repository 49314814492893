import React from "react";

import PropTypes from "prop-types";

export const splitTextWrappedByTagsRegex =
  /((?=<)(?!<\/)<)(.*?)((?= \/>)|(?=>))>([^<]+)([^>]+>)|([^<]+)/g;
export const containsTagsRegex = /<[a-z][\s\S]*>/i;
const readAttributesRegex = /(?=\w+=)(?:([^=]+)=["']([^"']*)["'])/g;

const removeStringTags = (text, open, close) =>
  `${text.replace(new RegExp(open), "").replace(new RegExp(close), "")}`.trim();

export const readAttributes = (text) => {
  const matches = text.match(readAttributesRegex) || [];
  return matches.reduce((props, attr) => {
    const key = attr.substring(0, attr.indexOf("="));
    return {
      ...props,
      [key]: attr.substring(key.length + 2, attr.length - 1),
    };
  }, {});
};

const addJsxTags = (
  { open, close, jsx, mapAttributesToProps = () => ({}) },
  index,
  text
) =>
  jsx(
    removeStringTags(text, open, close),
    index,
    mapAttributesToProps(readAttributes(text))
  );

const tagDefined = (tags, text) =>
  Object.keys(tags).filter((tag) =>
    text.trim().replace(/^</, "").startsWith(tag)
  );

const parseTag = (tags, includesTagArr, text) =>
  includesTagArr.length > 0
    ? includesTagArr.map((tag, index) => addJsxTags(tags[tag], index, text))
    : text;

const parseTaggedText = ({ splitText, tags }) =>
  splitText.map((fragment) =>
    fragment.includes("<")
      ? parseTag(tags, tagDefined(tags, fragment), fragment)
      : fragment
  );

const parseSplitText = ({ splitText, tags }) =>
  parseTaggedText({ splitText, tags }).reduce((prev, curr) => [prev, curr]);

const StringToJsxParser = ({ text, tags = {} }) => (
  <span>
    {containsTagsRegex.test(text)
      ? parseSplitText({
          splitText: text.match(splitTextWrappedByTagsRegex),
          tags,
        })
      : text}
  </span>
);

StringToJsxParser.propTypes = {
  text: PropTypes.string.isRequired,
  tags: PropTypes.object,
};

StringToJsxParser.displayName = "StringToJsxParser";

export default StringToJsxParser;
