import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import ErrorSection from "@components/error-section";
import { GridRow, GridColumn } from "@components/grid";
import ContentWrapper from "@components/layout/content-wrapper";
import Navigation from "@components/navigation";

import WelcomeMat from "../welcome-mat";

import styles from "./index.css";

const AppContentSection = ({
  appLoaded,
  content,
  contentFlex,
  mainId,
  mainStyle,
  notification,
  showChrome,
  showErrorSection,
  showSmallNavigationOnly,
}) => (
  <div
    className={classnames({
      [styles.hidden]: !appLoaded,
      [styles.showSmallNavigationOnly]: showSmallNavigationOnly,
    })}
  >
    <ContentWrapper>
      <GridRow flex={contentFlex}>
        {showChrome && (
          <GridColumn
            hideSmallOnly={!showSmallNavigationOnly}
            smallSize={12}
            largeSize={4}
          >
            <WelcomeMat notification={notification} />
            <Navigation notification={notification} />
          </GridColumn>
        )}
        <GridColumn
          hideSmallOnly={showSmallNavigationOnly}
          smallSize={12}
          largeSize={showChrome ? 8 : 12}
          flex={contentFlex}
        >
          <main
            className={classnames(mainStyle, styles.main)}
            id={mainId}
            role="main"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            {showErrorSection ? <ErrorSection /> : content}
          </main>
        </GridColumn>
      </GridRow>
    </ContentWrapper>
  </div>
);

AppContentSection.propTypes = {
  appLoaded: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  contentFlex: PropTypes.bool.isRequired,
  mainId: PropTypes.string.isRequired,
  mainStyle: PropTypes.string.isRequired,
  notification: PropTypes.shape(),
  showChrome: PropTypes.bool.isRequired,
  showErrorSection: PropTypes.bool.isRequired,
  showSmallNavigationOnly: PropTypes.bool.isRequired,
};

AppContentSection.defaultProps = {
  notification: null,
};

export default AppContentSection;
