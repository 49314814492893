import request from "asos.myaccount.request";

const LINKED_ACCOUNTS_ENDPOINT =
  "customer/identity/v1/identities/{customerId}/linked-accounts";
const UNLINK_SOCIAL_ACCOUNT_ENDPOINT =
  "customer/identity/v1/identities/{customerId}/linked-accounts/{provider}";

export const getLinkedAccounts = (identity, requestFn = request) =>
  requestFn(LINKED_ACCOUNTS_ENDPOINT, identity);

export const disconnectLinkedAccount = (
  identity,
  provider,
  requestFn = request
) =>
  requestFn(
    UNLINK_SOCIAL_ACCOUNT_ENDPOINT.replace("{provider}", provider),
    identity,
    {
      method: "DELETE",
    }
  );
