const getPayment = (state) => state.payment;

export const getCustomerPayments = (state) =>
  getPayment(state).customerPayments;

export const getPaymentCards = (state) =>
  getCustomerPayments(state).filter(({ type }) => type === "card");

export const getPaymentProcessors = (state) =>
  getCustomerPayments(state).filter(({ type }) => type === "processor");

export const getAllPaymentMethods = (state) =>
  getPayment(state).allPaymentMethods;

export const getPaymentOptions = (state) => getPayment(state).paymentOptions;

const isACard = (id) => id === "card";
const isInCustomersWallet = (wallet, id) =>
  wallet.find((processor) => processor.id === id);
const isRestrictedInCountry = (option) =>
  option.restrictions &&
  option.restrictions.find(
    (restriction) => restriction.code === "billingCountry"
  );

export const getPaymentProcessorOptions = (state) => {
  const paymentProcessors = getPaymentProcessors(state);
  const paymentOptions = getPaymentOptions(state);

  return paymentOptions.filter(
    (option) =>
      !isACard(option.id) &&
      !isInCustomersWallet(paymentProcessors, option.id) &&
      !isRestrictedInCountry(option)
  );
};

export const getDefaultCustomerPayment = (state) =>
  getCustomerPayments(state).find(({ isDefault }) => isDefault);

export const getCustomerPaymentById = (state, id) =>
  getCustomerPayments(state).find((cp) => cp.id === id);
