import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import classnames from "classnames";

import { ProgressIndicator } from "@asosteam/asos-web-component-library-progress-indicator";

import styles from "./index.css";

export interface LoadingContainerProps {
  children: React.ReactNode;
  small?: boolean;
  fullScreen?: boolean;
}

export const LoadingContainer = ({
  children,
  small = false,
  fullScreen = false,
}: LoadingContainerProps) => (
  <div
    data-testid="loading-container"
    className={classnames(styles.loadingContainer, {
      [styles.small]: small,
      [styles.fullScreen]: fullScreen,
    })}
  >
    {children}
  </div>
);

const Loading = () => (
  <TransitionGroup>
    <CSSTransition
      classNames={{
        exit: styles.leave,
        exitActive: styles.leaveActive,
      }}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={styles.loadingScreen}
        data-testid="loading-screen"
        data-auto-id="LoadingScreen"
      >
        <ProgressIndicator />
      </div>
    </CSSTransition>
  </TransitionGroup>
);

export default Loading;
