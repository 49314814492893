import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import classnames from "classnames";

import { Heading } from "@asosteam/asos-web-component-library-heading";
import {
  LONDON_2,
  LEAVESDEN_2,
} from "@asosteam/asos-web-component-library-typography";

import ListBlockSection from "@components/list-block-section";

import { camelCase } from "@utils/string";

import styles from "./index.css";

type AccordionItemProps = {
  collapsed: boolean;
  content: React.ReactNode;
  isSlim?: boolean;
  isSemantic?: boolean;
  isList?: boolean;
  position: number;
  title: string;
  toggle: (position: number) => void;
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  content,
  collapsed,
  isList = false,
  isSlim = false,
  isSemantic = true,
  position,
  title,
  toggle,
}: AccordionItemProps) => {
  const WrappingComponent = isList ? (isSemantic ? "li" : "div") : "div";

  const ariaId = camelCase(title);

  return (
    <WrappingComponent
      className={classnames({
        [styles.item]: true,
        [styles.opened]: !collapsed,
        [styles.slim]: isSlim,
      })}
    >
      <Heading domElement="h3" className={styles.verticalSpacing}>
        <button
          data-auto-id="accordionToggle"
          className={styles.toggle}
          onClick={() => toggle(position)}
          aria-controls={ariaId}
          aria-expanded={!collapsed}
        >
          <ListBlockSection isSlim={isSlim}>
            <span className={collapsed ? LEAVESDEN_2 : LONDON_2}>{title}</span>
            <span className={styles.closeIcon} aria-hidden="true" />
          </ListBlockSection>
        </button>
      </Heading>

      <TransitionGroup>
        {!collapsed && (
          <CSSTransition
            classNames={{
              enter: styles.contentEnter,
              enterActive: styles.contentEnterActive,
              exit: styles.contentLeave,
              exitActive: styles.contentLeaveActive,
            }}
            timeout={{ enter: 500, exit: 500 }}
          >
            <div
              aria-hidden={collapsed}
              id={ariaId}
              className={classnames({
                [styles.content]: true,
                [styles.collapsed]: collapsed,
              })}
            >
              {content}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </WrappingComponent>
  );
};

export default AccordionItem;
