import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";
import PropTypes from "prop-types";

import backHomeIcon from "@components/page-header/img/icon-back-home.svg";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const PremierHeader = ({
  dataAutoId,
  hasBgImage,
  headingImage,
  previousPageLink,
  title,
}) => {
  const formatTranslation = useFormatTranslation();

  return (
    <div
      className={classnames(styles.withHeadingImg, {
        [styles.detached]: true,
        [styles.bgImage]: hasBgImage,
      })}
      data-auto-id={dataAutoId}
      data-testid={dataAutoId}
    >
      {headingImage && (
        <img
          src={headingImage.src}
          alt={headingImage.alt}
          role="heading"
          aria-label="Asos Premier"
          aria-level="1"
          className={styles.imageHeader}
          data-auto-id="page-title"
        />
      )}
      <Link
        to={previousPageLink}
        className={classnames(styles.showOnSmall, styles.mobileBackLink)}
        data-auto-id="mobile-back-link"
      >
        <img
          src={backHomeIcon}
          alt={formatTranslation("ma_web_navigation_backtomyaccount")}
          className={styles.icon}
        />
      </Link>
      <span className={classnames(styles.mobileHeading, styles.showOnSmall)}>
        {formatTranslation(title)}
      </span>
    </div>
  );
};

PremierHeader.propTypes = {
  dataAutoId: null,
};

PremierHeader.propTypes = {
  dataAutoId: PropTypes.string,
  hasBgImage: PropTypes.bool.isRequired,
  headingImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  previousPageLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PremierHeader;
