import Cookies from "js-cookie";
import { format as formatUrl, parse as parseUrl } from "url";

import { replaceTokens } from "@utils/string";

import { getApiRoot } from "../../document-handlers";

const getFullPath = (
  path: string,
  query: Record<string, string>,
  bustCache: boolean
) => {
  const cacheBustQuery = bustCache ? { "cache-bust": Date.now() } : null;

  const urlParts = parseUrl(path, true);
  return formatUrl({
    ...urlParts,
    query: {
      ...urlParts.query,
      ...query,
      ...cacheBustQuery,
    },
    search: null,
  });
};
const getSiteOriginQuery = () => {
  const origin = Cookies.get("x-api-origin") || Cookies.get("x-site-origin");

  if (origin) {
    return {
      "x-site-origin": origin,
    };
  }
  return null;
};
const API_ROOT = getApiRoot();

export const buildUri = (
  path: string,
  query: Record<string, unknown> = {},
  customerId: string | null,
  customerGuid: string | null,
  isThirdParty: boolean,
  bustCache: boolean
) => {
  const populatedPath = replaceTokens(path, { customerId, customerGuid });

  const endpoint = isThirdParty
    ? populatedPath
    : `${API_ROOT}/${populatedPath}`;

  const enrichedQuery: Record<string, string> = {
    ...getSiteOriginQuery(),
    ...query,
  };

  const uri = getFullPath(endpoint, enrichedQuery, bustCache);

  return uri;
};
