import React from "react";

import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";

import StoreDetails from "../store-details";

export const getProviderDetails = (providers, store) =>
  providers.find(({ id }) => id === store.providerDetails[0].providerId);

export interface StoresListProps {
  countryCode: string;
  providers: {
    id: number;
  }[];
  selectDropOffStore: (arg0: any, arg1: any) => void;
  storesList: {
    name: string;
    providerDetails: {
      providerId: number;
    }[];
    address: {
      address1: string;
      postalCode: string;
    };
    distanceInMeters: number;
    distanceInMiles: number;
  }[];
}

const StoresList = ({
  countryCode,
  providers,
  selectDropOffStore,
  storesList,
}: StoresListProps) => (
  <div data-auto-id="store-list">
    {storesList.map((store, index) => {
      const hasHorizontalrule = index < storesList.length - 1;
      return (
        <div
          data-auto-id="store-list-entry"
          key={`${store.name}-${index.toString()}`}
        >
          <StoreDetails
            provider={getProviderDetails(providers, store)}
            countryCode={countryCode}
            selectDropOffStore={selectDropOffStore}
            store={store}
          />
          {hasHorizontalrule && <HorizontalRule />}
        </div>
      );
    })}
  </div>
);

export default StoresList;
