import { returnItems } from "@configuration/return-items";

import {
  containsOnlyLatinCharacters,
  containsXssRiskCharacters,
} from "../../../../../validation-rules";

export const validateReason = (reasonId) => {
  if (!reasonId) {
    return { id: "ma_web_returns_reasonrequirederror" };
  }
  return undefined;
};

export const validateNotes = (notes) => {
  if (
    notes &&
    (!containsOnlyLatinCharacters(notes) || containsXssRiskCharacters(notes))
  ) {
    return { id: "ma_web_returns_notecontainsnonlatin" };
  }
  if (notes && notes.length > returnItems.fields.notes.maxLength) {
    return { id: "ma_web_returns_notelengtherror" };
  }
  return undefined;
};

const validate = (values) => {
  const notes = validateNotes(values.notes);
  const reasonId = validateReason(values.reasonId);

  if (notes || reasonId) {
    return {
      notes,
      reasonId,
    };
  }

  return undefined;
};

export default validate;
