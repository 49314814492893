import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./index.css";

const NavGroup = ({ children }) => (
  // eslint-disable-next-line jsx-a11y/no-redundant-roles
  <ul
    className={classnames({
      [styles.navGroup]: true,
    })}
    role="list"
  >
    {children}
  </ul>
);

NavGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavGroup;
