import {
  isNotPresent,
  containsNonAsciiCharacters,
} from "../../../../validation-rules";

const isInApiErrorCodes = (errorCodes) => (code) => errorCodes.includes(code);

const validateCode = (value, hasApiError) => {
  if (isNotPresent(value)) {
    return { id: "ma_web_addgiftvoucher_missingcode" };
  }
  if (containsNonAsciiCharacters(value)) {
    return { id: "ma_web_addgiftvoucher_asciicharacters" };
  }
  if (value.length !== 16) {
    return { id: "ma_web_addgiftvoucher_vouchercodeinvalid" };
  }
  if (hasApiError("PossibleGiftCard")) {
    return {
      id: "ma_web_giftcardsandvouchers_voucher_possible_gift_card",
      link: {
        id: "ma_web_giftcardsandvouchers_voucher_add_gift_card_cta",
        url: "/gift-cards-and-vouchers/add/card?ctaRef=errorgiftcard",
      },
    };
  }
  if (
    hasApiError("VoucherCodeInvalid") ||
    hasApiError("VoucherCodeChecksumMismatch") ||
    hasApiError("VoucherNotFound")
  ) {
    return { id: "ma_web_addgiftvoucher_vouchercodeinvalid" };
  }
  if (hasApiError("VoucherAlreadyAssociated")) {
    return { id: "ma_web_addgiftvoucher_voucheralreadyassociated" };
  }
  if (hasApiError("VoucherExpired")) {
    return { id: "ma_web_addgiftvoucher_voucherexpired" };
  }
  if (hasApiError("VoucherCancelled")) {
    return { id: "ma_web_addgiftvoucher_vouchercancelled" };
  }
  return undefined;
};

const validate = ({ code, errorCodes = [] }) => {
  const hasApiError = isInApiErrorCodes(errorCodes);
  return {
    code: validateCode(code, hasApiError),
  };
};

export default validate;
