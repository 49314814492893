import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import {
  getPaymentMethodsAndOptions,
  unloadPaymentMethods,
} from "@state/payment/actions";

import DeletePaymentMethodModal from "./delete-payment-method-modal";

import styles from "./index.css";

const pageNameKey = "ma_web_paymentmethods_title";

const bundleLoader = () =>
  import(/* webpackChunkName: 'payment-method-list' */ "./payment-method-list");

const Wallet = () => {
  const dispatch = useDispatch();

  const { bundle: PaymentMethodList, fatalError: bundleError } =
    useBundle(bundleLoader);

  const {
    walletLoaded: loaded,
    fatalError,
    customerPayments,
    requireDeleteAcceptance,
  } = useSelector(
    (state: {
      payment: {
        walletLoaded: boolean;
        methodsLoaded: boolean;
        deletingPaymentMethod: boolean;
        customerPayments: {
          id: string;
        }[];
        requireDeleteAcceptance: boolean;
        loaded: boolean;
        fatalError: boolean;
      };
    }) => {
      return {
        walletLoaded:
          state.payment.walletLoaded &&
          state.payment.methodsLoaded &&
          !state.payment.deletingPaymentMethod,
        fatalError: state.payment.fatalError,
        customerPayments: state.payment.customerPayments,
        requireDeleteAcceptance: state.payment.requireDeleteAcceptance,
      };
    }
  );

  const currentCustomerPayments = useRef(customerPayments);

  useEffect(() => {
    dispatch(getPaymentMethodsAndOptions());

    return () => {
      dispatch(unloadPaymentMethods());
    };
  }, []);
  useEffect(() => {
    if (
      customerPayments.length &&
      currentCustomerPayments.current.length &&
      customerPayments[0].id !== currentCustomerPayments.current[0].id
    ) {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }

    currentCustomerPayments.current = customerPayments;
  }, [customerPayments]);

  const addNewMethodPath = "/payment-methods/add";

  if (loaded && !fatalError && !customerPayments.length) {
    return <Navigate to={addNewMethodPath} />;
  }

  const pageReady = PaymentMethodList && loaded;

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <div className={styles.headerWrapper}>
          <PageHeader
            buttonTo={addNewMethodPath}
            buttonText="ma_web_paymentmethods_addnew"
            dataAutoId="PaymentMethods"
            icon="payments"
            title={pageNameKey}
          />
        </div>
      }
      pageId="payment-methods"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError || bundleError}
      showScrollToTopButton
    >
      {pageReady && <PaymentMethodList />}
      {pageReady && requireDeleteAcceptance && <DeletePaymentMethodModal />}
    </Page>
  );
};

export default Wallet;
