import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools as composeEnhancers } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

import analyticsMiddleware from "./analytics/middleware";
import { START_USER_LEAVING, FINISH_USER_LEAVING } from "./application/types";
import createCallbackMiddleware from "./callback-middleware";
import reducers from "./reducer";
import {
  routerMiddleware,
  createReduxHistory,
} from "./route-history-middleware";
import createScrollMiddleware from "./scroll-middleware";

let reduxHistory;
export const getHistory = () => {
  return reduxHistory;
};

export default (
  state,
  identitySdk,
  createCallbackMiddlewareFn = createCallbackMiddleware,
  analyticsMiddlewareFn = analyticsMiddleware,
  createScrollMiddlewareFn = createScrollMiddleware
) => {
  const middleware = [
    createCallbackMiddlewareFn(START_USER_LEAVING, FINISH_USER_LEAVING),
    thunk.withExtraArgument(identitySdk),
    routerMiddleware,
    createScrollMiddlewareFn(window.scrollTo),
  ];

  // disable analytics middleware in test
  if (process.env.NODE_ENV !== "test") {
    middleware.push(analyticsMiddlewareFn());
  }

  if (process.env.NODE_ENV === "development" && process.env.REDUX_LOGGER) {
    // eslint-disable-next-line global-require
    const { createLogger } = require("redux-logger");
    middleware.push(createLogger({ collapsed: true }));
  }

  const enhancers = composeEnhancers(applyMiddleware(...middleware));
  const store = createStore(reducers(), state, enhancers);

  reduxHistory = createReduxHistory(store);

  if (module.hot) {
    module.hot.accept("./reducer", () => {
      const nextReducers = require("./reducer").default; // eslint-disable-line global-require

      store.replaceReducer(nextReducers);
    });
  }

  return store;
};
