import React from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import Accordion from "@components/accordion";
import Distance from "@components/distance";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../components/button";
import StoreOpeningHours from "../store-opening-hours";
import noPrint from "./icons/noPrint.svg";
import print from "./icons/print.svg";

import styles from "./index.css";

const icons = {
  print,
  noPrint,
};

export interface StoreDetailsProps {
  countryCode: string;
  provider: {
    logoUri: string;
    name: string;
    printerlessReturn: boolean;
  };
  selectDropOffStore: (arg0: any, arg1: any) => void;
  store: {
    address: {
      address1: string;
      postalCode: string;
    };
    distanceInMeters: number;
    distanceInMiles: number;
    name: string;
    tradingPeriods?: any[];
  };
}

const StoreDetails = ({
  countryCode,
  provider,
  selectDropOffStore,
  store,
}: StoreDetailsProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <CardSection hasLeftPadding={false} hasRightPadding={false}>
      <div className={styles.storeWrapper}>
        <img
          src={provider.logoUri}
          alt={provider.name}
          className={styles.providerIcon}
        />
        <div className={styles.storeDetailsWrapper}>
          <div className={styles.storeDetails}>
            <div className={styles.storeNameWrapper}>
              <span className={styles.storeName}>{store.name}</span>{" "}
              <Distance
                countryCode={countryCode}
                meters={store.distanceInMeters}
                miles={store.distanceInMiles}
              />
            </div>
            <div className={styles.storeAddress}>
              {store.address.address1}, {store.address.postalCode}
            </div>
          </div>
          <div className={styles.ctaWrapper}>
            <Button
              data-auto-id="selectDropOffStoreLocation"
              onClick={() => selectDropOffStore(store, provider)}
              size="default"
            >
              {formatTranslation(
                "ma_web_orderreturn_dropoff_storedetails_selectcta"
              )}
            </Button>
          </div>
          <div className={styles.printerIcons}>
            <div className={styles.icon}>
              <img
                src={provider.printerlessReturn ? icons.noPrint : icons.print}
                alt={formatTranslation(
                  provider.printerlessReturn
                    ? "ma_web_orderreturn_returnmethod_dropoff_printer_not_required"
                    : "ma_web_orderreturn_returnmethod_dropoff_printer_required"
                )}
                className={styles.socialIcon}
              />
            </div>
            <div className={styles.name}>
              {formatTranslation(
                provider.printerlessReturn
                  ? "ma_web_orderreturn_returnmethod_dropoff_printer_not_required"
                  : "ma_web_orderreturn_returnmethod_dropoff_printer_required"
              )}
            </div>
          </div>
          {store.tradingPeriods && store.tradingPeriods.length && (
            <Accordion
              items={[
                {
                  title: formatTranslation(
                    "ma_web_orderreturn_dropoff_storedetails_openinghours"
                  ),
                  content: (
                    <StoreOpeningHours tradingPeriods={store.tradingPeriods} />
                  ),
                },
              ]}
              isSlim
            />
          )}
        </div>
      </div>
    </CardSection>
  );
};

export default StoreDetails;
