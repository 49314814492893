import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../error";
import AddGift from "./add-gift";
import AddGiftCardRouteHandler from "./add-gift-card-route-handler";
import AddGiftVoucher from "./add-gift-voucher";
import VoucherList from "./voucher-list";

const GiftCardsAndVouchers = () => {
  return (
    <Routes>
      <Route index element={<VoucherList />} />
      <Route exact path="/add" element={<AddGift />} />
      <Route exact path="/add/card" element={<AddGiftCardRouteHandler />} />
      <Route exact path="/add/voucher" element={<AddGiftVoucher />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default GiftCardsAndVouchers;
