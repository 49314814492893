import React from "react";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";

import LoadMoreButton from "@components/load-more-button";
import LoadingIndicator from "@components/loading-indicator";

import styles from "./index.css";

export interface LoadMoreCardProps {
  failureMessageKey: string;
  hasErroredLoading: boolean;
  isLastPage: boolean;
  itemsCount: number;
  lastPageLabel?: string;
  loading: boolean;
  loadMore: () => void;
  remainingPageLabel?: string;
  totalItems: number;
}

const LoadMoreCard = ({
  failureMessageKey,
  hasErroredLoading,
  isLastPage,
  itemsCount,
  lastPageLabel,
  loading,
  loadMore,
  remainingPageLabel,
  totalItems,
}: LoadMoreCardProps) => (
  <Card>
    <CardSection
      hasLeftPadding={false}
      hasRightPadding={false}
      hasBottomPadding={!hasErroredLoading}
    >
      <div className={styles.center}>
        {lastPageLabel && remainingPageLabel && (
          <LoadingIndicator
            isLastPage={isLastPage}
            itemsCount={itemsCount}
            lastPageLabel={lastPageLabel}
            remainingPageLabel={remainingPageLabel}
            total={totalItems}
          />
        )}
        {totalItems !== itemsCount && (
          <div className={styles.button}>
            <LoadMoreButton
              apiError={hasErroredLoading}
              failureMessageKey={failureMessageKey}
              loading={loading}
              loadMore={loadMore}
            />
          </div>
        )}
      </div>
    </CardSection>
  </Card>
);

export default LoadMoreCard;
