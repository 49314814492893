import { getInstance } from "@asos-web-features/browser";

import { getNotBouncedVisit } from "@state/analytics/not-bounced-visit";

import { noticeError } from "@utils/monitoring";

export const activationCallback = (data, forceTracking) => {
  window.analyticsEventEmitter.emit("experiments.tracking", {
    experimentKey: data.experiment.key,
    experimentVariation: data.variation.key,
    forceTracking: forceTracking,
  });
};

const createFeaturesInstance = (
  options,
  coreAttributes,
  getInstanceFn = getInstance
) => {
  const { sdkKey, template } = options;

  const config = {
    sdkKey: sdkKey,
    datafileOptions: {
      urlTemplate: template,
    },
    logger: {
      log: () => {},
    },
    errorHandler: {
      handleError: (error) => {
        noticeError(error);
      },
    },
  };

  const asosFeaturesInstance = getInstanceFn(
    config,
    coreAttributes,
    activationCallback
  );

  // The setInterval below has been introduced because of a race condition where eVar225 wasn't available on time
  // in the render cycle to generate the notBouncedVisit
  let tries = 0;

  const interval = setInterval(() => {
    if (tries >= 10) {
      clearInterval(interval);
    }

    if (window?.s?.eVar225) {
      const prevCoreAttrs = asosFeaturesInstance.getCoreAttributes();
      asosFeaturesInstance.setCoreAttributes({
        ...prevCoreAttrs,
        ...getNotBouncedVisit(),
      });

      if (getNotBouncedVisit().notBouncedVisit) {
        asosFeaturesInstance.track("Not Bounced Visit");
      }

      clearInterval(interval);
    } else {
      tries++;
    }
  }, 1000);
  return asosFeaturesInstance;
};

export default createFeaturesInstance;
