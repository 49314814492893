const DATE_TIME_WITHOUT_TIMEZONE = /(.*T[0-9:.]+)$/;

/*
  Required due to bug TFS:342704
  Customer Profile API doesn't include timezone for subscription expiry.
  As we're doing calculations using this value against the user's local time,
  we need to ensure UTC defined for the returned value to keep calculations accurate.
*/
export const applyUtc = (date) =>
  date.replace(DATE_TIME_WITHOUT_TIMEZONE, "$1Z");

export const toDateOnlyIgnoringUTC = (isoDate) =>
  isoDate.replace(/-/g, "/").replace(/T.+/, "");
