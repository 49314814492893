import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import premier from "@asosteam/asos-web-component-library-icons/lib/four-point-premier.svg";
import colouredPremier from "@asosteam/asos-web-component-library-icons/lib/premier-colour.png";

import account from "./img/account-overview.svg";
import addresses from "./img/address-book.svg";
import applePay from "./img/apple-pay.svg";
import arrowUp from "./img/arrow-up.svg";
import asos from "./img/asos.svg";
import calendar from "./img/calendar.svg";
import card from "./img/card.svg";
import chevronRight from "./img/chevron-right.svg";
import communication from "./img/communication-preferences.svg";
import gifts from "./img/gifts.svg";
import googlePay from "./img/google-pay.svg";
import hanger from "./img/hanger.svg";
import info from "./img/info.svg";
import delivery from "./img/lorry-fast.svg";
import details from "./img/my-details.svg";
import orders from "./img/my-orders.svg";
import link from "./img/new-tab.svg";
import padlock from "./img/padlock.svg";
import payments from "./img/payments.svg";
import returns from "./img/returns.svg";
import signout from "./img/sign-out.svg";
import social from "./img/social-connect.svg";
import star from "./img/star.svg";
import tag from "./img/tag.svg";
import voucher from "./img/voucher.svg";

import styles from "./index.css";

const icons = {
  account,
  addresses,
  arrowUp,
  asos,
  calendar,
  chevronRight,
  communication,
  delivery,
  details,
  gifts,
  hanger,
  info,
  link,
  orders,
  padlock,
  payments,
  premier,
  colouredPremier,
  returns,
  signout,
  social,
  star,
  tag,
  card,
  voucher,
  applePay,
  googlePay,
};

const Icon = ({ alt, id, size }) => (
  <img
    src={icons[id]}
    alt={alt}
    className={classnames({
      [styles.icon]: true,
      [styles.xlarge]: size === "xlarge",
      [styles.large]: size === "large",
      [styles.small]: size === "small",
      [styles.tiny]: size === "tiny",
    })}
  />
);

Icon.propTypes = {
  alt: PropTypes.string,
  id: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.oneOf(["tiny", "small", "large", "xlarge", ""]),
};

Icon.defaultProps = {
  alt: "",
  size: "",
};

export default Icon;
