export const decodeJWT = (token) => {
  if (!token) {
    throw new Error("JWT was null or empty.");
  }

  try {
    const base64RawPayload = token.split(".")[1];
    const base64 = base64RawPayload.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new Error("JWT was malformed.");
  }
};
