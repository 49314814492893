import request from "asos.myaccount.request";

import { DELIVERY_ADDRESS } from "./constants";

const ADDRESS_ENDPOINT = "customer/profile/v2/customers/{customerId}/addresses";
const CHANGE_DEFAULT_ADDRESS_ENDPOINT =
  "customer/profile/v2/customers/{customerId}/addresses/swap";
const editAddressEndpoint = (addressId) =>
  `customer/profile/v2/customers/{customerId}/addresses/${addressId}`;
const FIND_ADDRESS_ENDPOINT =
  "https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws";
const RETRIEVE_ADDRESS_ENDPOINT =
  "https://api.addressy.com/Capture/Interactive/Retrieve/v1.10/json3.ws";

const FIND_ADDRESS_KEY = window.ASOS.myAccount.addressFinderKey;

export const getAddresses = (identity, requestFn = request) =>
  requestFn(ADDRESS_ENDPOINT, identity);

export const changeDefaultAddress = (
  oldDefaultId,
  newDefaultId,
  addressType,
  identity,
  requestFn = request
) =>
  requestFn(CHANGE_DEFAULT_ADDRESS_ENDPOINT, identity, {
    body: JSON.stringify({
      oldDefaultId,
      newDefaultId,
      changeDefaultDelivery: addressType === DELIVERY_ADDRESS,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

export const addAddress = (newAddress, identity, requestFn = request) =>
  requestFn(ADDRESS_ENDPOINT, identity, {
    body: JSON.stringify({
      address1: newAddress.address1,
      address2: newAddress.address2,
      countryCode: newAddress.countryCode,
      countyStateProvinceOrArea: newAddress.countyStateProvinceOrArea,
      countyStateProvinceOrAreaCode: newAddress.countyStateProvinceOrAreaCode,
      firstName: newAddress.firstName,
      lastName: newAddress.lastName,
      locality: newAddress.locality,
      postalCode: newAddress.postalCode,
      telephoneMobile: newAddress.telephoneMobile,
      defaultBilling: newAddress.defaultBilling,
      defaultDelivery: newAddress.defaultDelivery,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

export const findAddress = (
  query,
  container = { id: "" },
  countryCode,
  lang,
  requestFn = request,
  key = FIND_ADDRESS_KEY
) =>
  requestFn(FIND_ADDRESS_ENDPOINT, null, {
    query: {
      key,
      text: query,
      container: container.id,
      language: lang,
      countries: countryCode,
    },
  });

export const retrieveAddress = (
  id,
  requestFn = request,
  key = FIND_ADDRESS_KEY
) =>
  requestFn(RETRIEVE_ADDRESS_ENDPOINT, null, {
    query: {
      key,
      id,
    },
  });

export const deleteAddress = (addressId, identity, requestFn = request) =>
  requestFn(`${ADDRESS_ENDPOINT}/${addressId}`, identity, {
    method: "DELETE",
  });

export const editAddress = (updatedAddress, identity, requestFn = request) =>
  requestFn(editAddressEndpoint(updatedAddress.addressId), identity, {
    body: JSON.stringify({
      address1: updatedAddress.address1,
      address2: updatedAddress.address2,
      countryCode: updatedAddress.countryCode,
      countyStateProvinceOrArea: updatedAddress.countyStateProvinceOrArea,
      countyStateProvinceOrAreaCode:
        updatedAddress.countyStateProvinceOrAreaCode,
      firstName: updatedAddress.firstName,
      lastName: updatedAddress.lastName,
      locality: updatedAddress.locality,
      postalCode: updatedAddress.postalCode,
      telephoneMobile: updatedAddress.telephoneMobile,
      defaultBilling: updatedAddress.defaultBilling,
      defaultDelivery: updatedAddress.defaultDelivery,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });
