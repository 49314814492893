export const clearTokenFromStorage = (
  { identityConfig } = window.ASOS.myAccount
) => {
  if (window.localStorage) {
    try {
      window.localStorage.removeItem(identityConfig.identityConfigPersistKey);
    } catch (e) {
      /*NOTHING TO SEE HERE*/
    }
  }
};
