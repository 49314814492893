import React from "react";

import classnames from "classnames";

import styles from "./index.css";

export type GridColumnProps = {
  children: React.ReactNode;
  className?: string;
  largeSize?: number;
  mediumSize?: number;
  smallSize: number;
  flex?: boolean;
  hideSmallOnly?: boolean;
};

export const GridColumn: React.FC<GridColumnProps> = ({
  children,
  className = undefined,
  smallSize,
  mediumSize = undefined,
  largeSize = undefined,
  flex = false,
  hideSmallOnly = false,
}) => (
  <div
    className={classnames(
      styles.col,
      {
        [styles[`small-${smallSize}`]]: smallSize,
        [styles[`medium-${mediumSize}`]]: mediumSize,
        [styles[`large-${largeSize}`]]: largeSize,
        [styles.flex]: flex,
        [styles.hideSmallOnly]: hideSmallOnly,
      },
      className
    )}
  >
    {children}
  </div>
);

export type GridRowProps = {
  children: React.ReactNode;
  flex?: boolean;
};

export const GridRow: React.FC<GridRowProps> = ({ children, flex = false }) => (
  <div
    className={classnames(styles.container, {
      [styles.flex]: flex,
    })}
  >
    {children}
  </div>
);
