import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";

import { clearApiErrors } from "@state/application/actions";
import { submitGiftCard } from "@state/gift-cards-and-vouchers/actions";
import {
  getAddGiftCardFormLoaded,
  hasApiErrors,
} from "@state/gift-cards-and-vouchers/selectors";

import { scrollErrorIntoView } from "@utils/scroll-into-view";

import TranslatedFormComponent from "../../form";
import validate from "../validate";

const validateValues = (values) => validate(values);

export const onSubmit = async (
  values,
  dispatch,
  props,
  submitGiftCardFn = submitGiftCard,
  validateFn = validate
) => {
  const response = await dispatch(submitGiftCardFn(values));
  if (response.error && response.payload.apiValidationError) {
    const validationResults = validateFn(
      {
        ...values,
        errorCodes:
          response.payload.body &&
          response.payload.body.map((d) => d.errorCode),
      },
      props
    );

    throw new SubmissionError(validationResults);
  }
  if (response.error) throw new SubmissionError();
};

export const onSubmitFail = (
  validationResults,
  dispatch,
  submitError,
  props,
  scrollErrorIntoViewFn = scrollErrorIntoView
) => {
  const errors = validationResults || submitError.errors;
  if (!errors) return;

  scrollErrorIntoViewFn();
};

const mapStateToProps = (state) => ({
  hasApiError: hasApiErrors(state),
  loaded: getAddGiftCardFormLoaded(state),
});

const mapDispatchToProps = {
  clearApiErrors,
  submitGiftCard,
};

const fields = [
  {
    maxLength: 16,
    name: "Code",
    type: "tel",
  },
  {
    maxLength: 4,
    name: "Pin",
    type: "tel",
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    fields,
    form: "addGiftCard",
    onChange: validateValues,
    onSubmit,
    onSubmitFail,
    type: "AddGiftCard",
    validate: validateValues,
  })(TranslatedFormComponent)
);
