import { connect } from "react-redux";
import { change } from "redux-form";

import InputFieldWithClear from "./component";

const mapDispatchToProps = {
  change,
};

export default connect(undefined, mapDispatchToProps)(InputFieldWithClear);
