import { emitAnalyticsEvent } from "@client/analytics";

import { assignRouteSpecificAttrs } from "@state/analytics/middleware";

export async function emitter(trackingEvent, pathname: string) {
  if (trackingEvent && trackingEvent.payload) {
    trackingEvent.payload = {
      ...assignRouteSpecificAttrs(pathname),
      ...trackingEvent.payload,
    };
    emitAnalyticsEvent(trackingEvent);
  }
}
