import React from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "@components/button";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { validateUrl } from "@utils/url";

import styles from "./index.css";

const CUSTOMERCARE_URL = "https://www.asos.com/customer-care";

const PremierActivationError = () => {
  const formatTranslation = useFormatTranslation();
  const [searchParams] = useSearchParams();
  const urlParam = searchParams.get("returnUrl");
  const continueUrl = validateUrl(urlParam)
    ? urlParam
    : `${window.ASOS.myAccount.entryPoint}premier-delivery`;

  return (
    <>
      <div className={styles.premierActivationErrorWrapper}>
        <div className={styles.sadFaceIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 150 150"
          >
            <title>sad face</title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M120 120v20H30v-20 10H20v-10H10V30h10V20h10v10-20h90v20-10h10v10h10v90h-10v10h-10v-10zM0 50v50h10V50H0zm50 90v10h50v-10H50zM50 0v10h50V0H50zm90 50v50h10V50h-10z"
                fill="#F9D86E"
              />
              <path
                fill="#000"
                d="M50 70h10v10H50zm-10 0h10v10H40zm50 40h10v10H90zm-20-10h10v10H70zm10 0h10v10H80zm-20 0h10v10H60zm-10 10h10v10H50zM30 80h10v10H30zm80 0h10v10h-10zM90 70h10v10H90zm10 0h10v10h-10z"
              />
            </g>
          </svg>
        </div>
        <h1 className={styles.premierActivationErrorTitle}>
          {formatTranslation(
            "joinasospremier_errormessage_premiernotprovisioned"
          )}
        </h1>
        <p className={styles.premierActivationErrorText}>
          {formatTranslation(
            "joinasospremier_errormessage_premiernotprovisioned_subtext"
          )}
        </p>
        <div className={styles.premierActivationErrorCtas}>
          <Button
            buttonType="primary"
            data-auto-id="search-again-btn"
            onClick={() => window.open(CUSTOMERCARE_URL, "_blank")}
          >
            {formatTranslation(
              "joinasospremier_errormessage_premiernotprovisioned_getintouchctabutton"
            )}
          </Button>
          <Button
            buttonType="secondary"
            className={styles.continueCheckoutButton}
            data-auto-id="search-again-btn"
            onClick={() =>
              (window.location = continueUrl as unknown as Location)
            }
          >
            {formatTranslation(
              "joinasospremier_errormessage_premiernotprovisioned_continueshoppingctabutton"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PremierActivationError;
