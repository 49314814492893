import { connect } from "react-redux";

import { getCustomerPayments } from "@state/payment/selectors";

import AddBillingAddress from "./component";

const mapStateToProps = (state) => ({
  hasSavedPaymentMethods: getCustomerPayments(state).length > 0,
});

export default connect(mapStateToProps)(AddBillingAddress);
