import { createAction } from "redux-actions";

import {
  getCountry,
  getLanguage,
  getECommerceStore,
  getCurrency,
} from "../application/selectors";
import { getSegmentsAsCommaString } from "../audience-segments/selectors";
import {
  hasPremierSubscription,
  getLatestUserSubscription,
} from "../premier/selectors";
import { getSubscriptionOptions as getSubscriptionOptionsApi } from "./api";
import { hasLoaded } from "./selectors";
import {
  RECEIVE_SUBSCRIPTION_OPTIONS,
  REQUEST_SUBSCRIPTION_OPTIONS,
} from "./types";

export const receiveSubscriptionOptions = createAction(
  RECEIVE_SUBSCRIPTION_OPTIONS
);
export const requestSubscriptionOptions = createAction(
  REQUEST_SUBSCRIPTION_OPTIONS
);

export const getSubscriptionOptions =
  (getSubscriptionOptionsApiFn = getSubscriptionOptionsApi) =>
  async (dispatch, getState) => {
    const state = getState();

    if (hasLoaded(state)) {
      return null;
    }

    const currency = getCurrency(state);
    const subscriptionCountry = hasPremierSubscription(state)
      ? getLatestUserSubscription(state).country
      : getCountry(state);
    dispatch(requestSubscriptionOptions());

    try {
      const currentSubscriptionOptions = await getSubscriptionOptionsApiFn(
        getLanguage(state),
        getECommerceStore(state),
        subscriptionCountry,
        currency,
        getSegmentsAsCommaString(state)
      );

      return dispatch(receiveSubscriptionOptions(currentSubscriptionOptions));
    } catch (err) {
      return dispatch(receiveSubscriptionOptions(err));
    }
  };
