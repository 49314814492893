export const hasSegments = (state) =>
  state?.audienceSegments.loaded &&
  !state?.audienceSegments.fatalError &&
  state?.audienceSegments.segments?.length;

export const getSegments = (state) =>
  hasSegments(state) ? state.audienceSegments.segments : [];

export const getSegmentsAsCommaString = (state) =>
  getSegments(state)
    .map((segment) => segment.segmentId)
    .toString();
