import React from "react";
import { Route, Routes } from "react-router-dom";

import MyReturnCode from "./return-code";
import ReturnDetails from "./return-details";
import MyReturns from "./returns-history";

const Returns = () => (
  <Routes>
    <Route index element={<MyReturns />} />
    <Route path="return-code" element={<MyReturnCode />} />
    <Route path=":returnReference" element={<ReturnDetails />} />
  </Routes>
);

export default Returns;
