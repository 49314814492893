export const REQUEST_ASSOCIATE_GIFT_CARD = "REQUEST_ASSOCIATE_GIFT_CARD";
export const RECEIVE_ASSOCIATE_GIFT_CARD = "RECEIVE_ASSOCIATE_GIFT_CARD";

export const REQUEST_ASSOCIATE_GIFT_VOUCHER = "REQUEST_ASSOCIATE_GIFT_VOUCHER";
export const RECEIVE_ASSOCIATE_GIFT_VOUCHER = "RECEIVE_ASSOCIATE_GIFT_VOUCHER";

export const GIFT_CARD_API_ERROR = "GIFT_CARD_API_ERROR";
export const GIFT_VOUCHER_API_ERROR = "GIFT_VOUCHER_API_ERROR";

export const REQUEST_GIFT_VOUCHERS = "REQUEST_GIFT_VOUCHERS";
export const RECEIVE_GIFT_VOUCHERS = "RECEIVE_GIFT_VOUCHERS";

export const RESET_SAVE_NEW_GIFT_CARD_AND_VOUCHER =
  "RESET_SAVE_NEW_GIFT_CARD_AND_VOUCHER";

export const TRACK_OPEN_ADD_GIFT_CARD = "TRACK_OPEN_ADD_GIFT_CARD";
export const TRACK_OPEN_ADD_GIFT_VOUCHER = "TRACK_OPEN_ADD_GIFT_VOUCHER";

export const TRACK_SUCCESSFUL_ADD_GIFT_CARD = "TRACK_SUCCESSFUL_ADD_GIFT_CARD";
export const TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER =
  "TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER";

export const TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER =
  "TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER";
export const TRACK_BUY_GIFT_VOUCHER = "TRACK_BUY_GIFT_VOUCHER";

export const TRACK_ADD_GIFT_CARD_ATTEMPT = "TRACK_ADD_GIFT_CARD_ATTEMPT";
export const TRACK_ADD_GIFT_VOUCHER_ATTEMPT = "TRACK_ADD_GIFT_VOUCHER_ATTEMPT";
