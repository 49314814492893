import { getReturnedOrdersByReference } from "../returns-history/selectors";

const getReturnDetailsState = (state) => state.returnDetails;

export const getAllReturnDetails = (state) =>
  getReturnDetailsState(state).details;

export const getReturnDetailsIndexFromReturnReference =
  (state) => (returnReference) =>
    getAllReturnDetails(state).findIndex(
      (detail) => detail.returnReference === returnReference
    );

export const getReturnDetailsFromReturnReference =
  (state) => (returnReference) => {
    const fromReturnHistory =
      getReturnedOrdersByReference(state)(returnReference);
    if (fromReturnHistory) {
      return fromReturnHistory;
    }
    return getAllReturnDetails(state).find(
      (detail) => detail.returnReference === returnReference
    );
  };

export const getDetailsFatalError = (state) =>
  getReturnDetailsState(state).detailsFatalError;
