import Polyglot from "node-polyglot";

import { useLocaleContext } from "@context/LocaleContext";

import { useFormatTranslation } from "./useFormatTranslation";

export const useFormatUrl = () => {
  const { siteRoot } = useLocaleContext();
  const formatTranslation = useFormatTranslation();

  return (path: string, options: Polyglot.InterpolationOptions): string =>
    formatTranslation(path, { ...options, siteUrl: siteRoot });
};
