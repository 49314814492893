export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const camelCase = (string = "") => {
  const words = string.split(/(?=[$A-Z0-9])|(?:[\s-_/:-?{-~!"^`[\]]+)/g);
  return words.reduce((result, word, index) => {
    const lowerWord = word.toLowerCase();
    return result + (index ? capitalizeFirstLetter(lowerWord) : lowerWord);
  }, "");
};

export const replaceTokens = (
  text: string,
  replacements: Record<string, string> = {}
): string =>
  Object.keys(replacements).reduce<string>((accumulator, currentKey) => {
    const nextString = accumulator.replace(
      new RegExp(`{${currentKey}}`, "g"),
      replacements[currentKey]
    );
    return nextString;
  }, text as string);
