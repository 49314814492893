import { handleActions } from "redux-actions";

import { CLEAR_API_ERRORS } from "../application/types";
import { isPremierSuccessUrl } from "./selectors";
import * as types from "./types";

const updatePremierSubscriptions = (state, { error, payload }) => {
  if (error) {
    if (payload.statusCode === 404 && isPremierSuccessUrl()) {
      return {
        ...state,
        loaded: true,
        fatalError: false,
        premierSignUpPending: true,
      };
    }
    if (payload.statusCode === 404) {
      return {
        ...state,
        loaded: true,
        fatalError: false,
      };
    }
    return {
      ...state,
      loaded: true,
      fatalError: true,
    };
  }

  const subscriptions = payload;
  return {
    ...state,
    apiError: false,
    fatalError: false,
    loaded: true,
    subscriptions,
    tries: 0,
  };
};

const reducer = handleActions(
  {
    [types.API_ERROR]: (state) => {
      const { maxRetries, tries } = state;
      if (tries >= maxRetries) {
        return {
          ...state,
          apiError: false,
          fatalError: true,
          loaded: false,
          tries: 0,
          premierSignUpPending: false,
        };
      }
      return {
        ...state,
        fatalError: false,
        loaded: true,
        tries: tries + 1,
        apiError: true,
        premierSignUpPending: false,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      apiError: false,
      tries: 0,
    }),
    [types.REQUEST_PREMIER_SUBSCRIPTIONS]: (state) => ({
      ...state,
      fatalError: false,
      loaded: false,
    }),
    [types.RECEIVE_PREMIER_SUBSCRIPTIONS]: updatePremierSubscriptions,
    [types.PREMIER_WAITING_ON_API]: (state) => ({
      ...state,
      premierSignUpPending: true,
    }),
    [types.PREMIER_REDIRECTING]: (state) => ({
      ...state,
      redirecting: true,
    }),
    [types.PREMIER_FINISHED_REDIRECTING]: (state) => ({
      ...state,
      redirecting: false,
    }),
  },
  {
    apiError: false,
    fatalError: false,
    maxRetries: 2,
    tries: 0,
    redirecting: false,
    loaded: false,
    subscriptions: [],
    premierSignUpPending: false,
  }
);

export default reducer;
