export const DELIVERY_TYPES = {
  AFS_NON_PRIME: "afs_non_prime",
  AFS_PRIME: "afs_prime",
  DTC: "dtc",
  ASOS_CORE: "asos_core",
};

export const determineDeliveryType = (seller, source) => {
  if (seller && source && seller?.id === source?.id) {
    return DELIVERY_TYPES.DTC;
  }

  if (!source && seller) {
    return DELIVERY_TYPES.AFS_PRIME;
  }

  if (seller && source && seller?.id !== source?.id) {
    return DELIVERY_TYPES.AFS_NON_PRIME;
  }

  return DELIVERY_TYPES.ASOS_CORE;
};
