import React from "react";

import classnames from "classnames";

import ResponsiveImage from "@components/responsive-image";

import styles from "./index.css";

const imageSizes = `
  ${styles.sBreakpoint} ${styles.smallImageMaxSize},
  ${styles.defaultImageMaxSize}
`;

type ProductImageProps = {
  alt?: string;
  isVoucher?: boolean;
  quantity?: number;
  url: string;
};

const ProductImage: React.FC<ProductImageProps> = ({
  alt = undefined,
  isVoucher = false,
  quantity = 1,
  url,
}) => (
  <div
    className={classnames({
      [styles.imageWrapper]: true,
      [styles.voucherWrapper]: isVoucher,
    })}
  >
    <ResponsiveImage
      alt={alt}
      url={url}
      dynamicDimensions={{
        imageSizes: imageSizes,
        srcSetSizes: [100, 200, 300, 400, 500, 600, 700, 800],
        defaultSrcSetIndex: 2,
      }}
    />
    {quantity > 1 && (
      <span aria-hidden className={styles.quantity}>
        x{quantity}
      </span>
    )}
  </div>
);

export default ProductImage;
