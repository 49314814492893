import React from "react";

import PropTypes from "prop-types";

import InputFieldWithActionButton from "@components/input-field-with-action-button";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

const buttonAction = (change, formName, inputName, action) => () => {
  if (action) {
    action();
  }

  change(formName, inputName, "");
};

const InputFieldWithClear = ({
  change,
  clearAction,
  input,
  meta,
  ...props
}) => {
  const formatTranslation = useFormatTranslation();
  return (
    <InputFieldWithActionButton
      {...props}
      action={buttonAction(change, meta.form, input.name, clearAction)}
      buttonText={
        input.value === ""
          ? null
          : formatTranslation("ma_web_addressbook_clear")
      }
      input={input}
      meta={meta}
    />
  );
};

InputFieldWithClear.propTypes = {
  change: PropTypes.func.isRequired,
  clearAction: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string.isRequired,
  }).isRequired,
};

InputFieldWithClear.defaultProps = {
  clearAction: undefined,
};

export default InputFieldWithClear;
