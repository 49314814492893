import { createAction } from "redux-actions";
import { push } from "redux-first-history";

import { getOrderDetails, receiveOrderDetails } from "@state/orders/actions";
import {
  getOrderDetailsFromOrderReference,
  getReturnRmaRequestDateTime,
} from "@state/orders/selectors";
import {
  getReturnMultiParcelUserReviewedBannerStatus,
  getReturnItems,
  getReturnLocation,
  getReturnOrderReference,
  getReturnReferenceId,
  reasonsHaveLoaded,
  getReturnableItems as getReturnableItemsSelector,
} from "@state/returns/selectors";

import { scrollErrorIntoView } from "@utils/scroll-into-view";
import { totalQuantity } from "@utils/totalQuantity";

import {
  getCountry,
  getECommerceStore,
  getLanguage,
  getMapsKey,
} from "../application/selectors";
import {
  bookDropOffReturn,
  getDropOffPoints,
  getReturnableItems as getReturnableItemsApi,
  getReturnReasons as getReturnReasonsApi,
  getReturnReference as getReturnReferenceApi,
  googlePlacesSearch,
  getReturnChargeMessages as getReturnChargeMessagesApi,
} from "./api";
import * as types from "./types";
import { getOrderDates, getOrderReferences } from "./utils";

const GOOGLE_STATUSES = {
  INVALID_REQUEST: "INVALID_REQUEST",
  OK: "OK",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
  REQUEST_DENIED: "REQUEST_DENIED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  ZERO_RESULTS: "ZERO_RESULTS",
};

export const receiveReturnReasons = createAction(types.RECEIVE_RETURN_REASONS);
export const requestReturnReasons = createAction(types.REQUEST_RETURN_REASONS);

export const receiveReturnChargeMessages = createAction(
  types.RECEIVE_RETURN_CHARGE_MESSAGES
);

export const requestDropOffLocations = createAction(
  types.REQUEST_DROP_OFF_LOCATIONS
);
export const receiveDropOffLocations = createAction(
  types.RECEIVE_DROP_OFF_LOCATIONS
);

export const requestGooglePlaces = createAction(types.REQUEST_GOOGLE_PLACES);
export const receiveGooglePlaces = createAction(types.RECEIVE_GOOGLE_PLACES);

export const clearGooglePlacesAndDropOffErrors = createAction(
  types.CLEAR_GOOGLE_PLACES_DROP_OFF_ERRORS
);
export const clearGooglePlacesAndDropOffResults = createAction(
  types.CLEAR_GOOGLE_PLACES_DROP_OFF_RESULTS
);

export const searchForAddress = createAction(types.SEARCH_FOR_ADDRESS);
export const selectPlace = createAction(types.SELECT_PLACE);
export const selectLocation = createAction(types.SELECT_LOCATION);
export const resetReturnLocation = createAction(types.RESET_RETURN_LOCATION);

export const navigateToOrders =
  (pushFn = push) =>
  (dispatch) => {
    dispatch(pushFn("/orders"));
  };

export const navigateToReturn =
  (orderReference, pushFn = push) =>
  (dispatch, getState) => {
    const orderRef = orderReference || getReturnOrderReference(getState());
    dispatch(pushFn(`/orders/${orderRef}/return#method`));
  };

export const navigateToDropOff =
  (pushFn = push) =>
  (dispatch, getState) => {
    const orderReference = getReturnOrderReference(getState());
    dispatch(pushFn(`/orders/${orderReference}/return/drop-off`));
  };

export const navigateToConfirmation =
  (pushFn = push) =>
  (dispatch, getState) => {
    const orderReference = getReturnOrderReference(getState());
    const returnReference = getReturnReferenceId(getState());
    dispatch(
      pushFn(`/orders/${orderReference}/return/${returnReference}/confirmation`)
    );
  };

export const searchGooglePlaces =
  (searchAddress, countryCode, postalCode, getPlaces = googlePlacesSearch) =>
  async (dispatch, getState) => {
    try {
      dispatch(requestGooglePlaces());
      const mapsKey = getMapsKey(getState());
      const response = await getPlaces(
        searchAddress,
        countryCode,
        postalCode,
        mapsKey
      );
      const { status, results = [] } = response;

      if (
        status !== GOOGLE_STATUSES.OK &&
        status !== GOOGLE_STATUSES.ZERO_RESULTS
      ) {
        const error = new Error(`${status} - ${response.error_message}`);
        dispatch(receiveGooglePlaces(error));
        throw error;
      }

      const places = results.filter(
        ({ types: locationType }) => !locationType.includes("country")
      );
      dispatch(receiveGooglePlaces(places));
      return places;
    } catch (err) {
      dispatch(receiveGooglePlaces(err));
      throw err;
    }
  };

export const getDropOffLocations =
  (latitude, longitude, countryCode, searchDropOffPoints = getDropOffPoints) =>
  async (dispatch, getState) => {
    try {
      dispatch(requestDropOffLocations());

      const state = getState();
      const dropOffPoints = await searchDropOffPoints(
        latitude,
        longitude,
        countryCode,
        getECommerceStore(state),
        getLanguage(state)
      );

      dispatch(receiveDropOffLocations(dropOffPoints));
      return dropOffPoints;
    } catch (err) {
      dispatch(receiveDropOffLocations(err));
      throw err;
    }
  };

export const searchGoogleForDropOffLocations =
  (
    searchAddress,
    countryCode,
    postalCode,
    searchPlaces = searchGooglePlaces,
    getDropOffLocationsFn = getDropOffLocations,
    navigateToDropOffFn = navigateToDropOff,
    clearGooglePlacesAndDropOffResultsFn = clearGooglePlacesAndDropOffResults
  ) =>
  async (dispatch, getState) => {
    dispatch(clearGooglePlacesAndDropOffResultsFn());
    dispatch(
      searchForAddress({ formattedAddress: searchAddress, countryCode })
    );

    const places = await searchPlaces(
      searchAddress,
      countryCode,
      postalCode
    )(dispatch, getState);

    if (places.length === 1) {
      const { geometry } = places[0];

      const locations = await getDropOffLocationsFn(
        geometry.location.lat,
        geometry.location.lng,
        countryCode
      )(dispatch, getState);

      if (locations.dropoffPoints.length) {
        navigateToDropOffFn()(dispatch, getState);
      }

      return locations.dropoffPoints;
    } else if (places.length > 1) {
      navigateToDropOffFn()(dispatch, getState);
    }

    return [];
  };

export const selectPlaceForDropOff =
  (
    place,
    countryCode,
    getDropOffLocationsFn = getDropOffLocations,
    selectPlaceFn = selectPlace
  ) =>
  async (dispatch, getState) => {
    dispatch(selectPlaceFn(place));
    const { geometry } = place;

    const locations = await getDropOffLocationsFn(
      geometry.location.lat,
      geometry.location.lng,
      countryCode
    )(dispatch, getState);

    return locations.dropoffPoints;
  };
export const getReturnReasons =
  (getReturnReasonsApiFn = getReturnReasonsApi) =>
  async (dispatch, getState) => {
    const state = getState();
    if (reasonsHaveLoaded(state)) {
      return null;
    }

    try {
      const returnReasons = await getReturnReasonsApiFn(getLanguage(state));

      return dispatch(receiveReturnReasons(returnReasons));
    } catch (err) {
      return dispatch(receiveReturnReasons(err));
    }
  };

export const orderDateCache = {};

export const getReturnCountryCodes = async (
  returningItems,
  state,
  identity,
  dispatch,
  getCustomerOrderDetailsFn
) => {
  const countryCodes = [];
  const currentStore = getECommerceStore(state);
  const lang = getLanguage(state);
  for (const value of Object.values(returningItems)) {
    const orderDetails = getOrderDetailsFromOrderReference(state)(
      value.orderReference
    );

    if (orderDetails) {
      countryCodes.push(orderDetails.country);
    } else {
      try {
        const response = await getCustomerOrderDetailsFn(
          value.orderReference,
          currentStore,
          lang,
          identity
        );
        countryCodes.push(response.country);
        dispatch(receiveOrderDetails(response));
      } catch (err) {
        err.orderReference = value.orderReference;
        return dispatch(receiveOrderDetails(err));
      }
    }
  }

  return [...new Set(countryCodes)];
};

const setReturnChargeMessageAsNull = (dispatch) => {
  return dispatch(
    receiveReturnChargeMessages({
      policyDescription: null,
      feeMessage: null,
    })
  );
};

export const getReturnableItemsDate = (returnableItems) => {
  const orderDates = returnableItems?.itemsFromSelectedOrder?.map(
    (item) => item.orderDate.split("T")[0]
  );

  return [...new Set(orderDates)].join(",");
};

export const getReturnableItemsOrderReference = (returnableItems) => {
  const orderReferences = returnableItems?.itemsFromSelectedOrder?.map(
    (item) => item.orderReference
  );

  return [...new Set(orderReferences)].join(",");
};

export const getReturnChargeMessages =
  (getReturnChargeMessagesApiFn = getReturnChargeMessagesApi) =>
  async (dispatch, getState, identity) => {
    const state = getState();
    let itemsToReturn = [];

    const returns = getState().returns;

    if (!returns) {
      return setReturnChargeMessageAsNull(dispatch);
    }
    const returningItems = returns.returningItems;
    const returnableItems = returns.returnableItems;

    if (Object.keys(returningItems).length) {
      itemsToReturn = Object.values(returningItems).filter(
        (item) => item.isReturning === true
      );
    }

    const itemsToReturnSelected = itemsToReturn.length;

    const orderDateString = itemsToReturnSelected
      ? getOrderDates(returningItems)
      : getReturnableItemsDate(returnableItems);

    if (!orderDateString) {
      return setReturnChargeMessageAsNull(dispatch);
    }

    //Currently cache is only based on Order Dates
    if (orderDateCache[orderDateString]) {
      return dispatch(
        receiveReturnChargeMessages(orderDateCache[orderDateString])
      );
    }

    const orderReferenceString = itemsToReturnSelected
      ? getOrderReferences(returningItems)
      : getReturnableItemsOrderReference(returnableItems);

    if (!orderReferenceString) {
      return setReturnChargeMessageAsNull(dispatch);
    }

    try {
      const response = await getReturnChargeMessagesApiFn(
        orderReferenceString,
        getReturnRmaRequestDateTime(state),
        identity
      );
      orderDateCache[orderDateString] = response;

      return dispatch(receiveReturnChargeMessages(response));
    } catch (err) {
      return dispatch(receiveReturnChargeMessages(err));
    }
  };

export const receiveReturnableItems = createAction(
  types.RECEIVE_RETURNABLE_ITEMS
);
export const requestReturnableItems = createAction(
  types.REQUEST_RETURNABLE_ITEMS
);

export const getReturnableItems =
  (orderReference, getReturnableItemsApiFn = getReturnableItemsApi) =>
  async (dispatch, getState, identity) => {
    dispatch(requestReturnableItems());

    const state = getState();
    const browseCountry = getCountry(state);

    try {
      const response = await getReturnableItemsApiFn(
        orderReference,
        browseCountry,
        identity
      );
      return dispatch(receiveReturnableItems(response));
    } catch (err) {
      err.orderReference = orderReference;
      return dispatch(receiveReturnableItems(err));
    }
  };

export const selectDropOffStore =
  (
    location,
    provider,
    selectLocationFn = selectLocation,
    navigateToReturnFn = navigateToReturn
  ) =>
  (dispatch) => {
    dispatch(selectLocationFn({ location, provider }));
    dispatch(navigateToReturnFn());
  };

export const clearReturns = createAction(types.CLEAR_RETURNS);
export const openReturnForm = createAction(types.OPEN_RETURN_FORM);
export const closeReturnForm = createAction(types.CLOSE_RETURN_FORM);

export const updateReturningItem = createAction(types.UPDATE_RETURNING_ITEM);

export const receiveReturnReference = createAction(
  types.RECEIVE_RETURN_REFERENCE
);
export const requestReturnReference = createAction(
  types.REQUEST_RETURN_REFERENCE
);
export const setNoItemsError = createAction(types.SET_NO_ITEMS_ERROR);

export const getReturnReference =
  (
    orderReference,
    getReturnReferenceApiFn = getReturnReferenceApi,
    getReturnRmaRequestDateTimeFn = getReturnRmaRequestDateTime
  ) =>
  async (dispatch, getState, identity) => {
    const state = getState();
    if (orderReference === getReturnOrderReference(state)) {
      return null;
    }
    dispatch(requestReturnReference());
    const rmaRequestDateTime = getReturnRmaRequestDateTimeFn(state);

    try {
      const response = await getReturnReferenceApiFn(
        identity.customer.customerId,
        getLanguage(state),
        rmaRequestDateTime,
        identity
      );

      return dispatch(
        receiveReturnReference({
          orderReference,
          returnReference: response.returnReference,
        })
      );
    } catch (err) {
      return dispatch(receiveReturnReference(err));
    }
  };

export const startCreateReturn = createAction(types.START_CREATE_RETURN);
export const createReturnFailure = createAction(types.CREATE_RETURN_FAILURE);
export const clearReturnAttempts = createAction(types.CLEAR_RETURN_ATTEMPTS);

export const clearCreateReturnAttempts = () => (dispatch) =>
  dispatch(clearReturnAttempts());

export const resetReturnsState =
  (
    clearReturnsFn = clearReturns,
    clearGooglePlacesAndDropOffErrorsFn = clearGooglePlacesAndDropOffErrors,
    clearGooglePlacesAndDropOffResultsFn = clearGooglePlacesAndDropOffResults,
    resetReturnLocationFn = resetReturnLocation
  ) =>
  (dispatch) => {
    dispatch(clearReturnsFn());
    dispatch(clearGooglePlacesAndDropOffErrorsFn());
    dispatch(clearGooglePlacesAndDropOffResultsFn());
    dispatch(resetReturnLocationFn());
  };

export const createReturn =
  (
    orderReference,
    getReturnReasonsFn = getReturnReasons,
    getOrderDetailsFn = getOrderDetails,
    getReturnableItemsFn = getReturnableItems,
    getReturnReferenceFn = getReturnReference,
    pushFn = push,
    scrollErrorIntoViewFn = scrollErrorIntoView,
    startCreateReturnFn = startCreateReturn,
    resetReturnsStateFn = resetReturnsState
  ) =>
  async (dispatch, getState, identity) => {
    const showOrderDetailsLoadingSpinner = false;
    dispatch(resetReturnsStateFn());
    dispatch(startCreateReturnFn(orderReference));

    await Promise.all([
      getReturnReasonsFn()(dispatch, getState),
      getReturnableItemsFn(orderReference)(dispatch, getState, identity),
      getOrderDetailsFn(orderReference, showOrderDetailsLoadingSpinner)(
        dispatch,
        getState,
        identity
      ),
      getReturnReferenceFn(orderReference)(dispatch, getState, identity),
    ]).then((results) => {
      const errored = results.find((result) => result && result.error);
      if (errored) {
        dispatch(createReturnFailure(orderReference));
        scrollErrorIntoViewFn();
      } else {
        dispatch(clearReturnAttempts());
        dispatch(pushFn(`/orders/${orderReference}/return`));
      }
    });
  };

export const attemptReturnBooking = createAction(types.ATTEMPT_RETURN_BOOKING);
export const requestReturnBooking = createAction(types.REQUEST_RETURN_BOOKING);
export const receiveReturnBooking = createAction(types.RECEIVE_RETURN_BOOKING);

export const placeReturn =
  (bookDropOffReturnFn = bookDropOffReturn) =>
  async (dispatch, getState, identity) => {
    dispatch(requestReturnBooking());

    try {
      const state = getState();
      const language = getLanguage(state);
      const returnLocation = getReturnLocation(state);
      const returnPoint = returnLocation.store.providerDetails.find(
        ({ providerId }) => providerId === returnLocation.provider.id
      );
      const { itemsFromSelectedOrder, itemsFromOtherOrders } =
        getReturnableItemsSelector(state);
      const returnReference = getReturnReferenceId(state);
      const returningItems = getReturnItems(state);
      const items = Object.keys(returningItems)
        .map((key) => {
          const props = returningItems[key];
          if (props.isReturning) {
            return {
              variantId: props.variantId,
              quantity: props.quantity,
              orderReference: props.orderReference,
              returnReason: {
                code: props.reasonId,
                notes: props.notes,
              },
            };
          }
          return undefined;
        })
        .filter((item) => !!item);

      await bookDropOffReturnFn(
        returnReference,
        items,
        language,
        returnPoint.dropOffPointId,
        returnPoint.providerId,
        identity
      );

      const returnableItems =
        itemsFromSelectedOrder.concat(itemsFromOtherOrders);

      const itemQuantity = items.map((item) => {
        const { quantity } = item;

        return quantity;
      });

      const totalReturningQuantity = totalQuantity(itemQuantity);

      const hydratedReturnItems = items.map((returnItem) => {
        const returnableItem = returnableItems.find(
          (item) =>
            item.variantId === returnItem.variantId &&
            item.orderReference === returnItem.orderReference
        );
        const item = {
          ...returnItem,
          name: returnableItem.name,
          imageUrl: `https://${returnableItem.imageUrl}`,
        };

        return item;
      });

      const confirmation = {
        items: hydratedReturnItems,
        returnLocation,
        itemsTotal: totalReturningQuantity,
      };

      dispatch(receiveReturnBooking(confirmation));
      dispatch(navigateToConfirmation());
    } catch (error) {
      dispatch(receiveReturnBooking(error));
    }
  };

export const setReviewedReturn = createAction(types.SET_REVIEWED_RETURN);
export const setMultiParcelDialogError = createAction(
  types.SET_REVIEWED_RETURN_ERROR
);
export const resetMultiParcelDialogError = createAction(
  types.RESET_REVIEWED_RETURN_ERROR
);

export const toggleReviewReturn = () => (dispatch, getState) => {
  const state = getState();
  const hasBeenReviewed = getReturnMultiParcelUserReviewedBannerStatus(state);
  dispatch(setReviewedReturn(!hasBeenReviewed));
};
