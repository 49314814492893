import React from "react";

import classnames from "classnames";

import Icon from "@components/picture-icon";
import { Translation } from "@components/translation";

import styles from "./index.css";

interface ScrollToTopProps {
  onClick: () => void;
  show: boolean;
}

const ScrollToTop = ({ onClick, show }: ScrollToTopProps) => (
  <button
    className={classnames({
      [styles.scrollTop]: true,
      [styles.show]: show,
    })}
    onClick={onClick}
    role="link"
  >
    <span className={styles.icon}>
      <Icon id="arrowUp" size="small" />
    </span>
    <span className={styles.text}>
      <Translation id="ma_web_generic_backtotop" />
    </span>
  </button>
);

export default ScrollToTop;
