// This is required to let Webpack know where to find assets dynamically rather
// than embedding it in the Webpack config. This is because it will need to be
// changed by NuGet as the application moves through environments. It needs to be
// run before everything else.
__webpack_public_path__ = ASOS.myAccount.assetsLocation; // eslint-disable-line
window.__forceSmoothScrollPolyfill__ = true; // eslint-disable-line

require("raf/polyfill");
require("smoothscroll-polyfill").polyfill();
require("@asosteam/asos-web-component-library-icons/lib/apple.svg");
require("@asosteam/asos-web-component-library-icons/lib/google.svg");
require("@asosteam/asos-web-component-library-icons/lib/facebook.svg");
require("@asosteam/asos-web-component-library-icons/lib/four-point-premier.svg");
require("@asosteam/asos-web-component-library-icons/lib/premier-colour.png");
require("./client");
