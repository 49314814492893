import { connect } from "react-redux";

import { getCountryForCountryCode } from "@state/countries/selectors";

import VoucherDetails from "./component";

const mapStateToProps = (state, { country }) => ({
  countryName: getCountryForCountryCode(state, country),
});

export default connect(mapStateToProps)(VoucherDetails);
