import appConfig from "@appConfig";

const CHECKOUT_QUERY_SOURCE = "wc";
const MY_ACCOUNT_QUERY_SOURCE = "wa";
const internationalGiftCardsConfig = {
  AU: "https://www.asos.com/au/discover/gift-cards/?src=",
  FR: "https://www.asos.com/fr/decouvrez/cartes-cadeaux/?src=",
  US: "https://www.asos.com/us/discover/gift-cards/?src=",
};

const getInternationalGiftCardsLink = (browseCountry, hasCtaRefCo) => {
  if (
    appConfig.internationalGiftCardsEnabled &&
    internationalGiftCardsConfig[browseCountry]
  ) {
    let internationalGiftCardsUrl = internationalGiftCardsConfig[browseCountry];
    if (internationalGiftCardsUrl) {
      if (hasCtaRefCo) {
        internationalGiftCardsUrl += CHECKOUT_QUERY_SOURCE;
      } else {
        internationalGiftCardsUrl += MY_ACCOUNT_QUERY_SOURCE;
      }
    }
    return internationalGiftCardsUrl;
  }
  return null;
};

export default getInternationalGiftCardsLink;
