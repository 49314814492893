import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../error";
import OrderDetails from "./order-details";
import OrderHistory from "./order-history";
import ReturnConfirmation from "./return-confirmation";
import ReturnLocations from "./return-locations";
import ReturnOrder from "./return-order";

export const shouldUpdateOrders = ({ orders: { all } }) => all.length === 0;

const Orders = () => (
  <Routes>
    <Route index element={<OrderHistory />} />
    <Route path=":orderReference" element={<OrderDetails />} />
    <Route path=":orderReference/return" element={<ReturnOrder />} />
    <Route
      path=":orderReference/return/drop-off"
      element={<ReturnLocations />}
    />
    <Route
      path=":orderReference/return/:returnReference/confirmation"
      element={<ReturnConfirmation />}
    />
    <Route path="*" element={<Error />} />
  </Routes>
);

export default Orders;
