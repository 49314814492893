import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CardList } from "@asosteam/asos-web-component-library-cards";

import LoadMoreCard from "@components/load-more-card";
import LoadingList from "@components/loading-list";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getLanguage } from "@state/application/selectors";
import {
  buyGiftVoucher,
  getGiftVouchers,
} from "@state/gift-cards-and-vouchers/actions";
import {
  getVoucherCount,
  getVouchers,
  hasErroredLoadingMore as hasErroredLoading,
  isLoadingMoreVouchers,
} from "@state/gift-cards-and-vouchers/selectors";

import GiftHelpAccordion from "../../gift-help-accordion";
import VoucherDetails from "./voucher-details";
import VoucherRestrictionModal from "./voucher-restriction-modal";
import VoucherReturnsModal from "./voucher-returns-modal";

const VoucherListContent = () => {
  const [showRestrictionModal, setShowRestrictionModal] = useState(false);
  const [showReturnsModal, setShowReturnsModal] = useState(false);

  const formatTranslation = useFormatTranslation();

  const {
    hasErroredLoadingMore,
    isLoadingMore,
    language,
    vouchers,
    vouchersCount,
  } = useSelector((state) => {
    return {
      hasErroredLoadingMore: hasErroredLoading(state),
      isLoadingMore: isLoadingMoreVouchers(state),
      language: getLanguage(state),
      vouchers: getVouchers(state),
      vouchersCount: getVoucherCount(state),
    };
  });

  const dispatch = useDispatch();

  const buyGiftVoucherCall = () => dispatch(buyGiftVoucher());
  const getGiftVouchersCall = (loadMore: boolean) =>
    dispatch(getGiftVouchers(loadMore));

  function toggleRestrictionModal(toggle) {
    setShowRestrictionModal(toggle);
  }

  function toggleReturnsModal(toggle) {
    setShowReturnsModal(toggle);
  }

  return (
    <CardList isSemantic={false}>
      {vouchers.length > 0 && <i />}
      <LoadingList component={CardList}>
        {vouchers &&
          vouchers.map((voucher) => (
            <VoucherDetails
              key={voucher.voucherCode}
              language={language}
              openRestrictionModal={() => toggleRestrictionModal(true)}
              openReturnsModal={() => toggleReturnsModal(true)}
              {...voucher}
            />
          ))}
      </LoadingList>
      {vouchersCount !== vouchers.length && (
        <LoadMoreCard
          hasErroredLoading={hasErroredLoadingMore}
          failureMessageKey="ma_web_giftcardsandvouchers_pagination_loadingfailure"
          isLastPage={vouchersCount === vouchers.length}
          itemsCount={vouchers.length}
          loading={isLoadingMore}
          loadMore={() => getGiftVouchersCall(true)}
          totalItems={vouchersCount}
        />
      )}
      <GiftHelpAccordion
        title={formatTranslation("ma_web_giftcardsandvouchers_needhelp")}
        topLinks={[
          {
            id: "buy-gift-voucher",
            link: "ExternalLink.GiftCardsAndVouchers.BuyGiftVoucher",
            onClick: buyGiftVoucherCall,
            textKey: "ma_web_giftcardsandvouchers_buygiftvoucher",
          },
          {
            id: "gift-card-vouchers-faq",
            link: "ExternalLink.GiftCardsAndVouchers.GiftCardVouchersFAQ",
            textKey: "ma_web_giftcardsandvouchers_giftcardvouchersfaq",
          },
        ]}
      />
      <VoucherRestrictionModal
        isOpen={showRestrictionModal}
        onAccept={() => toggleRestrictionModal(false)}
      />
      <VoucherReturnsModal
        isOpen={showReturnsModal}
        onAccept={() => toggleReturnsModal(false)}
      />
    </CardList>
  );
};

export default VoucherListContent;
