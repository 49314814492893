import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../error";
import AddBillingAddress from "./add-billing-address";
import AddPaymentCard from "./add-payment-card";
import AddPaymentMethod from "./add-payment-method";
import Wallet from "./wallet";

const PaymentMethods = () => (
  <Routes>
    <Route index element={<Wallet />} />
    <Route exact path="/add/card" element={<AddPaymentCard />} />
    <Route exact path="/add" element={<AddPaymentMethod />} />
    <Route exact path="/missing-address" element={<AddBillingAddress />} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export default PaymentMethods;
