import React from "react";

import styles from "./index.css";

type SectionDividerProps = {
  children: React.ReactNode;
};

const SectionDivider: React.FC<SectionDividerProps> = ({ children }) => (
  <div className={styles.sectionDivider}>
    <span className={styles.sectionDividerText}>{children}</span>
  </div>
);

export default SectionDivider;
