import React from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";

import ButtonGroup from "@components/button-group";
import Modal from "@components/modal";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../components/button";

import styles from "./index.css";

export interface UserModal {
  communicationPreferencesError?: boolean;
  hasPreferencesLoaded?: boolean;
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  onSave?: () => void;
  shouldShowSaveAndExit?: boolean;
}

export interface UserLeavingModalProps extends UserModal {
  title: string;
  question: string;
}

const UserLeavingModal = ({
  communicationPreferencesError,
  hasPreferencesLoaded,
  isOpen,
  onAccept,
  onCancel,
  onSave = undefined,
  question,
  shouldShowSaveAndExit = false,
  title,
}: UserLeavingModalProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Modal
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={onCancel}
      padContent={false}
      title={title}
    >
      <div data-auto-id="LeaveWithoutSaveModal">
        <CardSection hasBottomPadding={false} hasTopPadding={false}>
          <div className={styles.question}>{question}</div>
        </CardSection>
        {communicationPreferencesError && (
          <CardSection
            hasLeftPadding={false}
            hasRightPadding={false}
            hasTopPadding={false}
          >
            <NotificationBar type="error">
              {formatTranslation("ma_web_communicationpreferences_saveerror")}
            </NotificationBar>
          </CardSection>
        )}
        <CardSection hasBottomPadding={false} hasTopPadding={false}>
          <div className={styles.buttonGroupWrapper}>
            <ButtonGroup flexDisplay centerContent>
              {shouldShowSaveAndExit && (
                <Button
                  buttonType="primary"
                  data-auto-id="save-leave-button"
                  onClick={onSave}
                  type="button"
                  isInProgress={!hasPreferencesLoaded}
                  size="large"
                >
                  {hasPreferencesLoaded &&
                    formatTranslation("ma_web_leavewithoutsavemodal_save")}
                </Button>
              )}
              <Button
                buttonType={shouldShowSaveAndExit ? "secondary" : "primary"}
                data-auto-id="leave-no-save-button"
                onClick={onAccept}
                type="button"
                size="large"
              >
                {formatTranslation("ma_web_leavewithoutsavemodal_confirm")}
              </Button>
              <Button
                buttonType="secondary"
                data-auto-id="cancel-leave-button"
                onClick={onCancel}
                type="button"
                size="large"
              >
                {formatTranslation("ma_web_leavewithoutsavemodal_cancel")}
              </Button>
            </ButtonGroup>
          </div>
        </CardSection>
      </div>
    </Modal>
  );
};

export default UserLeavingModal;
