import { handleActions } from "redux-actions";

import { CLEAR_API_ERRORS } from "../application/types";
import * as types from "./types";

const reducer = handleActions(
  {
    [types.API_ERROR]: (state) => {
      const { maxRetries, tries } = state;
      if (tries >= maxRetries) {
        return {
          ...state,
          apiError: false,
          fatalError: true,
          loaded: true,
          tries: 0,
        };
      }
      return {
        ...state,
        apiError: true,
        fatalError: false,
        loaded: true,
        tries: tries + 1,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      apiError: false,
      tries: 0,
    }),
    [types.RECEIVE_CHANGE_PASSWORD]: (state) => ({
      ...state,
      apiError: false,
      loaded: true,
      tries: 0,
    }),
    [types.REQUEST_CHANGE_PASSWORD]: (state) => ({
      ...state,
      fatalError: false,
      loaded: false,
    }),
  },
  {
    apiError: false,
    tries: 0,
    maxRetries: 2,
    fatalError: false,
    loaded: true,
  }
);

export default reducer;
