import { differenceInCalendarDays, parseISO } from "date-fns";

import {
  getLatestUserSubscription,
  isPremierAutoRenewalsEnabled,
} from "@state/premier/selectors";
import { getTrialOffer } from "@state/subscription-options/selectors";

import {
  PREMIER_NON_MEMBER,
  PREMIER_ACTIVE,
  PREMIER_ACTIVE_RECURRING,
  PREMIER_EXPIRED,
  PREMIER_EXPIRED_RECURRING,
  PREMIER_EXPIRING,
  PREMIER_TERM_ENDING_RECURRING,
  PREMIER_INITIALLY_EXPIRED,
  PREMIER_INITIALLY_EXPIRED_RECURRING,
  PREMIER_NON_MEMBER_FREE_TRIAL_ELIGIBLE,
  PREMIER_FREE_TRIAL_EXPIRING,
  PREMIER_FREE_TRIAL_ACTIVE,
} from "./utils";

// eslint-disable-next-line complexity
export const getPremierAnalytics = (state) => {
  if (!isPremierAutoRenewalsEnabled()) {
    return "";
  }

  const latestPremierSubscription = getLatestUserSubscription(state);
  const trailOffer = getTrialOffer(state);

  const now = new Date();
  let daysDifference;

  if (
    latestPremierSubscription?.status === "Active" &&
    latestPremierSubscription.endDate
  ) {
    daysDifference = differenceInCalendarDays(
      parseISO(latestPremierSubscription.endDate),
      now
    );
  }

  if (
    latestPremierSubscription?.status === "Expired" &&
    latestPremierSubscription.endDate
  ) {
    daysDifference = differenceInCalendarDays(
      now,
      parseISO(latestPremierSubscription.endDate)
    );
  }

  // Conditions have been written in order of specificity in order to cover all scenarios
  if (
    latestPremierSubscription === null ||
    latestPremierSubscription.status === "PurchaseProcessing" ||
    latestPremierSubscription.status === "Cancelled"
  ) {
    if (trailOffer) {
      return PREMIER_NON_MEMBER_FREE_TRIAL_ELIGIBLE;
    }

    return PREMIER_NON_MEMBER;
  }

  if (
    latestPremierSubscription.status === "Active" &&
    daysDifference <= 30 &&
    latestPremierSubscription.instructionType === "OneOff"
  ) {
    return PREMIER_EXPIRING;
  }

  if (
    latestPremierSubscription.status == "Active" &&
    daysDifference <= 30 &&
    latestPremierSubscription.instructionType === "Recurring" &&
    !latestPremierSubscription.isTrialling
  ) {
    return PREMIER_TERM_ENDING_RECURRING;
  }

  if (
    latestPremierSubscription.status == "Expired" &&
    daysDifference <= 30 &&
    latestPremierSubscription.instructionType === "OneOff"
  ) {
    return PREMIER_INITIALLY_EXPIRED;
  }

  if (
    latestPremierSubscription.status == "Expired" &&
    daysDifference <= 30 &&
    latestPremierSubscription.instructionType === "Recurring"
  ) {
    return PREMIER_INITIALLY_EXPIRED_RECURRING;
  }

  if (
    latestPremierSubscription.status === "Active" &&
    latestPremierSubscription.instructionType === "OneOff"
  ) {
    return PREMIER_ACTIVE;
  }

  if (
    latestPremierSubscription.status === "Active" &&
    latestPremierSubscription.instructionType === "Recurring"
  ) {
    if (latestPremierSubscription.isTrialling) {
      if (
        latestPremierSubscription?.recurringSubscription
          ?.isMarkedToCancelAtEndOfTerm
      ) {
        return PREMIER_FREE_TRIAL_EXPIRING;
      }
      return PREMIER_FREE_TRIAL_ACTIVE;
    }

    return PREMIER_ACTIVE_RECURRING;
  }

  if (
    latestPremierSubscription.status === "Expired" &&
    latestPremierSubscription.instructionType === "OneOff"
  ) {
    return PREMIER_EXPIRED;
  }

  if (
    latestPremierSubscription.status === "Expired" &&
    latestPremierSubscription.instructionType === "Recurring"
  ) {
    return PREMIER_EXPIRED_RECURRING;
  }
};
