import React from "react";

import Icon from "@components/picture-icon";
import { SmartLink } from "@components/smart-link";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface ReturnsPolicyProps {
  advisoryMessage?: string;
  policyUrl?: string;
}

const ReturnsPolicy = ({ advisoryMessage, policyUrl }: ReturnsPolicyProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <>
      {advisoryMessage && <div className={styles.label}>{advisoryMessage}</div>}
      <div className={styles.infoField} aria-live="assertive">
        <Icon id="info" size="tiny" />
        <div>
          <span>{`${formatTranslation("ma_return_policy_final_sale")} `}</span>
          {policyUrl && (
            <span className={styles.returnsPolicy}>
              <SmartLink to={policyUrl} newWindow={true}>
                <Translation id="ma_return_policy_final_sale_link_text" />
              </SmartLink>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ReturnsPolicy;
