import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import { clearApiErrors } from "@state/application/actions";
import { getLanguage } from "@state/application/selectors";
import { getReturnDetails } from "@state/return-details/actions";
import {
  getDetailsFatalError,
  getReturnDetailsFromReturnReference,
} from "@state/return-details/selectors";
import { getLabelTokenFatalError } from "@state/returns-history/selectors";

const STATUS_DESCRIPTIONS = {
  FirstScanReceived: "ma_web_returns_status_firstscanreceived",
  ReturnAccepted: "ma_web_returns_status_returnaccepted",
  ReturnAcceptedWithLabel: "ma_web_returns_status_returnacceptedwithlabel",
};

const bundleLoader = () =>
  import(
    /* webpackChunkName: 'return-details-cards' */ "./return-details-cards"
  );

const ReturnDetails = () => {
  const dispatch = useDispatch();
  const { returnReference } = useParams();

  const pageNameKey = "ma_web_returns_details_title";

  const { bundle: ReturnDetailsCards, fatalError: bundleError } =
    useBundle(bundleLoader);

  const {
    returnDetailsLoaded: loaded,
    fatalError,
    details,
  } = useSelector((state: any) => {
    const details = getReturnDetailsFromReturnReference(state)(returnReference);
    const fatalError =
      getDetailsFatalError(state) || getLabelTokenFatalError(state);

    return {
      details,
      fatalError,
      language: getLanguage(state),
      returnDetailsLoaded: state.returnDetails && !fatalError && !!details,
    };
  });

  useEffect(() => {
    dispatch(clearApiErrors());

    dispatch(getReturnDetails(returnReference));
  }, [returnReference]);

  let statusKey = "";
  if (details) {
    statusKey =
      details.status === "ReturnAccepted" && details.returnDocumentUri
        ? "ReturnAcceptedWithLabel"
        : details.status;
  }
  const statusTranslationKey = STATUS_DESCRIPTIONS[statusKey] || "";

  const pageReady = ReturnDetailsCards && loaded;

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageHeader
          dataAutoId="return-details-header"
          description={statusTranslationKey}
          detached
          previousPageLink="/returns"
          title={pageNameKey}
          type="secondary"
        />
      }
      pageId="return-details"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError || bundleError}
      showScrollToTopButton
    >
      {pageReady && <ReturnDetailsCards />}
    </Page>
  );
};

export default ReturnDetails;
