import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import addresses from "./addresses/reducer";
import application from "./application/reducer";
import audienceSegments from "./audience-segments/reducer";
import communicationPreferences from "./communication-preferences/reducer";
import countries from "./countries/reducer";
import deliveryOptions from "./delivery-options/reducer";
import giftCardsAndVouchers from "./gift-cards-and-vouchers/reducer";
import identity from "./identity/reducer";
import orders from "./orders/reducer";
import password from "./password/reducer";
import payment from "./payment/reducer";
import premier from "./premier/reducer";
import returnDetails from "./return-details/reducer";
import returnsHistory from "./returns-history/reducer";
import returns from "./returns/reducer";
import { routerReducer } from "./route-history-middleware";
import socialAccounts from "./social-accounts/reducer";
import subscriptionOptions from "./subscription-options/reducer";
import user from "./user/reducer";

const createRootReducer = () =>
  combineReducers({
    addresses,
    application,
    audienceSegments,
    communicationPreferences,
    countries,
    deliveryOptions,
    form,
    giftCardsAndVouchers,
    identity,
    orders,
    password,
    payment,
    premier,
    returns,
    returnsHistory,
    returnDetails,
    router: routerReducer,
    socialAccounts,
    subscriptionOptions,
    user,
  });

export default createRootReducer;
