import React from "react";

import { Translation } from "@components/translation";

import styles from "./index.css";

export interface ReturnsVoucherLinksProps {
  openReturnsModal: () => void;
}

const ReturnsVoucherLinks = ({
  openReturnsModal,
}: ReturnsVoucherLinksProps) => {
  return (
    <div
      className={styles.notificationWrapper}
      data-auto-id="voucher-restriction-warning-message"
    >
      <div
        className={styles.voucherRestriction}
        id="GiftCardsAndVouchersReturnsLink"
      >
        <button
          className={styles.voucherReturnsLink}
          aria-describedby="GiftCardsAndVouchersReturnsLink"
          onClick={openReturnsModal}
        >
          <Translation id="ma_web_giftcardsandvouchers_returnslink" />
        </button>
      </div>
    </div>
  );
};

export default ReturnsVoucherLinks;
