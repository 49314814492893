import React from "react";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import UserLeavingModal, { UserModal } from "../user-leaving-modal";

const UserLeavingGenericModal = ({ ...props }: UserModal) => {
  const formatTranslation = useFormatTranslation();

  return (
    <UserLeavingModal
      {...props}
      question={formatTranslation(
        "ma_web_leavewithoutsavemodal_generic_question"
      )}
      title={formatTranslation("ma_web_leavewithoutsavemodal_generic_title")}
    />
  );
};

export default UserLeavingGenericModal;
