import React from "react";

import StringToJsxParser from "@components/string-to-jsx-parser";

import { useLocaleContext } from "@context/LocaleContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

export interface TranslationProps {
  id: string;
  values?: Record<string, any>;
}

export const Translation = ({ id, values = undefined }: TranslationProps) => {
  const formatTranslation = useFormatTranslation();
  const { tags } = useLocaleContext();

  return <StringToJsxParser text={formatTranslation(id, values)} tags={tags} />;
};
