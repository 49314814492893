import React from "react";

import classnames from "classnames";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { formatPrice } from "../../utils/format-price";
import VisuallyHidden from "../visually-hidden";

import styles from "./index.css";

export interface PriceProps {
  currency: string | null;
  language: string;
  value: number;
  isReduced?: boolean;
  isPrevious?: boolean;
  rrp?: boolean;
}

const Price = ({
  currency,
  language,
  value,
  isReduced = false,
  isPrevious = false,
  rrp = false,
}: PriceProps) => {
  const formatTranslation = useFormatTranslation();
  const price = formatPrice(language, currency, value);

  return (
    <>
      <VisuallyHidden>
        {isPrevious
          ? formatTranslation("ma_web_a11y_original_price")
          : formatTranslation("ma_web_a11y_price")}
      </VisuallyHidden>
      <span
        className={classnames({
          [styles.price]: true,
          [styles.reduced]: isReduced,
          [styles.was]: isPrevious,
        })}
      >
        {rrp && "RRP "}
        {price}
      </span>
    </>
  );
};

export default Price;
