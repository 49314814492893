import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./component.css";

const ContentWrapper = ({
  children,
  hasPaddingSmall,
  hasPaddingMedium,
  showChrome,
}) => (
  <div
    className={classnames({
      [styles.contentWrapper]: true,
      [styles.chromeless]: !showChrome,
      [styles.paddingSmall]: hasPaddingSmall,
      [styles.paddingMedium]: hasPaddingMedium,
    })}
  >
    {children}
  </div>
);

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hasPaddingSmall: PropTypes.bool,
  hasPaddingMedium: PropTypes.bool,
  showChrome: PropTypes.bool,
};

ContentWrapper.defaultProps = {
  hasPaddingSmall: false,
  hasPaddingMedium: false,
  showChrome: true,
};

export default ContentWrapper;
