/* eslint-disable import/no-dynamic-require, global-require */
import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { noticeError as noticeErrorFn } from "@utils/monitoring";

import defaultFlag from "./country-flags/africa.svg";

import styles from "./index.css";

const handleMissingFlag = (noticeError, ev) =>
  noticeError({
    ...ev,
    target: {
      ...ev.target,
      src: defaultFlag,
    },
  });

const makeSrcLink = (flagId, flagAssetsLocation) => {
  if (!flagId) {
    return defaultFlag;
  }
  return `${flagAssetsLocation}storesa/images/flags/${flagId.toLowerCase()}.png`;
};

const CountryFlagIcon = ({ id, flagAssetsLocation, noticeError, size }) => (
  <div className={styles.flagWrapper}>
    <img
      src={makeSrcLink(id, flagAssetsLocation)}
      className={classnames(styles[size], styles.icon)}
      alt=""
      onError={(e) => handleMissingFlag(noticeError, e)}
    />
    <div className={styles.iconShadow} />
  </div>
);

CountryFlagIcon.propTypes = {
  flagAssetsLocation: PropTypes.string.isRequired,
  id: PropTypes.string,
  noticeError: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["default", "small"]),
};

CountryFlagIcon.defaultProps = {
  id: null,
  size: "default",
  noticeError: noticeErrorFn,
};

export default CountryFlagIcon;
