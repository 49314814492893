import React from "react";

import styles from "./index.css";

export interface StoreAddressProps {
  address: {
    address1: string;
    address2?: string;
    address3?: string;
    locality?: string;
    subregionCode?: string;
    subregionName?: string;
    postalCode?: string;
  };
}

const StoreAddress = ({ address }: StoreAddressProps) => {
  const addressParts = [
    "address1",
    "address2",
    "address3",
    "locality",
    "subregionCode",
    "subregionName",
    "postalCode",
  ]
    .map((key) => ({ key, value: address[key] }))
    .filter(({ value }) => !!value);
  return (
    <span>
      {addressParts.map(({ key, value }, index, collection) => (
        <span key={key} className={styles.line}>
          {index + 1 < collection.length ? `${value},` : value}
        </span>
      ))}
    </span>
  );
};

export default StoreAddress;
