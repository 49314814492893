import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { HistoryRouter } from "redux-first-history/rr6";

import { AsosWebFeatureProvider } from "@asos-web-features/react";
import queryString from "query-string";

import installAnalytics from "@client/analytics";
import createFeaturesInstance from "@client/features";
import tags from "@client/tags";

import ErrorSection from "@components/error-section";
import LayoutSkeleton from "@components/layout-skeleton";

import { LocaleContextProvider } from "@context/LocaleContext";

import { getDefaultState as getApplicationDefaultState } from "@state/application/reducer";
import createStoreWithMiddleware, { getHistory } from "@state/index";

import { logError } from "@utils/logError";
import { notifyAppVisible, notifyCustomerId } from "@utils/monitoring";

import getTranslations from "../i18n";
import App from "./app";
import {
  getKeyStoreDataversion,
  getPageLanguage,
  getPagePlatform,
  getPageECommerceStore,
  getPageCurrency,
  getPageCountry,
  getPageSiteRoot,
} from "./document-handlers";
import getIdentity from "./identity";
import { setAnalyticsDebugMode } from "./url-handlers";

import "./index.css";

installAnalytics();
setAnalyticsDebugMode(global.location.href);

const eCommerceStore = getPageECommerceStore();
const country = getPageCountry();
const currency = getPageCurrency();
const language = getPageLanguage(); // with dialect
const platform = getPagePlatform();
const siteRoot = getPageSiteRoot();
const keyStoreDataversion = getKeyStoreDataversion();

const start = async (appConfig = window.ASOS.myAccount) => {
  const { identityConfig, optimizelyConfig } = appConfig;

  let identityError;

  const identity = await getIdentity(
    identityConfig,
    language,
    country,
    keyStoreDataversion,
    eCommerceStore
  ).catch((error) => logError((identityError = error)));

  if (identity) {
    notifyCustomerId(
      identity.customer.customerId,
      identity.customer.customerGuid
    );
  }

  const coreAttributes = {
    browseCountry: country,
    language: language,
    browseStore: eCommerceStore,
    platform: platform,
  };

  const asosFeaturesInstance = createFeaturesInstance(
    optimizelyConfig,
    coreAttributes
  );

  // Activate Experiments
  asosFeaturesInstance.onReady().then(() => {
    asosFeaturesInstance.activate("EMF345");
    asosFeaturesInstance.activate("EMF346");
  });

  const { chromeless = false } = queryString.parse(
    window.location.search.replace(/^\?/, "")
  );

  const rootElement = document.getElementById("app");

  render(<LayoutSkeleton />, rootElement);

  const translations = await getTranslations(
    language,
    country,
    eCommerceStore,
    appConfig["acc-stress"] && appConfig.i18n
  );

  const showChrome = chromeless === false || chromeless === "false";

  const store = createStoreWithMiddleware(
    {
      application: {
        ...getApplicationDefaultState(),
        config: appConfig,
        country,
        currency,
        eCommerceStore,
        language,
        loaded: false,
        platform,
        showChrome,
        siteRoot,
      },
    },
    identity
  );

  const renderApp = (Application) => {
    render(
      <Provider store={store}>
        <LocaleContextProvider
          language={language}
          siteRoot={siteRoot}
          translations={translations}
          tags={tags}
        >
          <HistoryRouter
            history={getHistory()}
            basename={process.env.NODE_ENV !== "test" ? "/my-account" : ""}
          >
            <AsosWebFeatureProvider instance={asosFeaturesInstance}>
              {identityError ? (
                <ErrorSection showCheckoutText={false} />
              ) : (
                <Application />
              )}
            </AsosWebFeatureProvider>
          </HistoryRouter>
        </LocaleContextProvider>
      </Provider>,
      rootElement,
      notifyAppVisible
    );
  };

  if (module.hot) {
    module.hot.accept("./app", () => {
      setTimeout(() => {
        try {
          const NextApp = require("./app").default; // eslint-disable-line global-require
          renderApp(NextApp);
        } catch (error) {
          const RedBox = require("redbox-react").default; // eslint-disable-line global-require
          render(<RedBox error={error} />, rootElement);
        }
      });
    });
  }

  renderApp(App);
};

start();
