import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";

import Layout from "@components/layout";
import PageContainer from "@components/layout/page-container";
import Loading, { LoadingContainer } from "@components/loading";
import PageHeader from "@components/page-header";
import PageInfo from "@components/page-info";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getGiftVouchers } from "@state/gift-cards-and-vouchers/actions";
import {
  getAddGiftCardFormFatalError,
  getAddGiftCardFormLoaded,
  getAddGiftCardFormSavedNewGiftCard,
  getGiftVouchersFatalError,
  getGiftVouchersLoaded,
} from "@state/gift-cards-and-vouchers/selectors";

import BackOfCardImg from "./back-of-card.svg";
import AddGiftCardForm from "./form";

import styles from "./component.css";

const AddGiftCard = () => {
  const dispatch = useDispatch();
  const formatTranslation = useFormatTranslation();

  useEffect(() => {
    dispatch(getGiftVouchers());
  }, []);

  const { fatalError, loaded, savedNewGiftCard } = useSelector((state) => {
    return {
      fatalError:
        getAddGiftCardFormFatalError(state) || getGiftVouchersFatalError(state),
      loaded: getAddGiftCardFormLoaded(state) && getGiftVouchersLoaded(state),
      savedNewGiftCard: getAddGiftCardFormSavedNewGiftCard(state),
    };
  });

  return savedNewGiftCard ? (
    <Navigate to="/gift-cards-and-vouchers" />
  ) : (
    <Layout data-auto-id="add-gift-card" showErrorSection={fatalError}>
      <PageInfo title="ma_web_addgiftcard_title" meta={[]} />
      <PageContainer>
        <PageHeader
          dataAutoId="AddGiftCardHeader"
          description="ma_web_addgiftcard_description"
          descriptionAlwaysVisible
          previousPageLink="/gift-cards-and-vouchers/add/"
          title="ma_web_addgiftcard_title"
          type="secondary"
        />
      </PageContainer>
      <LoadingContainer small>
        {!loaded && <Loading />}
        <Card>
          <CardSection hasTopPadding={false} hasBottomPadding={false}>
            <img
              alt={formatTranslation("ma_web_addgiftcard_backofcard")}
              className={styles.cardImage}
              src={BackOfCardImg}
            />
          </CardSection>
          <AddGiftCardForm />
        </Card>
      </LoadingContainer>
    </Layout>
  );
};

export default AddGiftCard;
