import React from "react";

import Layout from "@components/layout";
import Loading, { LoadingContainer } from "@components/loading";
import PageInfo from "@components/page-info";

export interface PageProps {
  children: React.ReactNode;
  loaded: boolean;
  pageHeader: React.ReactNode;
  pageId: string;
  pageTitleKey: string;
  showErrorSection: boolean;
  showScrollToTopButton?: boolean;
  smallLoadingContainer?: boolean;
}

const Page = ({
  children = null,
  loaded,
  pageHeader,
  pageId,
  pageTitleKey,
  showErrorSection,
  showScrollToTopButton = false,
  smallLoadingContainer = false,
}: PageProps) => (
  <Layout
    data-auto-id={pageId}
    showErrorSection={showErrorSection}
    showScrollToTopButton={showScrollToTopButton}
  >
    <PageInfo title={pageTitleKey} meta={[]} />
    {pageHeader}
    <LoadingContainer small={smallLoadingContainer}>
      {!loaded && <Loading />}
      {children}
    </LoadingContainer>
  </Layout>
);

export default Page;
