import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../error";
import AddAddress from "./add-address";
import AddressBook from "./address-book";
import EditAddress from "./edit-address";

const Addresses = () => (
  <Routes>
    <Route index element={<AddressBook />} />
    <Route path="/add" element={<AddAddress />} />
    <Route path="/edit/:id" element={<EditAddress />} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export default Addresses;
