import { v4 as uuid } from "uuid";

import { configureRequest } from "@asosteam/asos-web-request";

import { Identity } from "../@types/identity";
import { getApplicationVersion } from "../document-handlers";
import { newInteraction, noticeError } from "../utils/monitoring";
import { RequestError } from "./errors";
import { RequestOptions, buildRequestComponents } from "./requestComponents";

const getAuthHeader = async (identity: Identity) => {
  const token = await identity.getAccessToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const nativeToWebRequestMethod = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PATCH: "patch",
  PUT: "put",
} as const;

const asosWebRequest = configureRequest({
  client: {
    name: "Asos.Customer.MyAccount.Web.Ui",
    platform: "web",
    version: getApplicationVersion() as string,
  },
  defaultTimeout: Number(ASOS.myAccount.apiResponseTimeout),
  serverRequestLogger: (args) => noticeError(args),
});

export default async <TResponse extends object>(
  path: string,
  identity?: Identity,
  options: RequestOptions = {}
): Promise<TResponse | Array<any>> => {
  const customerId = identity?.customer.customerId;
  const customerGuid = identity?.customer.customerGuid;
  const authHeader = identity ? await getAuthHeader(identity) : null;

  const { url, headers, method, isThirdParty } = await buildRequestComponents(
    path,
    options,
    authHeader,
    customerId,
    customerGuid
  );

  newInteraction();

  const sendRequest = asosWebRequest[nativeToWebRequestMethod[method]];

  try {
    const response = await sendRequest<TResponse>({
      url,
      isThirdParty,
      body:
        typeof options.body === "string"
          ? JSON.parse(options.body)
          : options.body,
      cid: customerGuid,
      headers,
      uuid: uuid(),
    });
    return response.data;
  } catch (error) {
    const requestError = new RequestError(
      url,
      error.response.status,
      error.response.data
    );
    throw requestError;
  }
};
