import merge from "lodash.merge";

import BUNDLE_TYPES from "./bundle-types.json";
import { getLanguage } from "./utilities";

const loadBundle = (directory, fileName) =>
  import(
    /* webpackChunkName: "translations" */ `../i18n/${directory}/${fileName}.json`
  );

const getBundle = async (bundleType, fileName) => {
  try {
    return await loadBundle(bundleType, fileName.toLowerCase());
  } catch (err) {
    // Don't need to report to new relic as a missing bundle here is usually not a problem
    return {};
  }
};

const buildTranslationBundle = async (
  languageCode,
  countryCode,
  eCommerceStore,
  getBundleFn = getBundle
) =>
  merge(
    {},
    await getBundleFn(BUNDLE_TYPES.language, getLanguage(languageCode)),
    await getBundleFn(BUNDLE_TYPES.language, languageCode),
    await getBundleFn(BUNDLE_TYPES.eCommerceStore, "_default"),
    await getBundleFn(BUNDLE_TYPES.eCommerceStore, eCommerceStore),
    await getBundleFn(BUNDLE_TYPES.country, countryCode)
  );

const buildStressBundle = async (
  { countries = [], languages = [], stores = [] },
  getBundleFn
) => {
  const {
    dot: mapToDotNotation,
    object: mapFromDotNotation,
  } = require("dot-object"); // eslint-disable-line global-require

  const removeFileExtension = (path) => path.replace(/\.([a-z0-9])+$/gi, "");

  const loadBundlesByType = (bundleType, files) =>
    files.map((file) => getBundleFn(bundleType, removeFileExtension(file)));

  const getLongerValuesFromObject = (flattendObject, existingValues) =>
    Object.entries(flattendObject).reduce((longerValues, [key, value]) => {
      const currentLongest = existingValues[key];
      return !currentLongest || value.length > currentLongest.length
        ? { ...longerValues, [key]: value }
        : longerValues;
    }, {});

  const allBundles = await Promise.all([
    ...loadBundlesByType(BUNDLE_TYPES.country, countries),
    ...loadBundlesByType(BUNDLE_TYPES.language, languages),
    ...loadBundlesByType(BUNDLE_TYPES.eCommerceStore, stores),
  ]);

  return mapFromDotNotation(
    allBundles.reduce(
      (stressed, bundle) => ({
        ...stressed,
        ...getLongerValuesFromObject(mapToDotNotation(bundle), stressed),
      }),
      {}
    )
  );
};

const getTranslations = (
  languageCode,
  countryCode,
  eCommerceStore,
  stressTestFiles,
  getBundleFn = getBundle
) =>
  process.env.NODE_ENV !== "production" && stressTestFiles
    ? buildStressBundle(stressTestFiles, getBundleFn)
    : buildTranslationBundle(
        languageCode,
        countryCode,
        eCommerceStore,
        getBundleFn
      );

export default getTranslations;
