import React from "react";

import classnames from "classnames";

import styles from "./index.css";

type RadioButtonProps = {
  checked: boolean;
  input: {
    name: string;
    onChange: () => void;
  };
  layout?: "horizontal" | "vertical";
  text: string;
  value: string;
  required?: boolean;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  input,
  layout = "horizontal",
  text,
  value,
  required = false,
}) => {
  const id = `radio-${input.name}-${value}`;

  return (
    <span className={classnames(styles.inputWrapper, styles[layout])}>
      <input
        {...input}
        className={styles.radioInput}
        type="radio"
        checked={checked}
        value={value}
        id={id}
        required={required}
      />
      <label className={styles.styledInput} htmlFor={id}>
        <span className={styles.radioImageWrapper}>
          <span className={styles.radioImage} />
        </span>
        <span className={styles.label}>{text}</span>
      </label>
    </span>
  );
};

export default RadioButton;
