import React, { useEffect } from "react";
import ReactModal from "react-modal";

import PropTypes from "prop-types";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { LONDON_1 } from "@asosteam/asos-web-component-library-typography";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import closeIcon from "./close.svg";

import styles from "./index.css";

const Modal = ({ onRequestClose, padContent, title, children, ...rest }) => {
  const formatTranslation = useFormatTranslation();
  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      ReactModal.setAppElement("#app");
    }
  }, []);

  return (
    <ReactModal
      {...rest}
      aria={{ modal: true }}
      className={styles.modal}
      closeTimeoutMS={150}
      onRequestClose={onRequestClose}
      overlayClassName={styles.overlay}
      role="dialog"
    >
      <Card>
        <CardSection hasBottomPadding={false}>
          <div className={styles.wrapper}>
            <Heading
              sizing={LONDON_1}
              domElement="h2"
              className={styles.title}
              isUppercase={true}
            >
              {title}
            </Heading>
            {onRequestClose && (
              <button
                aria-label={formatTranslation("ma_web_modal_close")}
                className={styles.close}
                onClick={onRequestClose}
                data-auto-id="modal-close"
              >
                <img alt="" src={closeIcon} />
              </button>
            )}
          </div>
        </CardSection>
        <CardSection
          data-auto-id="modal-content"
          hasLeftPadding={padContent}
          hasRightPadding={padContent}
          hasTopPadding={false}
        >
          {children}
        </CardSection>
      </Card>
    </ReactModal>
  );
};

Modal.propTypes = {
  ...ReactModal.propTypes,
  padContent: PropTypes.bool,
  onRequestClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  padContent: true,
};

export default Modal;
