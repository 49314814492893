import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getHistory } from "@state/index";

import UserLeavingGenericModal from "../../app/components/user-leaving-generic-modal";

type TPromptProps = {
  when: boolean;
  ModalComponent?: FC<{
    formatTranslation: (key: string) => string;
    question: string;
    title: string;
    isOpen: boolean;
    onAccept: () => void;
    onCancel: () => void;
  }>;
};

const Prompt = ({
  when,
  ModalComponent = UserLeavingGenericModal,
}: TPromptProps) => {
  const formatTranslation = useFormatTranslation();
  const history = getHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const unblockHandle = useRef<any>();

  useEffect(() => {
    if (when) {
      unblockHandle.current = history.block((prompt) => {
        setCurrentPath(prompt.location.pathname);
        setShowPrompt(true);
        return "true";
      });
    }

    return function () {
      unblockHandle.current && unblockHandle.current();
    };
  }, [history, when]);

  const handleOK = useCallback(() => {
    unblockHandle.current && unblockHandle.current();

    history.push(currentPath);
  }, [currentPath, history]);

  const handleCancel = useCallback(() => {
    setShowPrompt(false);
  }, [currentPath, history]);

  return showPrompt ? (
    <ModalComponent
      formatTranslation={formatTranslation}
      question={formatTranslation(
        "ma_web_leavewithoutsavemodal_preferences_question"
      )}
      title={formatTranslation("ma_web_leavewithoutsavemodal_generic_title")}
      isOpen={showPrompt}
      onAccept={handleOK}
      onCancel={handleCancel}
    />
  ) : null;
};

export default Prompt;
