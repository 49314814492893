import React from "react";

import Icon from "@components/picture-icon";
import { SmartLink } from "@components/smart-link";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface ReturnToBrandProps {
  item?: {
    returnToName?: string;
    returnInstructionsUrl?: string;
  };
}

const ReturnToBrand = ({ item }: ReturnToBrandProps) => {
  const { returnToName, returnInstructionsUrl } = item;
  const formatTranslation = useFormatTranslation();
  return (
    <>
      {returnToName && (
        <div className={styles.label}>
          {formatTranslation("ma_returnsupplier_name", {
            brand: returnToName,
          })}
        </div>
      )}
      <div className={styles.infoField} aria-live="assertive">
        <Icon id="info" size="tiny" />
        <div>
          <span>{`${formatTranslation("ma_returnsupplier_info")} `}</span>
          {returnInstructionsUrl && (
            <span className={styles.returnToBrandInfo}>
              <SmartLink to={returnInstructionsUrl} newWindow={true}>
                <Translation id="ma_returnsupplier_faq" />
              </SmartLink>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ReturnToBrand;
