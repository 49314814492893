import { createAction } from "redux-actions";
import { reset } from "redux-form";

import { requestNotification } from "../application/actions";
import * as api from "./api";
import * as types from "./types";

export const receiveChangePassword = createAction(
  types.RECEIVE_CHANGE_PASSWORD
);
export const requestChangePassword = createAction(
  types.REQUEST_CHANGE_PASSWORD
);
export const triggerApiError = createAction(types.API_ERROR);

export const changePassword =
  (passwords, changePasswordApiFn = api.changePassword, resetForm = reset) =>
  async (dispatch, getState, identity) => {
    dispatch(requestChangePassword());
    try {
      const response = await changePasswordApiFn(passwords, identity);
      dispatch(receiveChangePassword(response));
      dispatch(resetForm("changePassword"));
      return dispatch(
        requestNotification("success", "ma_web_changepassword_passwordsaved")
      );
    } catch (err) {
      if (err.apiValidationError) {
        return dispatch(receiveChangePassword(err));
      }
      return null;
    }
  };
