const validateSearch = (search) => {
  if (search === "") {
    return {
      search: {
        id: "ma_web_orderreturn_dropoff_searchempty",
      },
    };
  }
  return undefined;
};

export const validateLocationResults = (locations) => {
  if (Array.isArray(locations) && locations.length < 1) {
    return {
      search: {
        id: "ma_web_orderreturn_returnmethod_dropoff_nonefound",
      },
    };
  }
  return undefined;
};

const validate = (values) => validateSearch(values.search);

export default validate;
