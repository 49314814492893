import Polyglot from "node-polyglot";

import { useLocaleContext } from "@context/LocaleContext";

export const useFormatTranslation = () => {
  const { polyglotInstance } = useLocaleContext();

  return (
    phrase: string,
    options: number | Polyglot.InterpolationOptions = {}
  ): string => polyglotInstance.t(phrase, options);
};
