import { Identity } from "src/@types/identity";

import request from "asos.myaccount.request";

const PREMIER_ENDPOINT =
  "commerce/premier/v1/customers/{customerId}/subscriptions?limit=1&expand=recurringSubscription";

const GET_SUBSCRIPTION_ENDPOINT = "commerce/premier/v1/customers/{customerId}";

const PREMIER_SIGN_UP_SESSION_ENDPOINT =
  "commerce/premier/v1/customers/{customerId}/sign-up-sessions";

const PREMIER_MANAGEMENT_SESSION_ENDPOINT =
  "commerce/premier/v1/customers/{customerId}/management-sessions";

export const getPremierSubscriptionStatus = (
  identity: Identity,
  requestFn = request
) => requestFn(GET_SUBSCRIPTION_ENDPOINT, identity);

export const getPremierSubscriptions = (identity, requestFn = request) =>
  requestFn(PREMIER_ENDPOINT, identity);

export const createPremierSubscriptionSignUpSession = (
  identity: Identity,
  {
    guid,
    language,
    currency,
    subscriptionOptionId,
    trialOfferId,
    redirectUrl = "",
  },
  requestFn = request
) =>
  requestFn<{ redirectUrl: string }>(
    PREMIER_SIGN_UP_SESSION_ENDPOINT,
    identity,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...(trialOfferId && { trialOfferId }),
        subscriptionOptionId,
        currency,
        language,
        redirectUris: {
          successUri: `${
            window.ASOS.myAccount.entryPoint
          }activating-premier?guid=${guid}${
            redirectUrl ? `&redirectUrl=${redirectUrl}` : ""
          }`,
          returnUri:
            redirectUrl ||
            window.ASOS.myAccount.entryPoint + "premier-delivery/",
        },
      }),
    }
  );

export const createPremierSubscriptionManagementSession = (
  identity: Identity,
  language: string,
  requestFn = request
) =>
  requestFn<{ redirectUrl: string }>(
    PREMIER_MANAGEMENT_SESSION_ENDPOINT,
    identity,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        language,
        returnUri: window.ASOS.myAccount.entryPoint + "premier-delivery/",
      }),
    }
  );
