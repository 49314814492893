import { createAction } from "redux-actions";

import { receiveAddresses } from "../addresses/actions";
import { getAddresses } from "../addresses/api";
import { getDefaultBillingAddress } from "../addresses/selectors";
import { requestNotification } from "../application/actions";
import {
  getLanguage,
  getPermittedPaymentOptions,
} from "../application/selectors";
import {
  addNewPaymentService,
  changeDefault,
  deletePaymentMethod as deletePaymentMethodApi,
  getPayments,
  getPaymentMethods,
  addNewPaymentCard,
} from "./api";
import {
  getAllPaymentMethods as getAllPaymentMethodsSelector,
  getCustomerPaymentById,
  getCustomerPayments,
  getDefaultCustomerPayment,
  getPaymentCards,
} from "./selectors";
import {
  ADD_PAYMENT_METHOD_ERROR,
  API_ERROR,
  ATTEMPT_DELETE_DEFAULT_PAYMENT_METHOD,
  ATTEMPT_SAVE_PAYMENT_CARD,
  CONFIRM_ADD_PAYMENT_METHOD,
  CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD,
  CONFIRM_DELETE_PAYMENT_ACCEPTANCE,
  CONFIRM_DELETE_PAYMENT_METHOD,
  CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE,
  DENY_DELETE_PAYMENT_ACCEPTANCE,
  RECEIVE_ALL_PAYMENT_METHODS,
  RECEIVE_PAYMENT_METHODS_OPTIONS,
  REQUEST_ADD_PAYMENT_METHOD,
  REQUEST_ALL_PAYMENT_METHODS,
  REQUEST_CHANGE_DEFAULT_PAYMENT_METHOD,
  REQUEST_DELETE_PAYMENT_METHOD,
  REQUEST_PAYMENT_METHODS_OPTIONS,
  REQUIRE_DELETE_PAYMENT_ACCEPTANCE,
  UNLOAD_PAYMENT_METHODS,
} from "./types";

export const requestAllPaymentMethods = createAction(
  REQUEST_ALL_PAYMENT_METHODS
);
export const receiveAllPaymentMethods = createAction(
  RECEIVE_ALL_PAYMENT_METHODS
);

export const getAllPaymentMethods =
  (getPaymentMethodsFn = getPaymentMethods) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      if (getAllPaymentMethodsSelector(state).length === 0) {
        dispatch(requestAllPaymentMethods());
        const options = await getPaymentMethodsFn(getLanguage(state));
        dispatch(receiveAllPaymentMethods(options.paymentMethods));
      }
    } catch (err) {
      dispatch(receiveAllPaymentMethods(err));
    }
  };

export const apiError = createAction(API_ERROR);

export const receivePaymentMethodsOptions = createAction(
  RECEIVE_PAYMENT_METHODS_OPTIONS
);
export const requestPaymentMethodsOptions = createAction(
  REQUEST_PAYMENT_METHODS_OPTIONS
);

export const addPaymentMethodError = createAction(ADD_PAYMENT_METHOD_ERROR);

export const getPaymentMethodsAndOptions =
  (
    getPaymentsFn = getPayments,
    getAddressesFn = getAddresses,
    receiveAddressesFn = receiveAddresses,
    getDefaultBillingAddressFn = getDefaultBillingAddress
  ) =>
  async (dispatch, getState, identity) => {
    const {
      application: { language },
      payment: { methodsLoaded, walletLoaded },
      addresses,
    } = getState();

    if (methodsLoaded && walletLoaded && addresses.loaded) {
      return null;
    }

    try {
      dispatch(requestPaymentMethodsOptions());

      const addressBook = await getAddressesFn(identity);
      dispatch(receiveAddressesFn(addressBook));

      const defaultBillingAddress = getDefaultBillingAddressFn(getState());
      const countryCode = defaultBillingAddress
        ? defaultBillingAddress.countryCode
        : null;

      const response = await getPaymentsFn(language, identity, countryCode);
      return dispatch(
        receivePaymentMethodsOptions({
          response,
          permittedPaymentOptions: getPermittedPaymentOptions(getState()),
        })
      );
    } catch (err) {
      return dispatch(receivePaymentMethodsOptions(err));
    }
  };

export const confirmChangeDefaultPaymentMethod = createAction(
  CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD
);

export const requestChangeDefaultPaymentMethod = createAction(
  REQUEST_CHANGE_DEFAULT_PAYMENT_METHOD
);

export const changeDefaultPaymentMethod =
  (id, type, isNew, changeDefaultFn = changeDefault) =>
  async (dispatch, getState, identity) => {
    dispatch(requestChangeDefaultPaymentMethod());

    try {
      await changeDefaultFn(id, type, identity);
      dispatch(confirmChangeDefaultPaymentMethod({ id, type, isNew }));
      dispatch(
        requestNotification(
          "success",
          "ma_web_paymentmethods_paymentmethodupdated"
        )
      );
    } catch (err) {
      dispatch(confirmChangeDefaultPaymentMethod(err));
      dispatch(
        addPaymentMethodError({
          id,
          message: "ma_web_paymentmethods_paymentmethodupdateerror",
        })
      );
    }
  };

// Delete payment method

export const attemptDeleteDefaultPaymentMethod = createAction(
  ATTEMPT_DELETE_DEFAULT_PAYMENT_METHOD
);

export const confirmDeletePaymentMethod = createAction(
  CONFIRM_DELETE_PAYMENT_METHOD
);

export const requireDeletePaymentAcceptance = createAction(
  REQUIRE_DELETE_PAYMENT_ACCEPTANCE
);

export const confirmDeletePaymentAcceptance = createAction(
  CONFIRM_DELETE_PAYMENT_ACCEPTANCE
);

export const denyDeletePaymentAcceptance = createAction(
  DENY_DELETE_PAYMENT_ACCEPTANCE
);

export const requestDeletePaymentMethod = createAction(
  REQUEST_DELETE_PAYMENT_METHOD
);

export const deletePaymentMethod =
  (id, type, deletePaymentMethodApiFn = deletePaymentMethodApi) =>
  async (dispatch, getState, identity) => {
    const { cardScheme } =
      getPaymentCards(getState()).find((card) => card.id === id) || {};
    dispatch(requestDeletePaymentMethod());

    try {
      await deletePaymentMethodApiFn(id, type, identity);
      dispatch(confirmDeletePaymentMethod({ id, type, cardScheme }));
      dispatch(
        requestNotification(
          "generic",
          type === "card"
            ? "ma_web_paymentmethods_carddeleted"
            : "ma_web_paymentmethods_paymentmethoddeleted"
        )
      );
    } catch (err) {
      dispatch(confirmDeletePaymentMethod(err));
      dispatch(
        addPaymentMethodError({
          id,
          message:
            type === "card"
              ? "ma_web_paymentmethods_carddeletederror"
              : "ma_web_paymentmethods_paymentmethoddeletederror",
        })
      );
    }
  };

export const attemptDeletePaymentMethod =
  (id, type, deletePaymentMethodFn = deletePaymentMethod) =>
  (dispatch, getState) => {
    const state = getState();
    const defaultCustomerPayment = getDefaultCustomerPayment(state);

    if (
      defaultCustomerPayment &&
      defaultCustomerPayment.id === id &&
      getCustomerPayments(state).length > 1
    ) {
      return dispatch(attemptDeleteDefaultPaymentMethod());
    }

    const customerPayment = getCustomerPaymentById(state, id);

    if (customerPayment.expired) {
      return dispatch(deletePaymentMethodFn(id, type));
    }

    return dispatch(requireDeletePaymentAcceptance({ id, type }));
  };

export const acceptDeletePaymentMethod =
  (deletePaymentMethodFn = deletePaymentMethod) =>
  (dispatch, getState) => {
    dispatch(confirmDeletePaymentAcceptance());

    const {
      payment: { paymentMethodAcceptanceId, paymentMethodAcceptanceType },
    } = getState();

    return dispatch(
      deletePaymentMethodFn(
        paymentMethodAcceptanceId,
        paymentMethodAcceptanceType
      )
    );
  };

export const unloadPaymentMethods = createAction(UNLOAD_PAYMENT_METHODS);

export const requestAddPaymentMethod = createAction(REQUEST_ADD_PAYMENT_METHOD);

export const confirmAddPaymentMethod = createAction(CONFIRM_ADD_PAYMENT_METHOD);

const isExpiredCard = (method) => method.type === "card" && method.expired;
const isDefaultPaymentMethodInvalid = (wallet) => {
  const defaultMethod = wallet.find((method) => method.isDefault);
  return !defaultMethod || isExpiredCard(defaultMethod);
};

export const addNewPaymentMethod =
  (
    id,
    addNewPaymentServiceFn = addNewPaymentService,
    getPaymentMethodsAndOptionsFn = getPaymentMethodsAndOptions
  ) =>
  async (dispatch, getState, identity) => {
    dispatch(requestAddPaymentMethod({ id }));

    try {
      const state = getState();

      const hasNoValidDefaultPaymentMethod = isDefaultPaymentMethodInvalid(
        state.payment.customerPayments
      );

      await addNewPaymentServiceFn(
        id,
        identity,
        hasNoValidDefaultPaymentMethod
      );
      dispatch(confirmAddPaymentMethod({ id }));
      dispatch(getPaymentMethodsAndOptionsFn());
      dispatch(
        requestNotification(
          "success",
          "ma_web_paymentmethods_paymentmethodsaved"
        )
      );
    } catch (err) {
      err.userAction = id;
      dispatch(apiError(err));
    }
  };

export const confirmSavePaymentCard = createAction(
  CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE
);

export const attemptSavePaymentCard = createAction(ATTEMPT_SAVE_PAYMENT_CARD);

export const savePaymentCard =
  (cardDetails, addNewPaymentCardFn = addNewPaymentCard) =>
  async (dispatch, getState, identity) => {
    dispatch(attemptSavePaymentCard());

    try {
      await addNewPaymentCardFn(cardDetails, identity);
      dispatch(
        requestNotification("success", "ma_web_paymentmethods_cardsaved")
      );
      dispatch(confirmSavePaymentCard(cardDetails));
      return dispatch(
        confirmChangeDefaultPaymentMethod({
          id: undefined,
          type: "card",
          isNew: true,
        })
      );
    } catch (err) {
      err.userAction = "addCard";
      return dispatch(apiError(err));
    }
  };
