import React from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import RadioButton from "@components/radio-button";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface LocationsListProps {
  deliveryCountry: string;
  places: {
    formatted_address: string;
    place_id: string;
  }[];
  selectPlaceForDropOff: (arg0: any, arg1: string) => void;
}

const LocationsList = ({
  deliveryCountry,
  places,
  selectPlaceForDropOff,
}: LocationsListProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <div>
      <CardSection
        hasBottomPadding={false}
        hasLeftPadding={false}
        hasRightPadding={false}
      >
        <Heading sizing={LONDON_2} domElement="h3">
          {formatTranslation("ma_web_orderreturn_returnmethod_didyoumean")}
        </Heading>
      </CardSection>
      <ul className={styles.list}>
        {places.map((address, index, array) => (
          <li key={address.place_id}>
            <RadioButton
              checked={false}
              input={{
                name: address.place_id,
                onChange: () => selectPlaceForDropOff(address, deliveryCountry),
              }}
              layout="vertical"
              value={address.place_id}
              text={address.formatted_address}
            />
            {index + 1 < array.length && <HorizontalRule />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationsList;
