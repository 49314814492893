/*
 * This Analytics middleware is responsible for figuring out,
 * generating and dispatching correct analytics event based
 * on app action and app state.
 *
 * To receive events you would have to add your
 * listener callback on window.analyticsEventEmitter object.
 *
 * Check FBEmitter documentation for details:
 * https://github.com/facebook/emitter
 */

import { emitAnalyticsEvent } from "@client/analytics";

import { SEGMENTS_TO_REPORT } from "@hooks/analytics/constants";

import { ordersPageSize } from "@configuration/order-history";

import {
  CONFIRM_CHANGE_DEFAULT_ADDRESS,
  CONFIRM_DELETE_ADDRESS,
  RECEIVE_ADD_ADDRESS,
  RECEIVE_EDIT_ADDRESS,
} from "@state/addresses/types";
import { resetMenuClicked } from "@state/application/actions";
import {
  getCountry,
  getCurrency,
  getECommerceStore,
  getLanguage,
  getMenuClicked,
  getPlatform,
} from "@state/application/selectors";
import {
  FINISH_STARTUP_LOADING,
  PAGE_LOAD_COMPLETE,
} from "@state/application/types";
import { getSegments } from "@state/audience-segments/selectors";
import { TRACK_SAVED_COMMUNICATION_PREFERENCES } from "@state/communication-preferences/types";
import {
  RECEIVE_ASSOCIATE_GIFT_CARD,
  TRACK_ADD_GIFT_CARD_ATTEMPT,
  TRACK_ADD_GIFT_VOUCHER_ATTEMPT,
  TRACK_BUY_GIFT_VOUCHER,
  TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER,
  TRACK_OPEN_ADD_GIFT_CARD,
  TRACK_OPEN_ADD_GIFT_VOUCHER,
  TRACK_SUCCESSFUL_ADD_GIFT_CARD,
  TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER,
} from "@state/gift-cards-and-vouchers/types";
import {
  getTotalOrders,
  getOrdersState,
  getOrderDetailsFromOrderReference,
} from "@state/orders/selectors";
import {
  OPEN_TRACK_HELP,
  OPEN_TRACK_PARCEL,
  PRODUCT_DETAILS,
  RECEIVE_CANCEL_ORDER,
  RECEIVE_ORDERS,
  REQUEST_ORDER_CANCEL_REASONS,
} from "@state/orders/types";
import { RECEIVE_CHANGE_PASSWORD } from "@state/password/types";
import {
  CONFIRM_ADD_PAYMENT_METHOD,
  CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD,
  CONFIRM_DELETE_PAYMENT_METHOD,
  CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE,
} from "@state/payment/types";
import { getPremierAnalytics } from "@state/premier/analytics";
import {
  hasPremierSubscription,
  isPremierSuccessUrl,
} from "@state/premier/selectors";
import {
  PREMIER_CLICK_JOIN,
  PREMIER_SUBSCRIPTION_MANAGE,
} from "@state/premier/types";
import { CLICK_DOWNLOAD_RETURN_ASSET } from "@state/returns-history/types";
import {
  getReturnItems,
  getReturnLocationProviderName,
  getReturnOrderReference,
  getReturnReferenceId,
} from "@state/returns/selectors";
import { RECEIVE_RETURN_BOOKING, SELECT_LOCATION } from "@state/returns/types";
import { REQUEST_CONNECT_LINKED_ACCOUNT } from "@state/social-accounts/types";
import { CLICK_RENEW_SUBSCRIPTION } from "@state/user/types";

import {
  DELIVERY_TYPES,
  determineDeliveryType,
} from "@utils/orders/deliveryType";

import { getCustomerId } from "../identity/selectors";
import { getPageLoadCount } from "./page-load-count";
import routesToTrack from "./routes";
import * as types from "./types";

export const getOrderFromOrderRef = (state, orderReference) =>
  state.orders.all.find((order) => order.orderReference === orderReference) ||
  state.orders.details.find((order) => order.orderReference === orderReference);

export const getOrderPaginationNumber = (state) =>
  Math.ceil(state.orders.all.length / ordersPageSize);

export const getRetutningItemsCount = (items) =>
  Object.keys(items).reduce((acc, key) => {
    const item = items[key];
    if (!item.isReturning) {
      return acc;
    }
    return acc + item.quantity;
  }, 0);

export const getFromattedRetutningItems = (items) =>
  Object.keys(items).reduce((acc, key) => {
    const item = items[key];
    if (!item.isReturning) {
      return acc;
    }
    return [
      ...acc,
      {
        quantity: item.quantity,
        reasonId: item.reasonId,
        variantId: item.variantId,
      },
    ];
  }, []);

export const getOrderHasDiscount = ({ orderTotal }) =>
  !!(
    orderTotal &&
    orderTotal.totalDiscount &&
    orderTotal.totalDiscount.value !== 0
  );

const interactions = {
  cancelled: "cancelled",
  click: "click",
  deleted: "deleted",
  saved: "saved",
  submit: "submit",
};

const trackingFrequency = {
  always: "always",
  session: "session",
};

const progress = {
  input: "input",
  start: "start",
  success: "success",
};

export const getReconsentStatus = () => {
  return undefined;
};

const getFilteredSegments = (state) => {
  const segments = getSegments(state);

  return segments
    .filter(({ segmentId }) => SEGMENTS_TO_REPORT.includes(segmentId))
    .map(({ segmentId }) => segmentId);
};

/*
 * This payload is provided for every qualifying
 * analytics event
 */
export const basicPayload = (state, pageDocument) => {
  return {
    countryISO: getCountry(state),
    currency: getCurrency(state),
    customerID: getCustomerId(state),
    gender: state.user.gender === "M" ? "men" : "women",
    language: getLanguage(state).toLowerCase(),
    pageType: "account page",
    platform: getPlatform(state).toLowerCase(),
    premierUser: hasPremierSubscription(state),
    recognizedUser: state.identity.isRecognised,
    reconsentStatus: getReconsentStatus({}, state.router.location),
    siteSection: "account",
    storeID: getECommerceStore(state).toLowerCase(),
    trackingFrequency: trackingFrequency.always,
    url: pageDocument.location.href,
    visitPageCount: getPageLoadCount(),
    segments: getFilteredSegments(state),
  };
};

/*
 * Helper method to figure out which Redux Form fields
 * has changed compared to the initial state.
 */
const detectChangedFields = (action, state) => {
  switch (action.meta.form) {
    case "my-details":
      return Object.keys(state.form["my-details"].fields)
        .map((key) => ({
          [key]:
            state.form["my-details"].values[key] !==
            state.form["my-details"].initial[key],
        }))
        .filter((key) => key);
    default:
      return null;
  }
};

/*
 * First step is to map Redux action to an analytics event
 */
/* eslint-disable-next-line complexity */
export const mapActionToPayload = (
  action,
  state,
  dispatch,
  pageDocument = document
) => {
  if (action.error) {
    return {
      // Commenting this out while building a fix for error analytics in integration tests
      // id: types.PAGE_INTERACTION,
      // payload: {
      //   ...basicPayload(state, pageDocument),
      //   context: 'error',
      //   interaction: 'error',
      //   error: {
      //     id: '000',
      //     message: 'There has been an error.',
      //   },
      // },
    };
  }
  switch (action.type) {
    case FINISH_STARTUP_LOADING: {
      return {
        id: types.FINISH_STARTUP_LOADING,
      };
    }

    case PAGE_LOAD_COMPLETE: {
      const pageId = (action.payload?.pageId || "").toLowerCase();

      if (pageId === "orderhistory") {
        const orders = getOrdersState(state).all;
        const deliveryTypeCounts = getDeliveryTypeCounts(orders);
        return {
          id: types.PAGE_LOAD,
          payload: {
            ...basicPayload(state, pageDocument),
            totalOrders: getTotalOrders(state),
            deliveryTypeCounts,
          },
        };
      } else if (pageId === "orderdetails") {
        const orderReference = state.router.location.pathname.split("/")[3];
        const order = getOrderFromOrderRef(state, orderReference);
        return {
          id: types.PAGE_LOAD,
          payload: {
            ...basicPayload(state, pageDocument),
            order: {
              status: order.status,
              numberOfDeliveryGroups: order.deliveryGroupsDetail?.length || 1,
              products: productDeliveryData(order),
            },
          },
        };
      } else if (pageId === "premier") {
        if (getMenuClicked(state)) {
          dispatch(resetMenuClicked());
          return {
            id: types.PAGE_LOAD,
            payload: {
              ...basicPayload(state, pageDocument),
              premierAutorenewals: true,
              premierStatus: getPremierAnalytics(state),
              premierStep: "menu-click",
            },
          };
        } else if (isPremierSuccessUrl()) {
          dispatch(resetMenuClicked());
          return {
            id: types.PAGE_LOAD,
            payload: {
              ...basicPayload(state, pageDocument),
              premierAutorenewals: true,
              premierStatus: getPremierAnalytics(state),
              premierStep: "successful",
            },
          };
        }
        break;
      } else {
        return {
          id: types.PAGE_LOAD,
          payload: {
            ...basicPayload(state, pageDocument),
          },
        };
      }
    }
    case REQUEST_ORDER_CANCEL_REASONS: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "cancel order",
          order: {
            status: getOrderFromOrderRef(state, action.payload.orderReference)
              .status,
          },
        },
      };
    }
    case RECEIVE_CANCEL_ORDER: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.cancelled,
          elementText: "cancel order",
          order: {
            status: getOrderFromOrderRef(state, action.payload.orderReference)
              .status,
            numberOfDeliveryGroups: getOrderFromOrderRef(
              state,
              action.payload.orderReference
            ).deliveryGroupsDetail.length,
          },
        },
      };
    }
    case RECEIVE_ORDERS: {
      if (action.payload.loadMore) {
        const deliveryTypeCounts = getDeliveryTypeCounts(
          action.payload.orderSummaries
        );
        return {
          id: types.PAGE_LOAD,
          payload: {
            ...basicPayload(state, pageDocument),
            pageTitle: `my orders page ${getOrderPaginationNumber(state)}`,
            deliveryTypeCounts,
          },
        };
      }
      break;
    }
    case "@@redux-form/START_SUBMIT":
      if (action.meta.form === "my-details") {
        return {
          id: types.PAGE_INTERACTION,
          payload: {
            ...basicPayload(state, pageDocument),
            interaction: "submit",
            elementText: "save changes",
            formFields: detectChangedFields(action, state),
          },
        };
      }
      break;
    case RECEIVE_ADD_ADDRESS:
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "add new address",
          address: {
            addressFinder: !!action.payload.addressFinderUsed,
            setDefault: {
              billing: action.payload.defaultBilling,
              delivery: action.payload.defaultDelivery,
            },
          },
        },
      };
    case RECEIVE_EDIT_ADDRESS:
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "edit address",
          address: {
            addressFinder: false,
            setDefault: {
              billing: action.payload.defaultBilling,
              delivery: action.payload.defaultDelivery,
            },
          },
        },
      };
    case SELECT_LOCATION:
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          context: "drop off change address",
          interaction: interactions.saved,
          elementText: "select",
        },
      };
    case RECEIVE_RETURN_BOOKING: {
      return {
        id: types.PAGE_LOAD,
        payload: {
          ...basicPayload(state, pageDocument),
          channel2: "my orders",
          context: "place return",
          pageTitle: "place return",
          return: {
            itemsTotal: getRetutningItemsCount(getReturnItems(state)),
            method: "drop off",
            courier: getReturnLocationProviderName(state),
            isDiscountApplied: getOrderHasDiscount(
              getOrderDetailsFromOrderReference(state)(
                getReturnOrderReference(state)
              )
            ),
            orderId: getReturnOrderReference(state),
            products: getFromattedRetutningItems(getReturnItems(state)),
            returnId: getReturnReferenceId(state),
          },
        },
      };
    }
    case CLICK_DOWNLOAD_RETURN_ASSET:
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          context: "download asset",
          interaction: interactions.click,
          elementText: "download asset",
        },
      };
    case CLICK_RENEW_SUBSCRIPTION:
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          premierAutorenewals: false,
          premierStatus: "premier expiring",
        },
      };

    case PREMIER_CLICK_JOIN: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          premierAutorenewals: true,
          premierStatus: getPremierAnalytics(state),
          premierStep: "join",
        },
      };
    }

    // case PREMIER_SUCCESSFUL_SIGNUP:
    //   return {
    //     id: types.PAGE_INTERACTION,
    //     payload: {
    //       ...basicPayload(state, pageDocument),
    //       premierAutorenewals: true,
    //       premierStatus: getPremierAnalytics(state),
    //       premierStep: "successful"
    //     }
    //   };

    case PREMIER_SUBSCRIPTION_MANAGE: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          premierAutorenewals: true,
          premierStatus: getPremierAnalytics(state),
          premierStep: "manage",
        },
      };
    }

    case CONFIRM_DELETE_ADDRESS: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.deleted,
          elementText: "delete",
        },
      };
    }
    case CONFIRM_CHANGE_DEFAULT_ADDRESS: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: "save address",
          elementText: action.payload.billingAddress
            ? "set as default billing address"
            : "set as default delivery address",
          address: {
            addressFinder: false,
            setDefault: {
              billing: action.payload.billingAddress,
              delivery: !action.payload.billingAddress,
            },
          },
        },
      };
    }
    case RECEIVE_CHANGE_PASSWORD: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "save password",
        },
      };
    }
    case CONFIRM_ADD_PAYMENT_METHOD: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "add new payment method",
          payment: {
            method: action.payload.id.toLowerCase(),
            setDefault: false,
          },
        },
      };
    }
    case CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE: {
      const { payload = {} } = action;
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "save card",
          payment: {
            method: "card",
            setDefault: payload.isDefault || false,
          },
        },
      };
    }
    case CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD: {
      const { id, type, isNew } = action.payload;
      const method =
        type && type.toLowerCase() === "card"
          ? type.toLowerCase()
          : id.toLowerCase();
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: isNew ? "save card" : "set as default payment method",
          payment: {
            method,
            setDefault: true,
          },
        },
      };
    }
    case CONFIRM_DELETE_PAYMENT_METHOD: {
      const { id, type, cardScheme } = action.payload;
      const method =
        type && type.toLowerCase() === "card"
          ? type.toLowerCase()
          : id.toLowerCase();
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.deleted,
          elementText: "delete",
          payment: { method, cardScheme },
        },
      };
    }
    case TRACK_SAVED_COMMUNICATION_PREFERENCES: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "save preferences",
          formFields: {
            preferencesPreSave: action.payload.preferencesPreSave,
            preferencesPostSave: action.payload.preferencesPostSave,
          },
        },
      };
    }

    case REQUEST_CONNECT_LINKED_ACCOUNT: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "connect",
          social: action.payload,
        },
      };
    }
    case OPEN_TRACK_HELP: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "help",
          order: {
            status: getOrderFromOrderRef(state, action.payload.orderReference)
              .status,
          },
        },
      };
    }
    case OPEN_TRACK_PARCEL: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: action.payload.isSingleParcel
            ? "track order"
            : `track parcel ${action.payload.parcelNo}`,
          order: {
            status: getOrderFromOrderRef(state, action.payload.orderReference)
              .status,
            numberOfDeliveryGroups: getOrderFromOrderRef(
              state,
              action.payload.orderReference
            ).deliveryGroupsDetail.length,
          },
        },
      };
    }
    case PRODUCT_DETAILS: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "product",
          product: {
            id: action.payload.id,
          },
        },
      };
    }
    case RECEIVE_ASSOCIATE_GIFT_CARD: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "save gift card",
          product: {
            progress: progress.success,
          },
        },
      };
    }
    case TRACK_OPEN_ADD_GIFT_CARD: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "gift card",
        },
      };
    }
    case TRACK_OPEN_ADD_GIFT_VOUCHER: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "gift voucher",
        },
      };
    }
    case TRACK_SUCCESSFUL_ADD_GIFT_CARD: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "save gift card",
          progress: progress.success,
        },
      };
    }
    case TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.saved,
          elementText: "save gift voucher",
          progress: progress.success,
        },
      };
    }
    case TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "add gift card voucher",
          progress: progress.start,
        },
      };
    }
    case TRACK_BUY_GIFT_VOUCHER: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "buy gift voucher",
          progress: progress.start,
        },
      };
    }
    case TRACK_ADD_GIFT_CARD_ATTEMPT: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "gift card",
          progress: progress.input,
        },
      };
    }
    case TRACK_ADD_GIFT_VOUCHER_ATTEMPT: {
      return {
        id: types.PAGE_INTERACTION,
        payload: {
          ...basicPayload(state, pageDocument),
          interaction: interactions.click,
          elementText: "gift voucher",
          progress: progress.input,
        },
      };
    }
    default:
      return {
        id: undefined,
      };
  }
  return { id: undefined };
};

/*
 * Step for adding extra data for a specific route
 */
export const assignRouteSpecificAttrs = (route, routes = routesToTrack) => {
  const extraInfo = {};
  const routeDefinition = routes.find((routeDef) =>
    routeDef.pattern.test(route)
  );

  if (routeDefinition) {
    extraInfo.pageTitle = routeDefinition.pageTitle;
    extraInfo.channel2 = routeDefinition.channel2 || "";
    extraInfo.context = routeDefinition.context;
  }

  return extraInfo;
};

export default (
    emitAnalyticsEventFn = emitAnalyticsEvent,
    pageDocument = document
  ) =>
  (store) =>
  (next) =>
  (action) => {
    const result = next(action);
    const state = store.getState();
    const dispatch = (a) => store.dispatch(a);

    const trackingEvent = mapActionToPayload(
      action,
      state,
      dispatch,
      pageDocument
    );

    if (trackingEvent && trackingEvent.payload) {
      trackingEvent.payload = {
        ...assignRouteSpecificAttrs(state.router.location.pathname),
        ...trackingEvent.payload,
      };
      emitAnalyticsEventFn(trackingEvent);
    }

    return result;
  };

function productDeliveryData(order) {
  return order.items
    .filter((item) => item.itemType === "Product")
    .map((item) => {
      if (
        !order.deliveryGroupsDetail ||
        order.deliveryGroupsDetail.length === 0
      ) {
        return {
          productId: item.productId,
          sourceId: item.seller?.id ? null : "primary",
          sellerId: item.seller?.id || "asos",
          deliveryType: determineDeliveryType(item.seller, undefined),
        };
      }

      const { source } = order.deliveryGroupsDetail.find((dgd) => {
        return dgd.variants.find((v) => v.variantId === item.variantId);
      });

      return {
        productId: item.productId,
        sourceId: source?.id || "primary",
        sellerId: item.seller?.id || "asos",
        deliveryType: determineDeliveryType(item.seller, source),
      };
    });
}

function getDeliveryTypeCounts(orders) {
  const deliveryTypeCounts = orders.reduce(
    (counts, order) => {
      order.deliveryGroupsDetail.forEach((dg) => {
        const mappedItems = order.items?.filter(({ variantId }) =>
          dg.variants.some(
            (parcelVariant) => parcelVariant.variantId === variantId
          )
        );

        let isAfs = false;
        let isDtc = false;

        mappedItems?.forEach((item) => {
          const deliveryType = determineDeliveryType(item.seller, dg.source);
          if (
            deliveryType === DELIVERY_TYPES.AFS_NON_PRIME ||
            deliveryType === DELIVERY_TYPES.AFS_PRIME
          ) {
            isAfs = true;
          } else if (deliveryType === DELIVERY_TYPES.DTC) {
            isDtc = true;
          }
        });

        if (isAfs) {
          counts.afs += 1;
        } else if (isDtc) {
          counts.dtc += 1;
        }
      });

      return counts;
    },
    {
      afs: 0,
      dtc: 0,
    }
  );
  return deliveryTypeCounts;
}
