import React from "react";

import classnames from "classnames";

import styles from "./index.css";

export type FieldLabelProps = {
  black?: boolean;
  emphasis?: boolean;
  inline?: boolean;
  sameLine?: boolean;
  text: string;
  showRequired?: boolean;
};

const FieldLabel: React.FC<FieldLabelProps> = ({
  black = false,
  text,
  inline = false,
  sameLine = false,
  emphasis = false,
  showRequired = false,
}) => (
  <span
    className={classnames({
      [styles.label]: true,
      [styles.black]: black,
      [styles.emphasis]: emphasis,
      [styles.inline]: inline,
      [styles.sameLine]: sameLine,
    })}
  >
    {showRequired ? text.replace(":", "*:") : text}
  </span>
);

export default FieldLabel;
