import { handleActions } from "redux-actions";

import orderBy from "lodash.orderby";

import { CLEAR_API_ERRORS } from "../application/types";
import {
  REQUEST_ASSET_ACCESS_TOKEN,
  RECEIVE_ASSET_ACCESS_TOKEN,
  REQUEST_RETURNS,
  RECEIVE_RETURNS,
  API_ERROR,
} from "./types";

const reducer = handleActions(
  {
    [API_ERROR]: (state, { payload: { userAction } }) => {
      const { maxRetries, tries } = state;
      if (tries[userAction] >= maxRetries) {
        return {
          ...state,
          apiError: {},
          fatalError: true,
        };
      }
      return {
        ...state,
        apiError: {
          [userAction]: true,
        },
        fatalError: false,
        paginationLoaded: true,
        tries: {
          ...state.tries,
          [userAction]: (tries[userAction] || 0) + 1,
        },
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      paginationLoaded: true,
      apiError: {},
      tries: {},
    }),
    [REQUEST_ASSET_ACCESS_TOKEN]: (state, { payload }) => ({
      ...state,
      labelTokenIsLoading: true,
      labelTokenReturnReference: payload,
    }),
    [RECEIVE_ASSET_ACCESS_TOKEN]: (state, { error }) => {
      if (error) {
        return {
          ...state,
          labelTokenIsLoading: false,
          labelTokenErrorCount: state.labelTokenErrorCount + 1,
        };
      }
      return {
        ...state,
        labelTokenErrorCount: 0,
        labelTokenIsLoading: false,
        labelTokenReturnReference: "",
      };
    },
    [REQUEST_RETURNS]: (state, { payload }) => {
      const loaded = payload && payload.loadMore ? state.loaded : false;
      return {
        ...state,
        fatalError: false,
        loaded,
        paginationLoaded: false,
      };
    },
    [RECEIVE_RETURNS]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          fatalError: true,
          loaded: false,
          paginationLoaded: false,
        };
      }

      const returns = payload.returns.map(
        ({ productItems, statusHistory, ...rest }) => ({
          ...rest,
          items: productItems,
          statusHistory: orderBy(statusHistory, "date", "asc"),
        })
      );

      return {
        ...state,
        returns: [...state.returns, ...returns],
        fatalError: false,
        loaded: true,
        paginationLoaded: true,
        total: payload.itemCount,
      };
    },
  },
  {
    returns: [],
    apiError: {},
    fatalError: false,
    labelTokenErrorCount: 0,
    labelTokenIsLoading: false,
    labelTokenReturnReference: "",
    loaded: false,
    paginationLoaded: false,
    maxRetries: 2,
    total: 0,
    tries: {},
  }
);

export default reducer;
