import React from "react";

import { CardWithHeading } from "@asosteam/asos-web-component-library-card-with-heading";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";

import { Table, Row, Cell } from "@components/commerce-table";
import FieldLabel from "@components/field-label";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

type ReturnTotalProps = {
  active: boolean;
  totalItemCount: number;
  feeMessage?: string;
  postPurchaseChargeInformationMessage?: string;
};

const ReturnTotal = ({
  active,
  totalItemCount,
  feeMessage = null,
  postPurchaseChargeInformationMessage = null,
}: ReturnTotalProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <CardWithHeading
      heading={formatTranslation("ma_web_orderreturn_returntotal_title")}
      collapsed={!active}
      isUppercase={true}
    >
      <div>
        <Table data-auto-id="order-refund-totals" returns>
          <tbody>
            <Row>
              <Cell>
                <Paragraph sizing="london2" className={styles.itemsToReturn}>
                  {formatTranslation(
                    "ma_web_orderreturn_returntotal_numberofitems"
                  )}
                </Paragraph>
              </Cell>
              <Cell>
                <span data-auto-id="refund-items-selected-count">
                  <FieldLabel
                    text={totalItemCount.toString()}
                    black
                    inline
                    emphasis
                  />
                </span>
              </Cell>
            </Row>
          </tbody>
        </Table>
        {feeMessage && (
          <Paragraph sizing="london2" className={styles.feeMessage}>
            {feeMessage}
          </Paragraph>
        )}
        {postPurchaseChargeInformationMessage && (
          <Paragraph
            sizing="leavesden2"
            className={styles.postPurchaseChargeInformationMessage}
            dangerouslySetInnerHTML={{
              __html: postPurchaseChargeInformationMessage,
            }}
          ></Paragraph>
        )}
        <Paragraph className={styles.refundCalculationDescription}>
          {formatTranslation(
            "ma_faster_refunds_refund_calculation_description"
          )}
        </Paragraph>
        <Paragraph
          data-auto-id="refund-destination"
          className={styles.paymentMethod}
        >
          <Translation
            id="ma_web_orderreturn_returnitem_paymentinformation"
            values={{
              link: formatTranslation(
                "ma_web_orderreturn_returnitem_paymentinformationlink"
              ),
            }}
          />
        </Paragraph>
      </div>
    </CardWithHeading>
  );
};

export default ReturnTotal;
