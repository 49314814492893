import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { clearApiErrors } from "@state/application/actions";
import { getCountries } from "@state/countries/actions";
import {
  buyGiftVoucher,
  getGiftVouchers,
  openAddGiftCardOrVoucher,
  resetSaveNewGiftCardAndVoucher,
} from "@state/gift-cards-and-vouchers/actions";
import {
  getGiftVouchersFatalError,
  getGiftVouchersLoaded,
  getVoucherCount,
} from "@state/gift-cards-and-vouchers/selectors";

import BuyVoucher from "../buy-voucher";
import VoucherListContent from "./content";

const VoucherList: React.FC = () => {
  const dispatch = useDispatch();

  const openAddGiftCardOrVoucherCall = () =>
    dispatch(openAddGiftCardOrVoucher());

  const buyGiftVoucherCall = () => dispatch(buyGiftVoucher());

  useEffect(() => {
    dispatch(clearApiErrors());
    dispatch(getGiftVouchers());
    dispatch(resetSaveNewGiftCardAndVoucher());
    dispatch(getCountries());
  }, []);

  const { fatalError, loaded, voucherCount } = useSelector((state) => {
    return {
      fatalError: getGiftVouchersFatalError(state),
      loaded: getGiftVouchersLoaded(state),
      voucherCount: getVoucherCount(state),
    };
  });

  const pageNameKey = "ma_web_giftcardsandvouchers_title";
  const hasNoVouchers = voucherCount === 0;
  const showHeaderButton = !hasNoVouchers;
  return (
    <Page
      loaded={loaded}
      pageHeader={
        <div>
          <PageHeader
            buttonText={
              showHeaderButton ? "ma_web_giftcardsandvouchers_addgift" : ""
            }
            buttonOnClick={
              showHeaderButton ? openAddGiftCardOrVoucherCall : null
            }
            dataAutoId="GiftCardsAndVouchersHeader"
            icon="gifts"
            previousPageLink="/"
            title={pageNameKey}
            type="primary"
            detached={hasNoVouchers}
          />
        </div>
      }
      pageId="gift-cards-and-vouchers"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError}
      showScrollToTopButton={!hasNoVouchers}
    >
      {loaded && !hasNoVouchers && <VoucherListContent />}
      {loaded && hasNoVouchers && (
        <BuyVoucher
          buyGiftVoucher={buyGiftVoucherCall}
          openAddGiftCardOrVoucher={openAddGiftCardOrVoucherCall}
        />
      )}
    </Page>
  );
};

export default VoucherList;
