import React from "react";

import classnames from "classnames";

import ContentWrapper from "@components/layout/content-wrapper";
import { SmartLink } from "@components/smart-link";
import { Translation } from "@components/translation";

import styles from "./index.css";

const Footer = () => (
  <footer className={styles.footer} role="contentinfo">
    <ContentWrapper>
      <div className={styles.linkWrapper}>
        <ul aria-label="Company Links">
          <li className={styles.footerSection}>
            <SmartLink
              className={classnames(styles.homepageLink, styles.navLink)}
              to="ExternalLink.HomePage"
              newWindow={false}
            >
              <Translation id="ma_web_footer_home" />
            </SmartLink>
          </li>
          <li className={classnames(styles.footerSection, styles.groupedLinks)}>
            <SmartLink
              to="ExternalLink.Footer.Terms"
              className={styles.navLink}
            >
              <Translation id="ma_web_footer_terms" />
            </SmartLink>
          </li>
          <li className={classnames(styles.footerSection, styles.groupedLinks)}>
            <SmartLink
              to="ExternalLink.Footer.Privacy"
              className={styles.navLink}
            >
              <Translation id="ma_web_footer_privacy" />
            </SmartLink>
          </li>
        </ul>
        <div className={classnames(styles.footerSection, styles.copySection)}>
          <div>
            <Translation
              id="ma_web_footer_copyright"
              values={{
                date: new Date().getUTCFullYear(),
              }}
            />
          </div>
        </div>
      </div>
    </ContentWrapper>
  </footer>
);

export default Footer;
