import { LOCATION_CHANGE } from "redux-first-history";

export const APP_STARTUP_ERROR = "APP_STARTUP_ERROR";
export const FINISH_STARTUP_LOADING = "FINISH_STARTUP_LOADING";

export const REQUEST_NOTIFICATION = "REQUEST_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const START_USER_LEAVING = "START_USER_LEAVING";
export const FINISH_USER_LEAVING = "FINISH_USER_LEAVING";

export const CLEAR_API_ERRORS = "CLEAR_API_ERRORS";

export const REQUEST_APP_DEPENDENCIES = "REQUEST_APP_DEPENDENCIES";
export const RECEIVE_APP_DEPENDENCIES = "RECEIVE_APP_DEPENDENCIES";

export const ROUTER_LOCATION_CHANGE = LOCATION_CHANGE;
export const PAGE_LOAD_COMPLETE = "PAGE_LOAD_COMPLETE";

export const SET_MENU_CLICKED = "SET_MENU_CLICKED";
export const RESET_MENU_CLICKED = "RESET_MENU_CLICKED";
