import React from "react";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const MILES_COUNTRIES = ["US", "GB"];

export const isMilesCountry = (country) =>
  MILES_COUNTRIES.includes(country.toUpperCase());
export const prettyPrintDistance = (distance) => {
  const endsWithAZero = /^.*0$/;
  const fixed = distance.toFixed(2);
  if (fixed.match(endsWithAZero)) {
    return Number(fixed).toString();
  }
  return fixed;
};

export interface DistanceProps {
  meters: number;
  miles: number;
  countryCode?: string;
}

const Distance = ({ meters, miles, countryCode = "GB" }: DistanceProps) => {
  const formatTranslation = useFormatTranslation();
  const shouldUseMiles = isMilesCountry(countryCode);
  const distance = shouldUseMiles ? miles : meters / 1000;
  const prettyDistance = prettyPrintDistance(distance);
  const metric = shouldUseMiles
    ? "ma_web_generic_distance_miles"
    : "ma_web_generic_distance_kilometers";

  return (
    <span className={styles.distance}>
      {`(${formatTranslation(metric, {
        distance: prettyDistance,
        smart_count: Number(prettyDistance),
      })})`}
    </span>
  );
};

export default Distance;
