import { isMockedEnvironment } from "../utils/mock-environment-checker";
import getIdentitySdk from "./identity-sdk";

const getIdentityMock = async () => {
  const identityMock = await import("./identity-mock");
  return identityMock.default();
};

const getIdentity = async (
  config,
  language,
  countryCode,
  keyStoreDataversion,
  eCommerceStore,
  isMockedEnvironmentFn = isMockedEnvironment,
  getIdentitySdkFn = getIdentitySdk,
  getIdentityMockFn = getIdentityMock
) =>
  isMockedEnvironmentFn()
    ? await getIdentityMockFn()
    : await getIdentitySdkFn(
        config,
        language,
        eCommerceStore,
        countryCode,
        keyStoreDataversion
      );

export default getIdentity;
