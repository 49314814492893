import React from "react";

import flatMap from "lodash.flatmap";
import PropTypes from "prop-types";

import { clearTokenFromStorage } from "@utils/storage";

import NavGroup from "./nav-group";
import NavItem from "./nav-item";

const Navigation = ({ navGroups, notification, setMenuClicked, logout }) => {
  const navItems = flatMap(navGroups, ({ items }) => items);

  return (
    <nav data-auto-id="main-nav">
      <NavGroup>
        {navItems.map(
          ({
            icon,
            labelKey,
            path,
            tagText,
            showBorderBottom,
            hideBorderTop,
          }) => (
            <NavItem
              key={labelKey}
              icon={icon}
              path={path}
              tagText={tagText}
              text={labelKey}
              hasNotification={!!(notification && notification.path === path)}
              hideBorderTop={hideBorderTop}
              setMenuClicked={setMenuClicked}
              showBorderBottom={showBorderBottom}
              shownOnlyOnLarge={path === "/"}
            />
          )
        )}
        <NavItem
          forcePageReload
          hideBorderTop={true}
          icon="signout"
          preflightHandler={clearTokenFromStorage}
          showBorderBottom={true}
          setMenuClicked={setMenuClicked}
          text="ma_web_navigation_signout"
          onClick={logout}
        />
      </NavGroup>
    </nav>
  );
};

Navigation.propTypes = {
  navGroups: PropTypes.arrayOf(
    PropTypes.shape({
      labelKey: PropTypes.string,
      path: PropTypes.string,
      icon: PropTypes.string,
      id: PropTypes.string,
      tagText: PropTypes.string,
    })
  ).isRequired,
  notification: PropTypes.shape({
    path: PropTypes.string,
  }),
  setMenuClicked: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  notification: null,
};

export default Navigation;
