export const getOrderDates = (items) => {
  if (
    !items ||
    Object.values(items).length === 0 ||
    typeof items !== "object"
  ) {
    return "";
  }

  // Sort dates to remove complexity due to permutation and split out time so that date is of YYYY-MM-DD format
  const filteredItemsToReturn = Object.values(items)
    .filter(({ isReturning }) => isReturning)
    .sort((a, b) => a.orderDate - b.orderDate)
    .map(({ orderDate }) => orderDate.split("T")[0]);

  const orderDateString = [...new Set(filteredItemsToReturn)].join(",");

  return orderDateString;
};

export const getOrderReferences = (items) => {
  if (
    !items ||
    Object.values(items).length === 0 ||
    typeof items !== "object"
  ) {
    return "";
  }

  const filteredItemsToReturn = Object.values(items)
    .filter(({ isReturning }) => isReturning)
    .map(({ orderReference }) => orderReference);

  const orderReferenceString = [...new Set(filteredItemsToReturn)].join(",");

  return orderReferenceString;
};
