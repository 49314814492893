import { ComponentType, useEffect, useState } from "react";

import { noticeError } from "@utils/monitoring";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBundle = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
) => {
  const [bundle, setBundle] = useState<T | null>(null);
  const [fatalError, setFatalError] = useState(false);

  useEffect(() => {
    const loadBundle = async () => {
      try {
        const bundle = await factory();
        setBundle(() => {
          return bundle.default;
        });
      } catch (err) {
        noticeError(err);

        setFatalError(true);
      }
    };
    loadBundle();
  }, [factory]);
  return {
    bundle,
    fatalError,
  };
};
