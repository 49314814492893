/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  CONFIRM_CHANGE_DEFAULT_ADDRESS,
  CONFIRM_DELETE_ADDRESS,
  RECEIVE_ADD_ADDRESS,
  RECEIVE_EDIT_ADDRESS,
} from "@state/addresses/types";
import { FINISH_STARTUP_LOADING } from "@state/application/types";
import { TRACK_SAVED_COMMUNICATION_PREFERENCES } from "@state/communication-preferences/types";
import {
  RECEIVE_ASSOCIATE_GIFT_CARD,
  TRACK_ADD_GIFT_CARD_ATTEMPT,
  TRACK_ADD_GIFT_VOUCHER_ATTEMPT,
  TRACK_BUY_GIFT_VOUCHER,
  TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER,
  TRACK_OPEN_ADD_GIFT_CARD,
  TRACK_OPEN_ADD_GIFT_VOUCHER,
  TRACK_SUCCESSFUL_ADD_GIFT_CARD,
  TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER,
} from "@state/gift-cards-and-vouchers/types";
import { getOrderDetailsFromOrderReference } from "@state/orders/selectors";
import {
  OPEN_TRACK_HELP,
  OPEN_TRACK_PARCEL,
  PRODUCT_DETAILS,
  RECEIVE_CANCEL_ORDER,
  RECEIVE_ORDERS,
  REQUEST_ORDER_CANCEL_REASONS,
} from "@state/orders/types";
import { RECEIVE_CHANGE_PASSWORD } from "@state/password/types";
import {
  CONFIRM_ADD_PAYMENT_METHOD,
  CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD,
  CONFIRM_DELETE_PAYMENT_METHOD,
  CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE,
} from "@state/payment/types";
import { getPremierAnalytics } from "@state/premier/analytics";
import {
  PREMIER_CLICK_JOIN,
  PREMIER_FREE_TRIAL_CLICK_JOIN,
  PREMIER_SUBSCRIPTION_MANAGE,
} from "@state/premier/types";
import { CLICK_DOWNLOAD_RETURN_ASSET } from "@state/returns-history/types";
import {
  getReturnItems,
  getReturnLocationProviderName,
  getReturnOrderReference,
  getReturnReferenceId,
} from "@state/returns/selectors";
import { RECEIVE_RETURN_BOOKING, SELECT_LOCATION } from "@state/returns/types";
import { REQUEST_CONNECT_LINKED_ACCOUNT } from "@state/social-accounts/types";
import { CLICK_RENEW_SUBSCRIPTION } from "@state/user/types";

import {
  detectChangedFields,
  getDeliveryTypeCounts,
  getFromattedRetutningItems,
  getOrderFromOrderRef,
  getOrderHasDiscount,
  getOrderPaginationNumber,
  getRetutningItemsCount,
} from "./analyticsUtils";
import * as types from "./types";

const interactions = {
  cancelled: "cancelled",
  click: "click",
  deleted: "deleted",
  saved: "saved",
  submit: "submit",
};

const progress = {
  input: "input",
  start: "start",
  success: "success",
};

export function getPageInteractionPayload(type, state, data) {
  switch (type) {
    case FINISH_STARTUP_LOADING: {
      return {
        id: types.FINISH_STARTUP_LOADING,
      };
    }
    case REQUEST_ORDER_CANCEL_REASONS: {
      return {
        interaction: interactions.click,
        elementText: "cancel order",
        order: {
          status: getOrderFromOrderRef(state, data.payload.orderReference)
            .status,
        },
      };
    }
    case RECEIVE_CANCEL_ORDER: {
      return {
        interaction: interactions.cancelled,
        elementText: "cancel order",
        order: {
          status: getOrderFromOrderRef(state, data.payload.orderReference)
            .status,
          numberOfDeliveryGroups: getOrderFromOrderRef(
            state,
            data.payload.orderReference
          ).deliveryGroupsDetail.length,
        },
      };
    }
    case RECEIVE_ORDERS: {
      if (data.payload.loadMore) {
        const deliveryTypeCounts = getDeliveryTypeCounts(
          data.payload.orderSummaries
        );
        return {
          pageTitle: `my orders page ${getOrderPaginationNumber(state)}`,
          deliveryTypeCounts,
        };
      }
      break;
    }
    case "@@redux-form/START_SUBMIT":
      if (data.meta.form === "my-details") {
        return {
          interaction: "submit",
          elementText: "save changes",
          formFields: detectChangedFields(data, state),
        };
      }
      break;
    case RECEIVE_ADD_ADDRESS:
      return {
        interaction: interactions.saved,
        elementText: "add new address",
        address: {
          addressFinder: !!data.payload.addressFinderUsed,
          setDefault: {
            billing: data.payload.defaultBilling,
            delivery: data.payload.defaultDelivery,
          },
        },
      };
    case RECEIVE_EDIT_ADDRESS:
      return {
        interaction: interactions.saved,
        elementText: "edit address",
        address: {
          addressFinder: false,
          setDefault: {
            billing: data.payload.defaultBilling,
            delivery: data.payload.defaultDelivery,
          },
        },
      };
    case SELECT_LOCATION:
      return {
        context: "drop off change address",
        interaction: interactions.saved,
        elementText: "select",
      };
    case RECEIVE_RETURN_BOOKING: {
      return {
        channel2: "my orders",
        context: "place return",
        pageTitle: "place return",
        return: {
          itemsTotal: getRetutningItemsCount(getReturnItems(state)),
          method: "drop off",
          courier: getReturnLocationProviderName(state),
          isDiscountApplied: getOrderHasDiscount(
            getOrderDetailsFromOrderReference(state)(
              getReturnOrderReference(state)
            )
          ),
          orderId: getReturnOrderReference(state),
          products: getFromattedRetutningItems(getReturnItems(state)),
          returnId: getReturnReferenceId(state),
        },
      };
    }
    case CLICK_DOWNLOAD_RETURN_ASSET:
      return {
        context: "download asset",
        interaction: interactions.click,
        elementText: "download asset",
      };
    case CLICK_RENEW_SUBSCRIPTION:
      return {
        premierAutorenewals: false,
        premierStatus: "premier expiring",
      };

    case PREMIER_CLICK_JOIN: {
      return {
        premierAutorenewals: true,
        premierStatus: getPremierAnalytics(state),
        premierStep: "join",
      };
    }

    case PREMIER_FREE_TRIAL_CLICK_JOIN: {
      return {
        premierAutorenewals: true,
        premierStatus: getPremierAnalytics(state),
        premierStep: "join_trial",
      };
    }

    // case PREMIER_SUCCESSFUL_SIGNUP:
    //   return {
    //     id: types.PAGE_INTERACTION,
    //     payload: {
    //
    //       premierAutorenewals: true,
    //       premierStatus: getPremierAnalytics(state),
    //       premierStep: "successful"
    //     }
    //   };

    case PREMIER_SUBSCRIPTION_MANAGE: {
      return {
        premierAutorenewals: true,
        premierStatus: getPremierAnalytics(state),
        premierStep: "manage",
      };
    }

    case CONFIRM_DELETE_ADDRESS: {
      return {
        interaction: interactions.deleted,
        elementText: "delete",
      };
    }
    case CONFIRM_CHANGE_DEFAULT_ADDRESS: {
      return {
        interaction: "save address",
        elementText: data.payload.billingAddress
          ? "set as default billing address"
          : "set as default delivery address",
        address: {
          addressFinder: false,
          setDefault: {
            billing: data.payload.billingAddress,
            delivery: !data.payload.billingAddress,
          },
        },
      };
    }
    case RECEIVE_CHANGE_PASSWORD: {
      return {
        interaction: interactions.saved,
        elementText: "save password",
      };
    }
    case CONFIRM_ADD_PAYMENT_METHOD: {
      return {
        interaction: interactions.saved,
        elementText: "add new payment method",
        payment: {
          method: data.payload.id.toLowerCase(),
          setDefault: false,
        },
      };
    }
    case CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE: {
      const { payload = {} } = data;
      return {
        interaction: interactions.saved,
        elementText: "save card",
        payment: {
          method: "card",
          setDefault: payload.isDefault || false,
        },
      };
    }
    case CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD: {
      const { id, type, isNew } = data.payload;
      const method =
        type && type.toLowerCase() === "card"
          ? type.toLowerCase()
          : id.toLowerCase();
      return {
        interaction: interactions.saved,
        elementText: isNew ? "save card" : "set as default payment method",
        payment: {
          method,
          setDefault: true,
        },
      };
    }
    case CONFIRM_DELETE_PAYMENT_METHOD: {
      const { id, type, cardScheme } = data.payload;
      const method =
        type && type.toLowerCase() === "card"
          ? type.toLowerCase()
          : id.toLowerCase();
      return {
        interaction: interactions.deleted,
        elementText: "delete",
        payment: { method, cardScheme },
      };
    }
    case TRACK_SAVED_COMMUNICATION_PREFERENCES: {
      return {
        interaction: interactions.saved,
        elementText: "save preferences",
        formFields: {
          preferencesPreSave: data.payload.preferencesPreSave,
          preferencesPostSave: data.payload.preferencesPostSave,
        },
      };
    }
    case REQUEST_CONNECT_LINKED_ACCOUNT: {
      return {
        interaction: interactions.click,
        elementText: "connect",
        social: data.payload,
      };
    }
    case OPEN_TRACK_HELP: {
      return {
        interaction: interactions.click,
        elementText: "help",
        order: {
          status: getOrderFromOrderRef(state, data.payload.orderReference)
            .status,
        },
      };
    }
    case OPEN_TRACK_PARCEL: {
      return {
        interaction: interactions.click,
        elementText: data.payload.isSingleParcel
          ? "track order"
          : `track parcel ${data.payload.parcelNo}`,
        order: {
          status: getOrderFromOrderRef(state, data.payload.orderReference)
            .status,
          numberOfDeliveryGroups: getOrderFromOrderRef(
            state,
            data.payload.orderReference
          ).deliveryGroupsDetail.length,
        },
      };
    }
    case PRODUCT_DETAILS: {
      return {
        interaction: interactions.click,
        elementText: "product",
        product: {
          id: data.payload.id,
        },
      };
    }
    case RECEIVE_ASSOCIATE_GIFT_CARD: {
      return {
        interaction: interactions.saved,
        elementText: "save gift card",
        product: {
          progress: progress.success,
        },
      };
    }
    case TRACK_OPEN_ADD_GIFT_CARD: {
      return {
        interaction: interactions.click,
        elementText: "gift card",
      };
    }
    case TRACK_OPEN_ADD_GIFT_VOUCHER: {
      return {
        interaction: interactions.click,
        elementText: "gift voucher",
      };
    }
    case TRACK_SUCCESSFUL_ADD_GIFT_CARD: {
      return {
        interaction: interactions.saved,
        elementText: "save gift card",
        progress: progress.success,
      };
    }
    case TRACK_SUCCESSFUL_ADD_GIFT_VOUCHER: {
      return {
        interaction: interactions.saved,
        elementText: "save gift voucher",
        progress: progress.success,
      };
    }
    case TRACK_OPEN_ADD_GIFT_CARD_OR_VOUCHER: {
      return {
        interaction: interactions.click,
        elementText: "add gift card voucher",
        progress: progress.start,
      };
    }
    case TRACK_BUY_GIFT_VOUCHER: {
      return {
        interaction: interactions.click,
        elementText: "buy gift voucher",
        progress: progress.start,
      };
    }
    case TRACK_ADD_GIFT_CARD_ATTEMPT: {
      return {
        interaction: interactions.click,
        elementText: "gift card",
        progress: progress.input,
      };
    }
    case TRACK_ADD_GIFT_VOUCHER_ATTEMPT: {
      return {
        interaction: interactions.click,
        elementText: "gift voucher",
        progress: progress.input,
      };
    }
    default:
      return {
        id: undefined,
      };
  }
}
