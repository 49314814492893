import React from "react";

import classnames from "classnames";

import styles from "./index.css";

export type ButtonGroup = {
  children: React.ReactNode;
  flexDisplay?: boolean;
  centerContent?: boolean;
};

const ButtonGroup: React.FC<ButtonGroup> = ({
  children,
  flexDisplay,
  centerContent,
}) => (
  <div
    className={classnames(styles.verticalSpace, {
      [styles.blockDisplay]: !flexDisplay,
      [styles.flexDisplay]: flexDisplay,
      [styles.centerContent]: centerContent,
    })}
  >
    {children}
  </div>
);

export default ButtonGroup;
