import { handleActions } from "redux-actions";

import * as types from "./types";

const getDeliveryOptionName = (payload) => {
  if (payload) {
    return payload.deliveryCountryOptions.find(
      (deliveryOption) => deliveryOption.premierIsDefault
    ).deliveryOptionType.name;
  }
  return null;
};

const getDeliveryOptionPrice = (payload) => {
  if (payload) {
    return payload.deliveryCountryOptions.find(
      (deliveryOption) => deliveryOption.premierIsDefault
    ).price.value;
  }
  return null;
};

const reducer = handleActions(
  {
    [types.RECEIVE_DELIVERY_OPTIONS]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            fatalError: true,
            loaded: true,
            valid: false,
          }
        : {
            ...state,
            fatalError: false,
            loaded: true,
            name: getDeliveryOptionName(payload),
            price: getDeliveryOptionPrice(payload),
            valid: true,
          },
    [types.REQUEST_DELIVERY_OPTIONS]: (state) => ({
      ...state,
      loaded: false,
    }),
  },
  {
    fatalError: false,
    loaded: false,
    name: null,
    price: null,
    valid: false,
  }
);

export default reducer;
