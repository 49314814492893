import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDate, getMonth, getYear, parseISO } from "date-fns";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";
import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { clearApiErrors as clearApiErrorsCall } from "@state/application/actions";

const loadContent = () =>
  import(/* webpackChunkName: 'my-details' */ "./components/form");

type State = {
  user: {
    apiError: boolean;
    dateOfBirth: string;
    fatalError: boolean;
    loaded: boolean;
  };
};

const MyDetails = () => {
  const formatTranslation = useFormatTranslation();
  const pageNameKey = "ma_web_mydetails_title";

  const { bundle: MyDetailsForm } = useBundle(loadContent);

  const { apiError, fatalError, formInitialValues, loaded } = useSelector(
    (state: State) => {
      const { apiError, dateOfBirth, fatalError, loaded, ...details } =
        state.user;
      const dDateOfBirth = parseISO(dateOfBirth);
      const year = getYear(dDateOfBirth);
      const isDateValid = year >= getYear(new Date()) - 100;

      return {
        apiError,
        fatalError,
        formInitialValues: {
          ...details,
          dateOfBirth: {
            year: isDateValid ? year : null,
            month: isDateValid ? getMonth(dDateOfBirth) : null,
            day: isDateValid ? getDate(dDateOfBirth) : null,
          },
        },
        loaded,
      };
    }
  );

  const dispatch = useDispatch();

  const clearApiErrors = () => dispatch(clearApiErrorsCall());

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageContainer>
          <PageHeader
            title={pageNameKey}
            icon="details"
            description={
              formatTranslation("ma_web_mydetails_description") +
              " " +
              formatTranslation("ma_web_indicates_required_field") +
              "."
            }
          />
        </PageContainer>
      }
      pageId="my-details"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError}
    >
      {MyDetailsForm && (
        <MyDetailsForm
          apiError={apiError}
          clearApiErrors={clearApiErrors}
          initialValues={formInitialValues}
          loaded={loaded}
        />
      )}
    </Page>
  );
};

export default MyDetails;
