import React from "react";

import { Checkbox } from "@asosteam/asos-web-component-library-checkbox";

import { Translation } from "@components/translation";

import styles from "./index.css";

export interface CheckboxWithErrorProps {
  input: {
    name?: string;
    [key: string]: any;
  };
  errorId: string;
  hasError?: boolean;
  [key: string]: any;
}

const CheckboxWithError = ({
  errorId,
  input,
  hasError,
  ...props
}: CheckboxWithErrorProps) => (
  <div data-auto-id={`cbwe-${input.name}`}>
    <div className={styles.selectContainer}>
      <Checkbox
        {...props}
        id={`${input.name}-id`}
        input={input}
        shouldFocus={hasError}
        ariaDescribedBy={`error-${input.name}`}
      />
    </div>
    {hasError && (
      <div className={styles.error} id={`error-${input.name}`}>
        <Translation id={errorId} />
      </div>
    )}
  </div>
);

export default CheckboxWithError;
