import React from "react";

import classnames from "classnames";

import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";

import { SmartLink } from "@components/smart-link";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface ConsolidatedReturnsMessageProps {
  hasNegativeTopMargin?: boolean;
  hasTopMargin?: boolean;
}

const ConsolidatedReturnsMessage = ({
  hasNegativeTopMargin = false,
  hasTopMargin = false,
}: ConsolidatedReturnsMessageProps) => {
  const formatTranslation = useFormatTranslation();

  return (
    <div
      className={classnames({
        [styles.ConsolidatedReturnsMessage]: true,
        [styles.negativeTopMargin]: hasNegativeTopMargin,
        [styles.topMargin]: hasTopMargin,
      })}
    >
      <NotificationBar type="alert">
        {formatTranslation("returns_consolidation_message_phase3")}
        <div className={styles.ConsolidatedReturnsMessageLink}>
          <SmartLink
            to="ExternalLink.Returns.ConsolidatedReturnsMessageFindOutMore"
            newWindow={true}
          >
            <Translation id="returns_consolidation_message_2_phase3" />
          </SmartLink>
        </div>
      </NotificationBar>
    </div>
  );
};

export default ConsolidatedReturnsMessage;
