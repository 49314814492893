import React from "react";
import { Form, Field } from "redux-form";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import Dropdown from "@components/dropdown";
import InputField from "@components/input-field";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { returnItems } from "@configuration/return-items";

import { createRange } from "@utils/array";

import { validateNotes, validateReason } from "./validate";

import styles from "./index.css";

const getFieldProperties = (fieldName, formName, formatTranslation) => {
  const fieldConfig = returnItems.fields[fieldName];
  return {
    ...fieldConfig,
    "data-auto-id": `field-${formName}-${fieldName}`,
    label: formatTranslation(fieldConfig.label),
    name: fieldName,
    placeholder:
      fieldConfig.placeholder &&
      formatTranslation(fieldConfig.placeholder, fieldConfig),
  };
};

const quantityOptions = (returnableQuantity) =>
  createRange(returnableQuantity + 1, 1).map((value) => ({
    text: value.toString(),
    value: value.toString(),
  }));

export const onChange =
  (variantId, orderReference, fieldName, action) => (event, newValue) =>
    action(variantId, orderReference, fieldName, newValue);

export const parseInteger = (value) => parseInt(value, 10);

export interface ReturnItemFormProps {
  form: string;
  handleSubmit: (arg0: any) => void;
  onFieldChange: (arg0: any, arg1: any, arg2: any, arg3: any) => void;
  orderReference: string;
  returnableQuantity: number;
  returnReasons: {
    text: string;
    value: number;
  }[];
  variantId: number;
}

const ReturnItemForm = (props: ReturnItemFormProps) => {
  const {
    form,
    handleSubmit,
    onFieldChange,
    orderReference,
    returnableQuantity,
    returnReasons,
    variantId,
  } = props;

  const formatTranslation = useFormatTranslation();
  return (
    <Form data-auto-id={form} onSubmit={handleSubmit}>
      <CardSection
        hasTopPadding={false}
        hasLeftPadding={false}
        hasBottomPadding={false}
      >
        <div className={styles.fields}>
          {returnableQuantity > 1 && (
            <Field
              {...getFieldProperties("quantity", form, formatTranslation)}
              component={Dropdown}
              hasMargin={false}
              parse={parseInteger}
              onChange={onChange(
                variantId,
                orderReference,
                "quantity",
                onFieldChange
              )}
              options={quantityOptions(returnableQuantity)}
              shortWidth
            />
          )}
          <Field
            {...getFieldProperties("reasonId", form, formatTranslation)}
            component={Dropdown}
            hasMargin={returnableQuantity > 1}
            parse={parseInteger}
            onChange={onChange(
              variantId,
              orderReference,
              "reasonId",
              onFieldChange
            )}
            options={returnReasons}
            validate={validateReason}
          />
          <Field
            {...getFieldProperties("notes", form, formatTranslation)}
            component={InputField}
            onBlur={onChange(variantId, orderReference, "notes", onFieldChange)}
            validate={validateNotes}
          />
        </div>
      </CardSection>
    </Form>
  );
};

export default ReturnItemForm;
