const getSocialAccounts = (state) => state.socialAccounts;

export const getIfLinkedAccountsErrored = (state) =>
  getSocialAccounts(state).fatalError;

export const getIfLinkedAccountsLoaded = (state) =>
  getSocialAccounts(state).loaded;

export const getLinkedAccounts = (state) =>
  getSocialAccounts(state).linkedAccounts;

export const getIfAttemptedToUnlinkCurrentLoginProvider = (state) =>
  getSocialAccounts(state).attemptedToUnlinkCurrentLoginProvider;

export const getCurrentLoggedInProvider = (state) =>
  getSocialAccounts(state).loginProvider;

export const hasLoaded = (state) => getSocialAccounts(state).loaded;
