import React from "react";

import classnames from "classnames";

import styles from "./index.css";

type ProductNameProps = {
  ariaLabel?: string;
  children: React.ReactNode;
  isMultiLine?: boolean;
  isCreateReturn?: boolean;
};

const ProductName: React.FC<ProductNameProps> = ({
  children,
  ariaLabel = undefined,
  isMultiLine = false,
  isCreateReturn = false,
}) => (
  <div
    aria-label={ariaLabel}
    data-auto-id="product-name"
    className={classnames(styles.name, {
      [styles.multiLine]: isMultiLine,
      [styles.createReturn]: isCreateReturn,
    })}
  >
    {children}
  </div>
);

export default ProductName;
