export const getGiftCardsAndVouchers = (state) => state.giftCardsAndVouchers;

export const getAddGiftCardForm = (state) =>
  getGiftCardsAndVouchers(state).addGiftCardForm;

export const getAddGiftCardFormFatalError = (state) =>
  getAddGiftCardForm(state).fatalError;

export const getAddGiftCardFormLoaded = (state) =>
  getAddGiftCardForm(state).loaded;

export const getAddGiftCardFormSavedNewGiftCard = (state) =>
  getAddGiftCardForm(state).savedNewGiftCard;

export const getApiErrors = (state) => getGiftCardsAndVouchers(state).apiErrors;

export const hasApiErrors = (state) => {
  const apiErrors = getApiErrors(state);

  return Object.keys(apiErrors).some((key) => apiErrors[key]);
};

export const getAddGiftVoucherForm = (state) =>
  getGiftCardsAndVouchers(state).addGiftVoucherForm;

export const getAddGiftVoucherFormLoaded = (state) =>
  getAddGiftVoucherForm(state).loaded;

export const getAddGiftVoucherFormFatalError = (state) =>
  getAddGiftVoucherForm(state).fatalError;

export const getAddGiftVoucherFormSavedNewGiftVoucher = (state) =>
  getAddGiftVoucherForm(state).savedNewGiftVoucher;

export const getVouchers = (state) => getGiftCardsAndVouchers(state).vouchers;

export const getGiftVouchersFatalError = (state) =>
  getGiftCardsAndVouchers(state).fatalError;

export const getGiftVouchersLoaded = (state) =>
  getGiftCardsAndVouchers(state).loaded;

export const getVoucherCount = (state) =>
  getGiftCardsAndVouchers(state).voucherCount;

export const isLoadingMoreVouchers = (state) =>
  getGiftCardsAndVouchers(state).loadingMore;

export const hasErroredLoadingMore = (state) => !!getApiErrors(state).loadMore;
