import React from "react";

import classnames from "classnames";

import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";

import styles from "./index.css";

export type SharedProps = {
  children: React.ReactNode;
};

export const Cell: React.FC<SharedProps> = ({ children, ...props }) => (
  <td {...props} className={styles.cell}>
    {children}
  </td>
);

export const Row: React.FC<SharedProps> = ({ children, ...props }) => (
  <tr {...props} className={styles.row}>
    {children}
  </tr>
);

export const Footer: React.FC<SharedProps> = ({ children, ...props }) => (
  <tfoot {...props} className={styles.footer}>
    <tr>
      <td colSpan={100}>
        <HorizontalRule />
      </td>
    </tr>
    {children}
  </tfoot>
);

export type TableProps = {
  returns?: boolean;
  children: React.ReactNode;
};

export const Table: React.FC<TableProps> = ({
  children,
  returns = false,
  ...props
}) => (
  <table
    {...props}
    className={classnames({
      [styles.table]: true,
      [styles.returns]: returns,
    })}
  >
    {children}
  </table>
);
