import React from "react";

import ErrorSection from "@components/error-section";
import ContentWrapper from "@components/layout/content-wrapper";

const AppStartupErrorSection = () => (
  <ContentWrapper>
    <ErrorSection />
  </ContentWrapper>
);

export default AppStartupErrorSection;
