import React from "react";

import { useFormatDate } from "@hooks/useFormatDate";

export const enhancedDateFormat = (format: string): string => {
  return format
    .replace("DD", "dd")
    .replace("YYYY", "yyyy")
    .replace("Do", "do")
    .replace("dddd", "EEEE");
};

export type FormattedDateProps = {
  date: Date;
  format: string;
};

const FormattedDate = ({ date, format }: FormattedDateProps) => {
  const formattedDate = useFormatDate();
  return <time>{formattedDate(enhancedDateFormat(format), date)}</time>;
};

export default FormattedDate;
