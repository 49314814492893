import React from "react";
import { Field } from "redux-form";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import InputField from "@components/input-field";
import RetryErrorWrapper from "@components/retry-error-wrapper";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../components/button";

import styles from "./index.css";

export interface AddGiftCardVoucherFormProps {
  hasApiError: boolean;
  fields: Array<{
    name: string;
    type?: string;
    maxLength: number;
  }>;
  handleSubmit: (onSubmit: () => void) => any;
  loaded: boolean;
  onSubmit: () => void;
  type: string;
}

const AddGiftCardVoucherForm = ({
  hasApiError,
  fields,
  handleSubmit,
  loaded,
  onSubmit,
  type,
}: AddGiftCardVoucherFormProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <form id={type} noValidate onSubmit={handleSubmit(onSubmit)}>
      <CardSection hasTopPadding={false}>
        <div className={styles.formFieldsWrapper}>
          {fields.map((field) => (
            <div
              id={`${type}_${field.name.toLowerCase()}`}
              key={`${type}_${field.name.toLowerCase()}`}
            >
              <Field
                component={InputField}
                disabled={!loaded}
                label={formatTranslation(
                  `ma_web_${type}_${field.name}`.toLowerCase()
                )}
                maxLength={field.maxLength}
                name={`${field.name.toLowerCase()}`}
                required
                type={field.type}
              />
            </div>
          ))}
        </div>
      </CardSection>
      <div className={styles.submitButtonWrapper}>
        <RetryErrorWrapper
          apiError={hasApiError}
          failureMessage={`ma_web_${type.toLowerCase()}_saveerror`}
        >
          <CardSection
            hasTopPadding={false}
            hasLeftPadding={false}
            hasRightPadding={false}
          >
            <div className={styles.buttonWrapper}>
              <Button type="submit" data-auto-id={`${type}SaveButton`}>
                {formatTranslation(`ma_web_${type.toLowerCase()}_save`)}
              </Button>
            </div>
          </CardSection>
        </RetryErrorWrapper>
      </div>
    </form>
  );
};

export default AddGiftCardVoucherForm;
