import { createAction } from "redux-actions";

import { getLanguage } from "@state/application/selectors";

import { getReturnDetails as getReturnDetailsFromApi } from "./api";
import * as types from "./types";

export const receiveReturnDetails = createAction(types.RECEIVE_RETURN_DETAILS);
export const requestReturnDetails = createAction(types.REQUEST_RETURN_DETAILS);

export const getReturnDetails =
  (returnReference, getReturnDetailsFn = getReturnDetailsFromApi) =>
  async (dispatch, getState, identity) => {
    dispatch(requestReturnDetails());

    try {
      const response = await getReturnDetailsFn(
        returnReference,
        getLanguage(getState()),
        identity
      );
      return dispatch(receiveReturnDetails(response));
    } catch (err) {
      err.returnReference = returnReference;
      return dispatch(receiveReturnDetails(err));
    }
  };
