import React from "react";

import styles from "./index.css";

type ProductWrapperProps = {
  children: React.ReactNode;
};

const ProductWrapper: React.FC<ProductWrapperProps> = ({ children }) => (
  <li className={styles.wrapper}>{children}</li>
);

export default ProductWrapper;
