import { Dispatch } from "react";
import { createAction } from "redux-actions";

import { Identity } from "src/@types/identity";

import { sendPageInteraction } from "@hooks/analytics/pageInteraction";
import { AnalyticsState } from "@hooks/analytics/pageLoadHook";

import { requestNotification } from "@state/application/actions";
import { getCurrency } from "@state/application/selectors";

import { redirectToUrl, addFragmentToUrl } from "@utils/url";

import { getPageLanguage } from "../../document-handlers";
import * as api from "./api";
import { hasLoaded, isPremierSuccessUrl } from "./selectors";
import * as types from "./types";

export const receivePremierSubscriptions = createAction(
  types.RECEIVE_PREMIER_SUBSCRIPTIONS
);

export const receivePremierStatus = createAction(types.RECEIVE_PREMIER_STATUS);

export const requestPremierSubscriptions = createAction(
  types.REQUEST_PREMIER_SUBSCRIPTIONS
);

export const setPremierSignUpPending = createAction(
  types.PREMIER_WAITING_ON_API
);

export const managePremierClick = createAction(
  types.PREMIER_SUBSCRIPTION_MANAGE
);

export const setRedirecting = createAction(types.PREMIER_REDIRECTING);
export const finishRedirecting = createAction(
  types.PREMIER_FINISHED_REDIRECTING
);

export const trackManagePremierCta = () => (dispatch: Dispatch<any>) => {
  dispatch(managePremierClick());
};

export const premierHandleRedirect =
  ({ subscriptionOptionId, redirectUrl, trialOfferId, direct }) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setRedirecting());
    dispatch(
      premierSignUp({
        subscriptionOptionId,
        trialOfferId,
        redirectUrl,
        direct,
      })
    );
  };

export const apiError = createAction(types.API_ERROR);

export const getPremierSubscriptions =
  (getPremierSubscriptionsFn = api.getPremierSubscriptions) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => object,
    identity: Identity
  ) => {
    const state = getState();
    if (hasLoaded(state)) {
      return null;
    }

    dispatch(requestPremierSubscriptions());

    const isSuccessUrl = isPremierSuccessUrl();

    try {
      const response = await getPremierSubscriptionsFn(identity);

      if (isSuccessUrl && Array.isArray(response) && response?.length === 0) {
        dispatch(setPremierSignUpPending());
      }

      return dispatch(receivePremierSubscriptions(response));
    } catch (err) {
      return dispatch(receivePremierSubscriptions(err));
    }
  };

export const getPremierStatus =
  (getPremierStatusFn = api.getPremierSubscriptionStatus) =>
  async (dispatch: Dispatch<any>, _, identity: Identity) => {
    try {
      const response = await getPremierStatusFn(identity);
      return dispatch(receivePremierStatus(response));
    } catch (err) {
      return dispatch(receivePremierStatus(err));
    }
  };

const flattenErrors = (error) =>
  Array.isArray(error.body)
    ? error.body.map((errorObj) => errorObj.errorCode)
    : [];

const handleSignupError = (direct, redirectUrl, fragment) =>
  redirectToUrl(
    redirectUrl
      ? addFragmentToUrl(redirectUrl, fragment)
      : direct
      ? window.ASOS.myAccount.entryPoint + "premier-delivery/"
      : ""
  );

export const premierSignUp =
  (
    {
      subscriptionOptionId = 1,
      trialOfferId = "",
      redirectUrl = "",
      direct = false,
    },
    createPremierSubscriptionSignUpSessionFn = api.createPremierSubscriptionSignUpSession
  ) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => AnalyticsState,
    identity: Identity
  ) => {
    try {
      const state = getState();

      const guid = identity.customer.customerGuid;
      const language = getPageLanguage();
      const currency = getCurrency(state);

      const response = await createPremierSubscriptionSignUpSessionFn(
        identity,
        {
          guid,
          language,
          currency,
          subscriptionOptionId,
          trialOfferId,
          redirectUrl,
        }
      );

      if (trialOfferId) {
        sendPageInteraction(types.PREMIER_FREE_TRIAL_CLICK_JOIN, state, {});
      } else {
        sendPageInteraction(types.PREMIER_CLICK_JOIN, state, {});
      }
      if (!Array.isArray(response)) {
        redirectToUrl(response.redirectUrl);
      }
    } catch (err) {
      dispatch(finishRedirecting());

      const errors = flattenErrors(err);

      if (errors.includes("CustomerAlreadyHasAnActivePremierSubscription")) {
        dispatch(
          requestNotification(
            "error",
            "premier_myaccount_joinasospremier_errormessage_alreadyactivecustomer"
          )
        );
        return handleSignupError(direct, redirectUrl, "already-activecustomer");
      }

      if (errors.includes("TrialOfferNotAvailable")) {
        dispatch(
          requestNotification(
            "error",
            "premier_myaccount_freetrialerrormessage"
          )
        );
        return handleSignupError(
          direct,
          redirectUrl,
          "trial-offernotavailable"
        );
      }

      dispatch(apiError(err));
      return handleSignupError(direct, redirectUrl, "signup-apierror");
    }
  };

export const premierAutoRenewalManage =
  (
    createPremierSubscriptionManagementSessionFn = api.createPremierSubscriptionManagementSession
  ) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => object,
    identity: Identity
  ) => {
    try {
      const lang = getPageLanguage();
      const response = await createPremierSubscriptionManagementSessionFn(
        identity,
        lang
      );
      if (!Array.isArray(response)) {
        window.location = response.redirectUrl as any as Location;
      }
    } catch (err) {
      return dispatch(apiError(err));
    }
  };
