export const CLEAR_RETURN_ATTEMPTS = "CLEAR_RETURN_ATTEMPTS";
export const CREATE_RETURN_FAILURE = "CREATE_RETURN_FAILURE";
export const END_CREATE_RETURN = "END_CREATE_RETURN";
export const RECEIVE_RETURN_REASONS = "RECEIVE_RETURN_REASONS";
export const RECEIVE_RETURN_CHARGE_MESSAGES = "RECEIVE_RETURN_CHARGE_MESSAGES";
export const RECEIVE_RETURN_REFERENCE = "RECEIVE_RETURN_REFERENCE";
export const REQUEST_RETURN_REASONS = "REQUEST_RETURN_REASONS";
export const RECEIVE_RETURNABLE_ITEMS = "RECEIVE_RETURNABLE_ITEMS";
export const REQUEST_RETURNABLE_ITEMS = "REQUEST_RETURNABLE_ITEMS";

export const CLEAR_RETURNS = "CLEAR_RETURNS";

export const SET_REVIEWED_RETURN = "SET_REVIEWED_RETURN";
export const SET_REVIEWED_RETURN_ERROR = "SET_REVIEWED_RETURN_ERROR";
export const RESET_REVIEWED_RETURN_ERROR = "RESET_REVIEWED_RETURN_ERROR";

export const OPEN_RETURN_FORM = "OPEN_RETURN_FORM";
export const CLOSE_RETURN_FORM = "CLOSE_RETURN_FORM";

export const DELETE_RETURNING_ITEM = "DELETE_RETURNING_ITEM";
export const UPDATE_RETURNING_ITEM = "UPDATE_RETURNING_ITEM";

export const REQUEST_RETURN_REFERENCE = "REQUEST_RETURN_REFERENCE";
export const START_CREATE_RETURN = "START_CREATE_RETURN";
export const SET_NO_ITEMS_ERROR = "SET_NO_ITEMS_ERROR";

export const SEARCH_FOR_ADDRESS = "SEARCH_FOR_ADDRESS";
export const SELECT_PLACE = "SELECT_PLACE";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const RESET_RETURN_LOCATION = "RESET_RETURN_LOCATION";

export const REQUEST_GOOGLE_PLACES = "REQUEST_GOOGLE_PLACES";
export const RECEIVE_GOOGLE_PLACES = "RECEIVE_GOOGLE_PLACES";

export const CLEAR_GOOGLE_PLACES_DROP_OFF_ERRORS =
  "CLEAR_GOOGLE_PLACES_DROP_OFF_ERRORS";
export const CLEAR_GOOGLE_PLACES_DROP_OFF_RESULTS =
  "CLEAR_GOOGLE_PLACES_DROP_OFF_RESULTS";

export const REQUEST_DROP_OFF_LOCATIONS = "REQUEST_DROP_OFF_LOCATIONS";
export const RECEIVE_DROP_OFF_LOCATIONS = "RECEIVE_DROP_OFF_LOCATIONS";

export const ATTEMPT_RETURN_BOOKING = "ATTEMPT_RETURN_BOOKING";
export const REQUEST_RETURN_BOOKING = "REQUEST_RETURN_BOOKING";
export const RECEIVE_RETURN_BOOKING = "RECEIVE_RETURN_BOOKING";
