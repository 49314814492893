import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";

import appConfig from "@appConfig";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import { getDefaultBillingAddress } from "@state/addresses/selectors";
import { clearApiErrors as clearApiErrorsAction } from "@state/application/actions";
import {
  getPaymentMethodsAndOptions as getPaymentMethodsAndOptionsAction,
  addNewPaymentMethod as addNewPaymentMethodAction,
} from "@state/payment/actions";
import {
  getCustomerPayments,
  getPaymentOptions,
  getPaymentProcessorOptions,
} from "@state/payment/selectors";

const pageNameKey = "ma_web_addpaymentmethod_title";

const loadContent = () =>
  import(/* webpackChunkName: 'add-payment-method' */ "./content");

export const updatedPaymentProcessorOptions = (
  paymentProcessorOptions,
  defaultBillingAddress
) => {
  return appConfig.paymentMethodRestrictions
    ? paymentProcessorOptions.filter(
        (option) =>
          !appConfig.paymentMethodRestrictions[option.id]?.includes(
            defaultBillingAddress.countryCode
          )
      )
    : paymentProcessorOptions;
};

export const AddPaymentMethod: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    apiErrors,
    attemptingToAddServiceId,
    fatalError,
    loaded,
    defaultBillingAddress,
    paymentOptions,
    paymentProcessorOptions,
    wallet,
  } = useSelector((state: any) => {
    const dataLoaded =
      state.payment.walletLoaded &&
      state.payment.methodsLoaded &&
      state.addresses.loaded;
    const addingPaymentService =
      !!state.payment.attemptingToAddServiceId.length;

    const defaultBillingAddress = getDefaultBillingAddress(state);

    const paymentProcessorOptions = getPaymentProcessorOptions(state);

    return {
      addServiceFailed: state.payment.addServiceFailed,
      apiErrors: state.payment.apiErrors,
      attemptingToAddServiceId: state.payment.attemptingToAddServiceId,
      fatalError: state.payment.fatalError,
      loaded: dataLoaded || addingPaymentService,
      defaultBillingAddress,
      paymentOptions: getPaymentOptions(state),
      paymentProcessorOptions: updatedPaymentProcessorOptions(
        paymentProcessorOptions,
        defaultBillingAddress
      ),
      wallet: getCustomerPayments(state),
    };
  });

  const addNewPaymentMethod = (id: string) =>
    dispatch(addNewPaymentMethodAction(id));

  const lastPaymentProcessorOptionsLength = useRef<number>(
    paymentProcessorOptions.length
  );

  useEffect(() => {
    const walletUpdated =
      lastPaymentProcessorOptionsLength.current >
      paymentProcessorOptions.length;
    lastPaymentProcessorOptionsLength.current = paymentProcessorOptions.length;

    if (loaded && !fatalError && walletUpdated) {
      navigate("/payment-methods");
    }
  }, [paymentProcessorOptions, loaded, fatalError, navigate]);

  useEffect(() => {
    dispatch(clearApiErrorsAction());
    dispatch(getPaymentMethodsAndOptionsAction());
  }, []);

  const { bundle: Content } = useBundle(loadContent);

  if (loaded && !fatalError) {
    if (!defaultBillingAddress) {
      return <Navigate to="/payment-methods/missing-address" />;
    }

    if (paymentProcessorOptions.length === 0) {
      return <Navigate to="/payment-methods/add/card" />;
    }
  }

  const cardOption = paymentOptions.find((option) => option.id === "card");
  const hasExistingPaymentMethods = loaded && !fatalError && wallet.length;
  const backLink = hasExistingPaymentMethods ? "/payment-methods" : "/";

  const lastOption =
    paymentProcessorOptions[paymentProcessorOptions.length - 1];
  const lastId = lastOption && lastOption.id;
  const hasBottomPadding = lastId ? !apiErrors[lastId] : true;

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageContainer>
          <PageHeader
            dataAutoId="AddPaymentMethod"
            title={pageNameKey}
            previousPageLink={backLink}
            type="secondary"
            description={
              wallet.length
                ? "ma_web_addpaymentmethod_getstarted"
                : "ma_web_addpaymentmethod_nonesaved"
            }
            descriptionType={wallet.length ? null : "info"}
          />
        </PageContainer>
      }
      pageId="add-payment-method"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError}
      smallLoadingContainer
    >
      {Content && (
        <Content
          addNewPaymentMethod={addNewPaymentMethod}
          apiErrors={apiErrors}
          attemptingToAddServiceId={attemptingToAddServiceId}
          cardOption={cardOption}
          hasCardSectionPadding={hasBottomPadding}
          paymentProcessorOptions={paymentProcessorOptions}
        />
      )}
    </Page>
  );
};

export default AddPaymentMethod;
