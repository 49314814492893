export const getReturnsHistory = (state) => state.returnsHistory;

export const getLabelTokenErrorCount = (state) =>
  getReturnsHistory(state).labelTokenErrorCount;

export const getIfDownloadReturnLabelErrored = (state) =>
  getLabelTokenErrorCount(state) > 0;

export const getIfReturnLabelIsLoading = (state) =>
  getReturnsHistory(state).labelTokenIsLoading;

export const getLabelTokenFatalError = (state) =>
  getLabelTokenErrorCount(state) >= 3;

export const getLabelTokenReturnReference = (state) =>
  getReturnsHistory(state).labelTokenReturnReference;

export const getIfLoadingReturnLabelForReturnReference = (state) => (ref) =>
  getIfReturnLabelIsLoading(state) &&
  getLabelTokenReturnReference(state) === ref;

export const getIfDownloadingReturnLabelErroredForReturnReference =
  (state) => (ref) =>
    getIfDownloadReturnLabelErrored(state) &&
    getLabelTokenReturnReference(state) === ref;

export const getReturnedOrders = (state) => getReturnsHistory(state).returns;

export const getReturnedOrdersByReference = (state) => (returnReference) =>
  getReturnedOrders(state).find(
    (order) => order.returnReference === returnReference
  );
