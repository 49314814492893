export const PREMIER_NON_MEMBER = "premier non member";
export const PREMIER_ACTIVE = "premier active";
export const PREMIER_ACTIVE_RECURRING = "premier active - recurring";
export const PREMIER_EXPIRED = "premier expired";
export const PREMIER_EXPIRED_RECURRING = "premier expired - recurring";
export const PREMIER_EXPIRING = "premier expiring";
export const PREMIER_TERM_ENDING_RECURRING = "premier term ending - recurring";
export const PREMIER_INITIALLY_EXPIRED = "premier initially expired";
export const PREMIER_INITIALLY_EXPIRED_RECURRING =
  "premier initially expired - recurring";

export const PREMIER_FREE_TRIAL_ACTIVE =
  "premier free trial active - recurring";
export const PREMIER_FREE_TRIAL_EXPIRING =
  "premier free trial expiring - recurring";
export const PREMIER_NON_MEMBER_FREE_TRIAL_ELIGIBLE =
  "premier non member - free trial eligible";
