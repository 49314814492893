/* eslint-disable import/default */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useBundle } from "@hooks/useBundle";

import { clearApiErrors } from "@state/application/actions";
import { getLinkedAccounts } from "@state/social-accounts/actions";

const bundleLoader = () =>
  import(/* webpackChunkName: 'social-connect' */ "./content/index");

export const SocialConnect: React.FC = () => {
  const dispatch = useDispatch();

  const pageNameKey = "ma_web_socialconnect_title";
  const { bundle: SocialConnectContent, fatalError: bundleError } =
    useBundle(bundleLoader);

  const { socialConnectLoaded: loaded, fatalError } = useSelector(
    (state: { socialAccounts: { loaded: boolean; fatalError: boolean } }) => {
      return {
        socialConnectLoaded: state.socialAccounts.loaded,
        fatalError: state.socialAccounts.fatalError,
      };
    }
  );

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);

    const connectLinkedAccountResponse = {
      provider: queryString.get("provider"),
      result: queryString.get("result"),
    };
    dispatch(clearApiErrors());
    dispatch(getLinkedAccounts(connectLinkedAccountResponse));
  }, []);

  const pageReady = SocialConnectContent && loaded;

  return (
    <Page
      loaded={loaded}
      pageHeader={
        <PageHeader
          icon="social"
          title={pageNameKey}
          description="ma_web_socialconnect_getstarted"
          hasBottomPadding
        />
      }
      pageId="social-accounts"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError || bundleError}
    >
      {pageReady && <SocialConnectContent />}
    </Page>
  );
};

export default SocialConnect;
