export const notifyCustomerId = (customerId, customerGuid) => {
  window.NREUM.setCustomAttribute("customerId", customerId);
  window.NREUM.setCustomAttribute("customerGuid", customerGuid);
};

export const notifyAppVisible = () => {
  window.NREUM.setCustomAttribute("domain", window.location.hostname);
  window.NREUM.addPageAction("appVisible");
};

export const notifyAppReady = () => {
  window.NREUM.setCustomAttribute("domain", window.location.hostname);
  window.NREUM.addPageAction("appReady");
};

export const noticeError = (err) => {
  // Re-throw to add stack trace on Safari / IE
  try {
    throw err;
  } catch (e) {
    window.NREUM.noticeError(e);
  }
};

export const newInteraction = () => window.NREUM.interaction().save();

export const performanceTimerStart = (name) => {
  const performance = window.performance;
  performance.mark(`${name}Start`);
};

export const performanceTimerEnd = (name) => {
  const performance = window.performance;
  const mark = performance.getEntriesByName(`${name}Start`, "mark");

  if (mark.length > 0) {
    performance.mark(`${name}Stop`);
    performance.measure(name, `${name}Start`, `${name}Stop`);
    const measures = performance.getEntriesByName(name, "measure");
    if (measures.length > 0) {
      const markerLoadTime = measures[0].duration;
      recordPerformanceTime(name, markerLoadTime);
      performance.clearMarks(`${name}Start`);
      performance.clearMarks(`${name}Stop`);
      performance.clearMeasures(name);
    }
  }
};

export const recordPerformanceTime = (name, durationMs) => {
  if (window.NREUM) {
    window.NREUM.setCustomAttribute("performanceTimerName", name);
    window.NREUM.setCustomAttribute("performanceDuration", durationMs);
    window.NREUM.addPageAction("performanceTimer");
  }
};
