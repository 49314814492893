import { handleActions } from "redux-actions";

import {
  RECEIVE_SUBSCRIPTION_OPTIONS,
  REQUEST_SUBSCRIPTION_OPTIONS,
} from "./types";

const isLanguageNotSupportedByStoreError = (payload) =>
  payload &&
  payload.body &&
  payload.body[0].errorCode === "LanguageNotSupportedByStore";

const isFatalError = (error, payload) =>
  !!(error && !isLanguageNotSupportedByStoreError(payload));

const reducer = handleActions(
  {
    [RECEIVE_SUBSCRIPTION_OPTIONS]: (state, { error, payload }) => ({
      ...state,
      ...(!error && payload),
      fatalError: isFatalError(error, payload),
      loaded: true,
    }),
    [REQUEST_SUBSCRIPTION_OPTIONS]: (state) => ({
      ...state,
      loaded: false,
      fatalError: false,
    }),
  },
  {
    loaded: false,
    fatalError: false,
    subscriptionOptions: [],
  }
);

export default reducer;
