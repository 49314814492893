import React from "react";

import queryString from "query-string";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const ReturnCode = () => {
  const formatTranslation = useFormatTranslation();
  const uri = queryString.parse(window.location.search).uri;
  const src = Array.isArray(uri) ? uri[0] : uri;

  return (
    <div className={styles.container}>
      <img src={src} alt={formatTranslation("returns_label_document_title")} />
    </div>
  );
};

export default ReturnCode;
