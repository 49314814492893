import request from "asos.myaccount.request";

const SUBSCRIPTION_OPTIONS_ENDPOINT =
  "commerce/subscriptionsoptions/v1/subscriptions";

export const getSubscriptionOptions = (
  language,
  store,
  country,
  currency,
  segmentsString,
  requestFn = request
) => {
  let options;

  if (segmentsString) {
    options = {
      headers: {
        "asos-c-segmentids": segmentsString,
      },
    };
  }

  return requestFn(
    `${SUBSCRIPTION_OPTIONS_ENDPOINT}?lang=${language}&store=${store}&country=${country}&currency=${currency}&showTrialOffers=true`,
    undefined,
    options
  );
};
