import React from "react";

import FormattedDate from "@components/formatted-date";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { toDateOnlyIgnoringUTC } from "@utils/date";

import styles from "./index.css";

export interface GiftVoucherStatusProps {
  cancellationDate?: string;
  cancelled?: boolean;
  debitable?: boolean;
  expired?: boolean;
  expiresAfter?: {
    days: number;
    months: number;
  };
  expiryDate?: string;
  lastDebitedOn?: string;
  redeemed?: boolean;
  remainingBalanceValue?: number;
}

const GiftVoucherStatus = ({
  cancellationDate = null,
  cancelled = false,
  debitable = true,
  expired = false,
  expiresAfter = null,
  expiryDate = null,
  lastDebitedOn = null,
  redeemed = false,
  remainingBalanceValue = 0,
}: GiftVoucherStatusProps) => {
  const voucherWillExpire = debitable && expiryDate !== null;
  const formatTranslation = useFormatTranslation();

  if (voucherWillExpire) {
    const voucherToExpireIn1Day =
      expiresAfter.days === 1 && expiresAfter.months === 0;

    if (voucherToExpireIn1Day) {
      return (
        <span>
          <span className={styles.expiringSoon}>
            {formatTranslation("ma_web_giftcardsandvouchers_expiresin1day")}
          </span>
          {" ("}
          <FormattedDate
            date={new Date(toDateOnlyIgnoringUTC(expiryDate))}
            format={formatTranslation("ma_web_generic_dateformat")}
          />
          {")"}
        </span>
      );
    }

    const voucherToExpireInXDays =
      expiresAfter.days > 1 && expiresAfter.months === 0;

    if (voucherToExpireInXDays) {
      return (
        <span>
          <span className={styles.expiringSoon}>
            {formatTranslation("ma_web_giftcardsandvouchers_expiresinxdays", {
              days: expiresAfter.days,
            })}
          </span>
          {" ("}
          <FormattedDate
            date={new Date(toDateOnlyIgnoringUTC(expiryDate))}
            format={formatTranslation("ma_web_generic_dateformat")}
          />
          {")"}
        </span>
      );
    }

    const voucherToExpireIn1Month = expiresAfter.months === 1;

    if (voucherToExpireIn1Month) {
      return (
        <span>
          {formatTranslation("ma_web_giftcardsandvouchers_expiresin1month")}
          {" ("}
          <FormattedDate
            date={new Date(toDateOnlyIgnoringUTC(expiryDate))}
            format={formatTranslation("ma_web_generic_dateformat")}
          />
          {")"}
        </span>
      );
    }

    const voucherToExpireInXMonths =
      expiresAfter.months > 1 && expiresAfter.months < 12;

    if (voucherToExpireInXMonths) {
      return (
        <span>
          {formatTranslation("ma_web_giftcardsandvouchers_expiresinxmonths", {
            months: expiresAfter.months,
          })}
          {" ("}
          <FormattedDate
            date={new Date(toDateOnlyIgnoringUTC(expiryDate))}
            format={formatTranslation("ma_web_generic_dateformat")}
          />
          {")"}
        </span>
      );
    }

    const voucherToExpireLater = expiresAfter.months >= 12;

    if (voucherToExpireLater) {
      return (
        <span>
          {formatTranslation("ma_web_giftcardsandvouchers_expires")}{" "}
          <FormattedDate
            date={new Date(toDateOnlyIgnoringUTC(expiryDate))}
            format={formatTranslation("ma_web_generic_dateformat")}
          />
        </span>
      );
    }
  }

  const voucherCancelled = cancelled && cancellationDate;

  if (voucherCancelled) {
    return (
      <span>
        {formatTranslation("ma_web_giftcardsandvouchers_cancelledon")}{" "}
        <FormattedDate
          date={new Date(cancellationDate)}
          format={formatTranslation("ma_web_generic_dateformat")}
        />
      </span>
    );
  }

  const voucherRedeemed =
    redeemed && lastDebitedOn && remainingBalanceValue === 0;

  if (voucherRedeemed) {
    return (
      <span>
        {formatTranslation("ma_web_giftcardsandvouchers_redeemedon")}{" "}
        <FormattedDate
          date={new Date(lastDebitedOn)}
          format={formatTranslation("ma_web_generic_dateformat")}
        />
      </span>
    );
  }

  const voucherHasExpired = expired && expiryDate;

  if (voucherHasExpired) {
    return (
      <span>
        {formatTranslation("ma_web_giftcardsandvouchers_expiredon")}{" "}
        <FormattedDate
          date={new Date(toDateOnlyIgnoringUTC(expiryDate))}
          format={formatTranslation("ma_web_generic_dateformat")}
        />
      </span>
    );
  }

  return null;
};

export default GiftVoucherStatus;
