import React from "react";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import FieldLabel from "@components/field-label";
import GiftCard from "@components/gift-card";
import Price from "@components/price/";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import {
  giftCard,
  giftVoucher,
  returnsVoucher,
} from "@configuration/voucher-types";

import GiftVoucherStatus from "../gift-voucher-status";
import ReturnsVoucherLink from "./returns-voucher-link";
import VoucherRestrictionMessage from "./voucher-restriction-message";

import styles from "./component.css";

export interface VoucherDetailsProps {
  cancellationDate?: string;
  cancelled: boolean;
  country: string;
  countryName?: string;
  currency: string;
  debitable: boolean;
  debitableForCountryAndCurrency: boolean;
  expired: boolean;
  expiresAfter?: {
    days: number;
    months: number;
  };
  expiryDate?: string;
  initialBalance: {
    text: string;
    value?: number;
  };
  language: string;
  lastDebitedOn?: string;
  maskedVoucherCode: string;
  openRestrictionModal?: () => void;
  openReturnsModal?: () => void;
  redeemed: boolean;
  remainingBalance: {
    text: string;
    value: number;
  };
  voucherType: string;
}

const VoucherDetails = ({
  cancellationDate = null,
  cancelled,
  country,
  countryName = "",
  currency,
  debitable,
  debitableForCountryAndCurrency,
  expired,
  expiresAfter = null,
  expiryDate = null,
  initialBalance,
  language,
  lastDebitedOn = null,
  maskedVoucherCode,
  openRestrictionModal,
  openReturnsModal,
  redeemed,
  remainingBalance,
  voucherType,
}: VoucherDetailsProps) => {
  const giftCardHeadings = {
    [giftCard]: "ma_web_giftcardsandvouchers_giftcard",
    [giftVoucher]: "ma_web_giftcardsandvouchers_giftvoucher",
    [returnsVoucher]: "ma_web_giftcardsandvouchers_returnsvoucher",
  };

  const formatTranslation = useFormatTranslation();

  return (
    <Card>
      <CardSection className={styles.cardSectionContent}>
        <div className={styles.headingSection}>
          <Heading sizing={LONDON_2} domElement="h2" isUppercase={true}>
            {formatTranslation(giftCardHeadings[voucherType])}
          </Heading>
          <Paragraph className={styles.status}>
            <GiftVoucherStatus
              cancellationDate={cancellationDate}
              cancelled={cancelled}
              debitable={debitable}
              expired={expired}
              expiresAfter={expiresAfter}
              expiryDate={expiryDate}
              lastDebitedOn={lastDebitedOn}
              redeemed={redeemed}
              remainingBalanceValue={remainingBalance.value}
            />
          </Paragraph>
          {voucherType === "Out of policy return" && (
            <ReturnsVoucherLink openReturnsModal={openReturnsModal} />
          )}
          <div className={styles.lineBreak}>
            <HorizontalRule />
          </div>
        </div>
        <div className={styles.giftCardWrapper}>
          <GiftCard
            value={remainingBalance.value}
            country={country}
            currency={currency}
            language={language}
            disabled={!debitable}
            voucherType={voucherType}
          />
        </div>

        <div className={styles.details}>
          <div className={styles.initialBalanceDetail}>
            <FieldLabel
              text={formatTranslation(
                "ma_web_giftcardsandvouchers_initialbalance"
              )}
            />
            <div>
              <Price
                currency={currency}
                language={language}
                value={initialBalance.value}
              />
            </div>
          </div>
          <div>
            <FieldLabel
              text={formatTranslation(
                "ma_web_giftcardsandvouchers_vouchercode"
              )}
            />
            <div data-auto-id="giftVoucherCode">
              {maskedVoucherCode.toUpperCase()}
            </div>
          </div>
        </div>
        {!expired && !debitableForCountryAndCurrency && (
          <VoucherRestrictionMessage
            countryName={countryName}
            currency={currency}
            maskedVoucherCode={maskedVoucherCode}
            openRestrictionModal={openRestrictionModal}
            voucherType={voucherType}
          />
        )}
      </CardSection>
    </Card>
  );
};

export default VoucherDetails;
