import { handleActions } from "redux-actions";

import { CLEAR_API_ERRORS } from "../application/types";
import { removeAddress } from "./selectors";
import * as types from "./types";

const sortAddresses = (addresses) => {
  const deliveryAddress = addresses.find((a) => a.defaultDelivery);
  const billingAddress = addresses.find((a) => a.defaultBilling);
  const rest = addresses
    .filter((a) => !a.defaultBilling && !a.defaultDelivery)
    .sort((a, b) => {
      const createdA = new Date(a.created);
      const createdB = new Date(b.created);

      if (createdA.valueOf() === createdB.valueOf()) {
        return 0;
      }

      return createdA > createdB ? -1 : 1;
    });

  const defaults =
    billingAddress &&
    deliveryAddress &&
    billingAddress.addressId === deliveryAddress.addressId
      ? [deliveryAddress]
      : [deliveryAddress, billingAddress];

  return [...defaults, ...rest].filter((x) => !!x);
};

const updateAddresses = (state, { error }) => {
  if (error) {
    return {
      ...state,
      deletedAddress: false,
      fatalError: false,
      savedAddress: false,
      loaded: true,
      valid: true,
    };
  }

  return {
    ...state,
    apiErrors: {},
    deletedAddress: false,
    fatalError: false,
    savedAddress: true,
    tries: {},
    valid: false,
    addressFinder: {
      ...state.addressFinder,
      showSelectedAddressBox: false,
    },
  };
};

const reducer = handleActions(
  {
    [types.ADD_ADDRESS_BOOK_ERROR]: (state, { payload: { id, message } }) => ({
      ...state,
      addressBookError: { id, message },
    }),
    [types.ADD_ADDRESS_BOOK_WARNING]: (
      state,
      { payload: { id, message } }
    ) => ({
      ...state,
      addressBookWarning: { id, message },
    }),
    [types.CONFIRM_CHANGE_DEFAULT_ADDRESS]: (state, { error }) =>
      error
        ? {
            ...state,
            changingDefaultAddress: false,
          }
        : {
            ...state,
            addressBookError: undefined,
            addressBookWarning: undefined,
            changedDefaultAddress: true,
            changingDefaultAddress: false,
            valid: false,
          },
    [types.RECEIVE_ADDRESSES]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            fatalError: true,
            loaded: true,
            valid: false,
          }
        : {
            ...state,
            addressBook: sortAddresses(payload.addresses),
            deletedAddress: false,
            fatalError: false,
            loaded: true,
            valid: true,
            savedAddress: false,
          },
    [types.REQUEST_ADDRESSES]: (state) => ({
      ...state,
      loaded: false,
    }),
    [types.REQUEST_CHANGE_DEFAULT_ADDRESS]: (state) => ({
      ...state,
      changedDefaultAddress: false,
      changingDefaultAddress: true,
    }),
    [types.REQUEST_ADD_ADDRESS]: (state) => ({
      ...state,
      loaded: false,
    }),
    [types.RECEIVE_ADD_ADDRESS]: updateAddresses,
    [types.REQUEST_FIND_ADDRESS]: (state) => ({
      ...state,
      addressFinder: {
        ...state.addressFinder,
        loaded: false,
      },
    }),
    [types.RECEIVE_FIND_ADDRESS]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            apiErrors: {
              ...state.apiErrors,
              addressFinder: true,
            },
            addressFinder: {
              ...state.addressFinder,
              loaded: true,
            },
          }
        : {
            ...state,
            apiErrors: {
              ...state.apiErrors,
              addressFinder: false,
            },
            addressFinder: {
              ...state.addressFinder,
              ...payload,
              loaded: true,
            },
          },
    [types.REQUEST_RETRIEVE_ADDRESS]: (state) => ({
      ...state,
      addressFinder: {
        ...state.addressFinder,
        items: [],
        loaded: false,
      },
    }),
    [types.RECEIVE_RETRIEVE_ADDRESS]: (state, { error }) =>
      error
        ? {
            ...state,
            apiErrors: {
              ...state.apiErrors,
              addressFinder: true,
            },
            addressFinder: {
              ...state.addressFinder,
              showSelectedAddressBox: false,
              loaded: true,
            },
          }
        : {
            ...state,
            apiErrors: {
              ...state.apiErrors,
              addressFinder: false,
            },
            addressFinder: {
              ...state.addressFinder,
              showSelectedAddressBox: true,
              loaded: true,
            },
            showManualAddressFields: false,
          },
    [types.REQUIRE_DELETE_ADDRESS_ACCEPTANCE]: (
      state,
      { payload: { id, type } }
    ) => ({
      ...state,
      addressAcceptanceId: id,
      addressAcceptanceType: type,
      requireDeleteAcceptance: true,
    }),
    [types.CONFIRM_DELETE_ADDRESS_ACCEPTANCE]: (state) => ({
      ...state,
      requireDeleteAcceptance: false,
    }),
    [types.DENY_DELETE_ADDRESS_ACCEPTANCE]: (state) => ({
      ...state,
      requireDeleteAcceptance: false,
    }),
    [types.CANCEL_DELETE_ADDRESS_ACCEPTANCE]: (state) => ({
      ...state,
      requireDeleteAcceptance: false,
      deletingAddress: false,
    }),
    [types.REQUEST_DELETE_ADDRESS]: (state) => ({
      ...state,
      deletingAddress: true,
    }),
    [types.CONFIRM_DELETE_ADDRESS]: (state, { payload: { id } }) => ({
      ...removeAddress(state, id),
      apiErrors: {},
      deletedAddress: true,
      deletingAddress: false,
      addressBookError: undefined,
      addressBookWarning: undefined,
      tries: {},
      valid: false,
    }),
    [types.REQUEST_EDIT_ADDRESS]: (state) => ({
      ...state,
      loaded: false,
    }),
    [types.RECEIVE_EDIT_ADDRESS]: updateAddresses,
    [types.UNLOAD_ADDRESSES]: (state) => ({
      ...state,
      addressBookError: undefined,
      addressBookWarning: undefined,
      showManualAddressFields: false,
      addressFinder: {
        ...state.addressFinder,
        showSelectedAddressBox: false,
        breadCrumbs: [],
        items: [],
        receivedAddress: {},
      },
    }),
    [types.REMOVE_CRUMB]: (state, { payload }) => ({
      ...state,
      addressFinder: {
        ...state.addressFinder,
        breadCrumbs: state.addressFinder.breadCrumbs.filter(
          (crumb) => crumb.id !== payload.id
        ),
      },
    }),
    [types.ADD_CRUMB]: (state, { payload }) => {
      const breadCrumbs =
        payload.text && payload.id
          ? [...state.addressFinder.breadCrumbs, payload]
          : state.addressFinder.breadCrumbs;
      return {
        ...state,
        addressFinder: {
          ...state.addressFinder,
          breadCrumbs,
        },
      };
    },
    [types.CLEAR_BREAD_CRUMBS]: (state) => ({
      ...state,
      addressFinder: {
        ...state.addressFinder,
        breadCrumbs: [],
      },
    }),
    [types.SHOW_MANUAL_ADDRESS_FIELDS]: (state) => ({
      ...state,
      showManualAddressFields: true,
      addressFinder: {
        ...state.addressFinder,
        showSelectedAddressBox: false,
      },
    }),
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      apiErrors: {},
      fatalError: false,
      tries: {},
    }),
    [types.API_ERROR]: (state, { payload: { userAction } }) => {
      const { maxRetries, tries } = state;
      if (tries[userAction] >= maxRetries) {
        return {
          ...state,
          fatalError: true,
          loaded: true,
          apiErrors: {},
        };
      }
      return {
        ...state,
        fatalError: false,
        loaded: true,
        tries: {
          [userAction]: (tries[userAction] || 0) + 1,
        },
        apiErrors: {
          [userAction]: true,
        },
      };
    },
    [types.RESET_ADDRESS_FINDER_SEARCH]: (state) => ({
      ...state,
      addressFinder: {
        ...state.addressFinder,
        loaded: false,
        items: [],
      },
    }),
  },
  {
    addressBook: [],
    addressBookError: undefined,
    addressBookWarning: undefined,
    apiErrors: {},
    changedDefaultAddress: false,
    changingDefaultAddress: false,
    deletedAddress: false,
    deletingAddress: false,
    fatalError: false,
    loaded: false,
    maxRetries: 2,
    tries: {},
    requireDeleteAcceptance: false,
    valid: false,
    savedNewAddress: false,
    addressFinder: {
      showSelectedAddressBox: false,
      breadCrumbs: [],
      items: [],
      loaded: false,
    },
    savedAddress: false,
    showManualAddressFields: false,
  }
);

export default reducer;
