interface Service {
  serviceId: string;
  publishedFrom: string;
  [x: string]: any;
}

export const filterServices = (
  services: Service[],
  serviceIds: string[]
): Service[] =>
  services.filter((service) => serviceIds.includes(service.serviceId));
