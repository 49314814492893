export const REQUEST_CHANGE_DEFAULT_PAYMENT_METHOD =
  "REQUEST_CHANGE_DEFAULT_PAYMENT_METHOD";
export const CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD =
  "CONFIRM_CHANGE_DEFAULT_PAYMENT_METHOD";

export const REQUEST_PAYMENT_METHODS_OPTIONS =
  "REQUEST_PAYMENT_METHODS_OPTIONS";
export const RECEIVE_PAYMENT_METHODS_OPTIONS =
  "RECEIVE_PAYMENT_METHODS_OPTIONS";

export const REQUEST_DELETE_PAYMENT_METHOD = "REQUEST_DELETE_PAYMENT_METHOD";
export const CONFIRM_DELETE_PAYMENT_METHOD = "CONFIRM_DELETE_PAYMENT_METHOD";

export const REQUEST_ADD_PAYMENT_METHOD = "REQUEST_ADD_PAYMENT_METHOD";
export const CONFIRM_ADD_PAYMENT_METHOD = "CONFIRM_ADD_PAYMENT_METHOD";

export const REQUIRE_DELETE_PAYMENT_ACCEPTANCE =
  "REQUIRE_DELETE_PAYMENT_ACCEPTANCE";
export const CONFIRM_DELETE_PAYMENT_ACCEPTANCE =
  "CONFIRM_DELETE_PAYMENT_ACCEPTANCE";
export const DENY_DELETE_PAYMENT_ACCEPTANCE = "DENY_DELETE_PAYMENT_ACCEPTANCE";

export const ATTEMPT_DELETE_DEFAULT_PAYMENT_METHOD =
  "ATTEMPT_DELETE_DEFAULT_PAYMENT_METHOD";

export const UNLOAD_PAYMENT_METHODS = "UNLOAD_PAYMENT_METHODS";

export const ADD_PAYMENT_METHOD_ERROR = "ADD_PAYMENT_METHOD_ERROR";
export const ATTEMPT_SAVE_PAYMENT_CARD = "ATTEMPT_SAVE_PAYMENT_CARD";
export const CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE =
  "CONFIRM_SAVE_PAYMENT_CARD_ACCEPTANCE";

export const API_ERROR = "API_PAYMENT_ERROR";

export const REQUEST_ALL_PAYMENT_METHODS = "REQUEST_ALL_PAYMENT_METHODS";
export const RECEIVE_ALL_PAYMENT_METHODS = "RECEIVE_ALL_PAYMENT_METHODS";
